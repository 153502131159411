import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import { useTitle } from 'hooks/useTitle';

import { Stub } from 'components/Stub/desktop/bundle';

import { cnPermanentLinkScreen } from '../PermanentLinkScreen.cn';
import { PermanentLinkScreenProps } from '../PermanentLinkScreen.interface';
import { WithErrorProps } from './PermanentLinkScreen_error.interface';
import { i18nPermanentLinkScreen } from '../PermanentLinkScreen.i18n';

import './PermanentLinkScreen_error.css';

export const withError = withBemMod<WithErrorProps, PermanentLinkScreenProps>(
    cnPermanentLinkScreen(),
    { error: true },
    (PermanentLinkScreen): FC<WithErrorProps & PermanentLinkScreenProps> => (
        (props): ReactElement => {
            const {
                responseError,
            } = props;

            let {
                replacer,
            } = props;

            if (responseError) {
                let errorDescription = 'smth-went-wrong-description';

                if (responseError.code === 404) {
                    errorDescription = 'not-found-description';
                }

                useTitle([i18nPermanentLinkScreen(errorDescription)]);

                replacer = (
                    <Stub
                        type="noConnection"
                        title={String(responseError.code)}
                        description={i18nPermanentLinkScreen(errorDescription)}
                        className={cnPermanentLinkScreen('Stub')}
                    />
                );
            }

            return (
                <PermanentLinkScreen
                    {...props}
                    replacer={replacer}
                />
            );
        }
    ),
);
