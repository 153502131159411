import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import { cnDuration } from '../Duration';
import { DurationProps } from '../Duration.interface';

import './Duration_tone_gray.css';

export interface WithToneGrayProps extends DurationProps {
  tone?: 'gray'
}

export const withToneGray = withBemMod<WithToneGrayProps>(
    cnDuration(),
    { tone: 'gray' },
    (Duration): FC<WithToneGrayProps> => (props): ReactElement => (<Duration {...props} />),
);

export default withToneGray;
