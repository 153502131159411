import React, { ReactElement, useCallback } from 'react';
import { Button } from '@yandex-int/hr-components/Button/desktop/bundle';
import { cn } from '@bem-react/classname';
import i18n from '@yandex-int/i18n';
import * as keyset from '../../Clicker.i18n';
import { ClickerPage } from '../../Clicker.interface';

import './InstructionPage.css';

const cnInstructionPage = cn('InstructionPage');
const i18nClicker = i18n(keyset);

export interface InstructionPageProps {
    currentPage: ClickerPage;
    onChangePage: (page: ClickerPage, id?: string, env?: string) => void;
}

interface IPageInfoItem {
    next: ClickerPage;
    itemsCount: number;
    i18nSlug: string;
}

type IPageInfo = Record<ClickerPage, IPageInfoItem>

const PAGE_INFO: IPageInfo = {
    [ClickerPage.INSTRUCTION_INTRODUCTION]: {
        next: ClickerPage.INSTRUCTION_IMPORTANT,
        itemsCount: 4,
        i18nSlug: 'howItWorks',
    },
    [ClickerPage.INSTRUCTION_IMPORTANT]: {
        next: ClickerPage.INSTRUCTION_FINISH,
        itemsCount: 3,
        i18nSlug: 'please',
    },
    [ClickerPage.INSTRUCTION_FINISH]: {
        next: ClickerPage.START,
        itemsCount: 3,
        i18nSlug: 'whatCouldGoWrong',
    },
    [ClickerPage.MAIN]: { i18nSlug: '', itemsCount: 0, next: null },
    [ClickerPage.START]: { i18nSlug: '', itemsCount: 0, next: null },
};

export const InstructionPage = (props: InstructionPageProps): ReactElement => {
    const { currentPage, onChangePage } = props;

    const isIntroduction = currentPage === ClickerPage.INSTRUCTION_INTRODUCTION;
    const isImportant = currentPage === ClickerPage.INSTRUCTION_IMPORTANT;
    const isFinish = currentPage === ClickerPage.INSTRUCTION_FINISH;

    const { next, itemsCount, i18nSlug } = PAGE_INFO[currentPage];

    const onSkip = useCallback(() => onChangePage(ClickerPage.START), []);
    const onNextClick = useCallback(() => onChangePage(next), [next]);

    return (
        <>
            <div className={cnInstructionPage('Indicator')}>
                <div className={cnInstructionPage('IndicatorItem', { active: isIntroduction })} />
                <div className={cnInstructionPage('IndicatorItem', { active: isImportant })} />
                <div className={cnInstructionPage('IndicatorItem', { active: isFinish })} />
            </div>
            <div className={cnInstructionPage('Image', { type: i18nSlug })} />
            <div className={cnInstructionPage('Title')}>{i18nClicker(i18nSlug)}</div>
            <div className={cnInstructionPage('Info')}>
                { Array(itemsCount).fill('').map((_, i) => {
                    return (
                        <div key={i} className={cnInstructionPage('InfoItem')}>
                            <div className={cnInstructionPage('InfoEmoji')}>{i18nClicker(`${i18nSlug}Emoji${i + 1}`)}</div>
                            <div className={cnInstructionPage('InfoText')}>{i18nClicker(`${i18nSlug}Info${i + 1}`)}</div>
                        </div>
                    );
                }) }
            </div>
            <div className={cnInstructionPage('Buttons')}>
                <Button
                    className={cnInstructionPage('ButtonSkip')}
                    view="default"
                    size="l"
                    onClick={onSkip}
                >
                    {i18nClicker('skip')}
                </Button>
                <Button
                    className={cnInstructionPage('ButtonNext')}
                    view="action"
                    size="l"
                    onClick={onNextClick}
                >
                    {i18nClicker('nextInstruction')}
                </Button>
            </div>
        </>
    );
};
