import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    setTitle,
    RequestTitlePayload,
} from 'ducks/title';

const EM_DASH = '—';

/**
 * Выставляет title страницы в формате `payload[0] – payload[1] - ...`.
 * Может принимать любое количество аргументов.
 * Пример: `useTitle(['Хурал', 'Трансляции'])` выставит title для страницы "Хурал — Трансляции"
 *
 * @param payload
 */
export const useKhTitle = (payload: RequestTitlePayload[]): void => {
    const dispatch = useDispatch();

    const title = payload.join(` ${EM_DASH} `);

    useEffect(() => {
        dispatch(setTitle(title));
    }, [dispatch, title]);
};
