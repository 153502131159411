import { ApiResponseI } from './api.rxjs';
import HttpError from './httpError';

const DEFAULT_OPTIONS: RequestInit = {
    credentials: 'same-origin',
};
type IApiMethods = 'get' | 'post';

function buildInit(method: string, init?: RequestInit): RequestInit {
    return Object.assign({ method }, DEFAULT_OPTIONS, init || {});
}
type IApiRequest = <T>(url: string | URL, init?: RequestInit) => Promise<T>

const buildRequest = (method: IApiMethods): IApiRequest => <T>(
    url: string | URL,
    init?: RequestInit,
): Promise<T> =>
        fetch(String(url), buildInit(method, init))
            .then((response): Promise<ApiResponseI<T>> => {
                if (!response.ok) {
                    if (response.status === 401) {
                        window.location.href = `https://passport.yandex-team.ru/auth?retpath=${encodeURIComponent(window.location.href)}`;
                    } else throw new HttpError(response.status, response.statusText);
                }
                return response.json();
            })
            .then((data: ApiResponseI<T>): T => data.response_text as T)
            .catch((e): never => {
                throw new HttpError(e.code, e.message);
            });

const api = {
    get: buildRequest('get'),
    post: buildRequest('post'),
};

export default api;
