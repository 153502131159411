import { withBemMod, composeU } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import {
    MethodSelector as MethodSelectorBase,
    withTypeSelect,
} from 'components/MethodSelector';

import { cnParticipant } from '../Participant@desktop';
import { ParticipantProps } from '../Participant.interface';
import { WithMethodSelectProps } from './Participant_methodSelect.interface';
import './Participant_methodSelect@desktop.css';

const MethodSelector = composeU(
    withTypeSelect,
)(MethodSelectorBase);

export const withMethodSelect = withBemMod<WithMethodSelectProps, ParticipantProps>(
    cnParticipant(), { methodSelect: true }, (Participant): FC<WithMethodSelectProps & ParticipantProps> => (
        (props: WithMethodSelectProps & ParticipantProps): ReactElement => {
            const {
                onChangeSelectedMethod,
                value,
                options,
                changeSelectedDisabled,
            } = props;

            const select = (
                <MethodSelector
                    value={value}
                    options={options}
                    onChange={onChangeSelectedMethod}
                    className={cnParticipant('Select')}
                    disabled={changeSelectedDisabled}
                    size="s"
                    type="select"
                />
            );

            return (
                <Participant
                    {...props}
                    addon={select}
        />
            );
        }
    ),
);

export default withMethodSelect;
