import { withBemMod, composeU } from '@bem-react/core';
import React, { ReactElement, FC } from 'react';

import { IntrasearchSuggestion, SuggestionInvite } from 'components/Suggest/Suggest.interface';
import {
    Participant as ParticipantBase,
    withTypeRoom as withParticipantTypeRoom,
} from 'components/Participant/desktop';
import {
    cnSuggestItem,
    SuggestItemProps,
} from '../SuggestItem';

const Participant = composeU(withParticipantTypeRoom)(ParticipantBase);

export interface WithTypeRoomProps {
  type?: 'room'
  suggest?: IntrasearchSuggestion
}

export const withTypeRoom = withBemMod<WithTypeRoomProps, SuggestItemProps>(
    cnSuggestItem(),
    { type: 'room' },
    (SuggestItem): FC<WithTypeRoomProps & SuggestItemProps> =>
        (props: WithTypeRoomProps & SuggestItemProps): ReactElement => {
            const { suggest } = props;
            const {
                title,
                office_name: officeName,
                floor_name: floorName,
            } = suggest as SuggestionInvite;

            const subtitle = `${officeName}, ${floorName}`;
            const participant = (
                <Participant
                    type="room"
                    title={title}
                    subtitle={subtitle}
      />
            );
            return (
                <SuggestItem {...props}>
                    { participant }
                </SuggestItem>
            );
        },
);

export default withTypeRoom;
