import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';
import './Icon_size_m.css';

export interface WithSizeMProps {
  size?: 'm';
}

export const withSizeM = withBemMod<WithSizeMProps>(
    'Icon',
    { size: 'm' },
    (Icon): FC => ((props): ReactElement => (<Icon {...props} />)),
);

export default withSizeM;
