import { Action, createAction, handleActions } from 'redux-actions';
import { combineEpics, ofType, StateObservable } from 'redux-observable';
import { EMPTY, Observable, of, from } from 'rxjs';
import { catchError, map, mergeMap, switchMap, delay } from 'rxjs/operators';
import i18n from '@yandex-int/i18n';

import { notyError } from 'components/Notifications/Notifications.actions';
import { gotUser } from 'ducks/user';
import { getRandomUserLogin } from 'components/ClickerZoom/ClickerZoom.utils/getRandomUserLogin';
import { isApiError } from 'shared/lib/api/isApiError';
import { INotificationMessageType } from 'components/Notifications/Notifications.interface';
import {
    ChangeSlideType,
    ClickerEvent,
    ClickerMessage,
    ClickerZoomPage,
    SocketEventName,
    UserMessageCodes,
    WebSocketData,
    MessageCodes,
    MessageCode,
    ZoomDetails,
} from 'components/ClickerZoom/ClickerZoom.interface';
import { InjectedDependencies, StoreI } from '../store';
import * as keyset from '../../components/ClickerZoom/ClickerZoom.i18n';

const i18nClicker = i18n(keyset);
const MAX_RETRY = 5;

export interface ChangeSlidePayload {
    slide: ChangeSlideType;
    pin: string;
    zoomId: string;
}

export interface ChangePagePayload {
    page: ClickerZoomPage;
}

export interface DisconnectPayload {
    retry: boolean;
}

export interface SendMassagePayload {
    zoomId?: string;
    pin?: string;
    login?: string;
    event: ClickerEvent;
}

export interface ZoomDetailsLoadedPayload {
    meetingId: string;
    participantUUID: string;
    role: string;
}

export interface InitPayload {
    pin?: string;
    isZoomApp?: boolean;
}

export interface HostInitPayload {
    pin: string;
}

export interface ConnectPayload {
    pin?: string;
    zoomId?: string;
    login: string;
}

export type PayloadType = ChangeSlidePayload |
    ChangePagePayload |
    INotificationMessageType;

const INIT = 'vconf/ClickerZoom/INIT';
const CONNECT_SOCKET = 'vconf/ClickerZoom/CONNECT_SOCKET';
const CONNECTING_SOCKET = 'vconf/ClickerZoom/CONNECTING_SOCKET';
const CONNECTED_SOCKET = 'vconf/ClickerZoom/CONNECTED_SOCKET';
const DISCONNECT_SOCKET = 'vconf/ClickerZoom/DISCONNECT_SOCKET';
const RECEIVE_MESSAGE = 'vconf/ClickerZoom/RECEIVE_MESSAGE';
const SEND_MESSAGE = 'vconf/ClickerZoom/SEND_MESSAGE';
const SEND_AUTH_MESSAGE = 'vconf/ClickerZoom/SEND_AUTH_MESSAGE';
const CHANGE_SLIDE = 'vconf/ClickerZoom/CHANGE_SLIDE';
const CHANGE_PAGE = 'vconf/ClickerZoom/CHANGE_PAGE';
const LOAD_ZOOM_DETAILS = 'vconf/ClickerZoom/LOAD_ZOOM_DETAILS';
const LOADED_ZOOM_DETAILS = 'vconf/ClickerZoom/LOADED_ZOOM_DETAILS';
const CONNECT = 'vconf/ClickerZoom/CONNECT';

export const initAction = createAction<InitPayload>(INIT);
export const connectSocketAction = createAction(CONNECT_SOCKET);
export const connectingSocketAction = createAction<WebSocketData>(CONNECTING_SOCKET);
export const connectedSocketAction = createAction<Event>(CONNECTED_SOCKET);
export const disconnectSocketAction = createAction<DisconnectPayload>(DISCONNECT_SOCKET);
export const receiveMessageAction = createAction<ClickerEvent>(RECEIVE_MESSAGE);
export const sendMessageAction = createAction<SendMassagePayload>(SEND_MESSAGE);
export const sendAuthMessageAction = createAction<SendMassagePayload>(SEND_AUTH_MESSAGE);
export const changeSlideAction = createAction<ChangeSlidePayload>(CHANGE_SLIDE);
export const changePageAction = createAction<ChangePagePayload>(CHANGE_PAGE);
export const connectAction = createAction<ConnectPayload>(CONNECT);
export const loadZoomDetailsAction = createAction(LOAD_ZOOM_DETAILS);
export const loadedZoomDetailsAction = createAction<ZoomDetailsLoadedPayload>(LOADED_ZOOM_DETAILS);

export const idError = new Error(i18nClicker('idError'));
export const zoomDetailsError = new Error(i18nClicker('zoomDetailsError'));
export const socketError = new Error(i18nClicker('socketError'));
export const connectionClosedError = new Error(i18nClicker('connectionClosedError'));
export const serverUnavailableError = new Error(i18nClicker('serverUnavailableError'));

export interface ClickerZoomState {
    messages: Record<string, ClickerMessage>;
    userCodeMapping: Record<string, UserMessageCodes>;
    isLoading: boolean;
    retry: number;
    currentPage: ClickerZoomPage;
    zoom: ZoomDetails;
    meeting_title?: string;
}

export const initialState: ClickerZoomState = {
    messages: {},
    userCodeMapping: {},
    isLoading: false,
    retry: 0,
    currentPage: ClickerZoomPage.LOAD,
    zoom: null,
};

const messageCodes: MessageCodes = {
    [SocketEventName.RIGHT]: ['nextSlide', 'click', 'change'],
    [SocketEventName.LEFT]: ['prevSlide', 'early', 'back'],
};

const getRandomElementFrom = <T>(array: T[]): T => {
    const randomIndex = Math.floor(Math.random() * array.length);

    return array[randomIndex];
};

export const reducer = handleActions<ClickerZoomState, PayloadType>(
    {
        [CONNECTED_SOCKET]: state => ({
            ...state,
            isLoading: false,
            retry: 0,
        }),
        [DISCONNECT_SOCKET]: state => ({
            ...state,
            isLoading: true,
            retry: state.retry + 1,
        }),
        [LOADED_ZOOM_DETAILS]: (state, action: Action<ZoomDetailsLoadedPayload>) => ({
            ...state,
            zoom: { ...action.payload },
        }),
        [CHANGE_PAGE]: (state, action: Action<ChangePagePayload>) => ({
            ...state,
            messages: {},
            currentPage: action.payload.page,
        }),
        [RECEIVE_MESSAGE]: (state, action: Action<ClickerEvent>): ClickerZoomState => {
            const { id, event_name, time, login, meeting_title } = action.payload;

            if (event_name === SocketEventName.ROOM_IS_READY) {
                return {
                    ...state,
                    meeting_title,
                };
            }

            if (id !== undefined && (event_name === SocketEventName.RIGHT || event_name === SocketEventName.LEFT)) {
                const messageCode = state.userCodeMapping[login] && state.userCodeMapping[login][event_name] ||
                    getRandomElementFrom<MessageCode>(messageCodes[event_name]);

                return {
                    ...state,
                    messages: {
                        ...state.messages,
                        [id]: {
                            id,
                            eventName: event_name,
                            isProcessed: false,
                            timestamp: Number(new Date(time)),
                            login,
                            messageCode,
                        },
                    },
                    userCodeMapping: {
                        ...state.userCodeMapping,
                        [login]: {
                            ...state.userCodeMapping[login],
                            [event_name]: messageCode,
                        },
                    },
                };
            }

            if (id !== undefined && event_name === SocketEventName.ECHO && state.messages[id]) {
                return {
                    ...state,
                    messages: {
                        ...state.messages,
                        [id]: {
                            ...state.messages[id],
                            isProcessed: true,
                        },
                    },
                };
            }

            return state;
        },
    },
    initialState,
);

const initEpic = (
    action$: Observable<Action<InitPayload>>,
    state$: StateObservable<StoreI>,
    { userApi: { getUserData } }: InjectedDependencies,
) =>
    action$.pipe(
        ofType(INIT),
        switchMap(({ payload: { isZoomApp, pin } }) => {
            if (isZoomApp) {
                return of(loadZoomDetailsAction());
            }

            return from(getUserData()).pipe(
                mergeMap(result => {
                    if (pin) {
                        return of(
                            !isApiError(result) && gotUser(result),
                            connectSocketAction(),
                            connectAction({ pin, login: !isApiError(result) ? result.id : getRandomUserLogin() }),
                        );
                    }

                    return of(
                        !isApiError(result) && gotUser(result),
                        connectSocketAction(),
                        changePageAction({ page: ClickerZoomPage.START_HOST }),
                    );
                }),
            );
        }),
        catchError(() => {
            return of(
                notyError('data'),
            );
        }),
    );

const connectWsEpic = (
    action$: Observable<Action<void>>,
    _: StateObservable<StoreI>,
    { clickerZoomApi: { connectSocket } }: InjectedDependencies,
) =>
    action$.pipe(
        ofType(CONNECT_SOCKET),
        switchMap(() => {
            const wsData = connectSocket();

            return of(connectingSocketAction(wsData));
        }),
    );

const messageSubscriptionEpic = (
    action$: Observable<Action<WebSocketData>>,
    _: StateObservable<StoreI>,
    { clickerZoomApi: { logError, getAdditionalFromEvent } }: InjectedDependencies,
) =>
    action$.pipe(
        ofType(CONNECTING_SOCKET),
        switchMap(action => {
            return action.payload.webSocketSubject.pipe(
                map(receiveMessageAction),
                catchError((event: CloseEvent) => {
                    console.error(event);

                    const additional = getAdditionalFromEvent(event);
                    logError(socketError, 'messageSubscriptionEpic', additional);

                    return EMPTY;
                }),
            );
        }),
    );

const connectionCheckEpic = (action$: Observable<Action<WebSocketData>>) =>
    action$.pipe(
        ofType(CONNECTING_SOCKET),
        switchMap(action => {
            return action.payload.onOpenSubject.pipe(map(connectedSocketAction));
        }),
    );

const connectionFailEpic = (
    action$: Observable<Action<WebSocketData>>,
    state$: StateObservable<StoreI>,
    { clickerZoomApi: { logError, getAdditionalFromEvent } }: InjectedDependencies,
) =>
    action$.pipe(
        ofType(CONNECTING_SOCKET),
        switchMap(action => {
            return action.payload.onCloseSubject.pipe(map((event: CloseEvent) => {
                const additional = getAdditionalFromEvent(event);
                logError(connectionClosedError, 'connectionFailEpic', additional);

                const retry = state$.value.clicker.retry < MAX_RETRY;

                return disconnectSocketAction({ retry });
            }));
        }),
    );

const disconnectEpic = (
    action$: Observable<Action<DisconnectPayload>>,
    state$: StateObservable<StoreI>,
    { clickerZoomApi: { logError } }: InjectedDependencies,
) =>
    action$.pipe(
        ofType(DISCONNECT_SOCKET),
        switchMap(action => {
            const { retry } = action.payload;
            const delaySeconds = state$.value.clicker.retry * 1000;

            if (retry) {
                return of(connectSocketAction()).pipe(delay(delaySeconds));
            }

            return of(logError(serverUnavailableError, 'disconnectEpic'));
        }),
    );

const sendWsMessageEpic = (
    action$: Observable<Action<SendMassagePayload>>,
    state$: StateObservable<StoreI>,
    { clickerZoomApi: { getWsData, logError } }: InjectedDependencies,
) =>
    action$.pipe(
        ofType(SEND_MESSAGE),
        switchMap(action => {
            const { event } = action.payload;

            const wsData = getWsData();

            if (wsData) {
                wsData.webSocketSubject.next(event);
            } else {
                return of(
                    connectSocketAction(),
                    logError(socketError, 'sendWsMessageEpic'),
                );
            }

            return of();
        }),
    );

const connectEpic = (
    action$: Observable<Action<ConnectPayload>>,
) =>
    action$.pipe(
        ofType(CONNECT),
        switchMap(action => {
            const { pin, zoomId, login } = action.payload;

            return of(sendMessageAction({
                event: {
                    zoom_id: zoomId,
                    pin,
                    login,
                    event_name: SocketEventName.CLIENT_HANDSHAKE,
                },
            }));
        }),
    );

const receiveMessageEpic = (
    action$: Observable<Action<ClickerEvent>>,
) =>
    action$.pipe(
        ofType(RECEIVE_MESSAGE),
        switchMap(action => {
            const { event_name } = action.payload;

            if (event_name === SocketEventName.ROOM_IS_READY) {
                return of(
                    changePageAction({
                        page: ClickerZoomPage.MAIN,
                    }),
                );
            }

            if (
                event_name === SocketEventName.PIN_WRONG ||
                event_name === SocketEventName.ZOOM_WRONG ||
                event_name === SocketEventName.WAIT
            ) {
                return of(
                    changePageAction({
                        page: ClickerZoomPage.START_HOST,
                    }),
                );
            }

            return of();
        }),
    );

const changeSlideEpic = (
    action$: Observable<Action<ChangeSlidePayload>>,
): Observable<Action<PayloadType>> =>
    action$.pipe(
        ofType(CHANGE_SLIDE),
        mergeMap((action): Observable<Action<PayloadType>> => {
            const { slide, zoomId } = action.payload;

            const eventName = slide === ChangeSlideType.NEXT ? SocketEventName.RIGHT : SocketEventName.LEFT;
            const event = { event_name: eventName, time: new Date().toISOString(), zoomId };

            return of(sendMessageAction({ event }));
        }),
    );

const changePageEpic = (
    action$: Observable<Action<ChangePagePayload>>,
    $state: StateObservable<StoreI>,
    { clickerZoomApi: { getWsData, logError } }: InjectedDependencies,
): Observable<Action<PayloadType>> =>
    action$.pipe(
        ofType(CHANGE_PAGE),
        mergeMap((action): Observable<Action<PayloadType>> => {
            const { page } = action.payload;
            const { currentPage } = $state.value.clickerZoom;

            const startingManagement = page === ClickerZoomPage.MAIN && currentPage === ClickerZoomPage.START_HOST;
            const endingManagement = page === ClickerZoomPage.START_HOST && currentPage === ClickerZoomPage.MAIN;

            if (startingManagement || endingManagement) {
                const wsData = getWsData();

                if (!wsData) {
                    return of(
                        connectSocketAction(),
                        logError(socketError, 'managementEpic'),
                    );
                }

                const eventName = page === ClickerZoomPage.MAIN ?
                    SocketEventName.START_MANAGEMENT :
                    SocketEventName.END_MANAGEMENT;

                const event = { event_name: eventName, time: new Date().toISOString() };

                return of(sendMessageAction({ event }));
            }

            return EMPTY;
        }),
    );

const loadZoomDetailsEpic = (
    action$: Observable<Action<{}>>,
    state$: StateObservable<StoreI>,
    { clickerZoomApi: { loadZoomData, initZoomSdk } }: InjectedDependencies,
) =>
    action$.pipe(
        ofType(LOAD_ZOOM_DETAILS),
        mergeMap(() =>
            from(initZoomSdk()).pipe(
                mergeMap(() =>
                    from(loadZoomData()).pipe(
                        mergeMap(result => {
                            return of(
                                loadedZoomDetailsAction(result),
                                connectSocketAction(),
                                connectAction({
                                    zoomId: result.meetingId,
                                    login: getRandomUserLogin(),
                                }),
                            );
                        }),
                        catchError(() => {
                            return of(
                                notyError(zoomDetailsError.message + 'data'),
                            );
                        }),
                    ),
                ),
                catchError(() => of(
                    notyError(zoomDetailsError.message + 'config'),
                )),
            ),
        ),
    );

export const epic = combineEpics(
    initEpic,
    changeSlideEpic,
    changePageEpic,
    connectWsEpic,
    connectionCheckEpic,
    messageSubscriptionEpic,
    sendWsMessageEpic,
    connectionFailEpic,
    disconnectEpic,
    loadZoomDetailsEpic,
    connectEpic,
    receiveMessageEpic,
);

export const selectClickerZoom = (store: StoreI): ClickerZoomState => store.clickerZoom;
