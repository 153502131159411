export const ru = {
    'player-tumbler': 'Плеер Яндекс.Эфир',
    'chat-button': 'Перейти к чату',
    'all-streams-link': 'Все трансляции',
    'no-data-text': 'Нет данных',
    'show-calendar-awesome': 'Перейти ко встрече в календаре',
    'stream-title': 'Трансляция',
    'stream-error': 'Ошибка',
    'viewers-counter-title': 'Количество зрителей',
    player_type_protected: 'Защищенный поток',
    player_type_audio: 'Только звук',
    'rate-informer-title': 'Оцените качество трансляции',
    'rate-informer-textarea-placeholder': 'Напишите, пожалуйста, ваш отзыв',
    'rate-informer-success-title': 'Спасибо за ваш отзыв!',
    'rate-informer-error-title': 'При отправке произошла ошибка',
    'rate-informer-action-clear': 'Позже',
    'rate-informer-action-submit': 'Отправить',
    'stream-language-ru': 'RU',
    'stream-language-en': 'EN',
    'stream-language-title': 'Язык трансляции',
    'error.smth-went-wrong.title': 'Ой, что-то пошло не так...',
    'error.smth-went-wrong.description': 'Попробуйте создать обращение через жука',
    'error.not-found.title': 'Упс, эта трансляция уже прошла',
    'error.not-found.description': 'А может быть, вы ошиблись в адресе страницы',
    'error': 'Проблемка',
    'text-stream': 'Текстовая трансляция',
    'add-to-calendar': 'Добавить себе в календарь',
    'alt-settings': 'Настройки трансляции',
    'alt-viewers': 'Зрители',
    'button.troubleshooting': 'Возникла проблема с трансляцией',
    'solution.first': 'Убедитесь, что у вас стабильное соединение с сетью и включен звук',
    'solution.second': 'Перезагрузите ПК',
    'solution.third': 'Проверьте, что в вашем браузере разрешено воспроизведение защищенного контента (DRM)',
    'solution.fourth': 'Если не помогло, напишите нам через кнопку жучка на этой странице',
};
