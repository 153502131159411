import { cn } from '@bem-react/classname';
import i18n from '@yandex-int/i18n';
import React, { ReactElement } from 'react';

import { StreamsList } from './StreamsList/StreamsList.container';

import { StreamsScreenProps } from './StreamsScreen.interface';
import * as keyset from './StreamsScreen.i18n';
import './StreamsScreen.css';

const i18nStreamsScreen = i18n(keyset);

export const cnStreamsScreen = cn('StreamsScreen');

export const StreamsScreen = (props: StreamsScreenProps): ReactElement => {
    const {
        streams,
        upcomingStreams,
        replacer,
        className,
    } = props;

    return (
        <div className={cnStreamsScreen(null, [className])}>
            {
                replacer || (
                    <div className={cnStreamsScreen('Main')}>
                        <StreamsList
                            streams={streams}
                            title={i18nStreamsScreen('popular-current-streams')}
                        />
                        <StreamsList
                            streams={upcomingStreams}
                            title={i18nStreamsScreen('upcoming-streams')}
                        />
                    </div>
                )
            }
        </div>
    );
};
