import React, { ReactElement, FC } from 'react';
import { composeU, withBemMod } from '@bem-react/core';
import i18n from '@yandex-int/i18n';
import {
    Icon as IconBase,
    withTypeClose as withIconTypeClose,
} from '@yandex-lego/components/Icon/desktop';
import { Button as ButtonBase } from '@yandex-lego/components/Button/desktop/bundle';

import {
    withTypeDownload as withIconTypeDownload,
} from 'components/Icon';
import {
    withResponsive as withButtonResponsive,
} from 'components/Button';

import { WithTypeRecord } from './TableCell-Button_type_record.interface';
import { ButtonProps } from '../Button.interface';
import { cnTableCell } from '../../TableCell';
import * as keyset from '../../TableCell.i18n';
import './TableCell-Button_type_record.css';

const i18nTableCell = i18n(keyset);

const Icon = composeU(
    withIconTypeClose,
    withIconTypeDownload,
)(IconBase);

const Button = withButtonResponsive(ButtonBase);

const iconDownload = (iconClassName: string): ReactElement => <Icon type="download" size="m" className={iconClassName} />;
const iconClose = (iconClassName: string): ReactElement => <Icon type="close" className={iconClassName} />;

export const withTypeRecord = withBemMod<WithTypeRecord, ButtonProps>(
    cnTableCell('Button'),
    { type: 'record' },
    (ButtonBlock): FC<WithTypeRecord & ButtonProps> => (
        (props: WithTypeRecord & ButtonProps): ReactElement => {
            const {
                isDisabled,
                downloadRecordLink,
                downloadRecordText,
                onDeleteRecordClick,
            } = props;

            let addonAfter;

            if (!isDisabled) {
                addonAfter = (
                    <>
                        <Button
                            size="s"
                            view="pseudo"
                            pin="circle-circle"
                            icon={iconClose}
                            title={i18nTableCell('delete-record-title')}
                            responsive
                            width="max"
                            onClick={onDeleteRecordClick}
                            className={cnTableCell('DeleteRecordButton')}
                        >
                            {i18nTableCell('delete-record-title')}
                        </Button>
                    </>
                );
            }

            return (
                <ButtonBlock
                    {...props}
                    addonAfter={addonAfter}
                >
                    <Button
                        size="s"
                        view="pseudo"
                        pin="circle-circle"
                        disabled={isDisabled}
                        icon={iconDownload}
                        type="link"
                        url={downloadRecordLink}
                        target="_blank"
                        responsive
                        width="max"
                    >
                        {downloadRecordText}
                    </Button>
                </ButtonBlock>
            );
        }
    ),
);
