import { useEffect, useState } from 'react';

import { STATE, ResponseError } from '../KhAnnouncementScreen.interface';
import { isUpcomingEvent } from '../KhAnnouncementScreen.utils';

export const useScreenState = (isRequesting: boolean, id: string, responseError: ResponseError) => {
    const [state, setState] = useState<STATE>(STATE.LOADING);

    useEffect(() => {
        if (isRequesting) {
            setState(STATE.LOADING);
            return;
        }

        if (id) {
            setState(STATE.REDIRECT);
            return;
        }

        if (responseError) {
            if (isUpcomingEvent(responseError)) {
                setState(STATE.DISPLAY_NEXT_EVENT);
            } else {
                setState(STATE.ERROR);
            }
        }
    }, [responseError, id, isRequesting]);

    return state;
};
