import { composeU } from '@bem-react/core';
import { push } from 'connected-react-router';
import React, { FC, MouseEvent, ReactElement, useCallback, useState } from 'react';
import { connect } from 'react-redux';

import { usePlatformContext } from 'hooks';

import { StoreI } from 'ducks/store';
import { selectCurrentUser } from 'ducks/user';

import {
    StreamsListContainerProps,
    StreamsListContainerMapDispatchToProps,
    StreamsListContainerMapStateProps,
} from './StreamsList.interface';
import {
    getStreamListType,
    isSelection,
} from './StreamsList.util';
import { withTypeCurrent } from './_type/StreamsList_type_current';
import { withTypeUpcoming } from './_type/StreamsList_type_upcoming';
import { StreamsList as StreamsListViewBase } from './StreamsList';
import { MAX_STREAMS } from './StreamsList.constants';

const StreamsListView = composeU(
    withTypeUpcoming,
    withTypeCurrent,
)(StreamsListViewBase);

const StreamsListContainer: FC<StreamsListContainerProps> = (props: StreamsListContainerProps): ReactElement => {
    const {
        streams,
        isAdmin,
        title,
        push: pushAction,
    } = props;

    const platform = usePlatformContext();

    const [isAllStreamsVisible, setIsAllStreamsVisible] = useState<boolean>(false);
    const isShowAllButtonVisible = !isAllStreamsVisible && streams.length > MAX_STREAMS;

    const handleStreamPosterClick = useCallback((streamId: string): FunctionVoid => {
        return (): void => {
            pushAction(`/streams/${streamId}`);
        };
    }, [pushAction]);

    const handleShowAllButtonClick = useCallback((): void => {
        setIsAllStreamsVisible(true);
    }, []);

    const getNavigationCallback = useCallback((location: string): FunctionVoid => {
        return (): void => {
            pushAction(location);
        };
    }, [pushAction]);

    const handleActionsStreamPosterClick = useCallback((event: MouseEvent): void => {
        event.stopPropagation();
    }, []);

    const handleViewersCounterClick = useCallback((event: MouseEvent): void => {
        event.stopPropagation();
    }, []);

    const handleStreamPosterTitleTextClick = useCallback((event: MouseEvent): void => {
        if (isSelection()) {
            event.stopPropagation();
        }
    }, []);

    const handleStreamPosterDescriptionClick = useCallback((event: MouseEvent): void => {
        if (platform === 'desktop' || isSelection()) {
            event.stopPropagation();
        }
    }, [platform]);

    const handleHintClick = useCallback((event: MouseEvent): void => {
        event.stopPropagation();
    }, []);

    return (
        <StreamsListView
            type={getStreamListType(streams) as undefined}
            streams={streams as undefined}
            isAllStreamsVisible={isAllStreamsVisible}
            isShowAllButtonVisible={isShowAllButtonVisible}
            isAdmin={isAdmin}
            title={title}
            amount={streams.length}
            onStreamPosterClick={handleStreamPosterClick}
            onShowAllButtonClick={handleShowAllButtonClick}
            getNavigationCallback={getNavigationCallback}
            onActionsStreamPosterClick={handleActionsStreamPosterClick}
            onViewersCounterClick={handleViewersCounterClick}
            onStreamPosterTitleTextClick={handleStreamPosterTitleTextClick}
            onStreamPosterDescriptionClick={handleStreamPosterDescriptionClick}
            onHintClick={handleHintClick}
        />
    );
};

export const StreamsList = connect<StreamsListContainerMapStateProps, StreamsListContainerMapDispatchToProps>(
    (store: StoreI): StreamsListContainerMapStateProps => ({
        isAdmin: selectCurrentUser(store).is_admin,
    }),
    {
        push,
    },
)(StreamsListContainer);
