import { push } from 'connected-react-router';
import i18n from '@yandex-int/i18n';
import React, { FC, MouseEvent, ReactElement, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';

import throttle from 'lodash/throttle';

import { StoreI } from 'ducks/store';
import {
    selectActiveCalls,
    selectIsActiveCallsRequesting,
    selectIsShowAllCalls,
    requestActiveCalls,
    clearActiveCalls,
    setShowAllCalls,
} from 'ducks/activeCalls';
import { selectCurrentUser } from 'ducks/user';

import { usePlatformContext } from 'hooks';

import { notySuccess } from 'components/Notifications/Notifications.actions';

import * as keyset from './ActiveCalls.i18n';
import { ActiveCallsContainerProps, ActiveCallsMapStateToProps } from './ActiveCalls.interface';
import { ActiveCalls as ActiveCallsView } from './ActiveCalls';

const i18nActiveCalls = i18n(keyset);

const ActiveCallsContainer: FC<ActiveCallsContainerProps> = (props: ActiveCallsContainerProps): ReactElement => {
    const {
        isShowAllCalls,
        isActiveCallsRequesting,
        user,
        push: pushAction,
        requestActiveCalls: requestActiveCallsAction,
        clearActiveCalls: clearActiveCallsAction,
        setShowAllCalls: setShowAllCallsAction,
        notySuccess: notySuccessActions,
    } = props;

    const platform = usePlatformContext();

    useEffect((): FunctionVoid => {
        requestActiveCallsAction({
            showAll: isShowAllCalls,
        });

        return (): void => {
            clearActiveCallsAction();
        };
    }, [clearActiveCallsAction, isShowAllCalls, requestActiveCallsAction]);

    const handleNavigateToActiveCallScreenClick = useCallback((id: string, secret: string): FunctionVoid => {
        return (): void => {
            const params = new URLSearchParams({ secret });

            pushAction(`/active-call/${id}?${params}`);
        };
    }, [pushAction]);

    const getHandleRowClick = useCallback((id: string, secret: string): FunctionVoid => {
        return (): void => {
            if (platform === 'mobile') return;

            const selection = window.getSelection();

            if (selection.toString().length) return;

            const params = new URLSearchParams({ secret });
            pushAction(`/active-call/${id}?${params}`);
        };
    }, [platform, pushAction]);

    const handleCopyLink = useCallback(throttle((): void => {
        notySuccessActions(i18nActiveCalls('on-handle-copy-link'));
    }, 1000), []);

    const handleActiveCallParametersClick = useCallback((event: MouseEvent<HTMLDivElement>): void => {
        event.stopPropagation();
    }, []);

    const handleBubbleParticipantsClick = useCallback((event: MouseEvent<HTMLDivElement>): void => {
        event.stopPropagation();
    }, []);

    const handleTableCellActiveCallActionsClick = useCallback((event: MouseEvent<HTMLDivElement>): void => {
        event.stopPropagation();
    }, []);

    const handleShowAllChanged = useCallback(() => {
        setShowAllCallsAction(!isShowAllCalls);
    }, [isShowAllCalls, setShowAllCallsAction]);

    return (
        <ActiveCallsView
            {...props}
            onNavigateToActiveCallScreenClick={handleNavigateToActiveCallScreenClick}
            checkedAdminCheckbox={isShowAllCalls}
            isActiveCallsRequesting={isActiveCallsRequesting}
            isAdmin={user.is_admin}
            onCopyLink={handleCopyLink}
            onRowClick={getHandleRowClick}
            onActiveCallParametersClick={handleActiveCallParametersClick}
            onBubbleParticipantsClick={handleBubbleParticipantsClick}
            onTableCellActiveCallActionsClick={handleTableCellActiveCallActionsClick}
            onShowAllChanged={handleShowAllChanged}
        />
    );
};

export const ActiveCalls = connect(
    (state: StoreI): ActiveCallsMapStateToProps => ({
        activeCalls: selectActiveCalls(state),
        isActiveCallsRequesting: selectIsActiveCallsRequesting(state),
        isShowAllCalls: selectIsShowAllCalls(state),
        user: selectCurrentUser(state),
    }), {
        push,
        requestActiveCalls,
        clearActiveCalls,
        setShowAllCalls,
        notySuccess,
    },
)(ActiveCallsContainer);

export default ActiveCalls;
