import { compose, withBemMod } from '@bem-react/core';
import React, { FC, ReactElement, useCallback, useState } from 'react';

import {
    Link as LinkBase,
    withThemePseudo as withLinkThemePseudo,
} from '@yandex-lego/components/Link/desktop';

import { cnTextCollapse, i18nTextCollapse } from '../TextCollapse';
import { TextCollapseProps } from '../TextCollapse.interface';
import { WithCollapse } from './TextCollapse_collapse.interface';
import './TextCollapse_collapse.css';

const Link = compose(
    withLinkThemePseudo,
)(LinkBase);

export const withCollapse = withBemMod<WithCollapse, TextCollapseProps>(
    cnTextCollapse(),
    { collapse: true },
    (TextCollapse): FC<WithCollapse & TextCollapseProps> => (
        (props): ReactElement => {
            const { className } = props;
            const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

            const onToggleButtonClick = useCallback(() => {
                setIsCollapsed(!isCollapsed);
            }, [isCollapsed]);

            const addonAfter = (
                <Link
                    theme="pseudo"
                    onClick={onToggleButtonClick}
                    className={cnTextCollapse('ToggleLink')}
                >
                    { isCollapsed ? i18nTextCollapse('toggle-button-text-show') : i18nTextCollapse('toggle-button-text-hide') }
                </Link>
            );

            return (
                <TextCollapse
                    {...props}
                    addonAfter={addonAfter}
                    className={cnTextCollapse({ collapsed: isCollapsed }, [className])}
                />
            );
        }
    ),
);
