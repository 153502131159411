import { compose, composeU, withBemMod } from '@bem-react/core';
import i18n from '@yandex-int/i18n';

import { Button as ButtonBase } from '@yandex-lego/components/Button/desktop/bundle';
import { Icon as IconBase } from '@yandex-lego/components/Icon';
import React, { FC, ReactElement } from 'react';
import {
    withResponsive as withButtonResponsive,
    withToneGreen as withButtonToneGreen,
} from 'components/Button';

import {
    withTypeCalendarAwesome as withIconTypeCalendarAwesome,
    withTypeHandset as withIconTypeHandset,
    withTypeArrowRight as withIconTypeArrowRight,
    withTypeZoom as withIconTypeZoom,
    withTypeZoomRect as withIconTypeZoomRect,
    withTypeTelemost as withIconTypeTelemost,
} from 'components/Icon';
import { CalendarEvent } from 'components/CalendarEvents/CalendarEvents.interface';

import { TableCellProps } from '../TableCell.interface';
import { cnTableCell } from '../TableCell';
import * as keyset from '../TableCell.i18n';
import './TableCell_type_eventCallActions.css';
import { usePlatformContext } from '../../../hooks';

export interface WithTypeEventCallActionsProps {
  type?: 'eventCallActions';
  event?: CalendarEvent;
  onCollectCallButtonClick?: (data: CalendarEvent) => FunctionVoid;
  onNavigateToCallButtonClick?: (id: string, secret: string) => FunctionVoid;
}

const i18nTableCell = i18n(keyset);

const Button = compose(
    withButtonToneGreen,
    withButtonResponsive,
)(ButtonBase);

const Icon = composeU(
    withIconTypeHandset,
    withIconTypeArrowRight,
    withIconTypeCalendarAwesome,
    withIconTypeZoom,
    withIconTypeZoomRect,
    withIconTypeTelemost,
)(IconBase);

const iconArrowRight = (iconClassName: string): ReactElement => <Icon type="arrowRight" className={iconClassName} />;
const iconHandset = (iconClassName: string): ReactElement => <Icon type="handset" size="m" className={iconClassName} />;
const iconCalendarAwesome = (iconClassName: string): ReactElement => (<Icon type="calendarAwesome" size="m" className={iconClassName} />);
const iconZoomRound = (iconClassName: string): ReactElement => (<Icon type="zoom" size="m" className={iconClassName} />);
const iconZoomRectangle = (iconClassName: string): ReactElement => (<Icon type="zoomRect" size="m" className={iconClassName} />);
const iconTelemost = (iconClassName: string): ReactElement => (<Icon type="telemost" size="m" className={iconClassName} />);

export const withTypeEventCallActions = withBemMod<WithTypeEventCallActionsProps, TableCellProps>(
    cnTableCell(),
    { type: 'eventCallActions' },
    (TableCell): FC<WithTypeEventCallActionsProps & TableCellProps> => (
        (props: WithTypeEventCallActionsProps & TableCellProps): ReactElement => {
            const {
                onNavigateToCallButtonClick,
                onCollectCallButtonClick,
                event,
            } = props;

            const platform = usePlatformContext();

            const isMobile = platform === 'mobile';

            const iconZoom = isMobile ? iconZoomRectangle : iconZoomRound;

            const isTelemostLink = (event.conferenceUrl || '').includes('telemost');
            const isZoomLink = (event.conferenceUrl || '').includes('zoom');

            const calendarEventUrl = new URL('https://calendar.yandex-team.ru/event/');

            calendarEventUrl.searchParams.set('event_id', `${event.id}`);

            return (
                <TableCell {...props}>
                    {
                        isZoomLink && (
                            <Button
                                size="s"
                                view="pseudo"
                                pin="circle-circle"
                                type="link"
                                url={event.conferenceUrl}
                                icon={iconZoom}
                                title={i18nTableCell('zoom-conf')}
                                width="max"
                                responsive
                                className={cnTableCell('Button')}
                            >
                                {i18nTableCell('zoom-conf')}
                            </Button>
                        )
                    }
                    {
                        isTelemostLink && (
                            <Button
                                size="s"
                                view={isMobile ? 'pseudo' : 'clear'}
                                type="link"
                                url={event.conferenceUrl}
                                icon={iconTelemost}
                                title={i18nTableCell('telemost-conf')}
                                width="max"
                                responsive
                                className={cnTableCell('Button', { type: 'telemost' })}
                            >
                                {i18nTableCell('telemost-conf')}
                            </Button>
                        )
                    }
                    {
                        event.id !== null && (
                            <Button
                                size="s"
                                view="pseudo"
                                pin="circle-circle"
                                type="link"
                                url={calendarEventUrl.toString()}
                                icon={iconCalendarAwesome}
                                title={i18nTableCell('show-calendar-awesome')}
                                width="max"
                                responsive
                                className={cnTableCell('Button')}
                            >
                                {i18nTableCell('show-calendar-awesome')}
                            </Button>
                        )
                    }
                    {
                        event.call && (
                            <Button
                                size="s"
                                view="action"
                                pin="circle-circle"
                                icon={iconArrowRight}
                                onClick={onNavigateToCallButtonClick(event.call.id, event.call.secret)}
                                width="max"
                                title={i18nTableCell('show-call-details')}
                                responsive
                                className={cnTableCell('Button')}
                            >
                                {i18nTableCell('show-call-details')}
                            </Button>
                        )
                    }
                    {
                        !event.call && (
                            <Button
                                size="s"
                                view="pseudo"
                                pin="circle-circle"
                                icon={iconHandset}
                                onClick={onCollectCallButtonClick(event)}
                                width="max"
                                responsive
                                title={i18nTableCell('collect-call')}
                                className={cnTableCell('Button')}
                            >
                                {i18nTableCell('collect-call')}
                            </Button>
                        )
                  }
                </TableCell>
            );
        }
    ),
);

export default withTypeEventCallActions;
