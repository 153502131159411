import React, { ReactElement, FC } from 'react';
import { withBemMod } from '@bem-react/core';
import { Button } from '@yandex-lego/components/Button/desktop/bundle';

import { cnStreamScreen } from '../../StreamScreen.cn';
import { i18nStreamScreen } from '../../StreamScreen';
import { StreamScreenRateInformerProps } from '../StreamScreen-RateInformer.interface';

import { WithActionsProps } from './StreamScreen-RateInformer_actions.interface';

export const withActions = withBemMod<WithActionsProps, StreamScreenRateInformerProps>(
    cnStreamScreen('RateInformer'),
    { actions: true },
    (StreamScreenRateInformer): FC<WithActionsProps & StreamScreenRateInformerProps> => (
        (props: WithActionsProps & StreamScreenRateInformerProps): ReactElement => {
            const {
                isActionButtonDisabled,
                isClearButtonDisabled,
                onClearButtonClick,
                onActionButtonClick,
            } = props;

            const actionButtons = (
                <>
                    <Button
                        onClick={onClearButtonClick}
                        view="clear"
                        size="m"
                        disabled={isClearButtonDisabled}
                    >
                        {i18nStreamScreen('rate-informer-action-clear')}
                    </Button>
                    <Button
                        onClick={onActionButtonClick}
                        view="action"
                        size="m"
                        disabled={isActionButtonDisabled}
                    >
                        {i18nStreamScreen('rate-informer-action-submit')}
                    </Button>
                </>
            );

            return (
                <StreamScreenRateInformer
                    {...props}
                    actionButtons={actionButtons}
                />
            );
        }
    ),
);
