import { withBemMod } from '@bem-react/core';

import { Spin } from '@yandex-lego/components/Spin/bundle';
import React, { FC, ReactElement } from 'react';

import { cnPermanentLinkScreen } from '../PermanentLinkScreen.cn';
import { PermanentLinkScreenProps } from '../PermanentLinkScreen.interface';

import './PermanentLinkScreen_loading.css';

export interface WithLoading {
    loading?: boolean;
}

export const withLoading = withBemMod<WithLoading, PermanentLinkScreenProps>(
    cnPermanentLinkScreen(),
    { loading: true },
    (PermanentLinkScreen): FC<WithLoading & PermanentLinkScreenProps> => (
        (props: WithLoading & PermanentLinkScreenProps): ReactElement => {
            const replacer = (
                <div className={cnPermanentLinkScreen('Spinner')}>
                    <Spin
                        size="l"
                        view="default"
                        progress
                    />
                </div>
            );

            return (
                <PermanentLinkScreen
                    {...props}
                    replacer={replacer}
                />
            );
        }
    ),
);
