import { IClassNameProps } from '@bem-react/core';
import { ReactElement } from 'react';
import { Stream, ErrorDetails as StreamErrorDetails } from 'ducks/stream';

export type ResponseError = Stream['responseError'];
export type EventDetails = StreamErrorDetails['event'];

export enum STATE {
    LOADING = 'loading',
    DISPLAY_NEXT_EVENT = 'display-next-event',
    ERROR = 'error',
    REDIRECT = 'redirect',
}

export enum ANNOUNCEMENT_TYPE {
    STREAM = 'stream',
    CHAT = 'chat',
}

export interface KhAnnouncementScreenContainerProps extends IClassNameProps {
    announcementType: ANNOUNCEMENT_TYPE;
}

export interface KhAnnouncementScreenProps extends IClassNameProps {
    replacer?: ReactElement;
    event?: EventDetails;
    announcementType: ANNOUNCEMENT_TYPE;
}

export interface KhAnnouncementScreenContainerMatchParams {
    id: string;
}
