import { getI18nLang } from '@yandex-int/i18n';
import { compose } from '@bem-react/core';

import { push, getLocation } from 'connected-react-router';
import React, { FC, MutableRefObject, ReactElement, useCallback, useEffect, useState } from 'react';

import { connect } from 'react-redux';

import { selectCurrentUser } from 'ducks/user';
import { StoreI } from 'ducks/store';
import { getIsHeaderVisible } from 'ducks/header';

import { HeaderDesktop as HeaderDesktopViewBase } from './Header@desktop';
import { withNoAuthDesktop } from './_noAuth/Header_noAuth@desktop';
import {
    HeaderContainerProps,
    HeaderMapDispatchToProps,
    HeaderStateToProps,
    MenuItemClickEvent,
} from './Header.interface';
import { menu } from './Header.constants';
import {
    appendStaticScript,
    removeStaticScript,
} from './Header.util';
import './Header.css';
const { scripts, suggestOptions } = require('./scripts');

const {
    notifier,
    settings,
    serpSuggest,
    toolsCrossNav,
    toolsHeaderSuggest,
    toolsMenu,
} = scripts;

const HeaderDesktopView = compose(
    withNoAuthDesktop,
)(HeaderDesktopViewBase);

const HeaderDesktopContainer: FC<HeaderContainerProps> = (props: HeaderContainerProps): ReactElement => {
    const {
        user,
        isHeaderVisible,
        location,
        noAuth,
        push: pushAction,
    } = props;

    const lang = getI18nLang();

    const [isSettingsVisible, setIsSettingsVisible] = useState<boolean>(false);
    const [isNewTabButtonPressed, setIsNewTabButtonPressed] = useState<boolean>(false);
    const [settingsRef, setSettingsRef] = useState<MutableRefObject<HTMLDivElement>>(React.createRef());

    const handleWindowKeyDown = useCallback((event: KeyboardEvent) => {
        if (event.code === 'MetaLeft' || event.code === 'ControlLeft') {
            setIsNewTabButtonPressed(true);
        }
    }, []);

    const handleWindowKeyUp = useCallback((event: KeyboardEvent) => {
        if (event.code === 'MetaLeft' || event.code === 'ControlLeft') {
            setIsNewTabButtonPressed(false);
        }
    }, []);

    const handleLogoServiceClick = useCallback((event: MouseEvent) => {
        event.preventDefault();
        const { url } = menu.main;

        if (isNewTabButtonPressed) {
            window.open(url, '_blank');
        } else {
            pushAction(url);
        }
    }, [isNewTabButtonPressed, pushAction]);

    const handleSettingsClick = useCallback(() => {
        setIsSettingsVisible(!isSettingsVisible);
    }, [isSettingsVisible]);

    const handleMenuClick = useCallback((event: MenuItemClickEvent) => {
        const pageKey = event.detail.type;
        const { url, target } = menu[pageKey];

        if (isNewTabButtonPressed || target === '_blank') {
            window.open(url, '_blank');
        } else {
            pushAction(url);
        }
    }, [isNewTabButtonPressed, pushAction]);

    const preventDefault = useCallback((event: MouseEvent) => {
        event.preventDefault();
    }, []);

    useEffect(() => {
        appendStaticScript(toolsCrossNav.desktop({ content: 'js' }), 'nav');
        appendStaticScript(notifier.desktop({ content: 'js' }), 'notifier');
        appendStaticScript(settings.desktop({ content: 'js' }), 'settings');
        appendStaticScript(toolsMenu.desktop({ content: 'js' }), 'menu');
        appendStaticScript(serpSuggest.desktop({ content: 'js' }), 'serp-suggest', false);
        appendStaticScript(toolsHeaderSuggest.desktop({ content: 'js', ctx: suggestOptions, lang }), 'suggest');

        return () => {
            removeStaticScript('nav');
            removeStaticScript('notifier');
            removeStaticScript('settings');
            removeStaticScript('menu');
            removeStaticScript('suggest');
        };
    }, [isHeaderVisible, location.pathname]);

    useEffect(() => {
        window.addEventListener('keydown', handleWindowKeyDown);
        window.addEventListener('keyup', handleWindowKeyUp);

        return () => {
            window.removeEventListener('keydown', handleWindowKeyDown);
            window.removeEventListener('keyup', handleWindowKeyUp);
        };
    }, [handleWindowKeyDown, handleWindowKeyUp]);

    useEffect(() => {
        const menuElement = document.querySelector('.tools-header__menu');

        menuElement && menuElement.querySelectorAll('.tools-menu__link').forEach(link => {
            link.addEventListener('click', preventDefault);
        });
    }, [isHeaderVisible, preventDefault, user, location]);

    useEffect(() => {
        const logoService = document.querySelector('.tools-header__logo-service');
        logoService && logoService.addEventListener('click', handleLogoServiceClick);
    }, [isHeaderVisible, preventDefault, user, location, handleLogoServiceClick]);

    useEffect(() => {
        const header = document.querySelector('.Header');
        header && header.addEventListener('settingsClick', handleSettingsClick, true);
        header && header.addEventListener('tools-menu:item-click', handleMenuClick, true);

        return () => {
            header && header.removeEventListener('settingsClick', handleSettingsClick, true);
            header && header.removeEventListener('tools-menu:item-click', handleMenuClick, true);
        };
    }, [isHeaderVisible, handleSettingsClick, handleMenuClick]);

    useEffect(() => {
        const settings = document.querySelector<HTMLDivElement>('.Header .tools-settings');
        setSettingsRef({
            current: settings,
        });
    }, [isHeaderVisible, handleSettingsClick, handleMenuClick]);

    const handleCloseSettings = useCallback(() => {
        setIsSettingsVisible(false);
    }, []);

    return (
        <HeaderDesktopView
            user={user}
            isHeaderVisible={isHeaderVisible}
            onSettingsClose={handleCloseSettings}
            isSettingsVisible={isSettingsVisible}
            settingsRef={settingsRef}
            noAuth={noAuth}
        />
    );
};

export const HeaderDesktop = connect<HeaderStateToProps, HeaderMapDispatchToProps>(
    (store: StoreI): HeaderStateToProps => ({
        user: selectCurrentUser(store),
        isHeaderVisible: getIsHeaderVisible(store),
        location: getLocation(store),
    }),
    {
        push,
    },
)(HeaderDesktopContainer);
