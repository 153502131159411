import { compose } from '@bem-react/core';
import React, { FC, ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    getUser,
    selectCurrentUser,
    selectError as selectUserError,
} from 'ducks/user';
import { setTitle } from 'ducks/title';

import { KhPageContainerProps } from './KhPage.interface';
import { i18n } from './KhPage.translations';

import { KhPage as KhPageBase } from './KhPage';
import { withLoading } from './_loading/KhPage_loading';
import { withError } from './_error/KhPage_error';

const KhPageView = compose(
    withError,
    withLoading,
)(KhPageBase);

export const KhPage: FC<KhPageContainerProps> = (props): ReactElement => {
    const {
        children,
        contentTheme,
    } = props;

    const dispatch = useDispatch();
    const user = useSelector(selectCurrentUser);
    const userError = useSelector(selectUserError);

    dispatch(setTitle(i18n('default-title')));

    useEffect(() => {
        dispatch(getUser());
    }, [dispatch]);

    const isDataRequested = user || userError;
    const isLoading = !isDataRequested;
    const isError = Boolean(userError);
    const isUserUnauthorizedError = isError && userError.code === 401;

    useEffect(() => {
        if (isUserUnauthorizedError) {
            window.location.href = `https://passport.yandex-team.ru/auth?retpath=${encodeURIComponent(window.location.href)}`;
        }
    }, [isUserUnauthorizedError]);

    return (
        <KhPageView
            loading={isLoading || isUserUnauthorizedError}
            error={Boolean(userError)}
            contentTheme={contentTheme}
        >
            {children}
        </KhPageView>
    );
};
