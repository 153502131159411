import { cn } from '@bem-react/classname';
import React, { ReactElement, useEffect, useState } from 'react';
import { UserAvatar } from '@yandex-int/hr-components/UserAvatar/bundle';
import { Icon } from '@yandex-int/hr-components/Icon/desktop/bundle';
import dayjs from 'dayjs';
import { i18nRaw } from '@yandex-int/i18n';

import { ClickerMessage, SocketEventName } from '../../Clicker.interface';
import * as keyset from '../../Clicker.i18n';

import './MessageCard.css';

const PROCESSING_TIMEOUT = 2000;

const cnMessageCard = cn('MessageCard');
const i18nClicker = i18nRaw(keyset);

export const MessageCard = (props: ClickerMessage): ReactElement => {
    const { messageCode, login, isProcessed, timestamp, eventName } = props;
    const [timeoutExceeded, setTimeoutExceeded] = useState(false);
    const hasError = !isProcessed && timeoutExceeded;

    const noErrorIcon = isProcessed ? <Icon glyph="checkCircle" /> : <Icon glyph="clock" />;
    const errorIcon = <Icon glyph="errorTriangle" />;
    const statusIcon = hasError ? errorIcon : noErrorIcon;

    const arrowIcon = eventName === SocketEventName.LEFT ? <Icon glyph="arrowLongLeft" /> : <Icon glyph="arrowLongRight" />;

    const time = dayjs(timestamp).format('HH:mm:ss');

    useEffect(() => {
        setTimeout(() => setTimeoutExceeded(true), PROCESSING_TIMEOUT);
    }, []);

    return (
        <div className={cnMessageCard()}>
            <UserAvatar
                className={cnMessageCard('Avatar')}
                icon={`https://center.yandex-team.ru/api/v1/user/${login}/avatar/85.jpg`}
                size="m"
            />
            <div className={cnMessageCard('Content')}>
                <div className={cnMessageCard('Message')}>{i18nClicker(messageCode)}</div>
                <div className={cnMessageCard('Time')}>{time}</div>
            </div>
            <div className={cnMessageCard('Icons')}>
                <div className={cnMessageCard('Icon', { arrow: true })}>{arrowIcon}</div>
                <div className={cnMessageCard('Icon', { hasError, isProcessed })}>{statusIcon}</div>
            </div>
        </div>
    );
};
