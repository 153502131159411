import { withBemMod } from '@bem-react/core';
import { compose } from '@bem-react/core';
import { Icon as IconBase } from '@yandex-lego/components/Icon/desktop';
import React, { FC, ReactElement } from 'react';
import {
    withTypeMessengerQ as withIconTypeMessengerQ,
    withSizeM as withIconSizeM,
} from 'components/Icon';

import { cnMethodSelector } from '../../MethodSelector';
import { MethodSelectorOptionContentProps } from '../MethodSelector-OptionContent.interface';
import { getMethods } from '../../MethodSelector.util';

export interface WithTypeMessengerQ {
    type?: 'messengerQ';
}

const Icon = compose(
    withIconSizeM,
    withIconTypeMessengerQ,
)(IconBase);

const methods = getMethods();

export const withTypeMessengerQ = withBemMod<WithTypeMessengerQ, MethodSelectorOptionContentProps>(
    cnMethodSelector('OptionContent'),
    { type: 'messengerQ' },
    (OptionContent): FC<WithTypeMessengerQ & MethodSelectorOptionContentProps> => (
        (props: WithTypeMessengerQ & MethodSelectorOptionContentProps): ReactElement => {
            const {
                method,
            } = props;

            return (
                <OptionContent {...props}>
                    <Icon
                        type="messengerQ"
                        size="m"
                    />
                    <span className={cnMethodSelector('OptionContentText')}>
                        {methods[method]}
                    </span>
                </OptionContent>
            );
        }
    ),
);
