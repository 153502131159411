import { Language, getI18nLang } from '@yandex-int/i18n';

const LSPrefix = 'StreamLanguage';

const setLanguageInLS = (lang: Language) => {
    localStorage.setItem(LSPrefix, lang);
};

const getLanguageFromLS = (): Language | null => {
    return localStorage.getItem(LSPrefix) as Language;
};

export const useStreamLanguage = (): [Language, (lang: Language) => void] => ([
    getLanguageFromLS() ?? getI18nLang(),
    setLanguageInLS,
]);
