import React, { ReactElement } from 'react';
import i18n from '@yandex-int/i18n';
import dayjs from 'dayjs';
import { push } from 'connected-react-router';
import { cn } from '@bem-react/classname';
import { connect } from 'react-redux';
import { Button } from '@yandex-lego/components/Button/desktop/bundle';

import { StoreI } from 'ducks/store';
import { setCallForm } from 'ducks/createCallForm';
import {
    get as getCallTemplates,
    selectData as selectCallTemplatesData,
} from 'ducks/templates';
import { selectCurrentUser } from 'ducks/user';

import {
    CallTemplateListDispatchToPropsI,
    ICallTemplateListProps,
    CallTemplateListStateToPropsI,
} from 'components/CallTemplateList/CallTemplateList.interface';
import Unit from 'components/Unit';
import CallTemplate from 'components/CallTemplate';
import { CallTemplate as CallTemplateI } from 'components/CallTemplate/CallTemplate.interface';

import {
    setCallTemplate,
} from 'ducks/templateForm';

import * as keyset from './CallTemplateList.i18n';

const i18nCallTemplateList = i18n(keyset);

const cnCallTemplateList = cn('CallTemplateList');

class CallTemplateList extends React.Component<ICallTemplateListProps> {
    public componentDidMount(): void {
        const { props } = this;

        props.getCallTemplates();
    }

  public navigateToTemplateScreen = (): void => {
      const { props } = this;

      props.push('/template');
  }

  public navigateToEditTemplateScreen = (template: CallTemplateI): () => void => {
      const { props } = this;

      return (): void => {
          props.push(`/template/${template.id}`);
      };
  }

  public handleSelectTemplate(template: CallTemplateI): () => void {
      const { props } = this;

      return (): void => {
          window.scroll({
              behavior: 'smooth',
              top: 0,
          });
          props.setCallForm({
              ...template,
              templateId: template.id,
              name: `${template.name} ${dayjs().format('DD.MM.YYYY')}`,
              isPrivateStream: false,
          });
          props.push('/create-call');
      };
  }

  public render(): ReactElement {
      const { props } = this;

      return (
          <div className={cnCallTemplateList()}>
              <h2 className={cnCallTemplateList('Title')}>{i18nCallTemplateList('title')}</h2>
              <Unit>
                  <div className={cnCallTemplateList('Widget')}>
                      <h3 className={cnCallTemplateList('Label')}>{i18nCallTemplateList('new-template')}</h3>
                      <Button
                          view="pseudo"
                          size="m"
                          onClick={this.navigateToTemplateScreen}
                      >
                          {i18nCallTemplateList('create')}
                      </Button>
                  </div>
              </Unit>
              { props.templates.map((template: CallTemplateI): ReactElement => (
                  <CallTemplate
                      key={template.id}
                      template={template}
                      onSelect={this.handleSelectTemplate(template)}
                      onEdit={this.navigateToEditTemplateScreen(template)}
                />
              )) }
          </div>
      );
  }
}

export default connect<CallTemplateListStateToPropsI, CallTemplateListDispatchToPropsI>(
    (store: StoreI): CallTemplateListStateToPropsI => ({
        templates: selectCallTemplatesData(store),
        user: selectCurrentUser(store),
    }),
    {
        getCallTemplates,
        push,
        setCallTemplate,
        setCallForm,
    },
)(CallTemplateList);
