import React, { FC, useCallback } from 'react';

import { Notifications } from '@yandex-int/hr-components/Notifications/Notifications';

import { useHrComponentsPlatform } from 'hooks/useHrComponentsPlatform';

import { IKhHeaderNotificationsProps } from './KhHeader-Notifications.types';

export const KhHeaderNotifications: FC<IKhHeaderNotificationsProps> = () => {
    const { platform } = useHrComponentsPlatform();

    const getUnviewed = useCallback(() => {
        return fetch('https://bell.yandex-team.ru/bell/api/v1/get-ticker?utm_source_service=internal_staff', {
            mode: 'cors',
            credentials: 'include',
        })
            .then(response => response.json())
            .then(({ unviewed_count }) => unviewed_count)
            .catch(() => 0);
    }, []);

    return (
        <Notifications
            platform={platform}
            getUnviewed={getUnviewed}
        />
    );
};
