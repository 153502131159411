import { composeU, IClassNameProps } from '@bem-react/core';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { HostsContextProvider } from '@yandex-int/hr-components/Context/HostsContext';
import { Icon as IconBase } from '@yandex-lego/components/Icon/desktop';
import { withInNewTabIcon } from '@yandex-int/hr-components/Icon/_glyph/InNewTab';

import { PageHeader } from 'shared/ui/components/PageHeader';
import { Button } from 'shared/ui/components/Button';

import { useHrComponentsPlatform } from 'hooks';

import { selectCurrentUser } from 'ducks/user';

import { cnKhHeader } from './KhHeader.cn';
import { i18n } from './KhHeader.translations';

import { KhHeaderLogo } from './Logo/KhHeader-Logo';
import { KhHeaderNotifications } from './Notifications/KhHeader-Notifications';

import './KhHeader.css';

export type KhHeaderProps = IClassNameProps;

const Icon = composeU(
    withInNewTabIcon,
)(IconBase);

const hosts = {
    staff: { host: 'staff.yandex-team.ru' },
    center: { host: 'center.yandex-team.ru' },
};

const inNewTabIconProvider = (className: string) => (
    <Icon glyph="inNewTab" className={className} />
);

export const KhHeader: FC<KhHeaderProps> = ({ className }) => {
    const { platform } = useHrComponentsPlatform();
    const user = useSelector(selectCurrentUser);

    const actionItems = (
        <>
            <Button
                url={'https://wiki.yandex-team.ru/askdir/'}
                view="clear"
                size="m"
                iconLeft={inNewTabIconProvider}
                type="link"
                target="_blank"
            >
                AskDir
            </Button>
            <Button
                url={'https://clubs.at.yandex-team.ru/mag/posts.xml?tag=14285'}
                view="clear"
                size="m"
                iconLeft={inNewTabIconProvider}
                type="link"
                target="_blank"
            >
                {i18n('last-khurals')}
            </Button>
            <KhHeaderNotifications />
        </>
    );

    const actions = [actionItems];

    return (
        <HostsContextProvider hosts={hosts}>
            <PageHeader
                platform={platform}
                login={user.id}
                desktopLogo={(<KhHeaderLogo />)}
                mobileLogo={(<KhHeaderLogo />)}
                className={cnKhHeader({}, [className])}
                actions={actions}
        />
        </HostsContextProvider>
    );
};
