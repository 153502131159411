import { withBemMod, compose } from '@bem-react/core';
import React, { ReactElement, FC } from 'react';

import { IntrasearchSuggestion, SuggestionArbitrary } from 'components/Suggest/Suggest.interface';
import {
    Participant as ParticipantBase,
    withTypeArbitrary as withParticipantTypeArbitrary,
} from 'components/Participant/desktop';
import {
    cnSuggestItem,
    SuggestItemProps,
} from '../SuggestItem';

import './SuggestItem_type_arbitrary.css';

const Participant = compose(withParticipantTypeArbitrary)(ParticipantBase);

export interface WithTypeArbitraryProps {
  type?: 'arbitrary'
  suggest?: IntrasearchSuggestion
}

export const withTypeArbitrary = withBemMod<WithTypeArbitraryProps, SuggestItemProps>(
    cnSuggestItem(),
    { type: 'arbitrary' },
    (SuggestItem): FC<WithTypeArbitraryProps & SuggestItemProps> =>
        (props: WithTypeArbitraryProps & SuggestItemProps): ReactElement => {
            const { suggest } = props;
            const { id } = suggest as SuggestionArbitrary;

            const participant = (
                <Participant
                    type="arbitrary"
                    title="Внешний номер"
                    subtitle={id}
      />
            );
            return (
                <SuggestItem {...props}>
                    <span className={cnSuggestItem('Title')}>
                        Возможно, это внешний контакт:
                    </span>
                    { participant }
                </SuggestItem>
            );
        },
);

export default withTypeArbitrary;
