import { composeU } from '@bem-react/core';
import React, { ReactElement, useCallback, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import Autosuggest from 'react-autosuggest';
import debounce from 'lodash/debounce';

import { usePlatformContext } from 'hooks';

import { notyWarning, notyError } from 'components/Notifications/Notifications.actions';
import {
    SuggestItem as BaseSuggestItem,
    withTypePerson,
    withTypeRoom,
    withTypeArbitrary,
} from 'components/SuggestItem';
import { IParticipantType } from 'components/Participant/Participant.interface';
import { Suggest as SuggestView } from './Suggest';
import { suggestionHasPhone, suggestArbitrary, layerMap } from './Suggest.util';
import { getSuggestions, getMeta } from './Suggest.api';
import { i18nSuggest } from './Suggest.i18n';
import {
    IntrasearchSuggestion,
    SuggestContainerDispatchToProps,
    SuggestContainerProps,
    SuggestionLayer,
    LayerName,
} from './Suggest.interface';

const SuggestItem = composeU(
    withTypeRoom,
    withTypePerson,
    withTypeArbitrary,
)(BaseSuggestItem);

const SuggestContainer = (props: SuggestContainerProps): ReactElement => {
    const {
        autofocusable,
        onChange,
        layers,
        shouldProcessArbitrary = false,
        notyWarning: notyWarningAction,
        notyError: notyErrorAction,
        placeholder,
        className,
    } = props;

    const platform = usePlatformContext();
    const debounceDelay = platform === 'mobile' ? 350 : 200;

    const [suggestions, setSuggestions] = useState<IntrasearchSuggestion[]>([]);

    const [value, setValue] = useState('');

    const onSuggestionsClearRequested = useCallback((): void => {
        setSuggestions([]);
    }, []);

    const onSuggestionsFetchRequested = useCallback(({ value: text }): void => {
        const locale = 'ru';

        getSuggestions(locale, layers, text)
            .then((receivedSuggestions): void => {
                let suggestionsToSet: IntrasearchSuggestion[] = [];

                layers.forEach((layer): void => {
                    suggestionsToSet = suggestionsToSet.concat(receivedSuggestions[layer].result);
                });

                const arbitraryMember = suggestArbitrary(text);

                if (shouldProcessArbitrary && arbitraryMember) {
                    suggestionsToSet.push(arbitraryMember);
                }

                setSuggestions(suggestionsToSet);
            })
            .catch(() => {
                setSuggestions([]);
                notyErrorAction(i18nSuggest('suggest-request-error'));
            });
    }, [layers, notyErrorAction, shouldProcessArbitrary]);
    const throttledOnSuggestionsFetchRequested = useCallback(debounce(onSuggestionsFetchRequested, debounceDelay), []);

    const getSuggestionValue = useCallback((suggestion): string => {
        return suggestion.title || suggestion.id;
    }, []);

    const onSuggestionSelected = useCallback((event, { suggestion }): void => {
        suggestion.click_urls.forEach((url: string) => {
            const image = document.createElement('IMG');
            image.setAttribute('src', url);
        });
        if (suggestionHasPhone(suggestion)) {
            const metaRequest = {
                id: suggestion.id,
                type: layerMap[suggestion.layer as LayerName] as IParticipantType,
            };

            getMeta([metaRequest])
                .then((response): void => {
                    setValue('');
                    onChange(response[0]);
                });
        } else {
            notyWarningAction(`${suggestion.title}: отсутствует телефон`);
        }
    }, [onChange, notyWarningAction]);

    const renderSuggestion = useCallback((suggestion): ReactElement => {
        return (
            <SuggestItem
                type={layerMap[suggestion.layer as SuggestionLayer | 'arbitrary'] as undefined}
                suggest={suggestion}
            />
        );
    }, []);

    const onInputChange = useCallback((event, { newValue }): void => {
        setValue(newValue);
    }, []);

    const inputProps = useMemo((): Autosuggest.InputProps<IntrasearchSuggestion> => {
        return {
            onChange: onInputChange,
            autoFocus: autofocusable,
            placeholder,
            value,
        };
    }, [onInputChange, autofocusable, placeholder, value]);

    return (
        <SuggestView
            suggestions={suggestions}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            onSuggestionsFetchRequested={throttledOnSuggestionsFetchRequested}
            getSuggestionValue={getSuggestionValue}
            onSuggestionSelected={onSuggestionSelected}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
            className={className}
    />
    );
};

export const Suggest = connect<{}, SuggestContainerDispatchToProps>(
    null,
    { notyWarning, notyError },
)(SuggestContainer);

export default Suggest;
