import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';
import React, { ReactElement, ReactNode } from 'react';

import './SuggestItem.css';

export interface SuggestItemProps extends IClassNameProps {
  children?: ReactNode,
}

export const cnSuggestItem = cn('SuggestItem');

export const SuggestItem = (props: SuggestItemProps): ReactElement => {
    const { className, children } = props;

    return (
        <div className={cnSuggestItem('', [className])}>
            { children }
        </div>
    );
};

export default SuggestItem;
