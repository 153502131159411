export const ru = {
    'title-calendar-awesome': 'Перейти к календарю',
    'show-share': 'Поделиться',
    'show-go-to-chat': 'Перейти к чату',
    'stream-information': 'Информация о трансляции',
    'stream-is-on': 'Транслируется',
    'show-TV': 'Открыть чат в TV режиме',
    'viewers-count': 'Количество зрителей',
    'started at': 'Началась',
    'ago': 'назад',
    'will-started-at': 'Начнется через',
};
