import { push } from 'connected-react-router';
import i18n from '@yandex-int/i18n';
import React, { ChangeEvent, FC, ReactElement, useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux';

import { useTitle } from 'hooks/useTitle';

import { StoreI } from 'ducks/store';
import {
    requestAppendCallsHistory,
    requestCallsHistory,
    deleteRecord,
} from 'ducks/callsHistory';
import { setCallForm } from 'ducks/createCallForm';
import { selectCurrentUser } from 'ducks/user';

import { PaginationPayload } from 'components/Table';
import { ActiveCallI } from 'components/ActiveCalls/ActiveCalls.interface';

import {
    CallsHistoryContainerProps,
    CallsHistoryMapStateToProps,
    CallsHistoryMapDispatchToProps,
} from './CallsHistory.interface';
import { CallsHistory as CallsHistoryView } from './CallsHistory';
import * as keyset from './CallsHistory.i18n';

const i18nCallsHistory = i18n(keyset);

const CallsHistoryContainer: FC<CallsHistoryContainerProps> =
  (props: CallsHistoryContainerProps): ReactElement => {
      const {
          data,
          isRequesting,
          user,
          setCallForm: setCallFormAction,
          requestAppendCallsHistory: requestAppendCallsHistoryAction,
          requestCallsHistory: requestCallsHistoryAction,
          deleteRecord: deleteRecordAction,
          push: pushAction,
      } = props;

      useTitle([i18nCallsHistory('calls-history-title')]);

      const [withRecords, setWithRecords] = useState<boolean>(false);

      const [isFirstMount, setIsFirstMount] = useState<boolean>(true);

      const [deleteRecordCallId, setDeleteRecordCallId] = useState<string>(null);

      const [isDeleteRecordModalVisible, setIsDeleteRecordModalVisible] = useState<boolean>(false);

      useEffect((): void => {
          setIsFirstMount(false);
      }, []);

      useEffect((): void => {
          requestCallsHistoryAction({
              page: 1,
              withRecords: false,
          });
      }, [requestCallsHistoryAction]);

      const handleConfirmDeleteRecord = useCallback(() => {
          deleteRecordAction({ id: deleteRecordCallId });
          setIsDeleteRecordModalVisible(false);
      }, [deleteRecordAction, deleteRecordCallId]);

      const handleCancelDeleteRecord = useCallback(() => {
          setIsDeleteRecordModalVisible(false);
      }, []);

      const handleDeleteRecordClick = useCallback((id: string) => {
          return () => {
              setDeleteRecordCallId(id);
              setIsDeleteRecordModalVisible(true);
          };
      }, []);

      const handleChangeWithRecordFilter = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
          const targetWithRecordsFilter = event.target.value === 'withRecords';
          setWithRecords(targetWithRecordsFilter);
          requestCallsHistoryAction({
              page: 1,
              withRecords: targetWithRecordsFilter,
          });
      }, [requestCallsHistoryAction]);

      const handleRequestMore = useCallback((payload: PaginationPayload): void => {
          requestAppendCallsHistoryAction({
              ...payload,
              withRecords,
          });
      }, [requestAppendCallsHistoryAction, withRecords]);

      const handleStubButtonClick = useCallback((): void => {
          pushAction('/');
      }, [pushAction]);

      const getOnCollectCallClick = useCallback((call: ActiveCallI): FunctionVoid => {
          return (): void => {
              setCallFormAction({
                  ...call,
                  templateId: null,
                  eventId: null,
                  isPrivateStream: false,
              });

              pushAction('/create-call');
          };
      }, [pushAction, setCallFormAction]);

      return (
          <CallsHistoryView
              data={data}
              isRequesting={isRequesting}
              onStubButtonClick={handleStubButtonClick}
              onRequestMore={handleRequestMore}
              onChangeWithRecordFilter={handleChangeWithRecordFilter}
              getOnCollectCallClick={getOnCollectCallClick}
              isFirstMount={isFirstMount}
              withRecords={withRecords}
              user={user}
              isDeleteRecordModalVisible={isDeleteRecordModalVisible}
              onConfirmDeleteRecord={handleConfirmDeleteRecord}
              onCancelDeleteRecord={handleCancelDeleteRecord}
              onDeleteRecordClick={handleDeleteRecordClick}
          />);
  };

export const CallsHistory = connect<CallsHistoryMapStateToProps, CallsHistoryMapDispatchToProps>(
    (state: StoreI): CallsHistoryMapStateToProps => ({
        isRequesting: state.callsHistory.isRequesting,
        data: state.callsHistory.calls,
        user: selectCurrentUser(state),
    }),
    {
        requestCallsHistory,
        requestAppendCallsHistory,
        setCallForm,
        deleteRecord,
        push,
    },
)(CallsHistoryContainer);
