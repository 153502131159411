import { createAction, handleActions, Action } from 'redux-actions';
import { StoreI } from 'ducks/store';

export type Theme = 'normal' | 'grey';

export type ThemeState = Theme;

export const initialState: ThemeState = 'normal';

export type SetThemePayload = ThemeState;

const SET_THEME = 'vconf/theme/SET_THEME';

export const setTheme = createAction<SetThemePayload>(SET_THEME);

export const reducer = handleActions<ThemeState, SetThemePayload>({
    [SET_THEME]: (state, action: Action<SetThemePayload>): ThemeState => {
        const {
            payload,
        } = action;

        return payload;
    },
}, initialState);

export const selectTheme = (store: StoreI): ThemeState => store.theme;
