import { compose, composeU, withBemMod } from '@bem-react/core';

import { Icon as IconBase } from '@yandex-lego/components/Icon';
import React, { FC, ReactElement } from 'react';
import {
    withSizeS as withIconSizeS,
    withSizeM as withIconSizeM,
    withTypeChain as withIconTypeChain,
} from 'components/Icon';

import { WithIconChainProps } from './CopyText_icon_chain.interface';
import { cnCopyText } from '../CopyText';
import { CopyTextProps } from '../CopyText.interface';

const Icon = compose(
    withIconTypeChain,
    composeU(
        withIconSizeM,
        withIconSizeS,
    ),
)(IconBase);

export const withIconChain = withBemMod<WithIconChainProps, CopyTextProps>(
    cnCopyText(),
    { icon: 'chain' },
    (CopyText): FC<WithIconChainProps & CopyTextProps> => (props: WithIconChainProps & CopyTextProps): ReactElement => {
        const {
            size = 's',
        } = props;

        return (
            <CopyText
                {...props}
                addon={(
                    <Icon
                        size={size as undefined}
                        type="chain"
                    />
                )}
            />
        );
    },
);

export default withIconChain;
