import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import { cnParticipantAvatar } from '../ParticipantAvatar';
import { ParticipantAvatarProps } from '../ParticipantAvatar.interface';
import { WithTypeAttendeesProps } from './ParticipantAvatar_type_attendees.interface';

import attendeesAvatar from './assets/attendees.svg';

export const withTypeAttendees = withBemMod<WithTypeAttendeesProps, ParticipantAvatarProps>(
    cnParticipantAvatar(),
    { type: 'attendees' },
    (ParticipantAvatar): FC<ParticipantAvatarProps & WithTypeAttendeesProps> => (
        (props: ParticipantAvatarProps & WithTypeAttendeesProps): ReactElement => {
            const image = (
                <img
                    className={cnParticipantAvatar('Image')}
                    src={attendeesAvatar}
                    alt="avatar"
                />
            );

            return (
                <ParticipantAvatar
                    {...props}
                    replacer={image}
                />
            );
        }
    ),
);
