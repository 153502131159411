import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';
import './Icon_size_s.css';

export interface WithSizeSProps {
  size?: 's';
}

export const withSizeS = withBemMod<WithSizeSProps>(
    'Icon',
    { size: 's' },
    (Icon): FC => ((props): ReactElement => (<Icon {...props} />)),
);

export default withSizeS;
