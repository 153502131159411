export const en = {
    'title-create': 'Create template',
    'title-edit': 'Edit template',
    'participants-title': 'Participants',
    'name-label': 'Name',
    'name-field-placeholder': 'Enter template name',
    'owners-label': 'Owners',
    'record-label-title': 'Record',
    'record-label-subtitle': 'The ability to view call in history',
    'stream-label-title': 'Stream',
    'stream-label-subtitle': 'The ability to monitor a call without the participation of',
    'stream-description-label-title': 'Stream description',
    'stream-description-label-subtitle': 'The description of the stream is displayed in a tooltip on the cover of the stream',
    'stream-description-placeholder': 'Enter the stream description',
    'stream-picture-label-title': 'Stream cover',
    'stream-picture-label-subtitle': 'Recommended image size - {posterSize}px',
    'stream-picture-mobile-alert': 'To edit the cover go to the desktop version of VConf',
    cancel: 'Cancel',
    'button-create': 'Create',
    'button-update': 'Update',
    'stub-title': 'The template is not available',
    'stub-description': 'There is no template with this id or you do not own this template',
    'back-link-title': 'To the home page',
    'participants-suggest-placeholder': 'Enter name, room or external number',
    'owners-suggest-placeholder': 'Enter name',
    'eventId-label': 'Event',
    'eventId-field-placeholder': 'Enter an id or a link to the event in the calendar',
    'copy-permanent-stream-link-text': 'Permanent link to the stream',
    'copy-permanent-chat-link-text': 'Permanent link to the chat',
    'permanent-link-copied': 'Link copied',
    'delete-template': 'Delete template',
    'template-deleting': 'Template deleting',
    'delete-for-all': 'Delete for all owners',
    'select-file': 'Select file',
};
