import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import { cnTextCollapse } from '../TextCollapse';
import { TextCollapseProps } from '../TextCollapse.interface';
import { WithTypeHtml } from './TextCollapse_type_html.interface';

export const withTypeHtml = withBemMod<WithTypeHtml, TextCollapseProps>(
    cnTextCollapse(),
    { type: 'html' },
    (TextCollapse): FC<WithTypeHtml & TextCollapseProps> => (
        (props): ReactElement => {
            const {
                html,
            } = props;

            return (
                <TextCollapse {...props}>
                    <div dangerouslySetInnerHTML={{ __html: html }} />
                </TextCollapse>
            );
        }
    ),
);
