import { compose, IClassNameProps, composeU } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import React, { ReactElement, RefObject, KeyboardEvent, MouseEventHandler, KeyboardEventHandler } from 'react';

import { ParticipantI } from 'components/Participant/Participant.interface';
import {
    Bubble as BaseBubble,
    withTypePerson,
    withTypeCounter,
    withTypeRoom,
    withTypeArbitrary,
    withTypeCms,
    withThemeClear,
    BubbleData,
    BubbleTypes,
} from 'components/Bubble';

export interface BubbleParticipantsProps extends IClassNameProps {
  theme?: 'clear'
  popup?: boolean
  forwardRef?: (ref: RefObject<HTMLDivElement>) => void
  onClick?: MouseEventHandler
  onKeyDown?: KeyboardEventHandler
  participants: ParticipantI[]
}

interface Bubble {
  data: BubbleData
  type: BubbleTypes
}

export interface ParticipantsAccumulator {
    room: Bubble[];
    person: Bubble[];
    arbitrary: Bubble[];
    cms: Bubble[];
}

const Bubble = compose(
    withThemeClear,
    composeU(
        withTypePerson,
        withTypeRoom,
        withTypeCounter,
        withTypeArbitrary,
        withTypeCms,
    ),
)(BaseBubble);

export const cnBubbleParticipants = cn('BubbleParticipants');

export const BubbleParticipants = (props: BubbleParticipantsProps): ReactElement => {
    const {
        participants,
        theme,
        className,
        forwardRef,
        onClick,
        onKeyDown = (): void => {},
    } = props;

    const bubbleParticipantsRef = React.useRef<HTMLDivElement>(null);
    const attachedRef = React.useRef<HTMLDivElement>(null);
    const [visibleCount, setVisibleCount] = React.useState(0);
    const [isExpanding, setIsExpanding] = React.useState<boolean>(false);

    const handleKeyDown = React.useCallback((event: KeyboardEvent<HTMLDivElement>): void => {
        if (event.key !== 'Tab') {
            onKeyDown(event);
        }
    }, [onKeyDown]);

    React.useEffect((): void => {
        setVisibleCount(0);
        setIsExpanding(true);
    }, [participants]);

    const bubbles = React.useMemo((): Bubble[] => {
        const { room, person, arbitrary, cms } = participants.reduce((acc, participant): ParticipantsAccumulator => {
            const currentType = participant.type;
            const temp = {
                type: currentType,
                data: { id: participant.id, title: participant.name },
            };

            acc[currentType].push(temp);

            return acc;
        }, { room: [], person: [], arbitrary: [], cms: [] });

        return [...room, ...person, ...arbitrary, ...cms];
    }, [participants]);

    const renderBubble = React.useCallback((...args): ReactElement => {
        const [
            bubble,
            isCircleBubbleFirst = false,
            shouldAttachRef = false,
        ] = args;
        const type = bubble.type as BubbleTypes;
        const data = bubble.data as BubbleData;

        if (shouldAttachRef && forwardRef) {
            forwardRef(attachedRef);
        }

        const classNames = {
            room: cnBubbleParticipants('RoomBubble'),
            person: cnBubbleParticipants('PersonBubble', { isFirst: isCircleBubbleFirst }),
            arbitrary: cnBubbleParticipants('ArbitraryBubble', { isFirst: isCircleBubbleFirst }),
            counter: cnBubbleParticipants('CounterBubble', { isFirst: isCircleBubbleFirst }),
            cms: cnBubbleParticipants('CmsBubble', { isFirst: isCircleBubbleFirst }),
        };

        return (
            <div
                className={classNames[type]}
                key={data.id}
                ref={shouldAttachRef ? attachedRef : null}
            >
                <Bubble
                    theme={theme}
                    type={type as undefined}
                    data={data}
                />
            </div>
        );
    }, [theme, forwardRef]);

    const bubblesToShow: Bubble[] = bubbles.slice(0, visibleCount);
    if (visibleCount < bubbles.length) {
        bubblesToShow.push({
            type: 'counter',
            data: {
                id: 'counter',
                count: bubbles.length - visibleCount,
            },
        });
    }

    React.useLayoutEffect((): void => {
        const targetElem = bubbleParticipantsRef.current as HTMLDivElement;
        const isOverflowedByHeight = targetElem.offsetHeight < targetElem.scrollHeight;
        const isOverflowedByWudth = targetElem.offsetWidth < targetElem.scrollWidth;
        const isOverflowed = isOverflowedByHeight || isOverflowedByWudth;

        if (isOverflowed) {
            setVisibleCount((prevState): number => prevState - 1);
            setIsExpanding(false);
            return;
        }

        if (!isExpanding) {
            return;
        }

        if (visibleCount < participants.length) {
            setVisibleCount((prevState): number => prevState + 1);
        }
    }, [isExpanding, visibleCount, participants]);

    return (
        <div
            className={cnBubbleParticipants({}, [className])}
            ref={bubbleParticipantsRef}
            onClick={onClick}
            onKeyDown={handleKeyDown}
            tabIndex={0}
            role="button"
        >
            {
                bubblesToShow.map((bubble, index, arr): ReactElement => {
                    const bubbleType = bubble.type;

                    const circleBubbleTypes = ['person', 'counter', 'arbitrary'];
                    const isCircleBubbleFirst = circleBubbleTypes.includes(bubbleType) &&
                    (index === 0 || arr[index - 1].type === 'room');

                    const shouldAttachRef = index === arr.length - 1;

                    return renderBubble(bubble, isCircleBubbleFirst, shouldAttachRef);
                })
            }
        </div>
    );
};

export default BubbleParticipants;
