import { Subject } from 'rxjs';
import { UserError } from '../../ducks/user';

export type SlideEvent = SocketEventName.LEFT | SocketEventName.RIGHT;
export type MessageCode = 'nextSlide' | 'click' | 'change' | 'prevSlide' | 'early' | 'back';
export type MessageCodes = Record<SlideEvent, MessageCode[]>;
export type UserMessageCodes = Record<SlideEvent, MessageCode>;

export enum SocketEventName {
    CLIENT_HANDSHAKE = 'CLIENT_HANDSHAKE',
    ZOOM_WRONG = 'ZOOM_WRONG',
    PIN_WRONG = 'PIN_WRONG',
    LEFT = 'LEFT',
    RIGHT = 'RIGHT',
    START_MANAGEMENT = 'START_MANAGEMENT',
    END_MANAGEMENT = 'END_MANAGEMENT',
    ECHO = 'ECHO',
    PING = 'PING',
    ROOM_IS_READY = 'ROOM_IS_READY',
    ERROR = 'ERROR',
    WAIT = 'WAIT',
}

export enum ChangeSlideType {
    NEXT = 'next',
    PREVIOUS = 'previous',
}

export enum ArrowKey {
    LEFT = 'ArrowLeft',
    RIGHT = 'ArrowRight',
}

export enum BadgeColor {
    SUCCESS = 'var(--hr-color-accent-positive-default)',
    PENDING = 'var(--hr-color-accent-brand-default)',
    ERROR = 'var(--hr-color-accent-negative-default)',
}

export enum ClickerZoomPage {
    LOAD = 'LOAD',
    START_HOST = 'START_HOST',
    MAIN = 'MAIN',
    INSTRUCTION_INTRODUCTION = 'INSTRUCTION_INTRODUCTION',
    INSTRUCTION_IMPORTANT = 'INSTRUCTION_IMPORTANT',
    INSTRUCTION_FINISH = 'INSTRUCTION_FINISH',
}

export interface ClickerMessage {
    id: string;
    isProcessed: boolean;
    eventName: SlideEvent;
    timestamp: number;
    login: string;
    messageCode: string;
}

export interface ClickerEvent {
    event_name?: SocketEventName;
    meeting_title?: string;
    id?: string;
    login?: string;
    time?: string;
    pin?: string;
    zoom_id?: string;
    error?: string;
}

export interface WebSocketData {
    meetingId: string | null,
    webSocketSubject: Subject<ClickerEvent> | null,
    onOpenSubject: Subject<Event> | null;
    onCloseSubject: Subject<Event> | null;
}

export interface ClickerZoomMapStateToProps {
    messages: ClickerMessage[];
    userError: UserError;
    currentPage: ClickerZoomPage;
    env: string;
    isLoading: boolean;
}

export interface ZoomDetails {
    meetingId: string;
    participantUUID: string;
    role: string;
}

export type ClickerZoomOwnProps = {
    authRequired?: boolean;
}

export type ClickerZoomProps = ClickerZoomOwnProps;
