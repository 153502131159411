import { withBemMod, compose, composeU } from '@bem-react/core';
import i18n from '@yandex-int/i18n';

import { Button as ButtonBase } from '@yandex-lego/components/Button/desktop/bundle';
import { Icon as IconBase } from '@yandex-lego/components/Icon/desktop';
import React, { FC, ReactElement, useCallback } from 'react';
import {
    withResponsive as withButtonResponsive,
} from 'components/Button';

import {
    withTypeMicrophone as withIconTypeMicrophone,
    withTypeCamera as withIconTypeCamera,
    withTypeNoCamera as withIconTypeNoCamera,
    withTypeNoMicrophone as withIconTypeNoMicrophone,
    withToneWhite as withIconToneWhite,
} from 'components/Icon';

import * as keyset from '../Participant.i18n';
import { cnParticipant } from '../Participant@desktop';
import { ParticipantProps } from '../Participant.interface';
import { WithControls } from './Participant_controls.interface';
import './Participant_controls.css';

const i18nParticipant = i18n(keyset);

const Button = compose(
    withButtonResponsive,
)(ButtonBase);

const Icon = composeU(
    withIconTypeMicrophone,
    withIconTypeNoMicrophone,
    withIconTypeCamera,
    withIconTypeNoCamera,
    withIconToneWhite,
)(IconBase);

export const withControls = withBemMod<WithControls, ParticipantProps>(
    cnParticipant(), { controls: true }, (Participant): FC<WithControls & ParticipantProps> => (
        (props: WithControls & ParticipantProps): ReactElement => {
            const {
                microphoneActive,
                cameraActive,
                onToggleCamera,
                onToggleMicrophone,
            } = props;

            let cameraIconTone: 'white';
            let microphoneIconTone: 'white';
            let existsCamera = 'noCamera';
            let existsMicrophone = 'noMicrophone';

            if (cameraActive) {
                cameraIconTone = 'white';
                existsCamera = 'camera';
            }

            if (microphoneActive) {
                microphoneIconTone = 'white';
                existsMicrophone = 'microphone';
            }

            const iconCamera = useCallback((iconClassName): ReactElement => (
                <Icon
                    type={existsCamera as undefined}
                    tone={cameraIconTone}
                    className={iconClassName}
                />
            ), [cameraIconTone, existsCamera]);

            const iconMicrophone = useCallback((iconClassName): ReactElement => (
                <Icon
                    type={existsMicrophone as undefined}
                    tone={microphoneIconTone}
                    className={iconClassName}
                />
            ), [existsMicrophone, microphoneIconTone]);

            const controls = (
                <div className={cnParticipant('Controls')}>
                    <Button
                        onClick={onToggleCamera}
                        size="m"
                        view="default"
                        // TODO http://st/VCONF-320
                        // disabled={camera || acting}
                        disabled
                        checked={cameraActive}
                        icon={iconCamera}
                        width="max"
                        responsive
                        className={cnParticipant('ControlButton', { type: 'camera' })}
                    >
                        {(cameraActive) ? i18nParticipant('button-camera-off') : i18nParticipant('button-camera-on')}
                    </Button>
                    <Button
                        onClick={onToggleMicrophone}
                        size="m"
                        view="default"
                        // TODO http://st/VCONF-320
                        // disabled={microphone || acting}
                        disabled
                        checked={microphoneActive}
                        icon={iconMicrophone}
                        width="max"
                        responsive
                        className={cnParticipant('ControlButton', { type: 'microphone' })}
                    >
                        {(microphoneActive) ? i18nParticipant('button-microphone-off') : i18nParticipant('button-microphone-on')}
                    </Button>
                </div>
            );

            return (
                <Participant
                    {...props}
                    controlButtons={controls}
                />
            );
        }
    ),
);

export default withControls;
