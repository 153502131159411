import { push } from 'connected-react-router';
import React, { ChangeEvent, FC, ReactElement, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import {
    selectData,
    selectIsActionRequesting,
    setCallForm,
    requestCreateCall,
    clearCallForm,
    getEventAttendees,
} from 'ducks/createCallForm';
import { StoreI } from 'ducks/store';
import { setCallTemplate } from 'ducks/templateForm';
import { selectCalendarEventMeta } from 'ducks/calendarEventMeta';
import { selectCurrentUser } from 'ducks/user';
import {
    generate as generateInviteCallLink,
    selectLink as selectInviteCallLink,
} from 'ducks/inviteCallLink';

import { usePlatformContext } from 'hooks';

import { ParticipantsListGroup } from 'components/ParticipantsList/ParticipantsList.interface';
import { getConnectionMethod } from 'components/Participant/Participant.util';
import {
    generateParticipantsListData,
    getAllParticipantsFromList,
    getParticipantFromList,
} from 'components/ParticipantsList';
import { ActiveCallParticipantI } from 'components/ActiveCalls/ActiveCalls.interface';
import { CurrentUser } from 'components/ActiveCallScreen/ActiveCallScreen.interface';

import { CreateCallFormDesktop } from './CreateCallForm@desktop';
import { CreateCallFormMobile } from './CreateCallForm@mobile';
import {
    CreateCallFormContainerProps,
    CreateCallFormMapStateToProps,
    CreateCallFormMapDispatchToProps,
    CallFormDuration,
} from './CreateCallForm.interface';

const CreateCallFormContainer: FC<CreateCallFormContainerProps> = (props): ReactElement => {
    const {
        isActionRequesting,
        data,
        user,
        calendarEventMeta,
        inviteCallLink,
        setCallForm: setCallFormAction,
        setCallTemplate: setCallTemplateAction,
        requestCreateCall: requestCreateCallAction,
        clearCallForm: clearCallFormAction,
        getEventAttendees: getEventAttendeesAction,
        generateInviteCallLink: generateInviteCallLinkAction,
        push: pushAction,
        className,
    } = props;

    const platform = usePlatformContext();
    const CreateCallFormView = platform === 'mobile' ? CreateCallFormMobile : CreateCallFormDesktop;

    const isCallButtonEnable = data.participants.length > 0;

    const defaultName = calendarEventMeta?.event ? calendarEventMeta.event.name : '';

    const [currentUser, setCurrentUser] = useState<CurrentUser>({
        ...user,
        method: getConnectionMethod(user),
        state: 'join',
    });

    useEffect(() => {
        return () => {
            clearCallFormAction();
        };
    }, [clearCallFormAction]);

    useEffect((): void => {
        if (calendarEventMeta) {
            const { call } = calendarEventMeta;
            call.participants = call.participants.map(
                (participant: ActiveCallParticipantI): ActiveCallParticipantI => ({
                    ...participant,
                    method: getConnectionMethod(participant),
                }),
            );

            setCallFormAction(call);
        }
    }, [calendarEventMeta, setCallFormAction]);

    useEffect((): void => {
        if (calendarEventMeta?.event) {
            generateInviteCallLinkAction({
                eventId: calendarEventMeta?.event.id,
                force: false,
            });
        }
    }, [calendarEventMeta, generateInviteCallLinkAction]);

    const [
        participantsList,
        setParticipantsList,
    ] = useState<Map<string, ParticipantsListGroup>>(generateParticipantsListData([])[1]);

    useEffect((): void => {
        setParticipantsList(generateParticipantsListData(data.participants)[1]);
    }, [clearCallFormAction, data.participants]);

    const handleSubmit = useCallback(e => {
        e.preventDefault();
    }, []);

    const handleDeleteParticipant = useCallback((groupID, participantID): void => {
        const newParticipantsList = new Map(participantsList);
        const group = newParticipantsList.get(groupID) as ParticipantsListGroup;

        group.content.delete(participantID);

        setCallFormAction({
            ...data,
            participants: getAllParticipantsFromList(newParticipantsList),
        });
    }, [data, participantsList, setCallFormAction]);

    const changeParticipantSelectedMethod = useCallback((groupID, participantID, method) => {
        const newParticipantsList = new Map(participantsList);
        const participant = getParticipantFromList(
            newParticipantsList,
            groupID,
            participantID,
        ) as ActiveCallParticipantI;
        const group = newParticipantsList.get(groupID) as ParticipantsListGroup;

        group.content.set(participantID, {
            ...participant,
            method,
        });

        setCallFormAction({
            ...data,
            participants: getAllParticipantsFromList(newParticipantsList),
        });
    }, [data, participantsList, setCallFormAction]);

    const changeCurrentUserSelectedMethod = useCallback(method => {
        setCurrentUser({
            ...currentUser,
            method,
        });
    }, [currentUser]);

    const handleChangeParticipantSelectedMethod = useCallback((groupID, participantID, event): void => {
        changeParticipantSelectedMethod(groupID, participantID, event.target.value);

        if (participantID === currentUser.id) changeCurrentUserSelectedMethod(event.target.value);
    }, [changeCurrentUserSelectedMethod, changeParticipantSelectedMethod, currentUser.id]);

    const handleParticipantsSuggestSelect = useCallback((suggestion): void => {
        const newParticipantsList = new Map(participantsList);
        const group = newParticipantsList.get('main') as ParticipantsListGroup;

        group.content.set(suggestion.id, {
            ...suggestion,
            method: getConnectionMethod(suggestion),
        });

        setCallFormAction({
            ...data,
            participants: getAllParticipantsFromList(newParticipantsList),
        });
    }, [data, participantsList, setCallFormAction]);

    const handleGetEventAttendeesButtonClick = useCallback(() => {
        const attendees = calendarEventMeta.event.attendees.filter(attendee => attendee.decision === 'yes');
        attendees.push(calendarEventMeta.event.organizer);

        getEventAttendeesAction(attendees.map(attendee => ({
            type: 'person',
            id: attendee.login,
        })));
    }, [calendarEventMeta, getEventAttendeesAction]);

    const handleFieldRecordChange = useCallback((): void => {
        setCallFormAction({
            ...data,
            record: !data.record,
        });
    }, [data, setCallFormAction]);

    const handleFieldStreamChange = useCallback((): void => {
        setCallFormAction({
            ...data,
            stream: !data.stream,
        });
    }, [data, setCallFormAction]);

    const handleFieldIsPrivateStreamChange = useCallback((): void => {
        setCallFormAction({
            ...data,
            isPrivateStream: !data.isPrivateStream,
        });
    }, [data, setCallFormAction]);

    const handleDurationChange = useCallback((event): void => {
        setCallFormAction({
            ...data,
            duration: parseInt(event.target.value, 10) as CallFormDuration,
        });
    }, [data, setCallFormAction]);

    const handleCreateTemplateClick = useCallback((): void => {
        setCallTemplateAction({
            ...data,
            streamPicture: '',
            streamDescription: '',
            id: null,
            owners: [],
            eventExternalId: null,
            event: {
                name: null,
            },
        });
        pushAction('/template');
    }, [data, pushAction, setCallTemplateAction]);

    const handleCreateCallClick = useCallback((): void => {
        requestCreateCallAction({
            ...data,
            name: data.name || defaultName,
        });
    }, [data, defaultName, requestCreateCallAction]);

    const handleClearButtonClick = useCallback((): void => {
        clearCallFormAction();
    }, [clearCallFormAction]);

    const handleAddCurrentUser = useCallback((): void => {
        const requestData = {
            ...data,
            name: data.name || defaultName,
        };

        if (!getParticipantFromList(participantsList, 'main', currentUser.id)) {
            requestData.participants = [
                ...requestData.participants,
                { ...currentUser },
            ];
        }

        requestCreateCallAction(requestData);
    }, [currentUser, data, defaultName, participantsList, requestCreateCallAction]);

    const handleChangeCurrentUserSelectedMethod = useCallback(
        (event: ChangeEvent<HTMLSelectElement | HTMLInputElement>): void => {
            const method = event.target.value;

            changeCurrentUserSelectedMethod(method);

            if (getParticipantFromList(participantsList, 'main', currentUser.id)) {
                changeParticipantSelectedMethod('main', currentUser.id, method);
            }
        },
        [changeCurrentUserSelectedMethod, changeParticipantSelectedMethod, currentUser.id, participantsList],
    );

    const handleReGenerateInviteCallLinkClick = useCallback(() => {
        generateInviteCallLinkAction({
            eventId: calendarEventMeta?.event.id,
            force: true,
        });
    }, [calendarEventMeta, generateInviteCallLinkAction]);

    return (
        <CreateCallFormView
            name={data.name}
            record={data.record}
            stream={data.stream}
            isPrivateStream={data.isPrivateStream}
            duration={data.duration}
            user={user}
            currentUser={currentUser}
            calendarEventMeta={calendarEventMeta}
            participantsCount={data.participants.length}
            isActionRequesting={isActionRequesting}
            participantsList={participantsList}
            isCallButtonEnable={isCallButtonEnable}
            inviteCallLink={inviteCallLink}
            onSubmit={handleSubmit}
            onParticipantsSuggestSelect={handleParticipantsSuggestSelect}
            onDeleteParticipant={handleDeleteParticipant}
            onChangeParticipantSelectedMethod={handleChangeParticipantSelectedMethod}
            onFieldStreamChange={handleFieldStreamChange}
            onFieldRecordChange={handleFieldRecordChange}
            onFieldIsPrivateStreamChange={handleFieldIsPrivateStreamChange}
            onDurationChange={handleDurationChange}
            onCreateCallButtonClick={handleCreateCallClick}
            onCreateTemplateButtonClick={handleCreateTemplateClick}
            onClearButtonClick={handleClearButtonClick}
            onGetEventAttendeesButtonClick={handleGetEventAttendeesButtonClick}
            onAddCurrentUser={handleAddCurrentUser}
            onChangeCurrentUserSelectedMethod={handleChangeCurrentUserSelectedMethod}
            onReGenerateInviteCallLinkClick={handleReGenerateInviteCallLinkClick}
            className={className}
        />
    );
};

export const CreateCallForm = connect<CreateCallFormMapStateToProps, CreateCallFormMapDispatchToProps>(
    (store: StoreI): CreateCallFormMapStateToProps => ({
        data: selectData(store),
        calendarEventMeta: selectCalendarEventMeta(store),
        isActionRequesting: selectIsActionRequesting(store),
        user: selectCurrentUser(store),
        inviteCallLink: selectInviteCallLink(store),
    }),
    {
        setCallForm,
        setCallTemplate,
        requestCreateCall,
        clearCallForm,
        getEventAttendees,
        generateInviteCallLink,
        push,
    },
)(CreateCallFormContainer);

export default CreateCallForm;
