import React, { FC, ReactElement, useMemo } from 'react';
import { compose, composeU } from '@bem-react/core';
import { useComponentRegistry } from '@bem-react/di';

import { Button as ButtonBase } from '@yandex-lego/components/Button/desktop/bundle';
import { Select, Option } from '@yandex-lego/components/Select/desktop/bundle';
import {
    Icon as IconBase,
    withTypeArrow as withIconTypeArrow,
} from '@yandex-lego/components/Icon/desktop';
import { withResponsive as withButtonResponsive } from 'components/Button';
import {
    withSizeM as withIconSizeM,
    withTypeCalendarAwesome as withIconTypeCalendarAwesome,
    withTypeComment as withIconTypeComment,
} from 'components/Icon';

import { registryId } from '../StreamScreen';
import { StreamScreenControlsProps } from './StreamScreen-Controls.interface';
import { cnStreamScreen } from '../StreamScreen.cn';
import { i18nStreamScreen } from '../StreamScreen';

import './StreamScreen-Controls.css';

const Icon = compose(
    composeU(
        withIconTypeComment,
        withIconTypeCalendarAwesome,
        withIconTypeArrow,
    ),
    withIconSizeM,
)(IconBase);

const Button = withButtonResponsive(ButtonBase);

const iconCalendarAwesome = (iconClassName: string): ReactElement => (<Icon type="calendarAwesome" size="m" className={iconClassName} />);

export const Controls: FC<StreamScreenControlsProps> = (props: StreamScreenControlsProps): ReactElement => {
    const {
        eventId,
        calendarEventUrl,
        playerType,
        translatedStreams,
        lang: streamLang,
        navigateToChat,
        onStreamLanguageChanged,
        onPlayerTypeChange,
    } = props;

    const {
        ChatLink,
    } = useComponentRegistry(registryId);

    const tranlationOptions = useMemo<Option[]>(() => {
        if (!translatedStreams) {
            return [];
        }

        return Object.keys(translatedStreams).map(
            (lang): Option => ({
                value: lang,
                content: i18nStreamScreen(`stream-language-${lang}`),
            }),
        );
    }, [translatedStreams]);

    return (
        <div className={cnStreamScreen('Controls')}>
            {
                tranlationOptions.length > 1 && (
                    <div className={cnStreamScreen('StreamLanguageSwitcherContainer')}>
                        <Select
                            className={cnStreamScreen('StreamLanguage')}
                            value={streamLang}
                            onChange={onStreamLanguageChanged}
                            options={tranlationOptions}
                            view="classic"
                            size="s"
                            theme="normal"
                            width="max"
                            showAlwaysPlaceholder
                            placeholder={i18nStreamScreen('stream-language-title')}
                        />
                    </div>
                )
            }
            <div className={cnStreamScreen('PlayerTypeContainer')}>
                <Select
                    className={cnStreamScreen('PlayerType')}
                    value={playerType}
                    onChange={onPlayerTypeChange}
                    options={[
                        {
                            value: 'protected',
                            content: i18nStreamScreen('player_type_protected'),
                        },
                        {
                            value: 'audio',
                            content: i18nStreamScreen('player_type_audio'),
                        },
                    ]}
                    view="classic"
                    size="s"
                    theme="normal"
                    width="max"
                />
            </div>
            {
                eventId !== null && (
                    <Button
                        size="m"
                        view="clear"
                        type="link"
                        url={calendarEventUrl}
                        icon={iconCalendarAwesome}
                        title={i18nStreamScreen('show-calendar-awesome')}
                        responsive
                        className={cnStreamScreen('CalendarAwesomeButton')}
                    />
                )
            }
            <ChatLink navigateToChat={navigateToChat} />
        </div>
    );
};

export default Controls;
