import { withBemMod } from '@bem-react/core';
import { Spin } from '@yandex-lego/components/Spin/bundle';
import React, { ReactElement, FC } from 'react';
import { WithLoadingProps } from './KhStreamScreen_loading.interface';

import { KhStreamScreenProps } from '../KhStreamScreen.interface';
import { cnKhStreamScreen } from '../KhStreamScreen.cn';

import './KhStreamScreen_loading.css';

export const withLoading = withBemMod<WithLoadingProps, KhStreamScreenProps>(
    cnKhStreamScreen(),
    { loading: true },
    (KhStreamScreen): FC<WithLoadingProps & KhStreamScreenProps> => (
        (props: WithLoadingProps & KhStreamScreenProps): ReactElement => {
            const replacer = (
                <Spin
                    className={cnKhStreamScreen('Spin')}
                    size="l"
                    view="default"
                    progress
                />
            );

            return (
                <KhStreamScreen
                    {...props}
                    replacer={replacer}
                />
            );
        }
    ),
);
