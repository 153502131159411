import { compose } from '@bem-react/core';
import React, { FC, ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    selectStreamDetails,
    requestStreamDetailsPermanent,
    flushStreamError,
} from 'ducks/stream';

import { withLoading } from './_loading/KhAnnouncementScreen_loading';
import { withError } from './_error/KhAnnouncementScreen_error';
import {
    KhAnnouncementScreenContainerProps,
    STATE,
} from './KhAnnouncementScreen.interface';
import { KhAnnouncementScreen as KhAnnouncementScreenBase } from './KhAnnouncementScreen';
import {
    useRedirect,
    useScreenState,
} from './KhAnnouncementScreen.hooks';

const KhAnnouncementScreenView = compose(
    withLoading,
    withError,
)(KhAnnouncementScreenBase);

const KHURAL_TEMPLATE_ID = '3324';

export const KhAnnouncementScreen: FC<KhAnnouncementScreenContainerProps> =
    (props): ReactElement => {
        const { announcementType } = props;

        const dispatch = useDispatch();
        const { responseError, id, isRequesting } = useSelector(selectStreamDetails);

        const customTemplateId = new URLSearchParams(window.location.search).get('templateid');
        const templateId = customTemplateId || KHURAL_TEMPLATE_ID;

        const state = useScreenState(isRequesting, id, responseError);

        useRedirect(state, id, announcementType);

        useEffect(() => {
            dispatch(requestStreamDetailsPermanent(templateId));

            return () => {
                dispatch(flushStreamError());
            };
        }, [dispatch, templateId]);

        return (
            <KhAnnouncementScreenView
                loading={state === STATE.LOADING}
                error={state === STATE.ERROR}
                responseError={responseError}
                event={state === STATE.DISPLAY_NEXT_EVENT && responseError?.errorDetails?.event}
                announcementType={announcementType}
            />
        );
    };
