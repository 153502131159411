import { cn } from '@bem-react/classname';
import { compose } from '@bem-react/core';

import { Button } from '@yandex-lego/components/Button/desktop/bundle';

import {
    Icon as IconBase,
    withTypeArrow as withIconTypeArrow,
} from '@yandex-lego/components/Icon/desktop';
import React, { FC, ReactElement, useCallback, useState } from 'react';

import { ParticipantMobileProps } from './Participant.interface';
import './Participant@mobile.css';

const Icon = compose(
    withIconTypeArrow,
)(IconBase);

export const cnParticipant = cn('Participant');

export const Participant: FC<ParticipantMobileProps> = (props: ParticipantMobileProps): ReactElement => {
    const {
        className,
        name,
        recallButton,
        icon,
        addon,
        deleteButton,
        controlButtons,
        subtitle,
        actionType,
    } = props;

    const [isParametersVisible, setIsParametersVisible] = useState<boolean>(false);
    const onParametersButtonClick = useCallback((): void => {
        setIsParametersVisible(!isParametersVisible);
    }, [isParametersVisible]);

    const iconArrow = useCallback((iconClassName): ReactElement =>
        <Icon type="arrow" direction={isParametersVisible ? 'top' : 'bottom'} className={iconClassName} />, [isParametersVisible]);

    return (
        <div className={cnParticipant({ isParametersVisible, mobile: true }, [className])}>
            <div className={cnParticipant('Main')}>
                <div className={cnParticipant('LeftSide')}>
                    {icon}
                    <div className={cnParticipant('MetaInfo')}>
                        <div className={cnParticipant('Title')}>{ name }</div>
                        <div className={cnParticipant('Subtitle')}>{ subtitle }</div>
                    </div>
                </div>
                <div className={cnParticipant('RightSide')}>
                    <Button
                        view="clear"
                        size="s"
                        className={cnParticipant('ParametersButton')}
                        iconRight={iconArrow}
                        onClick={onParametersButtonClick}
                    >
                        {actionType}
                    </Button>
                </div>
            </div>
            <div className={cnParticipant('Parameters')}>
                {addon}
                {controlButtons}
                {recallButton}
                {deleteButton}
            </div>
        </div>
    );
};
