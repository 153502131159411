import { ResponseError } from './KhAnnouncementScreen.interface';

export const isUpcomingEvent = (responseError: ResponseError): boolean => {
    if (responseError) {
        const {
            errorCode,
            errorDetails,
        } = responseError;

        return Boolean(errorCode === 'no_active_calls_for_event_external_id' && errorDetails.event);
    }

    return false;
};
