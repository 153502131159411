import { compose, composeU, withBemMod } from '@bem-react/core';
import i18n from '@yandex-int/i18n';

import { Button as ButtonBase } from '@yandex-lego/components/Button/desktop/bundle';
import { Icon as IconBase } from '@yandex-lego/components/Icon';
import React, { FC, MouseEventHandler, ReactElement } from 'react';
import {
    withResponsive as withButtonResponsive,
} from 'components/Button';

import {
    withTypeArrowRight as withIconTypeArrowRight,
    withTypeSquare as withIconTypeSquare,
    withTypeCalendarAwesome as withIconTypeCalendarAwesome,
} from 'components/Icon';

import { CopyText } from 'components/CopyText';

import { TableCellProps } from '../TableCell.interface';
import { cnTableCell } from '../TableCell';
import * as keyset from '../TableCell.i18n';
import './TableCell_type_activeCallActions.css';

export interface WithTypeActiveCallActionsProps {
  type?: 'activeCallActions';
  id?: string;
  secret?: string;
  'event_id'?: number;
  onNavigateToActiveCallScreenButtonClick?: FunctionVoid;
  onTableCellActiveCallActionsClick?: MouseEventHandler;
}

const i18nTableCell = i18n(keyset);

const Button = compose(withButtonResponsive)(ButtonBase);

const Icon = composeU(
    withIconTypeArrowRight,
    withIconTypeSquare,
    withIconTypeCalendarAwesome,
)(IconBase);

const iconSquare = (iconClassName: string): ReactElement => (<Icon type="square" className={iconClassName} />);
const iconArrowRight = (iconClassName: string): ReactElement => (<Icon type="arrowRight" className={iconClassName} />);
const iconCalendarAwesome = (iconClassName: string): ReactElement => (<Icon type="calendarAwesome" size="m" className={iconClassName} />);

export const withTypeActiveCallActions = withBemMod<WithTypeActiveCallActionsProps, TableCellProps>(
    cnTableCell(),
    { type: 'activeCallActions' },
    (TableCell): FC<WithTypeActiveCallActionsProps & TableCellProps> => (
        (props: WithTypeActiveCallActionsProps & TableCellProps): ReactElement => {
            const {
                onNavigateToActiveCallScreenButtonClick,
                id,
                secret,
                event_id: eventId,
                onTableCellActiveCallActionsClick,
            } = props;

            const activeCallUrl = new URL(`${window.location.origin}/active-call/${id}`);
            const calendarEventUrl = new URL('https://calendar.yandex-team.ru/event/');

            activeCallUrl.searchParams.set('secret', `${secret}`);
            calendarEventUrl.searchParams.set('event_id', `${eventId}`);

            return (
                <TableCell {...props} onTableCellActiveCallActionsClick={onTableCellActiveCallActionsClick}>
                    {eventId !== null &&
                    <Button
                        size="s"
                        view="pseudo"
                        pin="circle-circle"
                        type="link"
                        url={calendarEventUrl.toString()}
                        icon={iconCalendarAwesome}
                        title={i18nTableCell('show-calendar-awesome')}
                        width="max"
                        responsive
                        className={cnTableCell('Button')}
                    >
                        {i18nTableCell('show-calendar-awesome')}
                    </Button>
                    }
                    <CopyText
                        onCopyText={i18nTableCell('table-active-calls-share-link-copy-message')}
                        textToCopy={activeCallUrl.toString()}
                    >
                        <Button
                            view="pseudo"
                            pin="circle-circle"
                            size="s"
                            icon={iconSquare}
                            title={i18nTableCell('table-active-calls-copy-call-link')}
                            responsive
                            width="max"
                            className={cnTableCell('Button', { type: 'copy-link' })}
                        >
                            {i18nTableCell('table-active-calls-copy-call-link')}
                        </Button>
                    </CopyText>
                    <Button
                        view="action"
                        pin="circle-circle"
                        size="s"
                        icon={iconArrowRight}
                        title={i18nTableCell('show-call-details')}
                        responsive
                        width="max"
                        onClick={onNavigateToActiveCallScreenButtonClick}
                        className={cnTableCell('Button', { type: 'show-call-details' })}
                    >
                        {i18nTableCell('show-call-details')}
                    </Button>
                </TableCell>
            );
        }
    ),
);

export default withTypeActiveCallActions;
