import duration from 'util/dayjs-duration';
import dayjs, { Dayjs } from 'dayjs';
import { cn } from '@bem-react/classname';
import i18n from '@yandex-int/i18n';
import React, { ReactElement } from 'react';

import { Selector } from './Selector';
import { DurationProps } from './Duration.interface';
import * as keyset from './Duration.i18n';

import './Duration.css';

export const cnDuration = cn('Duration');

const i18nDuration = i18n(keyset);

dayjs.extend(duration);

function getFormattedDuration(date1: Dayjs, date2: Dayjs): string {
    const diff = date1.diff(date2, 'second');
    const duration = dayjs().duration(diff, 'second');
    let formattedDuration = '';

    const passedDays = duration.D;
    if (passedDays > 0) {
        formattedDuration += i18nDuration('passed-days', { count: passedDays });
    } else {
        const passedHours = duration.H;
        if (passedHours > 0) {
            formattedDuration += i18nDuration('passed-hours', { passedHours });
        }

        const passedMinutes = duration.m;
        if (passedMinutes > 0) {
            const delimiter = passedHours > 0 ? ' ' : '';
            formattedDuration += `${delimiter}${i18nDuration('passed-minutes', { passedMinutes })}`;
        }

        if (passedHours === 0 && passedMinutes === 0) {
            formattedDuration = i18nDuration('less-than-minute');
        }
    }

    return formattedDuration;
}

export const Duration = (props: DurationProps): ReactElement => {
    const {
        to,
        startingPointISOString,
        duration,
        isDurationChanging,
        onChange,
        className,
    } = props;

    const start = to ? startingPointISOString : Date.now();
    const end = to ? Date.now() : startingPointISOString;

    const now = dayjs(start);
    const startingPoint = dayjs(end);

    const minutesPassed = now.diff(startingPoint, 'minute');
    const formattedTimePassed = getFormattedDuration(now, startingPoint);

    const [rerendersCount, setRerendersCount] = React.useState<number>(0);

    React.useEffect((): () => void => {
        const minuteDuration = 60 * 1000;
        const msLeftToPassMinute = minuteDuration - Date.now() % minuteDuration;

        const timeout = setTimeout((): void => {
            setRerendersCount(rerendersCount + 1);
        }, msLeftToPassMinute);

        return (): void => {
            clearTimeout(timeout);
        };
    }, [rerendersCount]);

    return (
        <div className={cnDuration({}, [className])}>
            { formattedTimePassed }
            <Selector
                minutesPassed={minutesPassed}
                duration={duration}
                isDurationChanging={isDurationChanging}
                onChange={onChange}
            />
        </div>
    );
};

export default Duration;
