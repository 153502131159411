import React, { FC, ReactElement, useEffect, useRef } from 'react';
import { cn } from '@bem-react/classname';
import i18n from '@yandex-int/i18n';

import { TextCollapseProps } from './TextCollapse.interface';
import * as keyset from './TextCollapse.i18n';
import './TextCollapse.css';

export const i18nTextCollapse = i18n(keyset);
export const cnTextCollapse = cn('TextCollapse');

export const TextCollapse: FC<TextCollapseProps> = (props): ReactElement => {
    const {
        children,
        addonAfter,
        setTextBlockRef,
        className,
    } = props;

    const textBlockRef = useRef();

    useEffect(() => {
        setTextBlockRef(textBlockRef);
    }, [setTextBlockRef]);

    return (
        <div className={cnTextCollapse(null, [className])}>
            <div
                ref={textBlockRef}
                className={cnTextCollapse('Text')}
            >
                {children}
            </div>
            {addonAfter}
        </div>
    );
};
