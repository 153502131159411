import apiRxJs, { ApiErrorI } from 'util/api.rxjs';
import { Observable } from 'rxjs';

type ResultUploadPicture = string | ApiErrorI;

export const uploadPicture = (payload: File): Observable<ResultUploadPicture> => {
    return apiRxJs.jing(payload, 'robot-vcs-www');
};

export interface UploadPictureApi {
  uploadPicture: (payload: File) => Observable<ResultUploadPicture>;
}

export const uploadPictureApi: UploadPictureApi = {
    uploadPicture,
};
