import i18n, { i18nRaw } from '@yandex-int/i18n';
import React, { FC, ReactElement } from 'react';

import { cnFooter } from '../Footer';
import * as keyset from '../Footer.i18n';
import './Footer-Copyright.css';

const i18nFooterRaw = i18nRaw(keyset);
const i18nFooter = i18n(keyset);

export const FooterCopyright: FC = (): ReactElement => {
    const currentYear = new Date().getFullYear();

    return (
        <div className={cnFooter('Copyright')}>
            © {currentYear}
            {' '}
            {
                i18nFooterRaw('org', {
                    link: <a href="https://yandex.ru/">{i18nFooter('yandex')}</a>,
                })
            }
        </div>
    );
};
