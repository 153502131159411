import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';
import './Icon_type_plus.css';

export interface WithTypePlusProps {
  type?: 'plus';
}

export const withTypePlus = withBemMod<WithTypePlusProps>(
    'Icon',
    { type: 'plus' },
    (Icon): FC => ((props): ReactElement => (<Icon {...props} />)),
);

export default withTypePlus;
