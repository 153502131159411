import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';
import './Icon_type_shareGray.css';

export interface WithTypeShareGrayProps {
    type?: 'shareGray';
}

export const withTypeShareGray = withBemMod<WithTypeShareGrayProps>(
    'Icon',
    { type: 'shareGray' },
    (Icon): FC => ((props): ReactElement => (<Icon {...props} />)),
);

export default withTypeShareGray;
