import { cn } from '@bem-react/classname';

const cnFormatTime = cn('FormatTime');

export default cnFormatTime;

export interface FormatTimeOwnProps {
  startDate: string;
  endDate: string;
  withTime?: boolean,
  withDate?: boolean
}

export type FormatTimeProps = FormatTimeOwnProps;
