import { Registry, withRegistry } from '@bem-react/di';
import { composeU, compose } from '@bem-react/core';

import {
    Participant as ParticipantBase,
    withDelete as withParticipantDelete,
    withMethodSelect as withParticipantMethodSelect,
    withTypePerson as withParticipantTypePerson,
    withTypeRoom as withParticipantTypeRoom,
    withTypeArbitrary as withParticipantTypeArbitrary,
} from 'components/Participant/mobile';

import {
    ParticipantsList as ParticipantsListBase,
    withSuggest,
    withCollapse,
    withTypeCommon,
    registryId as participantsListRegistryId,
} from 'components/ParticipantsList';

import { StreamMobile } from './Stream';
import { TemplateFormScreenHeaderMobile } from './Header/TemplateFormScreen-Header@mobile';
import {
    TemplateFormScreen as TemplateFormScreenBase,
    registryId as templateFormRegistryID,
} from './TemplateFormScreen';

const Participant = compose(
    withParticipantMethodSelect,
    withParticipantDelete,
    composeU(
        withParticipantTypeRoom,
        withParticipantTypePerson,
        withParticipantTypeArbitrary,
    ),
)(ParticipantBase);

const ParticipantsList = compose(
    withSuggest,
    withCollapse,
    withTypeCommon,
)((ParticipantsListBase));

const participantsListRegistry = new Registry({ id: participantsListRegistryId });
participantsListRegistry.set('Participant', Participant);

const registry = new Registry({ id: templateFormRegistryID });

registry.set('ParticipantsList', withRegistry(participantsListRegistry)(ParticipantsList));
registry.set('Header', TemplateFormScreenHeaderMobile);
registry.set('Stream', StreamMobile);

export const TemplateFormScreenMobile = withRegistry(registry)(TemplateFormScreenBase);

export default TemplateFormScreenMobile;
