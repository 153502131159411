import React, { FC, ReactElement, RefObject, useEffect, useState } from 'react';
import { compose, composeU } from '@bem-react/core';

import { TextCollapseContainerProps } from './TextCollapse.interface';
import { DEFAULT_MAX_HEIGHT } from './TextCollapse.constants';
import { TextCollapse as TextCollapseViewBase } from './TextCollapse';
import { withTypeHtml as withTextCollapseTypeHtml } from './_type/TextCollapse_type_html';
import { withTypeText as withTextCollapseTypeText } from './_type/TextCollapse_type_text';
import { withCollapse as withTextCollapse } from './_collapse/TextCollapse_collapse';

const TextCollapseView = compose(
    withTextCollapse,
    composeU(
        withTextCollapseTypeHtml,
        withTextCollapseTypeText,
    ),
)(TextCollapseViewBase);

export const TextCollapse: FC<TextCollapseContainerProps> = (props): ReactElement => {
    const {
        text,
        html,
        maxHeight = DEFAULT_MAX_HEIGHT, // Максимальная высота в px, дефолтное значение - высота трех строк
        className,
    } = props;

    const [textBlockRef, setTextBlockRef] = useState<RefObject<HTMLDivElement>>(null);
    const [isCollapse, setIsCollapse] = useState(false);

    useEffect(() => {
        if (!isCollapse) {
            setIsCollapse(textBlockRef?.current?.clientHeight > maxHeight);
        }
    }, [isCollapse, maxHeight, textBlockRef]);

    if (!text && !html) return null;

    let textCollapseType = 'text';
    if (html) textCollapseType = 'html';

    return (
        <TextCollapseView
            type={textCollapseType as undefined}
            text={text}
            html={html}
            className={className}
            setTextBlockRef={setTextBlockRef}
            collapse={isCollapse}
        />
    );
};
