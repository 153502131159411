export const en = {
    'of': 'of',
    'remaining': '{minutes} minutes left',
    'passed-days': {
        many: '{count} days',
        none: '',
        one: '{count} day',
        some: '{count} days',
    },
    'passed-hours': '{passedHours} h.',
    'passed-minutes': '{passedMinutes} min',
    'less-than-minute': '< 1 min',
};
