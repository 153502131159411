import { compose } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import BaseBubbleParticipants from 'components/BubbleParticipants/BubbleParticipants';
import { withPopup } from 'components/BubbleParticipants';

import { ParticipantsProps } from './Template-Participants.interface';
import { cnTemplate } from '../Template';

const BubbleParticipants = compose(
    withPopup,
)(BaseBubbleParticipants);

export const Participants: FC<ParticipantsProps> = (props: ParticipantsProps): ReactElement => {
    const {
        participants,
        onParticipantsClick,
        className,
    } = props;

    if (participants.length > 0) {
        return (
            <div className={cnTemplate('Participants', [className])}>
                <BubbleParticipants
                    participants={participants}
                    onClick={onParticipantsClick}
                    popup
                />
            </div>
        );
    }

    return null;
};
