import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';
import './Icon_type_calendarAwesome.css';

export interface WithTypeCalendarProps {
  type?: 'calendarAwesome';
}

export const withTypeCalendarAwesome = withBemMod<WithTypeCalendarProps>(
    'Icon',
    { type: 'calendarAwesome' },
    (Icon): FC => ((props): ReactElement => (<Icon {...props} />)),
);

export default withTypeCalendarAwesome;
