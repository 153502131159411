import i18n from '@yandex-int/i18n';
import React, { FC, ReactElement, useCallback } from 'react';
import { connect } from 'react-redux';
import throttle from 'lodash/throttle';

import { notySuccess } from 'components/Notifications/Notifications.actions';

import { CopyText as CopyTextView } from './CopyText';
import {
    CopyTextContainerProps,
    CopyTextMapDispatchToProps,
} from './CopyText.interface';
import * as keyset from './CopyText.i18n';

const i18nCopyText = i18n(keyset);

const CopyTextContainer: FC<CopyTextContainerProps> = (props: CopyTextContainerProps): ReactElement => {
    const {
        notySuccess: notySuccessAction,
        onCopyText,
    } = props;

    const handleCopy = useCallback(throttle((): void => {
        notySuccessAction(onCopyText || i18nCopyText('copy-message'));
    }, 1000), []);

    return (
        <CopyTextView
            {...props}
            onCopy={handleCopy}
        />
    );
};

export const CopyText = connect<null, CopyTextMapDispatchToProps>(
    null,
    {
        notySuccess,
    },
)(CopyTextContainer);

export default CopyText;
