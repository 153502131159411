import { withBemMod } from '@bem-react/core';
import i18n from '@yandex-int/i18n';
import React, { ReactElement, FC } from 'react';

import { WithEmptyProps } from './ChatScreen_empty.interface';
import { ChatScreenProps } from '../ChatScreen.interface';
import { cnChatScreen } from '../ChatScreen';
import * as keyset from '../ChatScreen.i18n';
import './ChatScreen_empty.css';

const i18nChatScreen = i18n(keyset);

export const withEmpty = withBemMod<WithEmptyProps, ChatScreenProps>(
    cnChatScreen(),
    { empty: true },
    (ChatScreen): FC<WithEmptyProps & ChatScreenProps> => (
        (props: WithEmptyProps & ChatScreenProps): ReactElement => {
            const replacer = (
                <div className={cnChatScreen('NoData')}>
                    {i18nChatScreen('no-data')}
                </div>
            );

            return (
                <ChatScreen
                    {...props}
                    replacer={replacer}
                />
            );
        }
    ),
);
