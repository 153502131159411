import React, { ReactElement } from 'react';
import { Button } from '@yandex-int/hr-components/Button/desktop/bundle';
import { Badge } from '@yandex-lego/components/Badge';
import { cn } from '@bem-react/classname';
import i18n from '@yandex-int/i18n';
import * as keyset from '../../Clicker.i18n';
import { ClickerIcon } from '../ClickerIcon/ClickerIcon';
import { BadgeColor } from '../../Clicker.interface';

import './StartPage.css';

const cnStartPage = cn('StartPage');
const i18nClicker = i18n(keyset);

export interface StartPageProps {
    id: string;
    isLoading: boolean;
    startManagement: () => void;
    openInstruction: () => void;
}

export const StartPage = (props: StartPageProps): ReactElement => {
    const { id, isLoading, startManagement, openInstruction } = props;

    const badgeColor = isLoading ? BadgeColor.PENDING : BadgeColor.SUCCESS;

    return (
        <>
            <div className={cnStartPage('Id')}>
                <Badge
                    className={cnStartPage('IdBadge')}
                    color={badgeColor}
                    outlineColor="var(--hr-color-page-background)"
                />
                {id}
            </div>
            <div className={cnStartPage('TitleWrapper')}>
                <ClickerIcon />
                <div className={cnStartPage('Title')}>{i18nClicker('clicker')}</div>
                <div className={cnStartPage('Subtitle')}>{i18nClicker('subtitle')}</div>
            </div>
            <div className={cnStartPage('Buttons')}>
                <Button
                    className={cnStartPage('ButtonInstruction')}
                    view="default"
                    size="l"
                    onClick={openInstruction}
                >
                    {i18nClicker('instruction')}
                </Button>
                <Button
                    className={cnStartPage('ButtonManage')}
                    view="action"
                    size="l"
                    onClick={startManagement}
                    progress={isLoading}
                >
                    {i18nClicker('manage')}
                </Button>
            </div>
        </>
    );
};
