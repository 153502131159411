import { createAction, handleActions } from 'redux-actions';

const HIDE = 'vconf/yandexBug/HIDE';
const SHOW = 'vconf/yandexBug/SHOW';
const SET_PARAMS = 'vconf/yandexBug/SET_PARAMS';

export const hideYandexBug = createAction(HIDE);
export const showYandexBug = createAction(SHOW);
export const setParamsYandexBug = createAction<Params>(SET_PARAMS);

export interface YandexBug {
  isHidden: boolean,
  params: Params,
}

export const initialState = {
    isHidden: false,
    params: {},
};

export type Params = Record<string, string>;
type Payload = Params;

export const reducer = handleActions<YandexBug, Payload>(
    {
        [HIDE]: (state): YandexBug => ({
            ...state,
            isHidden: true,
        }),

        [SHOW]: (state): YandexBug => ({
            ...state,
            isHidden: false,
        }),

        [SET_PARAMS]: (state, { payload }): YandexBug => ({
            ...state,
            params: payload,
        }),
    },
    initialState,
);
