import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';
import './Icon_type_mobile.css';

export interface WithTypeMobileProps {
  type?: 'mobile';
}

export const withTypeMobile = withBemMod<WithTypeMobileProps>(
    'Icon',
    { type: 'mobile' },
    (Icon): FC => ((props): ReactElement => (<Icon {...props} />)),
);

export default withTypeMobile;
