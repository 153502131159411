export const ru = {
    'table-events-title': 'Текущие встречи из Календаря',
    'button-collect-call': 'Собрать звонок',
    'history-button-download-record': 'Скачать запись',
    'history-button-play-record': 'Посмотреть запись',
    'history-button-recollect-call': 'Пересобрать звонок',
    'table-active-calls-title': 'Активные',
    'show-all-active-calls-label': 'Показывать все',
    'empty-active-calls-table-text': 'У вас нет активных звонков',
    'empty-calendar-events-table-text': 'У вас нет встреч',
    'show-calendar-awesome': 'Перейти ко встрече в календаре',
    'show-share': 'Поделиться',
    'show-go-to-chat': 'Перейти в чат',
    'show-TV': "TV",
    'show-open-stream': "Открыть трансляцию",
    'on': 'Вкл',
    'off': 'Выкл',
    'table-streams-copy-link-title-permanent': 'Постоянная ссылка на трансляцию',
    'table-streams-copy-link-title': 'Ссылка на трансляцию',
    'copy': 'Скопировать',
    'stream-start-time': 'Время начала',
    'stream-lasts': 'Длится',
    'stream-name': 'Название',
    'stream-viewers': 'Зрители',
    'table-show-more': 'Показать ещё',
    'delete-record-title': 'Удалить запись',
    'delete-record-confirm-text': 'Удалить',
    'delete-record-cancel-text': 'Отменить',
    'filters-all': 'Все',
    'filters-with-records': 'С записями',
    'column-title-datetime': 'Дата и время',
    'column-title-name': 'Название и код',
    'column-title-organizer': 'Организатор',
    'column-title-participants': 'Участники',
    'column-title-record': 'Запись',
};
