import { ApiErrorI } from 'util/api.rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { createSelector } from 'reselect';
import { Action, createAction, handleActions } from 'redux-actions';
import { ActiveCallI } from 'components/ActiveCalls/ActiveCalls.interface';
import { PaginationPayload } from 'components/Table';
import { notyError } from 'components/Notifications/Notifications.actions';
import { INotificationMessageType } from 'components/Notifications/Notifications.interface';

import { StoreI } from '../store';

const REQUEST = 'vconf/callsHistory/REQUEST';
const REQUEST_APPEND = 'vconf/callsHistory/REQUEST_APPEND';
const RECEIVE = 'vconf/callsHistory/RECEIVE';
const RECEIVE_ERROR = 'vconf/callsHistory/RECEIVE_ERROR';
const DELETE_RECORD = 'vconf/callsHistory/DELETE_RECORD';
const DELETE_RECORD_SUCCESS = 'vconf/callsHistory/DELETE_RECORD_SUCCESS';

export interface RequestCallsHistoryPayload extends PaginationPayload {
    withRecords?: boolean
}
export type ReceiveCallsHistoryPayload = ActiveCallI[];
export interface DeleteRecordPayload {
    id: string;
}
export type DeleteRecordSuccessPayload = DeleteRecordPayload;

export const requestCallsHistory = createAction(REQUEST);
export const requestAppendCallsHistory = createAction(REQUEST_APPEND);
export const receiveCallsHistory = createAction<ReceiveCallsHistoryPayload>(RECEIVE);
export const receiveErrorCallsHistory = createAction<null>(RECEIVE_ERROR);
export const deleteRecord = createAction<DeleteRecordPayload>(DELETE_RECORD);
export const deleteRecordSuccess = createAction<DeleteRecordSuccessPayload>(DELETE_RECORD_SUCCESS);

export interface CallsHistory {
  isRequesting: boolean;
  isFirstLoading: boolean;
  calls: ActiveCallI[];
}

export const initialState: CallsHistory = {
    isRequesting: false,
    isFirstLoading: true,
    calls: [],
};

type Payload = ReceiveCallsHistoryPayload | DeleteRecordSuccessPayload;

export const reducer = handleActions<CallsHistory, Payload>(
    {
        [REQUEST]: (state): CallsHistory => ({
            ...state,
            calls: [],
            isRequesting: true,
        }),

        [REQUEST_APPEND]: (state): CallsHistory => ({
            ...state,
            isRequesting: true,
        }),

        [RECEIVE]: (state, action: Action<ReceiveCallsHistoryPayload>): CallsHistory => {
            const { payload } = action;
            return {
                calls: [...state.calls, ...payload],
                isRequesting: false,
                isFirstLoading: false,
            };
        },

        [RECEIVE_ERROR]: state => ({
            ...state,
            isRequesting: false,
        }),

        [DELETE_RECORD_SUCCESS]: (state, action: Action<DeleteRecordSuccessPayload>): CallsHistory => {
            const { payload } = action;
            const { id } = payload;

            const call = state.calls.find(elem => elem.id === id);
            call.record = false;

            return {
                ...state,
                calls: [...state.calls],
            };
        },
    },
    initialState,
);

type RequestHistoryResponsePayload = ReceiveCallsHistoryPayload | INotificationMessageType;
type DeleteRecordResponsePayload = DeleteRecordSuccessPayload | INotificationMessageType;

export const epic = combineEpics(
    (action$: Observable<Action<RequestCallsHistoryPayload>>,
        $state: StoreI,
        { historyCallsApiMethods: { getHistoryCalls } }): Observable<Action<RequestHistoryResponsePayload>> =>
        action$.pipe(
            ofType(REQUEST, REQUEST_APPEND),
            mergeMap(({ payload }): Observable<Action<RequestHistoryResponsePayload>> => {
                const {
                    limit = 6,
                    page = 1,
                    withRecords = false,
                } = payload;

                const query = new URLSearchParams({
                    limit: `${limit}`,
                    page: `${page}`,
                });

                if (withRecords) {
                    query.append('with_record', '1');
                }

                return getHistoryCalls(query).pipe(
                    map((response): Action<ReceiveCallsHistoryPayload> => {
                        const possiblyErrorResponse = response as ApiErrorI;

                        if (possiblyErrorResponse.error) {
                            throw possiblyErrorResponse;
                        }

                        return receiveCallsHistory(response as ReceiveCallsHistoryPayload);
                    }),
                    catchError((error: ApiErrorI): Observable<Action<INotificationMessageType | null>> => of(
                        notyError(error.message),
                        receiveErrorCallsHistory(null),
                    )),
                );
            },
            ),
        ),

    (action$: Observable<Action<DeleteRecordPayload>>,
        $state: StoreI,
        { historyCallsApiMethods: { deleteRecord } }): Observable<Action<DeleteRecordResponsePayload>> =>
        action$.pipe(
            ofType(DELETE_RECORD),
            mergeMap(({ payload }): Observable<Action<DeleteRecordResponsePayload>> =>
                deleteRecord(payload).pipe(
                    map((response): Action<DeleteRecordSuccessPayload> => {
                        const possiblyErrorResponse = response as ApiErrorI;

                        if (possiblyErrorResponse.error) {
                            throw possiblyErrorResponse;
                        }

                        return deleteRecordSuccess(payload as DeleteRecordSuccessPayload);
                    }),
                    catchError((error: ApiErrorI): Observable<Action<INotificationMessageType>> => of(
                        notyError(error.message),
                    )),
                ),
            ),
        ),
);

const selectHistory = (state: StoreI): CallsHistory => state.callsHistory;

export const selectHistoryCalls = createSelector(
    selectHistory,
    ({ calls }): ActiveCallI[] => (calls),
);

export const selectHistoryCallsIsRequesting = createSelector(
    selectHistory,
    ({ isRequesting }): boolean => (isRequesting),
);

export const selectHistoryCallsIsFirstLoading = createSelector(
    selectHistory,
    ({ isFirstLoading }): boolean => (isFirstLoading),
);
