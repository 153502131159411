const suggestOptions = {
    formAction: '//search.yandex-team.ru/search',
    suggestUrl: '//search.yandex-team.ru/suggest/',
    searchParams: {
        layers: ['people', 'services'],
        withGaps: true,
        withHighlight: true,
    },
};

const scripts = {
    toolsUserAccount: {
        desktop: params => require('@yandex-lego/serp-header/dist/base/tools-user-account.desktop').getContent(params),
        mobile: params => require('@yandex-lego/serp-header/dist/base/tools-user-account.touch-phone').getContent(params),
    },
    toolsHeader: {
        desktop: params => require('@yandex-lego/serp-header/dist/base/tools-header.desktop').getContent(params),
        mobile: params => require('@yandex-lego/serp-header/dist/base/tools-header.touch-phone').getContent(params),
    },
    notifier: {
        desktop: params => require('@yandex-lego/serp-header/dist/tools/notifier.desktop').getContent(params),
        mobile: params => require('@yandex-lego/serp-header/dist/tools/notifier.touch-phone').getContent(params),
    },
    toolsCrossNav: {
        desktop: params => require('@yandex-lego/serp-header/dist/base/tools-cross-navigation.desktop').getContent(params),
        mobile: params => require('@yandex-lego/serp-header/dist/base/tools-cross-navigation.touch-phone').getContent(params),
    },
    settings: {
        desktop: params => require('@yandex-lego/serp-header/dist/base/tools-settings.desktop').getContent(params),
        mobile: params => require('@yandex-lego/serp-header/dist/base/tools-settings.touch-phone').getContent(params),
    },
    toolsMenu: {
        desktop: params => require('@yandex-lego/serp-header/dist/base/tools-menu.desktop').getContent(params),
        mobile: params => require('@yandex-lego/serp-header/dist/base/tools-menu.touch-phone').getContent(params),
    },
    chat: {
        desktop: params => require('@yandex-lego/serp-header/dist/base/chat.desktop').getContent(params),
        mobile: params => require('@yandex-lego/serp-header/dist/base/chat.touch-phone').getContent(params),
    },
    toolsHeaderSuggest: {
        desktop: params => require('@yandex-lego/serp-header/dist/base/tools-header-suggest.desktop').getContent(params),
    },
    serpSuggest: {
        desktop: () => require('@yandex-lego/search-suggest/static.meta.js')['serp@desktop.js'],
        mobile: () => require('@yandex-lego/search-suggest/static.meta.js')['serp@touch-phone.js'],
    },
};

module.exports = { scripts, suggestOptions };
