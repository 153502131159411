import { cn } from '@bem-react/classname';
import React, { FC, ReactElement } from 'react';

import { TableCellProps } from './TableCell.interface';

export const cnTableCell = cn('TableCell');

export const TableCell: FC<TableCellProps> = (props: TableCellProps): ReactElement => {
    const {
        children,
        className,
        onTableCellActiveCallActionsClick,
    } = props;

    return (
        <div
            className={cnTableCell(null, [className])}
            onClick={onTableCellActiveCallActionsClick}
        >
            {children}
        </div>
    );
};

export default TableCell;
