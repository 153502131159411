import { ApiErrorI } from 'util/api.rxjs';
import { withBemMod, compose } from '@bem-react/core';
import i18n from '@yandex-int/i18n';
import React, { FC, ReactElement } from 'react';

import {
    Stub as StubBase,
    withTypeNoCalls as withStubTypeNoCalls,
} from 'components/Stub';

import { CalendarEventRouterScreenProps } from '../CalendarEventRouterScreen.interface';
import { cnCalendarEventRouterScreen } from '../CalendarEventRouterScreen';
import * as keyset from '../CalendarEventRouterScreen.i18n';

export interface WithError {
  error?: boolean;
  errorDetails?: ApiErrorI;
}

const i18nCalendarEventRouterScreen = i18n(keyset);

const Stub = compose(
    withStubTypeNoCalls,
)(StubBase);

export const withError = withBemMod<WithError, CalendarEventRouterScreenProps>(
    cnCalendarEventRouterScreen(),
    { error: true },
    (CalendarEventRouterScreen): FC<CalendarEventRouterScreenProps & WithError> => (
        (props: CalendarEventRouterScreenProps & WithError): ReactElement => {
            return (
                <CalendarEventRouterScreen {...props}>
                    <div className={cnCalendarEventRouterScreen('Stub')}>
                        <Stub
                            type="noCalls"
                            title={i18nCalendarEventRouterScreen('event-error-title')}
                            description={i18nCalendarEventRouterScreen('event-error-description')}
            />
                    </div>
                </CalendarEventRouterScreen>
            );
        }
    ),
);

export default withError;
