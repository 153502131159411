import { withBemMod, compose } from '@bem-react/core';
import i18n from '@yandex-int/i18n';
import React, { FC, ReactElement } from 'react';
import {
    Stub as StubBase,
    withTypeNoCalls,
} from 'components/Stub';

import { cnStreamsScreen } from 'components/StreamsScreen/StreamsScreen';
import { StreamsScreenProps } from 'components/StreamsScreen/StreamsScreen.interface';

import * as keyset from '../StreamsScreen.i18n';
import './StreamsScreen_empty.css';

export interface WithEmpty {
    empty?: boolean;
}

const i18nStreamsScreenEmpty = i18n(keyset);

const Stub = compose(
    withTypeNoCalls,
)(StubBase);

export const withEmpty = withBemMod<WithEmpty, StreamsScreenProps>(
    cnStreamsScreen(),
    { empty: true },
    (StreamsScreen): FC<WithEmpty & StreamsScreenProps> => (
        (props: WithEmpty & StreamsScreenProps): ReactElement => {
            const replacer = (
                <div className={cnStreamsScreen('Stub')}>
                    <Stub
                        type="noCalls"
                        title={i18nStreamsScreenEmpty('no-streams')}
                        description={i18nStreamsScreenEmpty('no-streams-description')}
                    />
                </div>
            );

            return (
                <StreamsScreen
                    {...props}
                    replacer={replacer}
                />
            );
        }
    ),
);
