export const ru = {
    'duration-label': 'Длительность',
    'end-conference': 'Завершить звонок для всех',
    'end': 'Завершить',
    'cancel': 'Отменить',
    'suggest-placeholder': 'Введите имя, переговорку или внешний номер',
    'active-calls-show-calendar-awesome': 'Перейти ко встрече в календаре',
    'active-calls-copy-call-link': 'Скопировать ссылку на звонок',
    'title-copy-uri': 'Скопировать URI звонка',
    'active-call-title': 'Активный звонок',
    'call-is-absent-title': 'Отсутствует звонок',
    'to-main-page': 'На главную',
    'call-is-not-available': 'Активный звонок недоступен',
    'create-call': 'Собрать звонок',
    'streaming': 'Транслируется',
    'no-streaming': 'Не транслируется',
    'recording': 'Записывается',
    'no-recording': 'Не записывается',
    'share-stream': 'Поделиться трансляцией',
    'disconnect-list-title': 'Отключились',
    'recall-all-button': 'Перезвонить всем',
    'participants-title': 'Участники',
    'browser-invite-link-title': 'Зайти через браузер',
    'browser-invite-link-copy-title': 'Скопировать ссылку для присоединения через браузер',
    'browser-invite-link-copy-text': 'Ссылка для присоединения через браузер скопирована',
};
