import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import ErrorBox from 'components/ErrorBox/ErrorBox';

import { cnStreamScreen } from '../StreamScreen.cn';
import { StreamScreenProps } from '../StreamScreen.interface';
import { WithErrorProps } from './StreamScreen_error.interface';
import './StreamScreen_error.css';

export const withError = withBemMod<WithErrorProps, StreamScreenProps>(
    cnStreamScreen(),
    { error: true },
    (StreamScreen): FC<WithErrorProps & StreamScreenProps> => (
        (props: WithErrorProps & StreamScreenProps): ReactElement => {
            const {
                responseError,
            } = props;

            let {
                replacer,
            } = props;

            if (responseError) {
                replacer = (
                    <ErrorBox className={cnStreamScreen('ErrorBox')} code={responseError.code} />
                );
            }

            return (
                <StreamScreen
                    {...props}
                    replacer={replacer}
                />
            );
        }
    ),
);
