import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';
import './Icon_type_streamEllipse.css';

export interface WithTypeStreamEllipseProps {
    type?: 'streamEllipse';
}

export const withTypeStreamEllipse = withBemMod<WithTypeStreamEllipseProps>(
    'Icon',
    { type: 'streamEllipse' },
    (Icon): FC => ((props): ReactElement => (<Icon {...props} />)),
);

export default withTypeStreamEllipse;
