import React, { FC, ReactElement, useCallback, useRef, useState } from 'react';
import { composeU } from '@bem-react/core';

import { Icon as IconBase } from '@yandex-lego/components/Icon/desktop';
import { withCalendarIcon } from '@yandex-int/hr-components/Icon/_glyph/Calendar';
import { withInNewTabIcon } from '@yandex-int/hr-components/Icon/_glyph/InNewTab';
import { withInfoIcon } from '@yandex-int/hr-components/Icon/_glyph/Info';

import { useHrComponentsPlatform } from 'hooks';

import { Link } from 'shared/ui/components/Link';
import { Button } from 'shared/ui/components/Button';
import { Tooltip } from 'shared/ui/components/Tooltip';

import { Chat } from 'components/Chat';

import { KhStreamScreenProps } from './KhStreamScreen.interface';
import { RateInformer } from './RateInformer';
import { Controls } from './Controls/KhStreamScreen-Controls';
import { cnKhStreamScreen } from './KhStreamScreen.cn';
import { i18n } from './KhStreamScreen.translations';

import './KhStreamScreen.css';

export const registryId = cnKhStreamScreen();

const chatFlags = ['disableNavigation'];

const Icon = composeU(
    withCalendarIcon,
    withInNewTabIcon,
    withInfoIcon,
)(IconBase);

const calendarIconProvider = (className: string) => (
    <Icon glyph="calendar" size="m" className={className} sizeManagement="parent" />
);

const inNewTabIconProvider = (className: string) => (
    <Icon glyph="inNewTab" size="m" className={className} sizeManagement="parent" />
);

const infoIconProvider = (className: string) => (
    <Icon glyph="info" size="m" className={className} sizeManagement="parent" />
);

const TROUBLESHOOTING_WIKI_URL = 'https://wiki.yandex-team.ru/vp/users/drmhowto/';

export const KhStreamScreen: FC<KhStreamScreenProps> = (props: KhStreamScreenProps): ReactElement => {
    const {
        name,
        addonBefore,
        description,
        chatInviteHash,
        env,
        replacer,
        player,
        uri,
        eventId,
        stopTime,
        className,
    } = props;

    const { isDesktop } = useHrComponentsPlatform();

    const troubleshootingButtonRef = useRef();
    const [isTroubleshootingVisible, setIsTroubleshootingVisible] = useState(false);

    const onTroubleshootingClick = useCallback(() => {
        setIsTroubleshootingVisible(!isTroubleshootingVisible);
    }, [isTroubleshootingVisible]);

    const onTroubleshootingClose = useCallback(() => {
        setIsTroubleshootingVisible(false);
    }, []);

    const calendarEventUrl = new URL(`https://calendar.yandex-team.ru/event/${eventId}`);

    return (
        <div className={cnKhStreamScreen({}, [className])}>
            {addonBefore}
            <div className={cnKhStreamScreen('Content')}>
                {
                    replacer || (
                        <>
                            <div className={cnKhStreamScreen('Sides')}>
                                <div className={cnKhStreamScreen('Left')}>
                                    {player}
                                    <div className={cnKhStreamScreen('Wrapper')}>
                                        <h1 className={cnKhStreamScreen('Title')}>{name}</h1>
                                        <Controls {...props} />
                                    </div>
                                    {
                                        description && isDesktop && (
                                            <div className={cnKhStreamScreen('Description')}>
                                                {description}
                                            </div>
                                        )
                                    }
                                    {
                                        isDesktop && (
                                            <div className={cnKhStreamScreen('Links')}>
                                                <Button
                                                    view="clear"
                                                    size="m"
                                                    iconLeft={calendarIconProvider}
                                                    target="_blank"
                                                    type="link"
                                                    url={calendarEventUrl.toString()}
                                                    title="Добавить событие Хурала себе в календарь"
                                                >
                                                    {i18n('add-to-calendar')}
                                                </Button>
                                                <Button
                                                    view="clear"
                                                    size="m"
                                                    iconLeft={inNewTabIconProvider}
                                                    target="_blank"
                                                    type="link"
                                                    url="https://q.yandex-team.ru/#/join/531ecedb-942e-4816-8d2f-450546f51b7e"
                                                >
                                                    {i18n('text-stream')}
                                                </Button>
                                                <Button
                                                    view="clear"
                                                    iconLeft={infoIconProvider}
                                                    size="m"
                                                    innerRef={troubleshootingButtonRef}
                                                    onClick={onTroubleshootingClick}
                                                >
                                                    {i18n('button.troubleshooting')}
                                                </Button>
                                                <Tooltip
                                                    view="default"
                                                    size="m"
                                                    anchor={troubleshootingButtonRef}
                                                    scope="inplace"
                                                    hasTail
                                                    visible={isTroubleshootingVisible}
                                                    onClose={onTroubleshootingClose}
                                                    className={cnKhStreamScreen('TroubleshootingTooltip')}
                                                >
                                                    <div className={cnKhStreamScreen('TroubleshootingContent')}>
                                                        <div className={cnKhStreamScreen('Solution')}>
                                                            {i18n('solution.first')}
                                                        </div>
                                                        <div className={cnKhStreamScreen('Solution')}>
                                                            {i18n('solution.second')}
                                                        </div>
                                                        <div className={cnKhStreamScreen('Solution')}>
                                                            {i18n('solution.third')}
                                                            <br />
                                                            <Link
                                                                theme="normal"
                                                                target="_blank"
                                                                href={TROUBLESHOOTING_WIKI_URL}
                                                            >
                                                                {TROUBLESHOOTING_WIKI_URL}
                                                            </Link>
                                                        </div>
                                                        <div className={cnKhStreamScreen('Solution')}>
                                                            {i18n('solution.fourth')}
                                                        </div>
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        )
                                    }
                                </div>
                                <div className={cnKhStreamScreen('Right')}>
                                    <Chat
                                        className={cnKhStreamScreen('Chat')}
                                        chatInviteHash={chatInviteHash}
                                        env={env}
                                        flags={chatFlags}
                                    />
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
            <RateInformer
                uri={uri}
                stopTime={stopTime}
                name={name}
                env={env}
            />
        </div>
    );
};

export default KhStreamScreen;
