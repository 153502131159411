import { Subject } from 'rxjs';
import { UserError } from '../../ducks/user';

export type SlideEvent = SocketEventName.LEFT | SocketEventName.RIGHT;
export type MessageCode = 'nextSlide' | 'click' | 'change' | 'prevSlide' | 'early' | 'back';
export type MessageCodes = Record<SlideEvent, MessageCode[]>;
export type UserMessageCodes = Record<SlideEvent, MessageCode>;

export enum SocketEventName {
    LEFT = 'LEFT',
    RIGHT = 'RIGHT',
    START_MANAGEMENT = 'START_MANAGEMENT',
    END_MANAGEMENT = 'END_MANAGEMENT',
    ECHO = 'ECHO',
    PING = 'PING',
}

export enum ChangeSlideType {
    NEXT = 'next',
    PREVIOUS = 'previous',
}

export enum ArrowKey {
    LEFT = 'ArrowLeft',
    RIGHT = 'ArrowRight',
}

export enum BadgeColor {
    SUCCESS = 'var(--hr-color-accent-positive-default)',
    PENDING = 'var(--hr-color-accent-brand-default)',
    ERROR = 'var(--hr-color-accent-negative-default)',
}

export enum ClickerPage {
    START = 'START',
    MAIN = 'MAIN',
    INSTRUCTION_INTRODUCTION = 'INSTRUCTION_INTRODUCTION',
    INSTRUCTION_IMPORTANT = 'INSTRUCTION_IMPORTANT',
    INSTRUCTION_FINISH = 'INSTRUCTION_FINISH',
}

export interface ClickerMessage {
    id: string;
    isProcessed: boolean;
    eventName: SlideEvent;
    timestamp: number;
    login: string;
    messageCode: string;
}

export interface ClickerEvent {
    event_name: SocketEventName;
    id?: string;
    login?: string;
    time?: string;
    user?: string;
}

export interface WebSocketData {
    id: string | null,
    env: string | null;
    webSocketSubject: Subject<ClickerEvent> | null,
    onOpenSubject: Subject<Event> | null;
    onCloseSubject: Subject<Event> | null;
}

export interface ClickerMapStateToProps {
    messages: ClickerMessage[];
    userError: UserError;
    currentPage: ClickerPage;
    env: string;
    isLoading: boolean;
}

export interface ClickerMapDispatchToProps {
    onChangeSlide: (id: string, env: string, slide: ChangeSlideType) => void;
    onChangePage: (page: ClickerPage, id?: string, env?: string) => void;
    onConnect: (id: string, env: string) => void;
    getUser: () => void;
    showInactivityWarning: (message: string) => void;
}

export type ClickerOwnProps = {
    authRequired?: boolean;
}

export type ClickerProps = ClickerMapStateToProps & ClickerMapDispatchToProps & ClickerOwnProps;
