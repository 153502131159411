import { combineActions, createAction, handleAction } from 'redux-actions';
import {
    INotificationMessageType,
    NotificationPayloadI,
    INotificationTypes,
} from './Notifications.interface';

const notyErrorAction = 'noty:error';
const notyWarinigAction = 'noty:warning';
const notySuccessAction = 'noty:success';

export const notyError = createAction<INotificationMessageType>(notyErrorAction);
export const notyWarning = createAction<INotificationMessageType>(notyWarinigAction);
export const notySuccess = createAction<INotificationMessageType>(notySuccessAction);

const mapActionToType: { [key: string]: INotificationTypes } = {
    [notyErrorAction]: 'error',
    [notyWarinigAction]: 'warn',
    [notySuccessAction]: 'success',
};

export const initialState: NotificationPayloadI = {
    message: '',
    ts: 0,
    type: 'success',
};

export const reducer = handleAction<NotificationPayloadI, NotificationPayloadI>(
    combineActions(notyError, notyWarning, notySuccess),
    {
        next: (state, { payload, type }): NotificationPayloadI => {
            if (payload) {
                const notyType: INotificationTypes = mapActionToType[type];
                return { message: payload, ts: Date.now(), type: notyType };
            }

            return state;
        },
        throw: (): NotificationPayloadI => initialState,
    },
    initialState,
);
