import { withBemMod, compose } from '@bem-react/core';

import { Button } from '@yandex-lego/components/Button/desktop/bundle';

import {
    Icon as IconBase,
    withTypeClose,
} from '@yandex-lego/components/Icon/desktop';
import React, { FC, ReactElement } from 'react';

import { cnParticipant } from '../Participant@desktop';
import { ParticipantProps } from '../Participant.interface';
import { WithDeleteProps } from './Participant_delete.interface';
import './Participant_delete@desktop.css';

const Icon = compose(
    withTypeClose,
)(IconBase);

const iconClose = (iconClassName: string): ReactElement => (<Icon type="close" className={iconClassName} />);

export const withDelete = withBemMod<WithDeleteProps, ParticipantProps>(
    cnParticipant(), { delete: true }, (Participant): FC<WithDeleteProps & ParticipantProps> => (
        (props: WithDeleteProps & ParticipantProps): ReactElement => {
            const {
                onDelete,
            } = props;

            const deleteButton = (
                <div className={cnParticipant('DeleteButton')}>
                    <Button
                        onClick={onDelete}
                        view="clear"
                        size="s"
                        icon={iconClose}
                    />
                </div>
            );

            return (
                <Participant
                    {...props}
                    deleteButton={deleteButton}
                />
            );
        }
    ),
);

export default withDelete;
