import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import { cnStreamScreen } from '../StreamScreen.cn';
import { i18nStreamScreen } from '../StreamScreen';
import { StreamScreenProps } from '../StreamScreen.interface';
import { WithEmptyProps } from './StreamScreen_empty.interface';

import './StreamScreen_empty.css';

export const withEmpty = withBemMod<WithEmptyProps, StreamScreenProps>(
    cnStreamScreen(),
    { empty: true },
    (StreamScreen): FC<WithEmptyProps & StreamScreenProps> => (
        (props: WithEmptyProps & StreamScreenProps): ReactElement => {
            const replacer = (
                <div className={cnStreamScreen('NoData')}>
                    {i18nStreamScreen('no-data-text')}
                </div>
            );

            return (
                <StreamScreen
                    {...props}
                    replacer={replacer}
                />
            );
        }
    ),
);
