import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import { cnStub } from '../Stub';
import { StubProps } from '../Stub.interface';
import { WithTypeNoConnection } from './Stub_type_noConnection.interface';
import './Stub_type_noConnection.css';
import image from './assets/NoConnectionStub.svg';

export const withTypeNoConnection = withBemMod<WithTypeNoConnection, StubProps>(
    cnStub(),
    { type: 'noConnection' },
    (Stub): FC<WithTypeNoConnection & StubProps> => (
        (props: WithTypeNoConnection & StubProps): ReactElement => {
            return (
                <Stub
                    {...props}
                    image={image}
        />
            );
        }
    ),
);

export default withTypeNoConnection;
