import React, { FC, ReactElement } from 'react';

import { BackLink } from 'components/BackLink';

import { TemplateFormScreenHeaderProps } from './TemplateFormScreen-Header.interface';
import { cnTemplateFormScreen } from '../TemplateFormScreen';

import './TemplateFormScreen-Header.css';

export const TemplateFormScreenHeaderDesktop: FC<TemplateFormScreenHeaderProps> = (
    props: TemplateFormScreenHeaderProps): ReactElement => {
    const {
        title,
        className,
    } = props;

    return (
        <div className={cnTemplateFormScreen('Header', [className])}>
            <BackLink
                title={title}
                url="/"
                className={cnTemplateFormScreen('TitleBackLink')}
      />
        </div>
    );
};

export default TemplateFormScreenHeaderDesktop;
