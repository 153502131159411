import i18n from '@yandex-int/i18n';
import { composeU } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import {
    CallFormTitle as CallFormTitleBase,
    withTypeEvent as withCallFormTitleTypeEvent,
    withEdit as withCallFormTitleEdit,
} from 'components/CallFormTitle';

import { CallScreenHeaderProps } from './CreateCallScreen-Header.interface';
import { cnCreateCallScreen } from '../CreateCallScreen';

import * as keyset from '../CreateCallScreen.i18n';
import './CreateCallScreen-Header.css';

const i18nCallScreen = i18n(keyset);

const CallFormTitle = composeU(
    withCallFormTitleTypeEvent,
    withCallFormTitleEdit,
)(CallFormTitleBase);

export const Header: FC<CallScreenHeaderProps> =
  (props: CallScreenHeaderProps): ReactElement => {
      const {
          calendarEventMeta,
          onSetNameValue,
          name,
          className,
      } = props;

      let callFormTitleType;
      let defaultTitleValue = i18nCallScreen('without-name');

      if (calendarEventMeta?.event) {
          callFormTitleType = 'event';
          defaultTitleValue = calendarEventMeta.event.name;
      }

      return (
          <div className={cnCreateCallScreen('Header', [className])}>
              <CallFormTitle
                  type={callFormTitleType as undefined}
                  eventId={calendarEventMeta?.event?.id}
                  title={name || defaultTitleValue}
                  value={name}
                  edit
                  setValue={onSetNameValue}
              />
          </div>
      );
  };
