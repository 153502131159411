import { useEffect } from 'react';
import { UserError } from 'ducks/user';

export const useUserError = (userError: UserError, authRequired?: boolean) => {
    const isError = Boolean(userError);
    const isUserUnauthorizedError = isError && userError.code === 401;

    useEffect(() => {
        if (authRequired && isUserUnauthorizedError) {
            window.location.href = `https://passport.yandex-team.ru/auth?retpath=${encodeURIComponent(window.location.href)}`;
        }
    }, [authRequired, isUserUnauthorizedError]);

    return {
        isError,
        isUserUnauthorizedError,
    };
};
