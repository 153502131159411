import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import { useKhTitle } from 'hooks';

import { KhErrorState } from 'khural-components/KhErrorState';

import { cnKhStreamScreen } from '../KhStreamScreen.cn';
import { i18n } from '../KhStreamScreen.translations';
import { KhStreamScreenProps } from '../KhStreamScreen.interface';
import { WithErrorProps } from './KhStreamScreen_error.interface';

import gooseError from '../assets/goose-error.png';

import './KhStreamScreen_error.css';

export const withError = withBemMod<WithErrorProps, KhStreamScreenProps>(
    cnKhStreamScreen(),
    { error: true },
    (KhStreamScreen): FC<WithErrorProps & KhStreamScreenProps> => (
        (props: WithErrorProps & KhStreamScreenProps): ReactElement => {
            const {
                responseError,
            } = props;

            let {
                replacer,
            } = props;

            if (responseError) {
                let errorCode = responseError.code === 404 ? 'not-found' : 'smth-went-wrong';

                useKhTitle([i18n('error')]);

                replacer = (
                    <div className={cnKhStreamScreen('Error')}>
                        <KhErrorState
                            size="m"
                            image={gooseError}
                            title={i18n(`error.${errorCode}.title`)}
                            description={i18n(`error.${errorCode}.description`)}
                            className={cnKhStreamScreen('ErrorState')}
                        />
                    </div>
                );
            }

            return (
                <KhStreamScreen
                    {...props}
                    replacer={replacer}
                />
            );
        }
    ),
);
