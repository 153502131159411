import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';
import './Icon_type_ban.css';

export interface WithTypeBanProps {
  type?: 'ban';
}

export const withTypeBan = withBemMod<WithTypeBanProps>(
    'Icon',
    { type: 'ban' },
    (Icon): FC => ((props): ReactElement => (<Icon {...props} />)),
);
