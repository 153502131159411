import { cn } from '@bem-react/classname';

import { Modal } from '@yandex-lego/components/Modal/desktop/bundle';
import React, { FC, ReactElement } from 'react';

import { ConfirmationModalProps } from './ConfirmationModal.interface';
import './ConfirmationModal.css';

export const cnConfirmationModal = cn('ConfirmationModal');

export const ConfirmationModal: FC<ConfirmationModalProps> = (props: ConfirmationModalProps): ReactElement => {
    const {
        title,
        confirmButton,
        cancelButton,
        visible,
        onOutsideClick,
        className,
    } = props;

    return (
        <Modal
            theme="normal"
            visible={visible}
            onClose={onOutsideClick}
            keepMounted={false}
            contentVerticalAlign="middle"
            className={cnConfirmationModal({}, [className])}
        >
            <div className={cnConfirmationModal('Content')}>
                <h3 className={cnConfirmationModal('Title')}>{title}</h3>
                <div className={cnConfirmationModal('Buttons')}>
                    <div className={cnConfirmationModal('ConfirmButton')}>{confirmButton}</div>
                    <div className={cnConfirmationModal('CancelButton')}>{cancelButton}</div>
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmationModal;
