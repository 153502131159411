import i18n from '@yandex-int/i18n';
import React, { FC, ReactElement } from 'react';

import { ViewersCounter } from 'components/ViewersCounter';

import { StreamPosterViewersProps } from './StreamPoster-Viewers.interface';
import { cnStreamPoster } from '../StreamPoster';
import * as keyset from '../StreamPoster.i18n';
import './StreamPoster-Viewers.css';

const i18nStreamPoster = i18n(keyset);

export const Viewers: FC<StreamPosterViewersProps> = (props: StreamPosterViewersProps): ReactElement => {
    const {
        viewers,
        onViewersCounterClick,
    } = props;

    if (viewers) {
        return (
            <ViewersCounter
                count={viewers}
                onViewersCounterClick={onViewersCounterClick}
                title={i18nStreamPoster('viewers-count')}
                className={cnStreamPoster('Viewers')}
            />
        );
    }

    return null;
};
