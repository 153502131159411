import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';
import './Icon_type_zoom.css';

export interface WithTypeZoomProps {
  type?: 'zoom';
}

export const withTypeZoom = withBemMod<WithTypeZoomProps>(
    'Icon',
    { type: 'zoom' },
    (Icon): FC => ((props): ReactElement => (<Icon {...props} />)),
);
