import { composeU, withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import {
    MethodSelector as MethodSelectorBase,
    withTypeRadio,
    getMethods,
} from 'components/MethodSelector';

import { cnParticipant } from '../Participant@desktop';
import { ParticipantMobileProps } from '../Participant.interface';
import { WithMethodSelectProps } from './Participant_methodSelect.interface';
import './Participant_methodSelect@mobile.css';

const MethodSelector = composeU(
    withTypeRadio,
)(MethodSelectorBase);

export const withMethodSelect = withBemMod<WithMethodSelectProps, ParticipantMobileProps>(
    cnParticipant(), { methodSelect: true }, (Participant): FC<WithMethodSelectProps & ParticipantMobileProps> => (
        (props: WithMethodSelectProps & ParticipantMobileProps): ReactElement => {
            const {
                onChangeSelectedMethod,
                value,
                options = [],
                changeSelectedDisabled,
            } = props;

            const select = (
                <MethodSelector
                    value={value}
                    options={options}
                    onChange={onChangeSelectedMethod}
                    className={cnParticipant('Radiobutton')}
                    size="s"
                    type="radio"
                    disabled={changeSelectedDisabled}
                />
            );

            const methods = getMethods();

            return (
                <Participant
                    {...props}
                    addon={select}
                    actionType={methods[value]}
                />
            );
        }
    ),
);

export default withMethodSelect;
