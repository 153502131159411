import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';
import './Icon_type_cisco.css';

export interface WithTypeCiscoProps {
  type?: 'cisco';
}

export const withTypeCisco = withBemMod<WithTypeCiscoProps>(
    'Icon',
    { type: 'cisco' },
    (Icon): FC => ((props): ReactElement => (<Icon {...props} />)),
);
