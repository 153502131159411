import { cn } from '@bem-react/classname';
import { composeU } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import {
    StreamPoster as StreamPosterBase,
    withSizeL as withStreamPosterSizeL,
    withSizeM as withStreamPosterSizeM,
    withSizeS as withStreamPosterSizeS,
} from 'components/StreamPoster';

import { StreamPosterPreviewProps } from './StreamPosterPreview.interface';
import './StreamPosterPreview.css';

export const cnStreamPosterPreview = cn('StreamPosterPreview');

const StreamPoster = composeU(
    withStreamPosterSizeL,
    withStreamPosterSizeM,
    withStreamPosterSizeS,
)(StreamPosterBase);

export const StreamPosterPreview: FC<StreamPosterPreviewProps> = (props): ReactElement => {
    const {
        title,
        description,
        posterUrl,
        className,
    } = props;

    return (
        <div className={cnStreamPosterPreview(null, [className])}>
            <StreamPoster
                type="current"
                name={title}
                description={description}
                streamPosterUrl={posterUrl}
                size="l"
                info={null}
            />
            <StreamPoster
                type="current"
                name={title}
                description={description}
                streamPosterUrl={posterUrl}
                size="m"
                info={null}
            />
            <StreamPoster
                type="current"
                name={title}
                description={description}
                streamPosterUrl={posterUrl}
                size="s"
                info={null}
            />
        </div>
    );
};
