import { cn } from '@bem-react/classname';
import i18n from '@yandex-int/i18n';
import React, { FC, ReactElement } from 'react';

import { BackLink } from 'components/BackLink';

import { CallFormTitleProps } from './CallFormTitle.interface';
import * as keyset from './CallFormTitle.i18n';
import './CallFormTitle.css';

export const cnCallFormTitle = cn('CallFormTitle');

export const i18nCallFormTitle = i18n(keyset);

export const CallFormTitle: FC<CallFormTitleProps> = (props: CallFormTitleProps): ReactElement => {
    const {
        title,
        contentReplacer,
        addonAfter,
        className,
    } = props;

    return (
        <div className={cnCallFormTitle(null, [className])}>
            <BackLink
                title={i18nCallFormTitle('to-main-page')}
                url="/"
                className={cnCallFormTitle('BackLink')}
            />
            <div
                className={cnCallFormTitle('Title')}
                title={title}
            >
                {
                    contentReplacer || (
                        <span className={cnCallFormTitle('Text')}>{title}</span>
                    )
                }
                {addonAfter}
            </div>
        </div>
    );
};
