import React, { FC, ReactElement } from 'react';
import { withBemMod, composeU } from '@bem-react/core';

import { Select, Option } from '@yandex-lego/components/Select/desktop/bundle';

import { IParticipantMethods } from 'components/Participant/Participant.interface';

import { WithTypeSelect } from './MethodSelector_type_select.interface';
import {
    MethodSelectorProps,
    MethodsToOptionTypeMap,
} from '../MethodSelector.interface';
import { cnMethodSelector } from '../MethodSelector';
import {
    OptionContent as OptionContentBase,
    withTypeMessengerQ as withOptionContentTypeMessengerQ,
    withTypeMobile as withOptionContentTypeMobile,
    withTypeCisco as withOptionContentTypeCisco,
    withTypeEmail as withOptionContentTypeEmail,
} from '../OptionContent';
import { getMethods } from '../MethodSelector.util';

const OptionContent = composeU(
    withOptionContentTypeEmail,
    withOptionContentTypeCisco,
    withOptionContentTypeMobile,
    withOptionContentTypeMessengerQ,
)(OptionContentBase);

const MethodToOptionTypeMap: MethodsToOptionTypeMap = {
    cisco: 'cisco',
    messenger_q: 'messengerQ',
    mobile: 'mobile',
    email: 'email',
    placeholder: 'default',
};

export const withTypeSelect = withBemMod<WithTypeSelect, MethodSelectorProps>(
    cnMethodSelector(),
    { type: 'select' },
    (MethodSelector): FC<MethodSelectorProps & WithTypeSelect> => (
        (props: MethodSelectorProps & WithTypeSelect): ReactElement => {
            const {
                value,
                options = [],
                onChange,
                disabled,
                size,
            } = props;

            const methods = getMethods();

            return (
                <MethodSelector
                    {...props}
                >
                    <Select
                        value={value}
                        onChange={onChange}
                        options={
                          options
                              .filter((option: IParticipantMethods): string => methods[option])
                              .map((option: IParticipantMethods): Option => ({
                                  value: option,
                                  checkedText: methods[option],
                                  content: (
                                      <OptionContent
                                          method={option}
                                          type={MethodToOptionTypeMap[option] as undefined}
                                      />
                                  ),
                              }))
                        }
                        view="default"
                        size={size}
                        width="max"
                        disabled={disabled}
                    />
                </MethodSelector>
            );
        }
    ),
);

export default withTypeSelect;
