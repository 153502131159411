import { createCallFormMock, createCallFormApiMock } from 'components/CreateCallForm/CreateCallForm.mock';
import { templateMock, templateApiMock } from 'components/Template/Template.mock';
import { calendarEventApiMock, calendarEventMock } from 'components/CalendarEvents/CalendarEvents.mock';

import { CalendarEventMeta, CalendarEventMetaApi } from './calendarEventMeta';

export const calendarEventMetaMock: CalendarEventMeta = {
    call: {
        ...createCallFormMock,
    },
    template: {
        ...templateMock,
    },
    event: {
        ...calendarEventMock,
    },
    relevantTemplates: [
        {
            ...templateMock,
        },
    ],
};

export const calendarEventMetaApiMock: CalendarEventMetaApi = {
    call: {
        ...createCallFormApiMock,
    },
    template: {
        ...templateApiMock,
    },
    event: {
        ...calendarEventApiMock,
    },
    relevant_templates: [
        {
            ...templateApiMock,
        },
    ],
};
