import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';
import './Icon_type_check.css';

export interface WithTypeCheckProps {
  type?: 'check';
}

export const withTypeCheck = withBemMod<WithTypeCheckProps>(
    'Icon',
    { type: 'check' },
    (Icon): FC => ((props): ReactElement => (<Icon {...props} />)),
);
