import React, { FC, ReactElement } from 'react';
import { cn } from '@bem-react/classname';
import i18n from '@yandex-int/i18n';
import { Select } from '@yandex-lego/components/Select/desktop/bundle';

import { DurationSelectorProps } from './DurationSelector.interface';
import * as keyset from './DurationSelector.i18n';

const cnDurationSelector = cn('DurationSelector');
const i18nDurationSelector = i18n(keyset);

export const DurationSelector: FC<DurationSelectorProps> = (props: DurationSelectorProps): ReactElement => {
    const {
        value,
        size,
        onChange,
        className,
        minValue,
        disabled,
    } = props;

    return (
        <div className={cnDurationSelector(null, [className])}>
            <Select
                value={`${value}`}
                onChange={onChange}
                disabled={disabled}
                options={[
                    {
                        value: '30',
                        content: `30 ${i18nDurationSelector('minutes')}`,
                        disabled: minValue >= 30,
                    },
                    {
                        value: '60',
                        content: `60 ${i18nDurationSelector('minutes')}`,
                        disabled: minValue >= 60,
                    },
                    {
                        value: '120',
                        content: `120 ${i18nDurationSelector('minutes')}`,
                        disabled: minValue >= 120,
                    },
                    {
                        value: '240',
                        content: `240 ${i18nDurationSelector('minutes')}`,
                        disabled: minValue >= 240,
                    },
                    {
                        value: '720',
                        content: `720 ${i18nDurationSelector('minutes')}`,
                        disabled: minValue >= 720,
                    },
                ]}
                view="classic"
                size={size}
                theme="normal"
                width="max"
            />
        </div>
    );
};

export default DurationSelector;
