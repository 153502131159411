import { i18nHeader } from './Header.i18n';
import { Page } from './Header.interface';
import { menu } from './Header.constants';

export const appendStaticScript = (scriptSrc: string, scriptType: string, raw: boolean = true): void => {
    const scriptElement = document.createElement('script');

    if (scriptType) {
        scriptElement.setAttribute('data-script-type', scriptType);
    }

    if (raw) {
        scriptElement.innerHTML = scriptSrc;
    } else {
        scriptElement.src = scriptSrc;
    }

    document.body.appendChild(scriptElement);
};

export const removeStaticScript = (scriptType?: string) => {
    if (!scriptType) return;

    const script = document.body.querySelector(`script[data-script-type=${scriptType}]`);

    if (script) {
        script.remove();
    }
};

export const getCurrentPage = (): string => `/${window.location.pathname.split('/').filter(Boolean)[0] || ''}`;

export const getMenuCtx = () => Object.keys(menu).map((pageKey: Page) => ({
    ...menu[pageKey],
    text: i18nHeader(menu[pageKey].textKey),
    eventType: pageKey,
    active: getCurrentPage() === menu[pageKey].url ? 'yes' : false,
}));
