import { IClassNameProps, withBemMod } from '@bem-react/core';
import { cnMessageBar } from '@yandex-int/tools-components/MessageBar/desktop';

import './MessageBar_fullWidth.css';

export interface WithFullWidthProps extends IClassNameProps {
    /** Ширина */
    fullWidth?: boolean;
}

/**
 * Модификатор, отвечающий за тип иконки.
 */
export const withFullWidth = withBemMod<WithFullWidthProps>(cnMessageBar(), { fullWidth: true });
