import { compose, withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import {
    ParticipantAvatar as ParticipantAvatarBase,
    withTypeRoom as withParticipantAvatarTypeRoom,
} from 'components/ParticipantAvatar';

import { cnParticipant } from '../Participant@desktop';
import { ParticipantProps } from '../Participant.interface';
import { WithTypeRoomProps } from './Participant_type_room.interface';

const ParticipantAvatar = compose(
    withParticipantAvatarTypeRoom,
)(ParticipantAvatarBase);

export const withTypeRoom = withBemMod<WithTypeRoomProps, ParticipantProps>(
    cnParticipant(),
    { type: 'room' },
    (Participant): FC<ParticipantProps & WithTypeRoomProps> => (
        (props: ParticipantProps & WithTypeRoomProps): ReactElement => {
            const {
                title,
            } = props;

            const icon = (
                <ParticipantAvatar type="room" />
            );

            const name = (
                <div className={cnParticipant('Name')}>{title}</div>
            );

            return (
                <Participant
                    {...props}
                    icon={icon}
                    name={name}
        />
            );
        }
    ),
);

export default withTypeRoom;
