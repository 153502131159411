import { withBemMod } from '@bem-react/core';

import { Spin } from '@yandex-lego/components/Spin/bundle';
import React, { FC, ReactElement } from 'react';

import { TemplateFormScreenProps } from '../TemplateFormScreen.interface';
import { cnTemplateFormScreen } from '../TemplateFormScreen';
import './TemplateFormScreen_loading.css';

export interface WithLoading {
  loading?: boolean;
}

export const withLoading = withBemMod<WithLoading, TemplateFormScreenProps>(
    cnTemplateFormScreen(),
    { loading: true },
    (TemplateFormScreen): FC<WithLoading & TemplateFormScreenProps> => (
        (props: WithLoading & TemplateFormScreenProps): ReactElement => {
            const replacer = (
                <div className={cnTemplateFormScreen('Spinner')}>
                    <Spin
                        view="default"
                        size="l"
                        progress
                    />
                </div>
            );

            return (
                <TemplateFormScreen
                    {...props}
                    replacer={replacer}
        />
            );
        }
    ),
);

export default withLoading;
