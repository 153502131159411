import dayjs from 'dayjs';
import { withBemMod, compose, composeU } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import i18n from '@yandex-int/i18n';

import { Button as ButtonBase } from '@yandex-lego/components/Button/desktop/bundle';
import { Icon as IconBase } from '@yandex-lego/components/Icon/desktop';
import React, { FC, ReactElement } from 'react';
import {
    withPopup as withButtonPopup,
    withResponsive as withButtonResponsive,
} from 'components/Button';

import {
    CopyText as BaseCopyText,
    withIconSquare,
} from 'components/CopyText';

import {
    withTypeHandset as withIconTypeHandset,
    withTypeClock as withIconTypeClock,
    withTypeDownload as withIconTypeDownload,
    withTypeComment as withIconTypeComment,
    withTypeShare as withIconTypeShare,
    withTypePlay as withIconTypePlay,
    withSizeXs as withIconSizeXs,
    withSizeS as withIconSizeS,
    withSizeM as withIconSizeM,
    withTypeCalendarAwesome as withIconTypeCalendarAwesome,
} from 'components/Icon';

import {
    Duration as BaseDuration,
    withToneBlack as withDurationToneBlack,
} from 'components/Duration';

import {
    StreamsTableProps,
    WithTypeStreamsProps,
} from './Table_type_streams.interface';
import { cnTable } from '../Table';

import './Table_type_streams.css';
import * as keyset from '../Table.i18n';

const i18nTable = i18n(keyset);

const CopyText = compose(withIconSquare)(BaseCopyText);

const Icon = compose(
    composeU(
        withIconTypeHandset,
        withIconTypeClock,
        withIconTypeDownload,
        withIconTypeComment,
        withIconTypeShare,
        withIconTypePlay,
        withIconTypeCalendarAwesome,
    ),
    composeU(
        withIconSizeXs,
        withIconSizeS,
        withIconSizeM,
    ),
)(IconBase);

const Button = compose(
    withButtonResponsive,
    withButtonPopup,
)(ButtonBase);

const Duration = compose(withDurationToneBlack)(BaseDuration);

const cnShareLinkPopup = cn('ShareLinkPopup');

const iconShare = (iconClassName: string): ReactElement => <Icon type="share" size="m" className={iconClassName} />;
const iconComment = (iconClassName: string): ReactElement => <Icon type="comment" size="m" className={iconClassName} />;
const iconPlay = (iconClassName: string): ReactElement => <Icon type="play" size="m" className={iconClassName} />;
const iconCalendarAwesome = (iconClassName: string): ReactElement => (<Icon type="calendarAwesome" size="m" className={iconClassName} />);

const StreamsTable = (innerProps: StreamsTableProps): ReactElement => {
    const {
        streams,
        isTvButtonVisible = false,
        SourceTable,
        push,
    } = innerProps;

    const getNavigationCallback = React.useCallback((location: string): () => void => {
        return (): void => {
            push(location);
        };
    }, [push]);

    const rows = streams.map((stream): ReactElement => {
        const startTime = dayjs(stream.start_time);
        const formattedStartTime = startTime.format('HH:mm');

        const linkTitle = stream.template_id ? i18nTable('table-streams-copy-link-title-permanent') : i18nTable('table-streams-copy-link-title');

        const streamPath = stream.template_id ? `/template/${stream.template_id}/stream` : `/streams/${stream.id}`;

        const calendarEventUrl = new URL('https://calendar.yandex-team.ru/event/');
        calendarEventUrl.searchParams.set('event_id', `${stream.event_id}`);

        const shareLinkPopup = (
            <div className={cnShareLinkPopup()}>
                <span className={cnShareLinkPopup('Title')}>{ linkTitle }</span>
                <a
                    className={cnShareLinkPopup('Link')}
                    href={ `https://${window.location.host}${streamPath}` }
                >
                    { `https://${window.location.host}${streamPath}` }
                </a>
                <CopyText
                    textToCopy={ `https://${window.location.host}${streamPath}` }
                    textToDisplay={i18nTable('copy')}
                    icon="square"
                />
            </div>
        );

        return (
            <div
                className={cnTable('Row')}
                key={stream.id}
                data-id={stream.id}
            >
                <div className={cnTable('Cell', { type: 'streamName' })}>
                    <span className={cnTable('StreamName')}>
                        { stream.name }
                    </span>
                </div>
                <div className={cnTable('Cell')}>
                    <span className={cnTable('StartTime')}>{i18nTable('stream-start-time')}</span>
                    &nbsp;
                    { formattedStartTime }
                </div>
                <div className={cnTable('Cell', [cnTable('Stats')])}>
                    <div className={cnTable('Countdown')}>
                        <Duration
                            startingPointISOString={stream.start_time}
                        />
                    </div>
                </div>
                <div className={cnTable('Cell', { align: 'right' }, [cnTable('Stats')])}>
                    <div className={cnTable('Observers')}>
                        -
                    </div>
                </div>
                <div className={cnTable('Cell', { align: 'right' })}>
                    <div className={cnTable('ActionButtons')}>
                        {stream.event_id !== null &&
                        <Button
                            size="m"
                            view="default"
                            pin="circle-circle"
                            type="link"
                            url={calendarEventUrl.toString()}
                            icon={iconCalendarAwesome}
                            title={i18nTable('show-calendar-awesome')}
                            width="max"
                            responsive
                            className={cnTable('CalendarAwesomeButton')}
                        >
                            {i18nTable('show-calendar-awesome')}
                        </Button>
                        }
                        <Button
                            size="m"
                            view="default"
                            pin="circle-circle"
                            icon={iconShare}
                            title={i18nTable('show-share')}
                            width="max"
                            responsive
                            popup
                            popupContent={shareLinkPopup}
                            className={cnTable('StreamsShareLinkButton')}
                        >
                            {i18nTable('show-share')}
                        </Button>
                        <Button
                            size="m"
                            view="default"
                            pin="circle-circle"
                            icon={iconComment}
                            title={i18nTable('show-go-to-chat')}
                            width="max"
                            responsive
                            onClick={getNavigationCallback(`/streams/${stream.id}/chat`)}
                        >
                            {i18nTable('show-go-to-chat')}
                        </Button>
                        {isTvButtonVisible && (
                        <Button
                            size="m"
                            view="default"
                            pin="circle-circle"
                            title={i18nTable('show-TV')}
                            width="max"
                            responsive
                            onClick={getNavigationCallback(`/streams/${stream.id}/chat?tv-view=1`)}
                            className={cnTable('TvButton')}
                        >
                            {i18nTable('show-TV')}
                        </Button>
                        )}
                        <Button
                            size="m"
                            view="action"
                            pin="circle-circle"
                            icon={iconPlay}
                            title={i18nTable('show-open-stream')}
                            width="max"
                            responsive
                            onClick={getNavigationCallback(`/streams/${stream.id}`)}
                        >
                            {i18nTable('show-open-stream')}
                        </Button>
                    </div>
                </div>
            </div>
        );
    });

    const addonBefore = (
        <>
            <div className={cnTable('ContentHeader')}>
                <div className={cnTable('ColumnTitle')}>{i18nTable('stream-name')}</div>
                <div className={cnTable('ColumnTitle')}>{i18nTable('stream-start-time')}</div>
                <div className={cnTable('ColumnTitle')}>{i18nTable('stream-lasts')}</div>
                <div className={cnTable('ColumnTitle', { align: 'right' })}>{i18nTable('stream-viewers')}</div>
                <div className={cnTable('ColumnTitle')} />
            </div>
            <div className={cnTable('Content')}>
                {rows}
            </div>
        </>
    );

    return (
        <SourceTable
            {...innerProps}
            addonBefore={addonBefore}
    />
    );
};

const withTypeStreams = withBemMod<WithTypeStreamsProps>(
    cnTable(),
    { type: 'streams' },
    (Table): FC<WithTypeStreamsProps> => (outerProps: WithTypeStreamsProps): ReactElement => {
        return (
            <StreamsTable
                {...outerProps}
                SourceTable={Table}
            />
        );
    },
);

export default withTypeStreams;
