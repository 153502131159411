import { CallTemplateAPI } from 'ducks/templateForm';

import { CallFormDuration } from 'components/CreateCallForm/CreateCallForm.interface';
import { CallTemplate } from 'components/CallTemplate';

export const templateMock: CallTemplate = {
    name: 'Name',
    participants: [],
    owners: [],
    record: false,
    stream: false,
    streamPicture: '',
    streamDescription: '',
    id: 1,
    duration: 60 as CallFormDuration,
    eventExternalId: null,
    eventId: null,
    event: {
        name: null,
    },
};

export const templateApiMock: CallTemplateAPI = {
    name: 'Name',
    participants: [],
    owners: [],
    record: false,
    stream: false,
    stream_picture: '',
    stream_description: '',
    id: 1,
    duration: 60 as CallFormDuration,
    event_external_id: null,
    event_id: null,
    event: {
        name: null,
    },
};

export const templatesMock: CallTemplate[] = [
    {
        name: 'NoExternalId',
        participants: [],
        owners: [],
        record: false,
        stream: false,
        streamPicture: '',
        streamDescription: '',
        id: 1,
        duration: 60 as CallFormDuration,
        eventExternalId: null,
        eventId: null,
        event: {
            name: null,
        },
    },
    {
        name: 'ExternalId',
        participants: [],
        owners: [],
        record: false,
        stream: false,
        streamPicture: '',
        streamDescription: '',
        id: 1,
        duration: 60 as CallFormDuration,
        eventExternalId: 'ExternalId',
        eventId: null,
        event: {
            name: null,
        },
    },
    {
        name: 'ExternalIdAndOwner',
        participants: [],
        owners: ['grisshafkn'],
        record: false,
        stream: false,
        streamPicture: '',
        streamDescription: '',
        id: 1,
        duration: 60 as CallFormDuration,
        eventExternalId: 'ExternalId',
        eventId: null,
        event: {
            name: null,
        },
    },
];

export default templateMock;
