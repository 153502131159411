import { ApiErrorI } from 'util/api.rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { createSelector } from 'reselect';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap, map } from 'rxjs/operators';
import { createAction, handleActions, Action } from 'redux-actions';

import { notyError } from 'components/Notifications/Notifications.actions';
import { INotificationMessageType } from 'components/Notifications/Notifications.interface';
import { ActiveCallApi, ActiveCall } from 'components/ActiveCalls/ActiveCalls.interface';

import { StoreI } from 'ducks/store';
import { convertActiveCallApiResponse } from 'ducks/activeCall/activeCall.util';

import i18n from '@yandex-int/i18n';
import {
    convertUpcomingStreamApiResponse,
    sortUpcomingStreams,
} from './streams.util';

import * as keyset from './streams.i18n';

const i18nStreams = i18n(keyset);

export interface UpcomingStream {
    id: number,
    name: string,
    priority: number,
    streamDescription: string,
    streamPicture: string,
    nextEvent: {
        id: number;
        startTime: string,
        description: string,
    }
}

export interface UpcomingStreamApi {
    id: number,
    name: string,
    priority: number,
    stream_description: string,
    stream_picture: string,
    next_event: {
        id: number;
        start_time: string,
        description: string,
    }
}

export interface UpcomingStreamsState {
    isRequesting: boolean;
    list: UpcomingStream[];
}

export interface Streams {
    isRequesting: boolean;
    list: ActiveCall[];
    upcomingStreams: UpcomingStreamsState;
}

export const initialState: Streams = {
    isRequesting: false,
    list: null,
    upcomingStreams: {
        isRequesting: false,
        list: null,
    },
};

const REQUEST = 'vconf/StreamsList/REQUEST';
const REQUEST_UPCOMING = 'vconf/StreamsList/REQUEST_UPCOMING';
const REQUEST_ERROR = 'vconf/StreamsList/REQUEST_ERROR';
const REQUEST_UPCOMING_ERROR = 'vconf/StreamsList/REQUEST_UPCOMING_ERROR';
const RECEIVE = 'vconf/StreamsList/RECEIVE';
const RECEIVE_UPCOMING = 'vconf/StreamsList/RECEIVE_UPCOMING';
const RECEIVE_REFRESH = 'vconf/StreamsList/RECEIVE_REFRESH';
const RECEIVE_REFRESH_UPCOMING = 'vconf/StreamsList/RECEIVE_REFRESH_UPCOMING';
const CLEAR_STREAMS = 'vconf/StreamsList/CLEAR_STREAMS';

export interface RequestStreamsPayload {
    refresh?: boolean;
}
export type ReceiveStreamsPayload = ActiveCallApi[];
export type RequestUpcomingStreamsPayload = RequestStreamsPayload;
export type ReceiveUpcomingStreamsPayload = UpcomingStreamApi[];

export const requestStreams = createAction<RequestStreamsPayload>(REQUEST);
export const requestUpcomingStreams = createAction<RequestUpcomingStreamsPayload>(REQUEST_UPCOMING);
export const requestStreamsError = createAction<null>(REQUEST_ERROR);
export const requestUpcomingStreamsError = createAction<null>(REQUEST_UPCOMING_ERROR);
export const receiveStreams = createAction<ReceiveStreamsPayload>(RECEIVE);
export const receiveUpcomingStreams = createAction<ReceiveUpcomingStreamsPayload>(RECEIVE_UPCOMING);
export const receiveRefreshStreams = createAction<ReceiveStreamsPayload>(RECEIVE_REFRESH);
export const receiveRefreshUpcomingStreams = createAction<ReceiveUpcomingStreamsPayload>(RECEIVE_REFRESH_UPCOMING);
export const clearStreams = createAction(CLEAR_STREAMS);

type Payload = RequestStreamsPayload | ReceiveStreamsPayload | ReceiveUpcomingStreamsPayload;

export const reducer = handleActions<Streams, Payload>(
    {
        [REQUEST]: (state): Streams => {
            return {
                ...state,
                isRequesting: true,
            };
        },

        [REQUEST_UPCOMING]: (state): Streams => {
            return {
                ...state,
                upcomingStreams: {
                    ...state.upcomingStreams,
                    isRequesting: true,
                },
            };
        },

        [REQUEST_ERROR]: (state): Streams => {
            return {
                ...state,
                isRequesting: false,
            };
        },

        [REQUEST_UPCOMING_ERROR]: (state): Streams => {
            return {
                ...state,
                upcomingStreams: {
                    ...state.upcomingStreams,
                    isRequesting: false,
                },
            };
        },

        [RECEIVE]: (state: Streams, { payload }: Action<ReceiveStreamsPayload>): Streams => {
            return {
                ...state,
                isRequesting: false,
                list: [
                    ...payload.map(call => convertActiveCallApiResponse(call)),
                ],
            };
        },

        [RECEIVE_UPCOMING]: (state: Streams, { payload }: Action<ReceiveUpcomingStreamsPayload>): Streams => {
            return {
                ...state,
                upcomingStreams: {
                    ...state.upcomingStreams,
                    isRequesting: false,
                    list: [
                        ...payload.map(stream => convertUpcomingStreamApiResponse(stream)),
                    ],
                },
            };
        },

        [RECEIVE_REFRESH]: (state: Streams, { payload }: Action<ReceiveStreamsPayload>): Streams => {
            const newList = payload.length ? [...payload] : [];

            return {
                ...state,
                isRequesting: false,
                list: newList.map(call => convertActiveCallApiResponse(call)),
            };
        },

        [RECEIVE_REFRESH_UPCOMING]: (state: Streams, { payload }: Action<ReceiveUpcomingStreamsPayload>): Streams => {
            const newList = payload.length ? [...payload] : [];

            return {
                ...state,
                isRequesting: false,
                upcomingStreams: {
                    ...state.upcomingStreams,
                    isRequesting: false,
                    list: [
                        ...newList.map(stream => convertUpcomingStreamApiResponse(stream)),
                    ],
                },
            };
        },

        [CLEAR_STREAMS]: (): Streams => ({
            ...initialState,
        }),
    },
    initialState,
);

type StreamsResponsePayload = ActiveCallApi[] | INotificationMessageType;
type UpcomingStreamsResponsePayload = ActiveCallApi[] | INotificationMessageType;

export const epic = combineEpics(
    (action$: Observable<Action<RequestStreamsPayload>>,
        $state: StoreI,
        { streamsApi: { getStreams } }): Observable<Action<StreamsResponsePayload>> =>
        action$.pipe(
            ofType(REQUEST),
            mergeMap((action: Action<RequestStreamsPayload>): Observable<Action<StreamsResponsePayload>> => {
                const refresh = action.payload?.refresh;

                return getStreams().pipe(
                    map((response): Action<ReceiveStreamsPayload> => {
                        const possiblyErrorResponse = response as ApiErrorI;

                        if (possiblyErrorResponse.error) {
                            throw possiblyErrorResponse;
                        }

                        if (refresh) {
                            return receiveRefreshStreams(response as ReceiveStreamsPayload);
                        }
                        return receiveStreams(response as ReceiveStreamsPayload);
                    }),
                    catchError((): Observable<Action<INotificationMessageType | null>> => of(
                        notyError(i18nStreams('streams-error')),
                        requestStreamsError(null),
                    )),
                );
            }),
        ),

    (action$: Observable<Action<RequestStreamsPayload>>,
        $state: StoreI,
        { streamsApi: { getUpcomingStreams } }): Observable<Action<UpcomingStreamsResponsePayload>> =>
        action$.pipe(
            ofType(REQUEST_UPCOMING),
            mergeMap((action: Action<RequestStreamsPayload>): Observable<Action<UpcomingStreamsResponsePayload>> => {
                const refresh = action.payload?.refresh;
                const getUpcomingStreamsParams = {
                    limit: 100,
                };

                return getUpcomingStreams(getUpcomingStreamsParams).pipe(
                    map((response): Action<ReceiveUpcomingStreamsPayload> => {
                        const possiblyErrorResponse = response as ApiErrorI;

                        if (possiblyErrorResponse.error) {
                            throw possiblyErrorResponse;
                        }

                        const upcomingStreams = sortUpcomingStreams(response as ReceiveUpcomingStreamsPayload);

                        if (refresh) {
                            return receiveRefreshUpcomingStreams(upcomingStreams);
                        }

                        return receiveUpcomingStreams(upcomingStreams);
                    }),
                    catchError((): Observable<Action<INotificationMessageType | null>> => of(
                        notyError(i18nStreams('upcoming-streams-error')),
                        requestUpcomingStreamsError(null),
                    )),
                );
            }),
        ),
);

const selectStreamsState = (store: StoreI): Streams => store.streams;

export const selectStreamsList = createSelector(
    selectStreamsState,
    ({ list }): ActiveCall[] => list,
);

export const selectUpcomingStreams = createSelector(
    selectStreamsState,
    ({ upcomingStreams }): UpcomingStreamsState => upcomingStreams,
);
