export const en = {
    'all-streams-link': 'All streams',
    'smth-went-wrong-description': 'Something went wrong...',
    'not-found-description': 'Unfortunately, the data for the stream could not be found',
    'next-event': 'Stream will start at',
    'next-chat': 'The chat will be available as soon as the stream starts at',
    at: 'at',
    'title-chat': 'There will be a chat here',
    'title-stream': 'There will be a stream here',
};
