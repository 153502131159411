import React, { ReactElement, useCallback } from 'react';
import { composeU } from '@bem-react/core';
import { cn } from '@bem-react/classname';

import { Button } from '@yandex-lego/components/Button/desktop/bundle';
import {
    Icon as IconBase,
    withTypeClose as withIconTypeClose,
} from '@yandex-lego/components/Icon/desktop';
import {
    ParticipantAvatar as ParticipantAvatarBase,
    withTypePerson as withParticipantAvatarTypePerson,
    withTypeAttendees as withParticipantAvatarTypeAttendees,
} from 'components/ParticipantAvatar';

import { withTypePlus } from 'components/Icon';

import { YabblePropsI } from './Yabble.interface';
import './Yabble.css';

const Icon = composeU(
    withIconTypeClose,
    withTypePlus,
)(IconBase);

const ParticipantAvatar = composeU(
    withParticipantAvatarTypePerson,
    withParticipantAvatarTypeAttendees,
)(ParticipantAvatarBase);

export const cnYabble = cn('Yabble');

export const Yabble: React.FC<YabblePropsI> = (props: YabblePropsI): ReactElement => {
    const {
        login,
        participantType,
        actionType,
        onClick,
        addon,
        addonBefore,
        className,
    } = props;

    const iconAction = useCallback((iconClassName: string): ReactElement =>
        <Icon type={actionType as undefined} className={iconClassName} />, [actionType]);

    return (
        <div className={cnYabble(null, [className])}>
            <div className={cnYabble('Wrap')}>
                {addonBefore}
                <ParticipantAvatar
                    type={participantType as undefined}
                    id={login}
                />
                { addon }
                {
                    onClick && (
                        <Button
                            className={cnYabble('HoverButton')}
                            onClick={onClick}
                            view="clear"
                            size="s"
                            icon={iconAction}
                        />
                    )
                }
            </div>
        </div>
    );
};

export default Yabble;
