export const en = {
    'error': 'Problem',
    'error.smth-went-wrong.title': 'Oh, something went wrong...',
    'error.smth-went-wrong.description': 'Try to create a report through a bug',
    'error.not-found.title': 'Oh, there is no stream',
    'error.not-found.description': 'We couldn`t find the upcoming stream, maybe you made a mistake in the page address',
    'next-stream': 'Stream will start at',
    'next-chat': 'Chat will be available at',
    at: 'at',
    'title-chat': 'There will be a chat here',
    'title-stream': 'There will be a Khural here',
    'add-to-calendar': 'Add to your calendar',
};
