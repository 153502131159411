import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';
import './Icon_type_jabber.css';

export interface WithTypeJabberProps {
  type?: 'jabber';
}

export const withTypeJabber = withBemMod<WithTypeJabberProps>(
    'Icon',
    { type: 'jabber' },
    (Icon): FC => ((props): ReactElement => (<Icon {...props} />)),
);

export default withTypeJabber;
