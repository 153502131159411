/* global Ya */

import { cn } from '@bem-react/classname';
import React, { useEffect, useMemo, ReactElement } from 'react';
import { ChatProps } from './Chat.interface';

import './Chat.css';

const cnChat = cn('Chat');

export const Chat = (props: ChatProps): ReactElement => {
    const {
        chatInviteHash,
        env,
        className,
        flags = [],
    } = props;
    const iframeUrl = useMemo((): string =>
        env === 'production' ?
            'https://q.yandex-team.ru/embed/' :
            'https://renderer-chat-dev.test.q.yandex-team.ru/embed/'
    , [env]);

    /* istanbul ignore next */ /* Не получается протестировать из-за того, что дожидаемся загрузку внешнего скрипта */
    useEffect((): FunctionVoid => {
        const script = document.createElement('script');

        script.src = 'https://chat.s3.yandex.net/widget_light.js';
        script.onload = (): void => {
            const inviteHash = chatInviteHash;
            const mountNode = document.querySelector('.Chat');

            new Ya.ChatWidget({ // eslint-disable-line no-new
                inviteHash,
                mountNode,
                iframeUrl,
                iframeUrlParams: {
                    flags: flags.map((flag): string => `${flag}=1`).join(';'),
                    config: env === 'production' ? 'production' : 'development',
                    build: 'vconf',
                },
            });
        };
        document.head.appendChild(script);

        return (): void => {
            script.remove();
        };
    }, [chatInviteHash, iframeUrl, flags, env]);

    return <div className={cnChat(null, [className])} />;
};

export default Chat;
