import { getI18nLang, Language } from '@yandex-int/i18n';
import React, { FC, ReactElement } from 'react';

import { cnKhHeader } from '../KhHeader.cn';
import { i18n } from '../KhHeader.translations';

export type KhHeaderLogoProps = {};

const logoRu = (
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_1233_16120" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="4" width="30" height="30">
            <circle cx="15" cy="19" r="15" fill="white" />
        </mask>
        <g mask="url(#mask0_1233_16120)">
            <rect width="30" height="30" transform="translate(0 4)" fill="#FC3F1D" />
            <path d="M17.2116 12.4006H15.6936C13.0912 12.4006 11.7899 13.7018 11.7899 15.6536C11.7899 17.8222 12.6574 18.9066 14.6092 20.2078L16.1273 21.2921L11.7899 28.015H8.32007L12.4405 21.9427C10.055 20.2078 8.7538 18.6897 8.7538 15.8704C8.7538 12.4006 11.1393 10.015 15.6936 10.015H20.2478V28.015H17.2116V12.4006Z" fill="white" />
        </g>
        <path d="M47.8475 30.325C52.7875 30.325 56.7525 26.5225 56.7525 18.625C56.7525 10.7275 52.7875 7.0875 47.8475 7.0875C42.875 7.0875 38.975 10.89 38.975 18.7875C38.975 26.6525 42.875 30.325 47.8475 30.325ZM47.8475 27.27C45.02 27.27 42.9725 24.7025 42.9725 18.7225C42.9725 12.71 45.02 10.1425 47.8475 10.1425C50.675 10.1425 52.755 12.71 52.755 18.7225C52.755 24.7025 50.675 27.27 47.8475 27.27ZM70.4487 30H75.0312L66.9712 18.105L74.5112 7.4125H70.6112L63.2987 17.8125V7.4125H59.3662V30H63.2987V19.405L70.4487 30Z" />
    </svg>
);

const logoEn = (
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 19C0 10.7157 6.71573 4 15 4V4C23.2843 4 30 10.7157 30 19V19C30 27.2843 23.2843 34 15 34V34C6.71573 34 0 27.2843 0 19V19Z" fill="#FC3F1D" />
        <path d="M15.4069 20.5497C16.2834 22.4697 16.5756 23.1375 16.5756 25.4436V28.501H13.4451V23.3462L7.53906 10.501H10.8051L15.4069 20.5497ZM19.2678 10.501L15.4382 19.2036H18.6208L22.4608 10.501H19.2678Z" fill="white" />
        <path d="M47.8475 30.325C52.7875 30.325 56.7525 26.5225 56.7525 18.625C56.7525 10.7275 52.7875 7.0875 47.8475 7.0875C42.875 7.0875 38.975 10.89 38.975 18.7875C38.975 26.6525 42.875 30.325 47.8475 30.325ZM47.8475 27.27C45.02 27.27 42.9725 24.7025 42.9725 18.7225C42.9725 12.71 45.02 10.1425 47.8475 10.1425C50.675 10.1425 52.755 12.71 52.755 18.7225C52.755 24.7025 50.675 27.27 47.8475 27.27ZM70.4487 30H75.0312L66.9712 18.105L74.5112 7.4125H70.6112L63.2987 17.8125V7.4125H59.3662V30H63.2987V19.405L70.4487 30Z" />
    </svg>
);

const logo: Partial<Record<Language, ReactElement>> = {
    ru: logoRu,
    en: logoEn,
};

export const KhHeaderLogo: FC<KhHeaderLogoProps> = () => {
    const lang = getI18nLang();

    return (
        <>
            {logo[lang]}
            <img
                src={`https://yastatic.net/q/logoaas/v2/${i18n('logo')}.svg`}
                height={30}
                className={cnKhHeader('Logo')}
                alt="Khural"
            />
        </>
    );
};
