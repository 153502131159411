import { compose } from '@bem-react/core';
import i18n from '@yandex-int/i18n';

import { Button } from '@yandex-lego/components/Button/desktop/bundle';
import {
    Icon as IconBase,
    withTypeArrow as withIconTypeArrow,
} from '@yandex-lego/components/Icon/desktop';
import React, { FC, ReactElement, useCallback } from 'react';

import { TemplateList } from 'components/TemplateList';

import { cnCreateCallScreen } from '../CreateCallScreen';
import { CreateCallScreenMobileTemplatesProps } from './MobileTemplates.interface';
import * as keyset from '../CreateCallScreen.i18n';
import './MobileTemplates.css';

const i18nCreateCallScreen = i18n(keyset);

const Icon = compose(
    withIconTypeArrow,
)(IconBase);

export const MobileTemplates: FC<CreateCallScreenMobileTemplatesProps> =
    (props: CreateCallScreenMobileTemplatesProps): ReactElement => {
        const {
            visible,
            templateApplied,
            onTemplateClick,
            onTemplatesSwitcherClick,
        } = props;

        const iconTemplateVisible = useCallback((iconClassName): ReactElement => (
            <Icon
                type="arrow"
                direction={visible ? 'top' : 'bottom'}
                className={iconClassName}
            />
        ), [visible]);

        return (
            <div className={cnCreateCallScreen('MobileTemplates', { visible, templateApplied })}>
                <span className={cnCreateCallScreen('AppliedTemplateIndicator')} />
                <Button
                    view="clear"
                    size="m"
                    className={cnCreateCallScreen('TemplatesSwitcher')}
                    onClick={onTemplatesSwitcherClick}
                    iconRight={iconTemplateVisible}
                >
                    { visible ? i18nCreateCallScreen('mobile-templates-title-opened') : i18nCreateCallScreen('mobile-templates-title-closed')}
                </Button>
                <TemplateList
                    className={cnCreateCallScreen('MobileTemplatesList')}
                    onTemplateClick={onTemplateClick}
                />
            </div>
        );
    };
