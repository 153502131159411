import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';
import './Icon_type_nomicrophone.css';

export interface WithTypeNoMicrophoneProps {
  type?: 'noMicrophone';
}

export const withTypeNoMicrophone = withBemMod<WithTypeNoMicrophoneProps>(
    'Icon',
    { type: 'noMicrophone' },
    (Icon): FC => ((props): ReactElement => (<Icon {...props} />)),
);

export default withTypeNoMicrophone;
