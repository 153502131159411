import { ApiErrorI } from 'util/api.rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs-compat';
import { createSelector } from 'reselect';
import { of } from 'rxjs';
import { createAction, handleActions, Action } from 'redux-actions';
import { INotificationMessageType } from 'components/Notifications/Notifications.interface';

import { CallTemplateAPI } from 'ducks/templateForm';
import {
    InjectedDependencies as InjDeps,
    StoreI,
} from 'ducks/store';

import { CallTemplate } from 'components/CallTemplate/CallTemplate.interface';
import { notyError } from 'components/Notifications/Notifications.actions';

export interface TemplatesState {
  data: CallTemplate[];
  isRequesting: boolean;
}

export const initialState: TemplatesState = {
    data: [],
    isRequesting: false,
};

export type GotSuccessPayload = CallTemplateAPI[];

const GET = 'vconf/templates/get';
const GOT_ERROR = 'vconf/templates/got_error';
const GOT_SUCCESS = 'vconf/templates/gotSuccess';
const CLEAR = 'vconf/templates/clear';

export const get = createAction(GET);
export const gotError = createAction<null>(GOT_ERROR);
export const gotSuccess = createAction<GotSuccessPayload>(GOT_SUCCESS);
export const clear = createAction(CLEAR);

export const reducer = handleActions<TemplatesState, GotSuccessPayload>({
    [GET]: (state): TemplatesState => ({
        ...state,
        isRequesting: true,
    }),

    [GOT_ERROR]: (state): TemplatesState => ({
        ...state,
        isRequesting: false,
    }),

    [GOT_SUCCESS]: (state, action: Action<GotSuccessPayload>): TemplatesState => {
        const {
            payload,
        } = action;

        return {
            ...state,
            isRequesting: false,
            data: payload.map((template): CallTemplate => ({
                id: template.id,
                name: template.name,
                duration: template.duration,
                record: template.record,
                stream: template.stream,
                streamPicture: template.stream_picture,
                streamDescription: template.stream_description,
                owners: template.owners,
                participants: template.participants,
                eventExternalId: template.event_external_id,
                eventId: template.event_id,
                event: template.event,
            })),
        };
    },

    [CLEAR]: (): TemplatesState => {
        return {
            ...initialState,
        };
    },
}, initialState);

type EpicResult = GotSuccessPayload | INotificationMessageType;

export const epic = combineEpics(
    (action$: Observable<Action<void>>, state$: StoreI, api: InjDeps): Observable<Action<EpicResult>> =>
        action$.pipe(
            ofType(GET),
            mergeMap((): Observable<Action<CallTemplateAPI[] | INotificationMessageType>> =>
                api.templatesApi.getCallTemplates().pipe(
                    map((response): Action<GotSuccessPayload> => {
                        const possiblyErrorResponse = response as ApiErrorI;

                        if (possiblyErrorResponse.error) {
                            throw possiblyErrorResponse;
                        }

                        return gotSuccess(response as GotSuccessPayload);
                    }),
                    catchError((error: ApiErrorI): Observable<Action<INotificationMessageType | null>> => of(
                        notyError(error.message),
                        gotError(null),
                    )),
                ),
            ),
        ),
);

export const selectTemplates = (store: StoreI): TemplatesState => store.templates;

export const selectIsRequesting = createSelector(
    selectTemplates,
    ({ isRequesting }): boolean => isRequesting,
);

export const selectData = createSelector(
    selectTemplates,
    ({ data }): CallTemplate[] => data,
);
