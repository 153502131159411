import { cn } from '@bem-react/classname';
import React, { ReactElement } from 'react';
import Autosuggest from 'react-autosuggest';

import { SuggestProps } from './Suggest.interface';

import './Suggest.css';

const cnSuggest = cn('Suggest');

export const Suggest = (props: SuggestProps): ReactElement => {
    const {
        suggestions,
        onSuggestionsClearRequested,
        onSuggestionsFetchRequested,
        getSuggestionValue,
        onSuggestionSelected,
        renderSuggestion,
        inputProps,
        className,
    } = props;

    return (
        <div className={cnSuggest(null, [className])}>
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                getSuggestionValue={getSuggestionValue}
                onSuggestionSelected={onSuggestionSelected}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
      />
        </div>
    );
};

export default Suggest;
