import React from 'react';
import { cn } from '@bem-react/classname';

import './ClickerIcon.css';

const cnClickerIcon = cn('ClickerIcon');

export const ClickerIcon = () => {
    return (
        <svg className={cnClickerIcon()} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M60 2C60 0.895431 59.1046 0 58 0H2C0.895431 0 0 0.895431 0 2V23.9997C0 25.1043 0.895431 25.9997 2 25.9997H25.2406C26.987 25.9997 27.8945 23.9185 26.7062 22.6388L23.0688 18.7216C21.5656 17.1027 21.6593 14.5718 23.2781 13.0686C24.897 11.5654 27.4279 11.6591 28.9311 13.2779L41.9311 27.2779C43.3562 28.8126 43.3562 31.1868 41.9311 32.7215L28.9311 46.7216C27.4279 48.3404 24.897 48.4341 23.2781 46.9309C21.6593 45.4277 21.5656 42.8968 23.0688 41.278L26.7063 37.3606C27.8946 36.0809 26.987 33.9997 25.2407 33.9997L2 33.9997C0.895432 33.9997 0 34.8951 0 35.9997V58C0 59.1046 0.895432 60 2 60H58C59.1046 60 60 59.1046 60 58V2Z" fill="#015FBF" />
        </svg>
    );
};
