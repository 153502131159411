import {
    IntrasearchSuggestion,
    SuggestionArbitrary,
    SuggestionInvite,
    SuggestionPeople,
    LayerMap,
    ClickUrls,
} from 'components/Suggest/Suggest.interface';

export const layerMap: LayerMap = {
    people: 'person',
    invite: 'room',
    arbitrary: 'arbitrary',
};

export const suggestionHasPhone = (suggestion: IntrasearchSuggestion): boolean =>
    suggestion.layer === 'arbitrary' ||
  suggestion.layer === 'people' ||
  Boolean((suggestion as SuggestionInvite).video_conferencing) ||
  Boolean((suggestion as SuggestionInvite).phone);

const suggestArbitraryValidators = {
    zoom: (value: string): boolean => value.includes('@') || /^https:\/\/yandex\.zoom\.us\/j\/(\d)+/i.test(value),
    phone: (value: string): boolean => /^[^a-zа-яё]+$/i.test(value) && /(\d)+/.test(value),
};

export function suggestArbitrary(text: string): SuggestionArbitrary | null {
    const validators = Object.values(suggestArbitraryValidators);

    if (validators.some((validator): boolean => validator(text))) {
        return {
            id: text,
            layer: 'arbitrary',
            click_urls: [] as ClickUrls,
        };
    }

    return null;
}

export const inviteMock: SuggestionInvite = {
    title: '1.Lassen',
    layer: 'invite',
    id: 105,
    phone: '1186',
    url: 'testUrl',
    click_urls: ['click_urls'],

    city_id: 56,
    city_name: '',
    floor_id: 28,
    floor_name: '2nd floor',
    name_alternative: 'USA 2-1',
    name_exchange: 'conf_usa_lassen',
    office_id: 8,
    office_name: 'США, Ньюберипорт',
    video_conferencing: '1895',
};

export const peopleMock: SuggestionPeople = {
    title: 'Test Person',
    layer: 'people',
    id: 'testId',
    phone: 'testPhone',
    url: 'testUrl',
    click_urls: ['click_urls'],

    is_dismissed: false,
    department_name: 'testDepartment',
    is_memorial: false,
    is_robot: false,
    login: 'testLogin',
    staff_id: 0,
    uid: 'testUid',
};

export const arbitraryMock: SuggestionArbitrary = {
    id: '+1 (234) 567-89-00',
    layer: 'arbitrary',
    click_urls: [] as ClickUrls,
};
