import { withBemMod } from '@bem-react/core';
import i18n from '@yandex-int/i18n';
import React, { FC, ReactElement } from 'react';

import { Duration } from 'components/Duration';

import { WithTypeUpcomingProps } from './Duration_type_upcoming.interface';
import { StreamPosterDurationProps } from '../StreamPoster-Duration.interface';
import { cnStreamPoster } from '../../StreamPoster';
import * as keyset from '../../StreamPoster.i18n';

const i18nStreamPoster = i18n(keyset);

export const withDurationTypeUpcoming = withBemMod<WithTypeUpcomingProps, StreamPosterDurationProps>(
    'StreamPoster-Duration',
    { type: 'upcoming' },
    (StreamPosterDuration): FC<WithTypeUpcomingProps & StreamPosterDurationProps> =>
        (props: WithTypeUpcomingProps & StreamPosterDurationProps): ReactElement => {
            const {
                startTime,
            } = props;

            return (
                <StreamPosterDuration {...props}>
                    <div className={cnStreamPoster('StartTime')}>
                        {i18nStreamPoster('will-started-at')} <Duration startingPointISOString={startTime} to />
                    </div>
                </StreamPosterDuration>
            );
        },
);
