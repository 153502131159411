import i18n from '@yandex-int/i18n';
import { cn } from '@bem-react/classname';
import React, { FC, ReactElement } from 'react';
import { ErrorBoxPropsI } from 'components/ErrorBox/ErrorBox.interface';
import * as keyset from './ErrorBox.i18n';
import './ErrorBox.css';

const cnErrorBox = cn('ErrorBox');
const i18nErrorPage = i18n(keyset);

export const ErrorBox: FC<ErrorBoxPropsI> = (props: ErrorBoxPropsI): ReactElement => {
    const { code, className } = props;
    const error404 = code === 404 ? i18nErrorPage('page-not-found') : i18nErrorPage('error');
    const clientErrorMessage = (code >= 400 && code !== 404) ? i18nErrorPage('no-access') : error404;
    const codeMessage = code >= 500 ? i18nErrorPage('internal-server-error') : clientErrorMessage;

    return (
        <div className={cnErrorBox(null, [className])}>
            <h1>{i18nErrorPage('error')}</h1>
            <p className={cnErrorBox('Message')}>{`${codeMessage} ${code}`}</p>
        </div>
    );
};

export default ErrorBox;
