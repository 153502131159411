import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import { cnStub } from '../Stub';
import { StubProps } from '../Stub.interface';
import { WithTypeNoCalls } from './Stub_type_noCalls.interface';

import image from './assets/NoCallsStub.svg';

export const withTypeNoCalls = withBemMod<WithTypeNoCalls, StubProps>(
    cnStub(),
    { type: 'noCalls' },
    (Stub): FC<WithTypeNoCalls & StubProps> => (
        (props: WithTypeNoCalls & StubProps): ReactElement => {
            return (
                <Stub
                    {...props}
                    image={image}
        />
            );
        }
    ),
);

export default withTypeNoCalls;
