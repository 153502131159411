import { Dispatch } from 'redux';
import { compose, composeU } from '@bem-react/core';
import i18n from '@yandex-int/i18n';
import { Button } from '@yandex-lego/components/Button/desktop/bundle';
import {
    Icon as IconBase,
    withTypeClose as withIconTypeClose,
} from '@yandex-lego/components/Icon';
import React, { ReactElement, useCallback, useState } from 'react';
import { connect } from 'react-redux';

import { ConfirmationModal } from 'components/ConfirmationModal';
import Unit from 'components/Unit';
import { CallTemplateMapDispatchToPropsI, CallTemplatePropsI } from 'components/CallTemplate/CallTemplate.interface';
import BaseBubbleParticipants, { withPopup } from 'components/BubbleParticipants';
import {
    withTypeCalendarAwesome as withIconTypeCalendarAwesome,
    withSizeM as withIconSizeM,
} from 'components/Icon';
import { PERMANENT_LINK_TYPE } from 'components/PermanentLinkScreen/PermanentLinkScreen.interface';

import { deleteTemplateAction, deleteTemplateAllAction } from './CallTemplate.actions';
import { CopyLink } from './CallTemplate-CopyLink';
import * as keyset from './CallTemplate.i18n';

const i18nCallTemplate = i18n(keyset);

const BubbleParticipants = compose(
    withPopup,
)(BaseBubbleParticipants);

const Icon = compose(
    composeU(
        withIconTypeCalendarAwesome,
        withIconTypeClose,
    ),
    withIconSizeM,
)(IconBase);

const iconCalendarAwesome = (iconClassName: string): ReactElement => (<Icon type="calendarAwesome" size="m" className={iconClassName} />);
const iconClose = (iconClassName: string): ReactElement => (<Icon type="close" size="m" className={iconClassName} />);

const CallTemplate: React.FC<CallTemplatePropsI> = (props: CallTemplatePropsI): React.ReactElement => {
    const {
        template,
        onSelect,
        onEdit,
        onDeleteTemplateAll,
    } = props;

    const {
        id,
        name,
        participants,
        stream,
        eventId,
    } = template;

    const calendarEventUrl = new URL('https://calendar.yandex-team.ru/event/');
    calendarEventUrl.searchParams.set('event_id', `${eventId}`);

    const [isDeleteConfirmationModalVisible, setIsDeleteConfirmationModalVisible] = useState(false);
    const onDeleteCancel = useCallback((): void => {
        setIsDeleteConfirmationModalVisible(false);
    }, []);

    const onDeleteClick = useCallback((): void => {
        setIsDeleteConfirmationModalVisible(true);
    }, []);

    const onDeleteAllCallback = (): void => {
        setIsDeleteConfirmationModalVisible(false);
        onDeleteTemplateAll(template.id);
    };

    return (
        <Unit>
            <div className="CallTemplate">
                <h3 className="CallTemplate-title">{ name }</h3>
                {participants.length !== 0 &&
                <div className="CallTemplate-content">
                    <span className="CallTemplate-label">{i18nCallTemplate('participants')}</span>
                    <div className="CallTemplate-participants">
                        <BubbleParticipants
                            participants={participants}
                            popup
                        />
                    </div>
                </div>
                }
                <CopyLink
                    eventId={eventId}
                    templateId={id}
                    isStreamSwitcherOn={stream}
                    type={PERMANENT_LINK_TYPE.STREAM}
                />
                <CopyLink
                    eventId={eventId}
                    templateId={id}
                    isStreamSwitcherOn={stream}
                    type={PERMANENT_LINK_TYPE.CHAT}
                />
                <div className="CallTemplate-buttons">
                    <Button size="s" view="action" onClick={onSelect}>{i18nCallTemplate('select')}</Button>
                    <Button size="s" view="pseudo" onClick={onEdit}>{i18nCallTemplate('edit')}</Button>
                    {eventId &&
                        <Button
                            size="s"
                            view="pseudo"
                            type="link"
                            url={calendarEventUrl.toString()}
                            icon={iconCalendarAwesome}
                            title={i18nCallTemplate('to-calendar')}
                            width="max"
                            className={'CallTemplate-CalendarButton'}
                        />
                    }
                </div>

                <div className="CallTemplate-Delete">
                    <Button
                        view="clear"
                        size="m"
                        title={i18nCallTemplate('delete-template')}
                        icon={iconClose}
                        onClick={onDeleteClick}
                    />
                </div>
                <ConfirmationModal
                    title={i18nCallTemplate('template-deleting')}
                    visible={isDeleteConfirmationModalVisible}
                    confirmButton={(
                        <Button
                            size="m"
                            view="action"
                            onClick={onDeleteAllCallback}
                        >
                            {i18nCallTemplate('delete-all')}
                        </Button>
                    )}
                    cancelButton={(
                        <Button
                            size="m"
                            view="default"
                            onClick={onDeleteCancel}
                        >
                            {i18nCallTemplate('cancel')}
                        </Button>
                    )}
                />
            </div>
        </Unit>
    );
};

export default connect<null, CallTemplateMapDispatchToPropsI>(
    null,
    (dispatch: Dispatch): CallTemplateMapDispatchToPropsI => ({
        onDeleteTemplateAll(id): void {
            dispatch(deleteTemplateAllAction(id));
        },
        onDeleteTemplate(id): void {
            dispatch(deleteTemplateAction(id));
        },
    }),
)(CallTemplate);
