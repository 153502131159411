import apiRxJs, { ApiErrorI } from 'util/api.rxjs';
import { Observable } from 'rxjs';

import {
    RequestPayload,
    CalendarEventMetaApi,
} from './calendarEventMeta';

export const getEventMeta = (payload: RequestPayload): Observable<CalendarEventMetaApi | ApiErrorI> => {
    const query = new URLSearchParams();

    if (payload.eventId) {
        query.set('event_id', String(payload.eventId));
    }

    if (payload.templateId) {
        query.set('template_id', String(payload.templateId));
    }

    return apiRxJs.get<CalendarEventMetaApi | ApiErrorI>(`/frontapi/calls/create/meta/?${query}`);
};

export interface CalendarEventMetaApiDep {
  getEventMeta: (payload: RequestPayload) => Observable<CalendarEventMetaApi | ApiErrorI>;
}

export const calendarEventMetaApi: CalendarEventMetaApiDep = {
    getEventMeta,
};
