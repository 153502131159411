import { Registry, withRegistry } from '@bem-react/di';

import { CreateCallScreenContentDesktop } from './Content/CreateCallScreen-Content.container@desktop';

import {
    CreateCallScreen as CallScreenBase,
    registryId,
} from './CreateCallScreen';

const registry = new Registry({
    id: registryId,
});

registry.set('Content', CreateCallScreenContentDesktop);

export const CreateCallScreenDesktop = withRegistry(registry)(CallScreenBase);

export default CreateCallScreenDesktop;
