import { createSelector } from 'reselect';

import { Action, createAction, handleActions } from 'redux-actions';

import { StoreI } from 'ducks/store';

export type StreamPlayerState = {
    playerType: PlayerType;
    reportId: string;
    shouldGetReport: boolean;
};

export const initialState: StreamPlayerState = {
    playerType: 'protected',
    reportId: null,
    shouldGetReport: false,
};

export type PlayerType = 'protected' | 'audio';
export type SetReportIdPayload = string;
export type SetShouldGetReport = boolean;

export const SET_PLAYER_TYPE = 'vconf/streamPlayer/SET_PLAYER_TYPE';
export const SET_REPORT_ID = 'vconf/streamPlayer/SET_REPORT_ID';
export const SET_SHOULD_GET_REPORT = 'vconf/streamPlayer/SET_SHOULD_GET_REPORT';

export const setPlayerType = createAction<PlayerType>(SET_PLAYER_TYPE);
export const setReportId = createAction<SetReportIdPayload>(SET_REPORT_ID);
export const setShouldGetReport = createAction<SetShouldGetReport>(SET_SHOULD_GET_REPORT);

type Payload = PlayerType | SetReportIdPayload | SetShouldGetReport;

export const reducer = handleActions<StreamPlayerState, Payload>(
    {
        [SET_PLAYER_TYPE]: (state, action: Action<PlayerType>): StreamPlayerState => {
            const { payload } = action;
            return {
                ...state,
                playerType: payload,
            };
        },

        [SET_REPORT_ID]: (state, action: Action<SetReportIdPayload>): StreamPlayerState => {
            const { payload } = action;
            return {
                ...state,
                reportId: payload,
            };
        },

        [SET_SHOULD_GET_REPORT]: (state, action: Action<SetShouldGetReport>): StreamPlayerState => {
            const { payload } = action;
            return {
                ...state,
                shouldGetReport: payload,
            };
        },
    },
    initialState,
);

export const selectStreamPlayerState = (store: StoreI): StreamPlayerState => store.streamPlayer;

export const selectPlayerType = createSelector(
    selectStreamPlayerState,
    ({ playerType }): PlayerType => playerType,
);

export const selectShouldGetReport = createSelector(
    selectStreamPlayerState,
    ({ shouldGetReport }): boolean => shouldGetReport,
);

export const selectReportId = createSelector(
    selectStreamPlayerState,
    ({ reportId }): string => reportId,
);
