import { UpcomingStream } from 'ducks/streams';

import { Size } from 'components/StreamPoster/StreamPoster.interface';
import { ActiveCall } from 'components/ActiveCalls/ActiveCalls.interface';

import { StreamsListType } from './StreamsList.interface';

const sizeMap: Size[][] = [
    ['l', 'm', 'l', 'l', 'm'],
    [null, 's', 'm', 's', 's'],
];

export const getSize = (index: number, length: number): Size => {
    switch (index) {
        case 0:
            return sizeMap[index][length - 1] || 'l';
        case 1:
            return sizeMap[index][length - 1] || 'm';
        default:
            return 's';
    }
};

/**
 * Функция определяет, принадлежит ли входной объект типу ActiveCall
 * @param {ActiveCall[] | UpcomingStream[]} streams
 * @returns {Boolean}
 */
export const isCurrentStreams =
    (streams: ActiveCall[] | UpcomingStream[]): streams is ActiveCall[] => {
        return (streams as ActiveCall[])[0].startTime !== undefined;
    };

/**
 * Функция возвращает тип списка трансляций.
 * @param {ActiveCall[] | UpcomingStream[]} streams
 * @returns {StreamsListType}
 */
export const getStreamListType =
    (streams: ActiveCall[] | UpcomingStream[]): StreamsListType => {
        if (streams.length === 0) return 'empty';

        if (isCurrentStreams(streams)) {
            return 'current';
        }

        return 'upcoming';
    };

export const isSelection = (): boolean => {
    return window.getSelection().toString().length > 0;
};
