import { compose, composeU, withBemMod } from '@bem-react/core';
import { useComponentRegistry } from '@bem-react/di';
import { Icon as IconBase } from '@yandex-lego/components/Icon';
import i18n from '@yandex-int/i18n';
import CopyToClipboard from 'react-copy-to-clipboard';
import React, { FC, ReactElement } from 'react';

import { registryId } from 'components/GlobalContextRegistry/GlobalContextRegistry.constants';

import { TableCellProps } from '../TableCell.interface';
import { cnTableCell } from '../TableCell';
import './TableCell_type_callName.css';

import {
    withSizeM as withIconSizeM,
    withTypeCamera as withIconTypeCamera,
    withTypeRecord as withIconTypeRecord,
} from '../../Icon';

import * as keyset from '../TableCell.i18n';
import { ActiveCallI } from '../../ActiveCalls/ActiveCalls.interface';
import { INotificationMessageType } from '../../Notifications/Notifications.interface';

export interface WithTypeCallNameProps {
  type?: 'callName';
  uri?: string;
  name?: string;
  authorLogin?: string;
  call?: ActiveCallI | null;
  onCopyLink?: (payload: INotificationMessageType) => void;
}

const i18nTableCell = i18n(keyset);

const Icon = compose(
    withIconSizeM,
    composeU(
        withIconTypeRecord,
        withIconTypeCamera,
    ),
)(IconBase);

export const withTypeCallName = withBemMod<WithTypeCallNameProps, TableCellProps>(
    cnTableCell(),
    { type: 'callName' },
    (TableCell): FC<WithTypeCallNameProps & TableCellProps> => (
        (props: WithTypeCallNameProps & TableCellProps): ReactElement => {
            const {
                uri,
                name,
                authorLogin,
                call,
                onCopyLink,
            } = props;

            const { StaffName } = useComponentRegistry(registryId);

            const streamUrl = call ? new URL(`${window.location.origin}/streams/${call.id}`) : null;

            return (
                <TableCell {...props}>
                    <div className={cnTableCell('CallName')} title={name}>{name}</div>
                    {authorLogin &&
                    <div className={cnTableCell('CallID')}>
                        <div className={cnTableCell('CallIDValue')}>
                            { uri || '–' }
                        </div>
                        <StaffName id={authorLogin} name={authorLogin} />
                    </div>
                    }
                    {call &&
                    <div className={cnTableCell('EventCallParameters')}>
                        {call.stream &&
                        <div className={cnTableCell('CallAttribute', { type: 'camera' })}>
                            <CopyToClipboard
                                onCopy={onCopyLink}
                                text={`${streamUrl}`}
                            >
                                <Icon size="m" type="camera" title={i18nTableCell('table-active-calls-copy-stream-link')} />
                            </CopyToClipboard>
                        </div>
                        }
                        {call.record &&
                        <Icon
                            size="m"
                            type="record"
                            title={i18nTableCell('call-is-recorded')}
                            className={cnTableCell('CallAttribute', { type: 'recorded' })}
                        />
                        }
                    </div>
                    }
                </TableCell>
            );
        }
    ),
);

export default withTypeCallName;
