import { withBemMod, compose } from '@bem-react/core';
import i18n from '@yandex-int/i18n';
import React, { FC, ReactElement } from 'react';

import {
    Stub as StubBase,
    withTypeNoConnection as withStubTypeNoConnection,
} from 'components/Stub';

import { TemplateFormScreenProps } from '../TemplateFormScreen.interface';
import { cnTemplateFormScreen } from '../TemplateFormScreen';
import * as keyset from '../TemplateFormScreen.i18n';
import './TemplateFormScreen_empty.css';

export interface WithEmpty {
  empty?: boolean;
}

const i18nTemplateFormScreen = i18n(keyset);

const Stub = compose(
    withStubTypeNoConnection,
)(StubBase);

export const withEmpty = withBemMod<WithEmpty, TemplateFormScreenProps>(
    cnTemplateFormScreen(),
    { empty: true },
    (TemplateFormScreen): FC<WithEmpty & TemplateFormScreenProps> => (
        (props: WithEmpty & TemplateFormScreenProps): ReactElement => {
            const replacer = (
                <div className={cnTemplateFormScreen('Stub')}>
                    <Stub
                        type="noConnection"
                        title={i18nTemplateFormScreen('stub-title')}
                        description={i18nTemplateFormScreen('stub-description')}
          />
                </div>
            );

            return (
                <TemplateFormScreen
                    {...props}
                    replacer={replacer}
        />
            );
        }
    ),
);

export default withEmpty;
