import { cn } from '@bem-react/classname';
import { useDispatch, useSelector } from 'react-redux';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';

import { theme } from '@yandex-int/hr-components/Theme/presets/light';
import Notifications from 'components/Notifications';
import { cnTheme } from '@yandex-lego/components/Theme';
import {
    changeSlideAction,
    changePageAction,
    selectClickerZoom,
    connectAction,
    initAction,
} from 'ducks/clickerZoom';

import { Spinner } from 'components/Page/Spinner';
import { isZoomApp } from 'shared/lib/zoom/isZoomApp';
import { selectUserState } from 'ducks/user';
import { useLocation } from 'react-router';
import { useAppHeight } from '../../hooks/useAppHeight';
import { ClickerZoomPage, ClickerZoomProps } from './ClickerZoom.interface';
import { usePlatformContext } from '../../hooks';
import { ManagementPage } from './ClickerZoom.components/ManagementPage/ManagementPage';
import { StartHostPage } from './ClickerZoom.components/StartHostPage/StartHostPage';
import { InstructionPage } from './ClickerZoom.components/InstructionPage/InstructionPage';
import { notyWarning } from '../Notifications/Notifications.actions';

import './ClickerZoom.css';
import { getSortedMessages } from './ClickerZoom.selectors/getSortedMessages';
import { getRandomUserLogin } from './ClickerZoom.utils/getRandomUserLogin';

const cnClicker = cn('Clicker');

export const ClickerZoom = (props: ClickerZoomProps): ReactElement => {
    const {
    } = props;

    const dispatch = useDispatch();
    const clickerData = useSelector(selectClickerZoom);
    const user = useSelector(selectUserState);
    const messages = useSelector(getSortedMessages);

    const {
        currentPage,
        isLoading,
        zoom,
        meeting_title,
    } = clickerData;

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const pin = query.get('pin');

    const onChangeSlide = useCallback(slide => {
        dispatch(changeSlideAction({ slide, pin, zoomId: zoom?.meetingId }));
    }, [dispatch, pin, zoom?.meetingId]);

    const showInactivityWarning = useCallback(message => {
        dispatch(notyWarning(message));
    }, [dispatch]);

    const initHost = useCallback((pin: string) => {
        dispatch(connectAction({
            pin,
            zoomId: zoom ? zoom.meetingId : undefined,
            login: user?.data?.id ?? getRandomUserLogin(),
        }));
    }, [dispatch, user?.data?.id, zoom]);

    const onChangePage = useCallback(page => {
        dispatch(changePageAction({ page }));
    }, [dispatch]);

    const isManaging = currentPage === ClickerZoomPage.MAIN;
    const platform = usePlatformContext();

    useEffect(() => {
        dispatch(initAction({ pin, isZoomApp: isZoomApp() }));
    }, [dispatch, pin]);

    useAppHeight();

    const onManagementFinish = useCallback(() => {
        onChangePage(ClickerZoomPage.START_HOST);
    }, [onChangePage]);
    const onOpenInstruction = useCallback(() =>
        onChangePage(ClickerZoomPage.INSTRUCTION_INTRODUCTION)
    , [onChangePage]);

    const [pinValue, setPinValue] = useState();
    const onPinChange = useCallback(event => {
        setPinValue(event.target.value);
    }, []);

    const content = useMemo(() => {
        switch (currentPage) {
            case ClickerZoomPage.LOAD: {
                return (
                    <Spinner />
                );
            }

            case ClickerZoomPage.START_HOST: {
                return (
                    <StartHostPage
                        onPinChange={onPinChange}
                        pinValue={pinValue}
                        initHost={initHost}
                        openInstruction={onOpenInstruction}
                    />
                );
            }

            case ClickerZoomPage.MAIN: {
                return (
                    <ManagementPage
                        id={meeting_title}
                        messages={messages}
                        isLoading={isLoading}
                        isManaging={isManaging}
                        onChangeSlide={onChangeSlide}
                        stopManagement={onManagementFinish}
                        showInactivityWarning={showInactivityWarning}
                    />
                );
            }

            case ClickerZoomPage.INSTRUCTION_INTRODUCTION:
            case ClickerZoomPage.INSTRUCTION_IMPORTANT:
            case ClickerZoomPage.INSTRUCTION_FINISH: {
                return (
                    <InstructionPage
                        currentPage={currentPage}
                        onChangePage={onChangePage}
                    />
                );
            }
        }
    }, [
        currentPage,
        initHost,
        isLoading,
        isManaging,
        meeting_title,
        messages,
        onChangePage,
        onChangeSlide,
        onManagementFinish,
        onOpenInstruction,
        onPinChange,
        pinValue,
        showInactivityWarning,
    ]);

    return (
        <div className={cnClicker(null, [cnTheme(theme)])}>
            <div className={cnClicker('ContentWrapper', { platform })}>
                {content}
            </div>
            <Notifications />
        </div>
    );
};
