export const ru = {
    'player-tumbler': 'Плеер Яндекс.Эфир',
    'chat-button': 'Перейти к чату',
    'all-streams-link': 'Все трансляции',
    'no-data-text': 'Нет данных',
    'show-calendar-awesome': 'Перейти ко встрече в календаре',
    'stream-title': 'Трансляция',
    'stream-error': 'Ошибка',
    'viewers-counter-title': 'Количество зрителей',
    player_type_protected: 'Защищенный поток',
    player_type_audio: 'Только звук',
    'rate-informer-title': 'Оцените качество трансляции',
    'rate-informer-textarea-placeholder': 'Напишите, пожалуйста, ваш отзыв',
    'rate-informer-success-title': 'Спасибо за ваш отзыв!',
    'rate-informer-error-title': 'При отправке произошла ошибка',
    'rate-informer-action-clear': 'Позже',
    'rate-informer-action-submit': 'Отправить',
    'stream-language-ru': 'Русский',
    'stream-language-en': 'Английский',
    'stream-language-title': 'Язык трансляции',
};
