import i18n from '@yandex-int/i18n';
import { useComponentRegistry } from '@bem-react/di';
import React, { FC, ReactElement } from 'react';

import { cnCreateCallForm, registryId } from '../CreateCallForm';
import { CreateCallFormParticipantsProps } from './CreateCallForm-Participants.interface';
import * as keyset from '../CreateCallForm.i18n';
import './CreateCallForm-Participants.css';

const i18nCallForm = i18n(keyset);

export const Participants: FC<CreateCallFormParticipantsProps> =
    (props: CreateCallFormParticipantsProps): ReactElement => {
        const {
            participantsList,
            participantsCount,
            user,
            calendarEventMeta,
            onParticipantsSuggestSelect,
            onChangeParticipantSelectedMethod,
            onDeleteParticipant,
            onGetEventAttendeesButtonClick,
        } = props;

        const {
            ParticipantsList,
        } = useComponentRegistry(registryId);

        let isAddEventAttendees = calendarEventMeta?.event?.attendees.length > 0;

        return (
            <div className={cnCreateCallForm('Participants')}>
                <div className={cnCreateCallForm('ParticipantsTitle')}>
                    {i18nCallForm('participants-title')}
                </div>
                <ParticipantsList
                    data={participantsList}
                    onSuggestSelect={onParticipantsSuggestSelect}
                    onChangeParticipantSelectedMethod={onChangeParticipantSelectedMethod}
                    onDeleteParticipant={onDeleteParticipant}
                    isListHidden={participantsCount === 0}
                    type="common"
                    suggest
                    placeholder={i18nCallForm('participants-suggest-placeholder')}
                    user={user}
                    addEventAttendees={isAddEventAttendees}
                    onGetEventAttendeesButtonClick={onGetEventAttendeesButtonClick}
                />
            </div>
        );
    };
