import { compose } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import i18n from '@yandex-int/i18n';
import React, { FC, ReactElement } from 'react';

import {
    CopyText as BaseCopyText,
    withIconSquare,
} from 'components/CopyText';

import * as keyset from './ShareLinkPopup.i18n';
import { ShareLinkPopupProps } from './ShareLinkPopup.interface';
import './ShareLinkPopup.css';

const i18nShareLinkPopup = i18n(keyset);

export const cnShareLinkPopup = cn('ShareLinkPopup');

const CopyText = compose(withIconSquare)(BaseCopyText);

export const ShareLinkPopup: FC<ShareLinkPopupProps> = (props: ShareLinkPopupProps): ReactElement => {
    const {
        templateId,
        id,
    } = props;

    const isPermanentLink = Boolean(templateId);

    const popupTitle = isPermanentLink ? i18nShareLinkPopup('table-streams-copy-link-title-permanent') : i18nShareLinkPopup('table-streams-copy-link-title');

    const streamPath = isPermanentLink ? `/template/${templateId}/stream` : `/streams/${id}`;
    const chatPath = isPermanentLink ? `/template/${templateId}/chat` : `/streams/${id}/chat`;

    const streamHref = `https://${window.location.host}${streamPath}`;
    const chatHref = `https://${window.location.host}${chatPath}`;

    return (
        <div className={cnShareLinkPopup()}>
            <span className={cnShareLinkPopup('Title')}>{ popupTitle }</span>
            <span className={cnShareLinkPopup('Link')}>
                {i18nShareLinkPopup('stream')}:
                &nbsp;
                <a href={streamHref}>
                    {streamHref}
                </a>
            </span>
            <span className={cnShareLinkPopup('Link')}>
                {i18nShareLinkPopup('chat')}:
                &nbsp;
                <a href={chatHref}>
                    {chatHref}
                </a>
            </span>
            <CopyText
                textToCopy={streamHref}
                textToDisplay={i18nShareLinkPopup('copy-stream-link')}
                icon="square"
            />
            <CopyText
                textToCopy={chatHref}
                textToDisplay={i18nShareLinkPopup('copy-chat-link')}
                icon="square"
            />
        </div>
    );
};
