import dayjs from 'dayjs';

import {
    UpcomingStream,
    UpcomingStreamApi,
} from './streams';

/**
 * Преобразует объект запланированной трансляции из формата, который вернул backend
 * в формат, который используется в store
 *
 * @param stream
 * @returns formattedStream
 */
export const convertUpcomingStreamApiResponse = (stream: UpcomingStreamApi): UpcomingStream => ({
    id: stream.id,
    name: stream.name,
    priority: stream.priority,
    streamDescription: stream.stream_description,
    streamPicture: stream.stream_picture,
    nextEvent: {
        id: stream.next_event.id,
        startTime: stream.next_event.start_time,
        description: stream.next_event.description,
    },
});

/**
 * Сортирует запланированные трансляции в порядке уменьшения приоритета, если он есть
 * Если приоритет трансляции не указан, то сортирует в порядке увеличения даты начала трансляции
 *
 * @param streams
 * @returns sortedStreams
 */
export const sortUpcomingStreams = (streams: UpcomingStreamApi[]): UpcomingStreamApi[] => streams.sort(
    (firstStream, secondStream) => {
        const streamsPriorityDiff = secondStream.priority - firstStream.priority;
        if (streamsPriorityDiff) return streamsPriorityDiff;

        const firstStartDate = dayjs(firstStream.next_event.start_time);
        const secondStartDate = dayjs(secondStream.next_event.start_time);

        if (firstStartDate.isSame(secondStartDate)) return 0;

        if (firstStartDate.isBefore(secondStartDate)) return -1;

        return 1;
    },
);
