import { cn } from '@bem-react/classname';
import React, { FC, ReactElement } from 'react';

import { StubProps } from './Stub.interface';

import './Stub.css';

export const cnStub = cn('Stub');

export const Stub: FC<StubProps> = (props: StubProps): ReactElement => {
    const {
        className,
        title,
        description,
        button,
        image,
    } = props;

    return (
        <div className={cnStub({}, [className])}>
            {
        image && (
        <div className={cnStub('Image')}>
            <img src={image} alt="У вас пока нет звонков" />
        </div>
        )
      }
            <div className={cnStub('Title')}>{title}</div>
            {
        description && (
        <div className={cnStub('Description')}>{description}</div>
        )
      }
            {
        button && (
        <div className={cnStub('Button')}>
            {button}
        </div>
        )
      }
        </div>
    );
};

export default Stub;
