import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';
import './Icon_type_hint.css';

export interface WithTypeHintProps {
  type?: 'hint';
}

export const withTypeHint = withBemMod<WithTypeHintProps>(
    'Icon',
    { type: 'hint' },
    (Icon): FC => ((props): ReactElement => (<Icon {...props} />)),
);
