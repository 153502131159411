import { compose, composeU } from '@bem-react/core';
import { push, goBack } from 'connected-react-router';
import i18n from '@yandex-int/i18n';
import { connect } from 'react-redux';
import React, { ChangeEvent, FC, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router';

import { StoreI, history } from 'ducks/store';
import {
    selectData,
    selectIsTemplateActionRequesting,
    selectOwnersMeta,
    selectIsTemplateRequesting,
    selectIsTemplateStreamPictureUploading,
    resetStreamPicture,
    changeStreamPicture,
    requestTemplateSave,
    requestTemplateUpdate,
    requestTemplate,
    addOwners,
    deleteOwner,
    requestOwnersMeta,
    clearTemplateForm,
} from 'ducks/templateForm';
import { deleteTemplateAllAction } from 'components/CallTemplate/CallTemplate.actions';

import { RequestParticipantsData } from 'ducks/activeCall';
import { selectCurrentUser } from 'ducks/user';

import {
    usePlatformContext,
    useTitle,
} from 'hooks';

import { ParticipantsListGroup } from 'components/ParticipantsList/ParticipantsList.interface';
import {
    generateParticipantsListData,
    getAllParticipantsFromList,
    getParticipantFromList,
} from 'components/ParticipantsList';
import { ActiveCallParticipantI } from 'components/ActiveCalls/ActiveCalls.interface';

import { ParticipantI } from 'components/Participant/Participant.interface';
import {
    TemplateFormScreenDesktop as TemplateFormScreenDesktopBase,
} from './TemplateFormScreen@desktop';
import {
    TemplateFormScreenMobile as TemplateFormScreenMobileBase,
} from './TemplateFormScreen@mobile';
import {
    withTypeCreate as withTemplateFormScreenTypeCreate,
} from './_type/TemplateFormScreen_type_create';
import {
    withTypeEdit as withTemplateFormScreenTypeEdit,
} from './_type/TemplateFormScreen_type_edit';
import {
    withLoading as withTemplateFormScreenLoading,
} from './_loading/TemplateFormScreen_loading';
import {
    withEmpty as withTemplateFormScreenEmpty,
} from './_empty/TemplateFormScreen_empty';

import {
    TemplateFormScreenContainerProps,
    TemplateFormScreenMapDispatchToProps,
    TemplateFormScreenMapStateToProps,
    TemplateFormScreenMatchParams,
} from './TemplateFormScreen.interface';

import * as keyset from './TemplateFormScreen.i18n';

const i18nTemplateFormScreen = i18n(keyset);

const TemplateFormScreenDesktopView = compose(
    withTemplateFormScreenLoading,
    withTemplateFormScreenEmpty,
    composeU(
        withTemplateFormScreenTypeCreate,
        withTemplateFormScreenTypeEdit,
    ),
)(TemplateFormScreenDesktopBase);

const TemplateFormScreenMobileView = compose(
    withTemplateFormScreenLoading,
    withTemplateFormScreenEmpty,
    composeU(
        withTemplateFormScreenTypeCreate,
        withTemplateFormScreenTypeEdit,
    ),
)(TemplateFormScreenMobileBase);

export const TemplateFormScreenContainer: FC<TemplateFormScreenContainerProps> = (
    props: TemplateFormScreenContainerProps): ReactElement => {
    const {
        data,
        isTemplateActionRequesting,
        ownersMeta,
        user,
        isTemplateRequesting,
        isStreamPictureUploading,
        addOwners: addOwnersAction,
        deleteOwner: deleteOwnerAction,
        requestTemplate: requestTemplateAction,
        requestOwnersMeta: requestOwnersMetaAction,
        requestTemplateSave: requestTemplateSaveAction,
        requestTemplateUpdate: requestTemplateUpdateAction,
        clearTemplateForm: clearTemplateFormAction,
        changeStreamPicture: changeStreamPictureAction,
        resetStreamPicture: resetStreamPictureAction,
        push: pushAction,
        goBack: goBackAction,
        deleteTemplateAllAction,
    } = props;

    const match = useRouteMatch<TemplateFormScreenMatchParams>();

    const platform = usePlatformContext();
    const TemplateFormScreenView = platform === 'mobile' ? TemplateFormScreenMobileView : TemplateFormScreenDesktopView;
    const templateId = match.params.id;
    const templateFormScreenViewType = templateId ? 'edit' : 'create';
    const eventName = data.event?.name;

    const [isDeleteConfirmationModalVisible, setIsDeleteConfirmationModalVisible] = useState(false);

    const [templateName, setTemplateName] = useState<string>(data.name);
    const [templateOwners, setTemplateOwners] = useState<Array<string>>(data.owners);
    const [templateEventId, setTemplateEventId] = useState<string>(data.eventId ? String(data.eventId) : null);
    const [templateParticipants, setTemplateParticipants] = useState<ParticipantI[]>(data.participants);
    const [templateStream, setTemplateStream] = useState<boolean>(data.stream);
    const [templateStreamDescription, setTemplateStreamDescription] = useState<string>(data.streamDescription);
    const [templateRecord, setTemplateRecord] = useState<boolean>(data.record);

    useEffect((): void => {
        if (data.name) {
            setTemplateName(data.name);
        }
    }, [data.name]);

    useEffect((): void => {
        if (data.owners.length) {
            setTemplateOwners(data.owners);
        }
    }, [data.owners]);

    useEffect((): void => {
        if (data.eventId) {
            setTemplateEventId(data.eventId ? String(data.eventId) : null);
        }
    }, [data.eventId]);

    useEffect((): void => {
        if (data.participants.length) {
            setTemplateParticipants(data.participants);
        }
    }, [data.participants]);

    useEffect((): void => {
        if (data.stream) {
            setTemplateStream(data.stream);
        }
    }, [data.stream]);

    useEffect((): void => {
        if (data.streamDescription) {
            setTemplateStreamDescription(data.streamDescription);
        }
    }, [data.streamDescription]);

    useEffect((): void => {
        if (data.record) {
            setTemplateRecord(data.record);
        }
    }, [data.record]);

    useEffect((): void => {
        const newOwners = templateOwners.filter((owner): boolean => !ownersMeta[owner]);
        const ownersToRequestMeta = newOwners.map((owner): RequestParticipantsData => ({
            id: owner,
            type: 'person',
        }));

        if (ownersToRequestMeta.length > 0) {
            requestOwnersMetaAction(ownersToRequestMeta);
        }
    }, [requestOwnersMetaAction, ownersMeta, templateOwners]);

    const [didntRequestData, setDidntRequestData] = React.useState<boolean>(true);

    const [participantsList, setParticipantsList] = useState<Map<string, ParticipantsListGroup>>(
        generateParticipantsListData([])[1],
    );

    if (didntRequestData) {
        if (templateId) {
            requestTemplateAction({
                id: templateId,
            });
        } else {
            setTemplateOwners([...templateOwners, user.id]);
        }
        setDidntRequestData(false);
    }

    useEffect((): void => {
        if (templateParticipants.length > 0) {
            setParticipantsList(
                generateParticipantsListData(
                    templateParticipants.map((participant): ActiveCallParticipantI => ({
                        ...participant,
                        state: 'disconnected',
                        camera_active: false,
                        microphone_active: true,
                    })),
                )[1],
            );
        }
    }, [templateParticipants, clearTemplateFormAction, data, ownersMeta]);

    const title = useMemo(() => {
        let parts;

        if (templateFormScreenViewType === 'create') {
            parts = [i18nTemplateFormScreen('title-create')];
        } else {
            parts = [i18nTemplateFormScreen('title-edit')];

            if (templateName) {
                parts.unshift(templateName);
            }
        }

        return parts;
    }, [templateFormScreenViewType, templateName]);

    useTitle(title);

    useEffect((): FunctionVoid => {
        return (): void => {
            clearTemplateFormAction(null);
        };
    }, [clearTemplateFormAction]);

    const handleSubmit = useCallback(e => {
        e.preventDefault();
    }, []);

    const handleDeleteParticipant = useCallback((groupID, participantID): void => {
        const newParticipantsList = new Map(participantsList);
        const group = newParticipantsList.get(groupID) as ParticipantsListGroup;

        group.content.delete(participantID);

        setTemplateParticipants(getAllParticipantsFromList(newParticipantsList));
    }, [participantsList]);

    const handleChangeParticipantSelectedMethod = useCallback((groupID, participantID, event): void => {
        const newParticipantsList = new Map(participantsList);
        const participant = getParticipantFromList(
            newParticipantsList,
            groupID,
            participantID,
        ) as ActiveCallParticipantI;
        const group = newParticipantsList.get(groupID) as ParticipantsListGroup;

        group.content.set(participantID, {
            ...participant,
            method: event.target.value,
        });

        setTemplateParticipants(getAllParticipantsFromList(newParticipantsList));
    }, [participantsList]);

    const handleParticipantsSuggestSelect = useCallback((suggestion): void => {
        const newParticipantsList = new Map(participantsList);
        const group = newParticipantsList.get('main') as ParticipantsListGroup;
        let messenger = suggestion.action[0];

        if (suggestion.action.includes('messenger_q')) {
            messenger = 'messenger_q';
        }

        group.content.set(suggestion.id, {
            ...suggestion,
            state: 'disconnected',
            camera_active: false,
            microphone_active: true,
            method: messenger,
        });

        setTemplateParticipants(getAllParticipantsFromList(newParticipantsList));
    }, [participantsList]);

    const handleFieldNameChange = useCallback((event): void => {
        setTemplateName(event.target.value);
    }, [setTemplateName]);

    const handleFieldEventChange = useCallback((event): void => {
        setTemplateEventId(event.target.value);
    }, [setTemplateEventId]);

    const handleFieldRecordChange = useCallback((): void => {
        setTemplateRecord(!templateRecord);
    }, [templateRecord]);

    const handleFieldStreamChange = useCallback((): void => {
        setTemplateStream(!templateStream);
    }, [templateStream]);

    const handleFieldStreamDescriptionChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>): void => {
        setTemplateStreamDescription(event.target.value);
    }, []);

    const handleDeleteOwner = useCallback((id): FunctionVoid => {
        return (): void => {
            deleteOwnerAction({ id });
            setTemplateOwners(templateOwners.filter((owner): boolean => (owner !== id)));
        };
    }, [deleteOwnerAction, templateOwners]);

    const handleOwnersSuggestSelect = useCallback((suggestion): void => {
        const isOwnerAlreadyAdded = templateOwners.includes(suggestion.id);

        if (isOwnerAlreadyAdded) return;

        setTemplateOwners([...templateOwners, suggestion.id]);
        addOwnersAction([suggestion]);
    }, [addOwnersAction, templateOwners]);

    const handleCancelButtonClick = useCallback((): void => {
        if (history.length > 1) {
            goBackAction();
        } else {
            pushAction('/');
        }
    }, [goBackAction, pushAction]);

    const handleCreateButtonClick = useCallback((): void => {
        requestTemplateSaveAction({
            id: data.id,
            duration: data.duration,
            eventExternalId: data.eventExternalId,
            name: templateName,
            owners: templateOwners,
            eventId: templateEventId,
            participants: templateParticipants,
            stream: templateStream,
            streamDescription: templateStreamDescription,
            streamPicture: data.streamPicture,
            record: templateRecord,
            event: data.event,
        });
    }, [
        data.id,
        data.duration,
        data.eventExternalId,
        data.event,
        data.streamPicture,
        templateName,
        templateOwners,
        templateEventId,
        templateParticipants,
        templateStream,
        templateStreamDescription,
        templateRecord,
        requestTemplateSaveAction,
    ]);

    const handleDeleteCancelClick = useCallback((): void => {
        setIsDeleteConfirmationModalVisible(false);
    }, []);

    const handleDeleteButtonClick = useCallback((): void => {
        setIsDeleteConfirmationModalVisible(true);
    }, []);

    const handleDeleteAllClick = useCallback((): void => {
        setIsDeleteConfirmationModalVisible(false);
        deleteTemplateAllAction(data.id);
        pushAction('/');
    }, [pushAction, deleteTemplateAllAction, data.id]);

    const handleEditButtonClick = useCallback((): void => {
        requestTemplateUpdateAction({
            id: data.id,
            duration: data.duration,
            eventExternalId: data.eventExternalId,
            name: templateName,
            owners: templateOwners,
            eventId: templateEventId,
            participants: templateParticipants,
            stream: templateStream,
            streamDescription: templateStreamDescription,
            streamPicture: data.streamPicture,
            record: templateRecord,
            event: data.event,
        });
    }, [
        data.id,
        data.duration,
        data.eventExternalId,
        data.event,
        data.streamPicture,
        templateName,
        templateOwners,
        templateEventId,
        templateParticipants,
        templateStream,
        templateStreamDescription,
        templateRecord,
        requestTemplateUpdateAction,
    ]);

    const handleFieldStreamPictureChange = useCallback((event): void => {
        if (event.target.files.length) {
            changeStreamPictureAction(event.target.files[0]);
        }
    }, [changeStreamPictureAction]);

    const handleFieldStreamPictureReset = useCallback((): void => {
        resetStreamPictureAction(null);
    }, [resetStreamPictureAction]);

    return (
        <TemplateFormScreenView
            type={templateFormScreenViewType as undefined}
            loading={templateFormScreenViewType === 'edit' && (didntRequestData || isTemplateRequesting)}
            empty={templateFormScreenViewType === 'edit' && !didntRequestData && !data.id && !isTemplateRequesting}
            templateName={templateName}
            templateOwners={templateOwners}
            templateEventId={templateEventId}
            templateParticipants={templateParticipants}
            templateStream={templateStream}
            templateStreamPicture={data.streamPicture}
            templateStreamDescription={templateStreamDescription}
            templateRecord={templateRecord}
            participantsList={participantsList}
            templateId={Number(templateId)}
            eventId={data.eventId}
            eventName={eventName}
            isTemplateActionRequesting={isTemplateActionRequesting}
            isStreamPictureUploading={isStreamPictureUploading}
            isDeleteConfirmationModalVisible={isDeleteConfirmationModalVisible}
            ownersMeta={ownersMeta}
            onSubmit={handleSubmit}
            onCreateButtonClick={handleCreateButtonClick}
            onEditButtonClick={handleEditButtonClick}
            onDeleteCancelClick={handleDeleteCancelClick}
            onDeleteButtonClick={handleDeleteButtonClick}
            onDeleteAllClick={handleDeleteAllClick}
            onCancelButtonClick={handleCancelButtonClick}
            onChangeParticipantSelectedMethod={handleChangeParticipantSelectedMethod}
            onDeleteParticipant={handleDeleteParticipant}
            onDeleteOwner={handleDeleteOwner}
            onFieldNameChange={handleFieldNameChange}
            onFieldEventChange={handleFieldEventChange}
            onFieldRecordChange={handleFieldRecordChange}
            onFieldStreamChange={handleFieldStreamChange}
            onFieldStreamDescriptionChange={handleFieldStreamDescriptionChange}
            onFieldStreamPictureChange={handleFieldStreamPictureChange}
            onFieldStreamPictureReset={handleFieldStreamPictureReset}
            onOwnersSuggestSelect={handleOwnersSuggestSelect}
            onParticipantsSuggestSelect={handleParticipantsSuggestSelect}
    />
    );
};

export const TemplateFormScreen = connect<TemplateFormScreenMapStateToProps, TemplateFormScreenMapDispatchToProps>(
    (store: StoreI): TemplateFormScreenMapStateToProps => ({
        isTemplateActionRequesting: selectIsTemplateActionRequesting(store),
        isTemplateRequesting: selectIsTemplateRequesting(store),
        isStreamPictureUploading: selectIsTemplateStreamPictureUploading(store),
        data: selectData(store),
        ownersMeta: selectOwnersMeta(store),
        user: selectCurrentUser(store),
    }),
    {
        resetStreamPicture,
        changeStreamPicture,
        requestTemplateSave,
        requestTemplateUpdate,
        addOwners,
        deleteOwner,
        requestOwnersMeta,
        requestTemplate,
        clearTemplateForm,
        push,
        goBack,
        deleteTemplateAllAction,
    },
)(TemplateFormScreenContainer);
