import React, { ReactElement, FC } from 'react';
import { compose, withBemMod } from '@bem-react/core';
import {
    SvgIcon as SvgIconBase,
    withTypeError,
} from 'components/SvgIcon';

import { cnStreamScreen } from '../../StreamScreen.cn';
import { i18nStreamScreen } from '../../StreamScreen';
import { StreamScreenRateInformerProps } from '../StreamScreen-RateInformer.interface';

import { WithErrorProps } from './StreamScreen-RateInformer_error.interface';

import './StreamScreen-RateInformer_error.css';

const SvgIcon = compose(withTypeError)(SvgIconBase);

export const withError = withBemMod<WithErrorProps, StreamScreenRateInformerProps>(
    cnStreamScreen('RateInformer'),
    { error: true },
    (StreamScreenRateInformer): FC<WithErrorProps & StreamScreenRateInformerProps> => (
        (props: WithErrorProps & StreamScreenRateInformerProps): ReactElement => {
            const replacer = (
                <div className={cnStreamScreen('RateInformerTitle', { state: 'error' })}>
                    <SvgIcon
                        type="error"
                        className={cnStreamScreen('RateInformerErrorIcon')}
                    />
                    <span className={cnStreamScreen('RateInformerErrorText')}>
                        {i18nStreamScreen('rate-informer-error-title')}
                    </span>
                </div>
            );

            return (
                <StreamScreenRateInformer
                    {...props}
                    replacer={replacer}
                />
            );
        }
    ),
);
