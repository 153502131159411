import { cn } from '@bem-react/classname';
import { compose } from '@bem-react/core';
import { getI18nLang } from '@yandex-int/i18n';

import {
    Icon as IconBase,
} from '@yandex-lego/components/Icon/desktop';
import React, { FC, ReactElement } from 'react';
import {
    withTypeEye,
    withToneGray,
} from 'components/Icon';

import { ViewersCounterProps } from './ViewersCounter.interface';
import './ViewersCounter.css';

const Icon = compose(
    withTypeEye,
    withToneGray,
)(IconBase);

const cnViewersCounter = cn('ViewersCounter');

export const ViewersCounter: FC<ViewersCounterProps> = (props: ViewersCounterProps): ReactElement => {
    const {
        count,
        className,
        onViewersCounterClick,
        title,
        iconTone,
    } = props;

    const formattedNumber = count ? new Intl.NumberFormat(getI18nLang()).format(count) : '–';

    return (
        <div
            className={cnViewersCounter(null, [className])}
            onClick={onViewersCounterClick}
            title={title}
        >
            <Icon type="eye" size="m" tone={iconTone} />
            <span className={cnViewersCounter('Count')}>{formattedNumber}</span>
        </div>
    );
};
