export const ru = {
    main: 'Главная',
    history: 'История',
    streams: 'Трансляции',
    staff: 'Я на Стаффе',
    options: 'Настройки',
    logout: 'Выход',
    menu: 'Меню',
    docs: 'Документация',
    yandex: 'Яндекс',
};
