import { webSocket } from 'rxjs/webSocket';
import zoomSdk from '@zoom/appssdk';

import { Subject } from 'rxjs';
import { isLocalEnvironment } from 'shared/lib/env/isLocalEnvironment';
import { WebSocketData } from '../../components/ClickerZoom/ClickerZoom.interface';
import { notyError } from '../../components/Notifications/Notifications.actions';
import { ZoomDetailsLoadedPayload } from './clickerZoom';

const wsData: WebSocketData = {
    meetingId: null,
    webSocketSubject: null,
    onOpenSubject: null,
    onCloseSubject: null,
};

const getSocketUrl = () => {
    return isLocalEnvironment() ?
        'wss://vconf.test.yandex-team.ru/clicker/external/ws' :
        `wss://${window.location.host}/clicker/external/ws`;
};

const getWsData = () => {
    return wsData;
};

const clearWsData = () => {
    wsData.onOpenSubject && wsData.onOpenSubject.complete();
    wsData.onCloseSubject && wsData.onCloseSubject.complete();
    wsData.webSocketSubject && wsData.webSocketSubject.complete();

    wsData.meetingId = null;
    wsData.webSocketSubject = null;
    wsData.onOpenSubject = null;
    wsData.onCloseSubject = null;
};

const connectSocket = () => {
    clearWsData();

    wsData.onOpenSubject = new Subject();
    wsData.onCloseSubject = new Subject();

    wsData.webSocketSubject = webSocket({
        url: getSocketUrl(),
        openObserver: wsData.onOpenSubject,
        closeObserver: wsData.onCloseSubject,
    });

    return wsData;
};

const getAdditionalFromEvent = (event: CloseEvent) => {
    const ws = (event.target || event.currentTarget || {}) as WebSocket;

    const url = ws.url || '';
    const code = event.code || '';
    const timeStamp = event.timeStamp || '';
    const type = event.type || '';
    const reason = event.reason || '';

    return { url, timeStamp, code, type, reason };
};

const logError = (error: Error, method: string, additional: Object = {}) => {
    console.error(error);
    console.error('additional:', additional);

    const env = process.env.NODE_ENV === 'test' ? 'testing' : process.env.NODE_ENV;
    const version = process.env.VERSION;

    if (window?.Ya?.Rum?.logError) {
        window.Ya.Rum.logError({
            message: error.message,
            block: 'clickerZoomEpic',
            method: method,
            page: 'clicker-zoomv',
            service: 'vconf',
            level: Ya.Rum.ERROR_LEVEL.ERROR,
            ignoreErrorMessage: true,
            additional,
        }, error, { env, version });
    }

    return notyError(error.message);
};

const initZoomSdk = async() => {
    await zoomSdk.config({
        popoutSize: { width: 480, height: 360 },
        capabilities: ['shareApp', 'getMeetingContext', 'getUserContext'],
    });
};

const loadZoomData = async(): Promise<ZoomDetailsLoadedPayload> => {
    const meetingUUID = await zoomSdk.getMeetingUUID();
    const userContext = await zoomSdk.getUserContext();

    return {
        participantUUID: userContext.participantUUID,
        meetingId: meetingUUID.meetingUUID,
        role: userContext.role,
    };
};

export interface ClickerZoomApi {
    connectSocket: typeof connectSocket;
    getWsData: typeof getWsData;
    logError: typeof logError;
    getAdditionalFromEvent: typeof getAdditionalFromEvent;
    loadZoomData: typeof loadZoomData;
    initZoomSdk: typeof initZoomSdk;
}

export const clickerZoomApi: ClickerZoomApi = {
    connectSocket,
    getWsData,
    logError,
    getAdditionalFromEvent,
    loadZoomData,
    initZoomSdk,
};
