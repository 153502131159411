import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import { YabblePropsI } from '../Yabble.interface';
import { cnYabble } from '../Yabble';
import './Yabble_fixed.css';

export interface WithFixed {
  fixed?: boolean;
}

export const withFixed = withBemMod<WithFixed, YabblePropsI>(
    cnYabble(),
    { fixed: true },
    (Yabble): FC<WithFixed & YabblePropsI> => ((props: WithFixed & YabblePropsI): ReactElement =>
        (<Yabble {...props} />)),
);

export default withFixed;
