import { withBemMod, compose, composeU } from '@bem-react/core';
import { cn } from '@bem-react/classname';

import { useComponentRegistry } from '@bem-react/di';

import {
    Popup as PopupPresenter,
    withViewDefault,
    withTargetAnchor,
} from '@yandex-lego/components/Popup/desktop';

import { Link } from '@yandex-lego/components/Link/desktop/bundle';
import React, { FC, ReactElement, RefObject, useCallback } from 'react';
import { registryId } from 'components/GlobalContextRegistry/GlobalContextRegistry.constants';

import {
    ParticipantAvatar as ParticipantAvatarBase,
    withTypeRoom as withParticipantAvatarTypeRoom,
    withTypePerson as withParticipantAvatarTypePerson,
    withTypeArbitrary as withParticipantAvatarTypeArbitrary,
} from 'components/ParticipantAvatar';

import { ParticipantI } from 'components/Participant/Participant.interface';
import {
    cnBubbleParticipants,
    BubbleParticipantsProps,
} from '../BubbleParticipants';

import './BubbleParticipants_popup.css';

const cnBubbleParticipantsPopup = cn('BubbleParticipantsPopup');

const Popup = compose(
    withViewDefault,
    withTargetAnchor,
)(PopupPresenter);

const ParticipantAvatar = composeU(
    withParticipantAvatarTypeRoom,
    withParticipantAvatarTypePerson,
    withParticipantAvatarTypeArbitrary,
)(ParticipantAvatarBase);

export interface BubbleParticipantsPopup extends BubbleParticipantsProps {
  popup: boolean
}

interface ParticipantsAccumulator {
    room: ParticipantI[];
    person: ParticipantI[];
    arbitrary: ParticipantI[];
    cms: ParticipantI[];
}

const withPopup = withBemMod<BubbleParticipantsPopup>(
    cnBubbleParticipants(),
    { popup: true },
    (BubbleParticipants): FC<BubbleParticipantsPopup> => (props: BubbleParticipantsPopup): ReactElement => {
        const {
            participants,
            onClick = (): void => {},
            onKeyDown = (): void => {},
        } = props;

        const { StaffName } = useComponentRegistry(registryId);

        const [isPopupVisible, setIsPopupVisible] = React.useState(false);

        const togglePopupVisibility = React.useCallback((): void => {
            document.body.classList.toggle('Body-Modal_opened');
            setIsPopupVisible((prevState): boolean => !prevState);
        }, []);

        const [lastBubbleRef, setLastBubbleRef] = React.useState<RefObject<HTMLDivElement> | {}>({});
        const handleRefSetting = React.useCallback((incomingRef: RefObject<HTMLDivElement>): void => {
            setLastBubbleRef(incomingRef);
        }, []);

        const { room, person, arbitrary } = React.useMemo((): ParticipantsAccumulator => {
            return participants.reduce((acc: ParticipantsAccumulator, participant): ParticipantsAccumulator => {
                const targetArray = acc[participant.type];
                targetArray.push(participant);

                return acc;
            }, { room: [], person: [], arbitrary: [], cms: [] });
        }, [participants]);

        let anchorProps = {};
        if (window.innerWidth > 768) {
            anchorProps = {
                directions: ['bottom-end', 'top-end', 'bottom-start', 'top-start'],
                target: 'anchor',
                anchor: lastBubbleRef,
            };
        }

        const onParticipantsBubbleClick = useCallback((event): void => {
            onClick(event);
            togglePopupVisibility();
        }, [onClick, togglePopupVisibility]);

        const onParticipantsBubbleKeyDown = useCallback((event): void => {
            onKeyDown(event);
            togglePopupVisibility();
        }, [onKeyDown, togglePopupVisibility]);

        return (
            <React.Fragment>
                <BubbleParticipants
                    {...props}
                    onClick={onParticipantsBubbleClick}
                    onKeyDown={onParticipantsBubbleKeyDown}
                    forwardRef={handleRefSetting}
                />
                <Popup
                    visible={isPopupVisible}
                    onClose={togglePopupVisibility}
                    className={cnBubbleParticipantsPopup()}
                    hasTail
                    keepMounted={false}
                    view="default"
                    {...anchorProps}
                >
                    <div className={cnBubbleParticipantsPopup('Content')}>
                        <button
                            className={cnBubbleParticipantsPopup('CloseButton')}
                            onClick={togglePopupVisibility}
                            type="button"
                    />
                        <div className={cnBubbleParticipantsPopup('Header')}>
                            {'Участники '}
                            <span className={cnBubbleParticipantsPopup('Text', { type: 'secondary' })}>
                                { participants.length }
                            </span>
                        </div>
                        <div className={cnBubbleParticipantsPopup('Body')}>
                            { room.map((roomItem): ReactElement => (
                                <div
                                    className={cnBubbleParticipantsPopup('Row')}
                                    key={roomItem.id}
                                >
                                    <ParticipantAvatar
                                        className={cnBubbleParticipantsPopup('Avatar')}
                                        type="room"
                                    />
                                    <div className={cnBubbleParticipantsPopup('Title')}>
                                        { roomItem.name }
                                    </div>
                                </div>
                            ))}
                            { person.map((personItem): ReactElement => (
                                <Link
                                    theme="black"
                                    className={cnBubbleParticipantsPopup('Row', { type: 'person' })}
                                    key={personItem.id}
                                    href={`https://staff.yandex-team.ru/${personItem.id}`}
                                    target="_blank"
                                >
                                    <ParticipantAvatar
                                        className={cnBubbleParticipantsPopup('Avatar')}
                                        id={personItem.id}
                                        type="person"
                                    />
                                    <div className={cnBubbleParticipantsPopup('Title')}>
                                        <StaffName id={personItem.id} name={personItem.name} />
                                    </div>
                                </Link>
                            ))}
                            { arbitrary.map((arbitraryItem): ReactElement => (
                                <div
                                    className={cnBubbleParticipantsPopup('Row')}
                                    key={arbitraryItem.id}
                                >
                                    <ParticipantAvatar
                                        className={cnBubbleParticipantsPopup('Avatar')}
                                        type="arbitrary"
                                    />
                                    <div className={cnBubbleParticipantsPopup('Title')}>
                                        { arbitraryItem.name }
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </Popup>
            </React.Fragment>
        );
    },
);

export default withPopup;
