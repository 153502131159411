import { compose } from '@bem-react/core';
import { push } from 'connected-react-router';
import i18n from '@yandex-int/i18n';
import useInterval from '@use-it/interval';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router';
import React, { ChangeEvent, FC, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';

import { StoreI } from 'ducks/store';
import {
    selectActiveCall,
    selectActiveCallIsRequesting,
    selectAddParticipants,
    selectIsCallAffectRequesting,
    requestActiveCallDetails,
    requestParticipantDetails,
    changeNewParticipantMethod,
    clearNewParticipants,
    deleteNewParticipant,
    addNewParticipants,
    addParticipantsToCall,
    affectParticipantsInCall,
    endCall,
    receiveParticipantDetails,
    updateCallDetails,
    changeDuration,
    selectIsDurationChanging,
} from 'ducks/activeCall';
import { selectCurrentUser } from 'ducks/user';
import { selectIsPageVisible } from 'ducks/pageVisibility';

import {
    usePlatformContext,
    useTitle,
} from 'hooks';

import { IParticipantMethods } from 'components/Participant/Participant.interface';
import { ActiveCallI, ActiveCallParticipantI } from 'components/ActiveCalls/ActiveCalls.interface';
import { ParticipantsListGroup } from 'components/ParticipantsList/ParticipantsList.interface';
import {
    generateParticipantsListData,
    getParticipantFromList,
    getAllParticipantsFromList,
} from 'components/ParticipantsList';
import { getConnectionMethod } from 'components/Participant/Participant.util';

import { CallFormDuration } from 'components/CreateCallForm/CreateCallForm.interface';
import {
    ActiveCallScreenContainerMatchParams,
    ActiveCallScreenContainerProps,
    ActiveCallScreenMapStateToProps,
    CurrentUser,
} from './ActiveCallScreen.interface';

import { ActiveCallScreenMobile as ActiveCallScreenMobileBase } from './ActiveCallScreen@mobile';
import { ActiveCallScreenDesktop as ActiveCallScreenDesktopBase } from './ActiveCallScreen@desktop';
import { withLoading } from './_loading/ActiveCallScreen_loading';
import { withEmpty } from './_empty/ActiveCallScreen_empty';
import * as keyset from './ActiveCallScreen.i18n';

const ActiveCallScreenMobileView = compose(
    withEmpty,
    withLoading,
)(ActiveCallScreenMobileBase);

const ActiveCallScreenDesktopView = compose(
    withEmpty,
    withLoading,
)(ActiveCallScreenDesktopBase);

const i18nActiveCall = i18n(keyset);

const ActiveCallScreenContainer: FC<ActiveCallScreenContainerProps> = (props): ReactElement => {
    const {
        isRequesting,
        isCallAffectRequesting,
        addParticipants,
        user,
        isDurationChanging,
        isPageVisible,
        changeNewParticipantMethod: changeNewParticipantMethodAction,
        receiveParticipantDetails: receiveParticipantDetailsAction,
        deleteNewParticipant: deleteNewParticipantAction,
        addNewParticipants: addNewParticipantsAction,
        addParticipantsToCall: addParticipantsToCallAction,
        affectParticipantsInCall: affectParticipantsInCallAction,
        requestActiveCallDetails: requestActiveCallDetailsAction,
        clearNewParticipants: clearNewParticipantsAction,
        updateCallDetails: updateCallDetailsAction,
        endCall: endCallAction,
        push: pushAction,
        changeDuration: changeDurationAction,
    } = props;

    const match = useRouteMatch<ActiveCallScreenContainerMatchParams>();

    const call: ActiveCallI = Object(props.call);
    const platform = usePlatformContext();
    const ActiveCallScreenView = platform === 'mobile' ? ActiveCallScreenMobileView : ActiveCallScreenDesktopView;

    const [didntRequestData, setDidntRequestData] = React.useState<boolean>(true);

    const isEmpty = !call.id && !didntRequestData;
    const isLoading = isRequesting || didntRequestData;

    const title = useMemo(() => {
        let parts = [];

        if (isEmpty && !isRequesting) {
            parts = [i18nActiveCall('call-is-absent-title')];
        } else {
            parts = [i18nActiveCall('active-call-title')];

            if (call.name) {
                parts.unshift(`${call.name}`);
            }
        }

        return parts;
    }, [call.name, isEmpty, isRequesting]);

    useTitle(title);

    /**
     * Блок запроса за данными звонка
     */
    const callId = match.params.id;
    const searchParams = new URLSearchParams(window.location.search);
    const secret = searchParams.get('secret') || '';

    useEffect((): void => {
        requestActiveCallDetailsAction({
            callId,
            secret,
        });

        setDidntRequestData(false);
    }, [callId, requestActiveCallDetailsAction, secret]);

    const onUpdateActiveCallScreen = useCallback((): void => {
        if (isPageVisible && call.id) {
            updateCallDetailsAction({
                callId,
                secret,
            });
        }
    }, [call.id, callId, isPageVisible, secret, updateCallDetailsAction]);

    useInterval(onUpdateActiveCallScreen, 10 * 1000);

    /**
     * Коллбэк завершения звонка
     */
    const handleCallEnd = useCallback((): void => {
        endCallAction({
            callId: call.id,
            secret,
        });
    }, [call.id, endCallAction, secret]);

    /**
     * В компоненте три списка Participants:
     *  - Ожидают добавления
     *  - Активные
     *  - Отключились
     *
     * Далее: формирование данных и обработчиков для этих списков
     */

    /**
     * Формирование списка для 'Активные'
     */
    const [
        activeParticipantsCount,
        activeParticipantsList,
    ] = useMemo((): [number, Map<string, ParticipantsListGroup>] => {
        return generateParticipantsListData(call.participants, 'active');
    }, [call.participants]);
    const handleDeleteActiveParticipant = useCallback((groupID, participantID): void => {
        const participant = getParticipantFromList(
            activeParticipantsList,
            groupID,
            participantID,
        ) as ActiveCallParticipantI;

        affectParticipantsInCallAction({
            action: 'disconnect',
            callId: call.id,
            participants: [participant],
            secret,
        });
    }, [activeParticipantsList, affectParticipantsInCallAction, call.id, secret]);

    /* istanbul ignore next */ /* TODO Этот код временно не используется http://st/VCONF-320 */
    const handleToggleParticipantMicrophone = useCallback((groupID, participantID): void => {
        const participant = getParticipantFromList(
            activeParticipantsList,
            groupID,
            participantID,
        ) as ActiveCallParticipantI;

        affectParticipantsInCallAction({
            action: 'toggle_microphone',
            callId: call.id,
            participants: [participant],
            secret,
        });
    }, [activeParticipantsList, affectParticipantsInCallAction, call.id, secret]);

    /* istanbul ignore next */ /* TODO Этот код временно не используется http://st/VCONF-320 */
    const handleToggleParticipantCamera = useCallback((groupID, participantID): void => {
        const participant = getParticipantFromList(
            activeParticipantsList,
            groupID,
            participantID,
        ) as ActiveCallParticipantI;

        affectParticipantsInCallAction({
            action: 'toggle_camera',
            callId: call.id,
            participants: [participant],
            secret,
        });
    }, [activeParticipantsList, affectParticipantsInCallAction, call.id, secret]);

    /**
     * Формирование списка для 'Ожидают добавления'
     */
    const [addParticipantsCount, addParticipantsList] = useMemo((): [number, Map<string, ParticipantsListGroup>] => {
        const addParticipantsArray = Object.keys(addParticipants).map((key: string): ActiveCallParticipantI => ({
            ...addParticipants[key],
            state: 'disconnected',
            camera_active: true,
            microphone_active: true,
        }));

        return generateParticipantsListData(addParticipantsArray, null);
    }, [addParticipants]);

    const handleChangeAddParticipantSelectedMethod = useCallback((groupID, participantID, event): void => {
        changeNewParticipantMethodAction({
            id: participantID,
            method: event.target.value,
        });
    }, [changeNewParticipantMethodAction]);

    const handleDeleteAddParticipant = useCallback((groupID, participantID): void => {
        deleteNewParticipantAction({
            id: participantID,
        });
    }, [deleteNewParticipantAction]);

    const handleAddAllParticipantToCall = useCallback((): void => {
        addNewParticipantsAction({
            callId: call.id,
            participants: getAllParticipantsFromList(addParticipantsList),
            secret,
        });
    }, [addNewParticipantsAction, call.id, addParticipantsList, secret]);

    const handleClearAddParticipants = useCallback((): void => {
        clearNewParticipantsAction();
    }, [clearNewParticipantsAction]);

    /**
     * Формирование списка для 'Отключились'
     */
    const [
        disconnectedParticipantsList,
        setDisconnectedParticipantsList,
    ] = useState<Map<string, ParticipantsListGroup>>(new Map());
    const [
        newDisconnectedParticipantsCount,
        newDisconnectedParticipantsList,
    ] = useMemo((): [number, Map<string, ParticipantsListGroup>] => {
        return generateParticipantsListData(call.participants, 'disconnected', disconnectedParticipantsList);
    }, [call.participants, disconnectedParticipantsList]);

    const handleChangeDisconnectedParticipantSelectedMethod = useCallback((groupID, participantID, event): void => {
        const participant = getParticipantFromList(
            newDisconnectedParticipantsList,
            groupID,
            participantID,
        ) as ActiveCallParticipantI;

        participant.method = event.target.value;

        setDisconnectedParticipantsList(new Map(newDisconnectedParticipantsList));
    }, [newDisconnectedParticipantsList]);

    const handleRecallToAllDisconnectedParticipants = useCallback((): void => {
        addParticipantsToCallAction({
            callId: call.id,
            participants: getAllParticipantsFromList(newDisconnectedParticipantsList),
            secret,
        });
    }, [addParticipantsToCallAction, call.id, newDisconnectedParticipantsList, secret]);

    const handleRecallToDisconnectedParticipant = useCallback((groupID, participantID): void => {
        const participant = getParticipantFromList(
            newDisconnectedParticipantsList,
            groupID,
            participantID,
        ) as ActiveCallParticipantI;

        addParticipantsToCallAction({
            callId: call.id,
            participants: [participant],
            secret,
        });
    }, [addParticipantsToCallAction, call.id, newDisconnectedParticipantsList, secret]);

    const handleRemoveDisconnectedParticipant = useCallback((groupID, participantID): void => {
        const participant = getParticipantFromList(
            newDisconnectedParticipantsList,
            groupID,
            participantID,
        ) as ActiveCallParticipantI;

        affectParticipantsInCallAction({
            action: 'remove',
            callId: call.id,
            participants: [participant],
            secret,
        });
    }, [affectParticipantsInCallAction, call.id, newDisconnectedParticipantsList, secret]);

    /**
     * Добавление из саджеста
     */

    const handleSuggestSelect = useCallback((addedSuggestion): void => {
        receiveParticipantDetailsAction(addedSuggestion);
    }, [receiveParticipantDetailsAction]);

    /**
     * Обработка клика по кнопке возврата на главную в заглушке
     */
    const handleStubButtonClick = useCallback((): void => {
        pushAction('/create-call');
    }, [pushAction]);

    /**
     * Обработчики для блока 'Про меня'
     */
    const [currentUser, setCurrentUser] = useState<CurrentUser>({
        ...user,
        method: getConnectionMethod(user),
        state: 'join',
    });

    useEffect((): void => {
        const userParticipant = call.participants?.find((participant): boolean => participant.id === user.id);

        if (call.id !== '' && userParticipant) {
            const method = userParticipant.state === currentUser.state ? currentUser.method : userParticipant.method;
            setCurrentUser({
                ...userParticipant,
                method,
            });
        }
    }, [call.id, call.participants, currentUser.method, currentUser.state, user.id]);

    const handleDisconnectCurrentUser = useCallback((): void => {
        affectParticipantsInCallAction({
            action: 'disconnect',
            callId: call.id,
            participants: [currentUser],
            secret,
        });
    }, [affectParticipantsInCallAction, call.id, currentUser, secret]);

    const handleAddCurrentUser = useCallback((): void => {
        addParticipantsToCallAction({
            callId: call.id,
            participants: [currentUser],
            secret,
        });
    }, [addParticipantsToCallAction, call.id, currentUser, secret]);

    const handleChangeCurrentUserMethod = useCallback(
        (event: ChangeEvent<HTMLSelectElement | HTMLInputElement>): void => {
            setCurrentUser({
                ...currentUser,
                method: event.target.value as IParticipantMethods,
            });
        }, [currentUser],
    );

    const handleDurationChange = useCallback((event: ChangeEvent<HTMLSelectElement>): void => {
        changeDurationAction({
            callId: call.id,
            duration: parseInt(event.target.value, 10) as CallFormDuration,
            previousDuration: call.duration,
        });
    }, [call.duration, call.id, changeDurationAction]);

    const handleGoToCmsClick = useCallback(() => {
        const url = new URL(call.invite_link);

        url.searchParams.set('guest-name', user.name);

        window.open(`${url}`, '_blank');
    }, [call.invite_link, user.name]);

    return (
        <ActiveCallScreenView
            call={call}
            loading={isLoading}
            empty={isEmpty}
            user={user}
            activeParticipantsList={activeParticipantsList}
            activeParticipantsCount={activeParticipantsCount}
            onDeleteActiveParticipant={handleDeleteActiveParticipant}
            addParticipantsList={addParticipantsList}
            addParticipantsCount={addParticipantsCount}
            onClearAddParticipants={handleClearAddParticipants}
            onDeleteAddParticipant={handleDeleteAddParticipant}
            onChangeAddParticipantSelectedMethod={handleChangeAddParticipantSelectedMethod}
            onSuggestSelect={handleSuggestSelect}
            disconnectedParticipantsList={newDisconnectedParticipantsList}
            disconnectedParticipantsCount={newDisconnectedParticipantsCount}
            onChangeDisconnectedParticipantSelectedMethod={handleChangeDisconnectedParticipantSelectedMethod}
            onRecallToAllDisconnectedParticipants={handleRecallToAllDisconnectedParticipants}
            onRecallToDisconnectedParticipant={handleRecallToDisconnectedParticipant}
            onRemoveDisconnectedParticipant={handleRemoveDisconnectedParticipant}
            onAddAllParticipantToCall={handleAddAllParticipantToCall}
            onEndCall={handleCallEnd}
            onStubButtonClick={handleStubButtonClick}
            currentUser={currentUser}
            onDisconnectCurrentUser={handleDisconnectCurrentUser}
            onAddCurrentUser={handleAddCurrentUser}
            onChangeCurrentUserSelectedMethod={handleChangeCurrentUserMethod}
            isCallAffectRequesting={isCallAffectRequesting}
            onToggleParticipantMicrophone={handleToggleParticipantMicrophone}
            onToggleParticipantCamera={handleToggleParticipantCamera}
            isDurationChanging={isDurationChanging}
            onDurationChange={handleDurationChange}
            onGoToCmsClick={handleGoToCmsClick}
        />
    );
};

export const ActiveCallScreen = connect(
    (store: StoreI): ActiveCallScreenMapStateToProps => ({
        isRequesting: selectActiveCallIsRequesting(store),
        isCallAffectRequesting: selectIsCallAffectRequesting(store),
        call: selectActiveCall(store),
        addParticipants: selectAddParticipants(store),
        user: selectCurrentUser(store),
        isDurationChanging: selectIsDurationChanging(store),
        isPageVisible: selectIsPageVisible(store),
    }),
    {
        changeDuration,
        requestActiveCallDetails,
        requestParticipantDetails,
        receiveParticipantDetails,
        updateCallDetails,
        changeNewParticipantMethod,
        deleteNewParticipant,
        clearNewParticipants,
        addNewParticipants,
        affectParticipantsInCall,
        addParticipantsToCall,
        endCall,
        push,
    },
)(ActiveCallScreenContainer);
