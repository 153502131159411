import { createAction, handleActions, Action } from 'redux-actions';
import { StoreI } from 'ducks/store';

export type PageVisibilityState = boolean;

export const initialState: PageVisibilityState = true;

export type SetVisibilityPayload = boolean;

const SET_VISIBILITY = 'vconf/pageVisibility/SET_VISIBILITY';

export const setVisibility = createAction<SetVisibilityPayload>(SET_VISIBILITY);

type Payload = SetVisibilityPayload;

export const reducer = handleActions<PageVisibilityState, Payload>({
    [SET_VISIBILITY]: (state, action: Action<SetVisibilityPayload>): PageVisibilityState => {
        const {
            payload,
        } = action;

        return payload;
    },
}, initialState);

export const selectIsPageVisible = (store: StoreI): PageVisibilityState => store.pageVisibility;
