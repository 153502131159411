import i18n from '@yandex-int/i18n';
import { cn } from '@bem-react/classname';
import { composeU } from '@bem-react/core';
import { Button } from '@yandex-lego/components/Button/desktop/bundle';
import { Icon as IconBase } from '@yandex-lego/components/Icon/desktop';
import { Link } from '@yandex-lego/components/Link/desktop/bundle';
import React, { FC, ReactElement } from 'react';

import {
    withTypeSquare as withIconTypeSquare,
    withTypeUpdate as withIconTypeUpdate,
} from 'components/Icon';
import { CopyText } from 'components/CopyText';
import { Hint } from 'components/Hint';

import { InviteCallLinkProps } from './InviteCallLink.interface';
import * as keyset from './InviteCallLink.i18n';
import './InviteCallLink.css';

const cnInviteCallLink = cn('InviteCallLink');
const i18nInviteCallLink = i18n(keyset);

const Icon = composeU(
    withIconTypeSquare,
    withIconTypeUpdate,
)(IconBase);

const iconUpdate = (iconClassName: string) => (<Icon type="update" className={iconClassName} />);
const iconCopy = (iconClassName: string) => (<Icon type="square" className={iconClassName} />);

export const InviteCallLink: FC<InviteCallLinkProps> = (props): ReactElement => {
    const {
        calendarEventMeta,
        inviteCallLink,
        onReGenerateInviteCallLinkClick,
        className,
    } = props;

    const isInviteCallLinkVisible = calendarEventMeta?.event && inviteCallLink;

    if (!isInviteCallLinkVisible) return null;

    let secret = new URL(inviteCallLink).searchParams.get('secret');

    return (
        <div className={cnInviteCallLink(null, [className])}>
            <div className={cnInviteCallLink('Title')}>
                {i18nInviteCallLink('invite-call-link-title')}
                <Hint
                    text={i18nInviteCallLink('invite-call-link-hint')}
                    className={cnInviteCallLink('InviteLinkHint')}
                    iconTone="black"
                />
            </div>
            <div className={cnInviteCallLink('Content')}>
                <Link
                    view="default"
                    title={inviteCallLink}
                >
                    {secret}
                </Link>
                <CopyText
                    textToCopy={inviteCallLink}
                    onCopyText={i18nInviteCallLink('invite-call-link-copy-success')}
                >
                    <Button
                        view="default"
                        size="s"
                        title={i18nInviteCallLink('copy-invite-call-link-title')}
                        icon={iconCopy}
                        className={cnInviteCallLink('InviteLinkButton', { type: 'copy' })}
                    />
                </CopyText>
                <Button
                    view="default"
                    size="s"
                    onClick={onReGenerateInviteCallLinkClick}
                    title={i18nInviteCallLink('regenerate-invite-call-link')}
                    icon={iconUpdate}
                    className={cnInviteCallLink('InviteLinkButton', { type: 'regenerate' })}
                />
            </div>
        </div>
    );
};
