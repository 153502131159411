import React, { FC } from 'react';

import { cnContainer, cnRow, cnCol } from 'shared/ui/components/Grid';

import { cnKhPage } from '../KhPage.cn';

import './_theme/KhPage-Content_theme_gray.css';
import './KhPage-Content.css';

export const KhPageContent: FC = ({ children }) => {
    return (
        <div className={cnKhPage('Content')}>
            <div className={cnContainer({ size: 'fluid' })}>
                <div className={cnRow()}>
                    <div className={cnCol()}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};
