import React, { FC, ReactElement, useCallback } from 'react';
import { withBemMod, composeU } from '@bem-react/core';

import { Button } from '@yandex-lego/components/Button/desktop/bundle';
import { Icon as IconBase } from '@yandex-lego/components/Icon/desktop';
import {
    withTypeMicrophone as withIconTypeMicrophone,
    withTypeCamera as withIconTypeCamera,
    withTypeNoCamera as withIconTypeNoCamera,
    withTypeNoMicrophone as withIconTypeNoMicrophone,
    withToneWhite as withIconToneWhite,
} from 'components/Icon';

import { cnParticipant } from '../Participant@desktop';
import { ParticipantProps } from '../Participant.interface';
import { WithControls } from './Participant_controls.interface';
import './Participant_controls.css';

const Icon = composeU(
    withIconTypeMicrophone,
    withIconTypeNoMicrophone,
    withIconTypeCamera,
    withIconTypeNoCamera,
    withIconToneWhite,
)(IconBase);

export const withControls = withBemMod<WithControls, ParticipantProps>(
    cnParticipant(), { controls: true }, (Participant): FC<WithControls & ParticipantProps> => (
        (props: WithControls & ParticipantProps): ReactElement => {
            const {
                microphoneActive,
                cameraActive,
                onToggleCamera,
                onToggleMicrophone,
            } = props;

            let cameraIconTone: 'white';
            let microphoneIconTone: 'white';
            let existsCamera = 'noCamera';
            let existsMicrophone = 'noMicrophone';

            if (cameraActive) {
                cameraIconTone = 'white';
                existsCamera = 'camera';
            }

            if (microphoneActive) {
                microphoneIconTone = 'white';
                existsMicrophone = 'microphone';
            }

            const iconCamera = useCallback((iconClassName): ReactElement => (
                <Icon
                    type={existsCamera as undefined}
                    tone={cameraIconTone}
                    className={iconClassName}
                />
            ), [cameraIconTone, existsCamera]);

            const iconMicrophone = useCallback((iconClassName): ReactElement => (
                <Icon
                    type={existsMicrophone as undefined}
                    tone={microphoneIconTone}
                    className={iconClassName}
                />
            ), [existsMicrophone, microphoneIconTone]);

            const controlButtons = (
                <div className={cnParticipant('Controls')}>
                    <Button
                        onClick={onToggleCamera}
                        view="pseudo"
                        size="s"
                        // TODO http://st/VCONF-320
                        // disabled={camera || acting}
                        disabled
                        checked={cameraActive}
                        icon={iconCamera}
                        className={cnParticipant('ControlButton', { type: 'camera' })}
                    />
                    <Button
                        onClick={onToggleMicrophone}
                        view="pseudo"
                        size="s"
                        // TODO http://st/VCONF-320
                        // disabled={microphone || acting}
                        disabled
                        checked={microphoneActive}
                        icon={iconMicrophone}
                        className={cnParticipant('ControlButton', { type: 'microphone' })}
                    />
                </div>
            );

            return (
                <Participant
                    {...props}
                    controlButtons={controlButtons}
                />
            );
        }
    ),
);

export default withControls;
