import React, { ReactElement, FC } from 'react';
import { withBemMod } from '@bem-react/core';
import { Spin } from '@yandex-lego/components/Spin/bundle';

import { cnStreamScreen } from '../../StreamScreen.cn';

import { StreamScreenRateInformerProps } from '../StreamScreen-RateInformer.interface';

import { WithLoadingProps } from './StreamScreen-RateInformer_loading.interface';

export const withLoading = withBemMod<WithLoadingProps, StreamScreenRateInformerProps>(
    cnStreamScreen('RateInformer'),
    { loading: true },
    (StreamScreenRateInformer): FC<WithLoadingProps & StreamScreenRateInformerProps> => (
        (props: WithLoadingProps & StreamScreenRateInformerProps): ReactElement => {
            const trailing = (
                <Spin
                    className={cnStreamScreen('Spin')}
                    size="l"
                    view="default"
                    progress
                />
            );

            return (
                <StreamScreenRateInformer
                    {...props}
                    trailing={trailing}
                />
            );
        }
    ),
);
