import { withBemMod } from '@bem-react/core';
import i18n from '@yandex-int/i18n';

import { Button } from '@yandex-lego/components/Button/desktop/bundle';
import React, { FC, ReactElement } from 'react';

import { ConfirmationModal } from 'components/ConfirmationModal';

import { TemplateFormScreenProps } from '../TemplateFormScreen.interface';
import { cnTemplateFormScreen } from '../TemplateFormScreen';
import * as keyset from '../TemplateFormScreen.i18n';

export interface WithTypeEditProps {
  type?: 'edit';
  isDeleteConfirmationModalVisible?: boolean;
  onEditButtonClick?: FunctionVoid;
  onDeleteButtonClick?: FunctionVoid;
  onDeleteCancelClick?: FunctionVoid;
  onDeleteAllClick?: FunctionVoid;
}

const i18nTemplateFormScreen = i18n(keyset);

export const withTypeEdit = withBemMod<WithTypeEditProps, TemplateFormScreenProps>(
    cnTemplateFormScreen(),
    { type: 'edit' },
    (TemplateFormScreen): FC<TemplateFormScreenProps & WithTypeEditProps> => (
        (props: TemplateFormScreenProps & WithTypeEditProps): ReactElement => {
            const {
                onEditButtonClick,
                onDeleteButtonClick,
                onDeleteCancelClick,
                onDeleteAllClick,
                isTemplateActionRequesting,
                isDeleteConfirmationModalVisible,
                templateName,
                templateParticipants,
                templateOwners,
                templateEventId,
            } = props;

            const isButtonDisabled =
              isTemplateActionRequesting ||
              templateName === '' ||
              (templateParticipants.length === 0 && !templateEventId) ||
              templateOwners.length === 0;

            const deleteTemplate = (
                <>
                    <Button
                        view="clear"
                        size="m"
                        onClick={onDeleteButtonClick}
                        className={cnTemplateFormScreen('ControlButton', { type: 'delete' })}
                    >
                        {i18nTemplateFormScreen('delete-template')}
                    </Button>
                    <ConfirmationModal
                        title={i18nTemplateFormScreen('template-deleting')}
                        visible={isDeleteConfirmationModalVisible}
                        confirmButton={(
                            <Button
                                view="action"
                                size="m"
                                onClick={onDeleteAllClick}
                            >
                                {i18nTemplateFormScreen('delete-for-all')}
                            </Button>
                        )}
                        cancelButton={(
                            <Button
                                view="default"
                                size="m"
                                onClick={onDeleteCancelClick}
                            >
                                {i18nTemplateFormScreen('cancel')}
                            </Button>
                        )}
                    />
                </>
            );

            const actionButton = (
                <Button
                    view="action"
                    size="m"
                    onClick={onEditButtonClick}
                    disabled={isButtonDisabled}
                    className={cnTemplateFormScreen('ControlButton', { type: 'action' })}
                >
                    {i18nTemplateFormScreen('button-update')}
                </Button>
            );

            return (
                <TemplateFormScreen
                    {...props}
                    deleteTemplate={deleteTemplate}
                    actionButton={actionButton}
                    title={i18nTemplateFormScreen('title-edit')}
                />
            );
        }
    ),
);

export default withTypeEdit;
