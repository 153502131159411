import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import { cnStreamPoster } from '../StreamPoster';

import './StreamPoster_size_s.css';

export interface WithSizeSProps {
    size?: 's';
}

export const withSizeS = withBemMod<WithSizeSProps>(
    cnStreamPoster(),
    { size: 's' },
    (StreamPoster): FC => ((props): ReactElement => (<StreamPoster {...props} />)),
);

export default withSizeS;
