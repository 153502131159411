export const en = {
    'player-tumbler': 'Player Yandex.Ephir',
    'chat-button': 'Go to chat',
    'all-streams-link': 'All streams',
    'no-data-text': 'No data',
    'show-calendar-awesome': 'Go to calendar event',
    'stream-title': 'Stream',
    'stream-error': 'Error',
    'viewers-counter-title': 'Number of viewers',
    player_type_protected: 'Protected stream',
    player_type_audio: 'Audio only',
    'rate-informer-title': 'Evaluate the quality of the stream',
    'rate-informer-textarea-placeholder': 'Please write your review',
    'rate-informer-success-title': 'Thank you for your feedback!',
    'rate-informer-error-title': 'An error occurred while sending',
    'rate-informer-action-clear': 'Later',
    'rate-informer-action-submit': 'Send',
    'stream-language-ru': 'Russian',
    'stream-language-en': 'English',
    'stream-language-title': 'Stream language',
};
