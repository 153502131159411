import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';
import './Icon_type_edit.css';

export interface WithTypeEditProps {
  type?: 'edit';
}

export const withTypeEdit = withBemMod<WithTypeEditProps>(
    'Icon',
    { type: 'edit' },
    (Icon): FC => ((props): ReactElement => (<Icon {...props} />)),
);
