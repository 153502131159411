import React, { FC, ReactElement } from 'react';
import { Popup } from '@yandex-lego/components/Popup/desktop/bundle';

import { Settings } from 'components/Settings';

import { HeaderSettingsProps } from './Header-Settings.interface';
import { cnHeader } from '../Header@desktop';
import './Header-Settings.css';

export const HeaderSettings: FC<HeaderSettingsProps> = (props: HeaderSettingsProps): ReactElement => {
    const {
        isVisible,
        targetRef,
        onClose,
    } = props;

    return (
        <Popup
            visible={isVisible}
            view="default"
            keepMounted={false}
            target="anchor"
            anchor={targetRef}
            direction="bottom-end"
            secondaryOffset={10}
            onClose={onClose}
            className={cnHeader('Settings')}
        >
            <Settings
                onClose={onClose}
            />
        </Popup>
    );
};
