import { compose } from '@bem-react/core';
import i18n from '@yandex-int/i18n';
import { useDispatch, useSelector } from 'react-redux';
import React, { FC, useEffect, ReactElement, useMemo } from 'react';
import { useRouteMatch } from 'react-router';

import { useTitle } from 'hooks';

import {
    selectStreamDetails,
    requestStreamDetails,
    flushStreamDetails,
} from 'ducks/stream';
import {
    hideHeader,
    showHeader,
} from 'ducks/header';
import {
    hideFooter,
    showFooter,
} from 'ducks/footer';
import {
    hideYandexBug,
    showYandexBug,
} from 'ducks/yandexBug';
import {
    hideYandexLamp,
    showYandexLamp,
} from 'ducks/yandexLamp';
import { selectCurrentUser } from 'ducks/user';

import { ChatScreenContainerMatchParams } from './ChatScreen.interface';
import { ChatScreen as ChatScreenBase } from './ChatScreen';
import { withLoading } from './_loading/ChatScreen_loading';
import { withEmpty } from './_empty/ChatScreen_empty';
import * as keyset from './ChatScreen.i18n';

const i18nChatScreen = i18n(keyset);

const ChatScreenView = compose(
    withEmpty,
    withLoading,
)(ChatScreenBase);

const tvView = new URLSearchParams(window.location.search).get('tv-view');

export const ChatScreen: FC = (): ReactElement => {
    const dispatch = useDispatch();
    const match = useRouteMatch<ChatScreenContainerMatchParams>();

    const callId = match.params.id;
    const [isFirstMount, setIsFirstMount] = React.useState<boolean>(true);
    const { environment } = useSelector(selectCurrentUser);
    const {
        chatInviteHash,
        isRequesting,
        name,
        templateId,
    } = useSelector(selectStreamDetails);

    const title = useMemo(() => {
        const parts = [i18nChatScreen('chat-screen-title')];

        if (name) {
            parts.unshift(`${name}`);
        }

        return parts;
    }, [name]);

    useTitle(title);

    useEffect((): void => {
        setIsFirstMount(false);
    }, []);

    useEffect((): FunctionVoid => {
        dispatch(requestStreamDetails(callId));
        dispatch(hideHeader());
        dispatch(hideYandexBug());
        dispatch(hideYandexLamp());
        dispatch(hideFooter());

        return (): void => {
            dispatch(flushStreamDetails());
            dispatch(showHeader());
            dispatch(showYandexBug());
            dispatch(showYandexLamp());
            dispatch(showFooter());
        };
    }, [dispatch]);

    return (
        <ChatScreenView
            loading={isFirstMount || isRequesting}
            empty={!chatInviteHash}
            callId={callId}
            templateId={templateId}
            chatInviteHash={chatInviteHash}
            env={environment}
            isTvView={tvView === '1'}
      />
    );
};
