import StaffCardBase from 'staff-card';
import React, { ReactElement } from 'react';

interface StaffCardPropsI {
  login: string
  children: ReactElement | ReactElement[]
}

interface StaffCardInstanceI {
  login?: string
  userData?: Record<string, string> | null
}

class StaffCard extends React.Component<StaffCardPropsI> {
  public card: StaffCardInstanceI = {}

  private btnRef = React.createRef();

  public componentDidMount(): void {
      const { login } = this.props;

      this.card = new StaffCardBase(this.btnRef.current, login);
  }

  public componentWillReceiveProps({ login }: StaffCardPropsI): void {
      this.card.userData = null;
      this.card.login = login;
  }

  public componentWillUnmount(): void {
      this.card = {};
  }

  public render(): ReactElement {
      const { props } = this;

      return React.cloneElement(React.Children.only(props.children), { ref: this.btnRef });
  }
}

export default StaffCard;
