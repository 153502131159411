import { cn } from '@bem-react/classname';
import { useComponentRegistry } from '@bem-react/di';
import React, { FC, ReactElement } from 'react';

import { CreateCallScreenProps } from './CreateCallScreen.interface';
import './CreateCallScreen.css';

export const cnCreateCallScreen = cn('CreateCallScreen');
export const registryId = cnCreateCallScreen();

export const CreateCallScreen: FC<CreateCallScreenProps> = (props: CreateCallScreenProps): ReactElement => {
    const {
        replacer,
        data,
        calendarEventMeta,
        onSetNameValue,
        className,
    } = props;

    const {
        Content,
    } = useComponentRegistry(registryId);

    return (
        <div className={cnCreateCallScreen(null, [className])}>
            {
                replacer || (
                    <div className={cnCreateCallScreen('Container')}>
                        <Content
                            calendarEventMeta={calendarEventMeta}
                            onSetNameValue={onSetNameValue}
                            data={data}
                        />
                    </div>
                )
            }
        </div>
    );
};

export default CreateCallScreen;
