import { composeU, withBemMod } from '@bem-react/core';
import { Button } from '@yandex-lego/components/Button/desktop/bundle';
import { Icon as IconBase } from '@yandex-lego/components/Icon/desktop';
import { Textinput } from '@yandex-lego/components/Textinput/desktop/bundle';
import React, { ChangeEvent, FC, ReactElement, useCallback, useState } from 'react';

import {
    withTypeEdit as withIconTypeEdit,
    withTypeCheck as withIconTypeCheck,
    withTypeBan as withIconTypeBan,
} from 'components/Icon';

import { WithEditProps } from './CallFormTitle_edit.interface';
import { CallFormTitleProps } from '../CallFormTitle.interface';
import {
    cnCallFormTitle,
    i18nCallFormTitle,
} from '../CallFormTitle';
import './CallFormTitle_edit.css';

const Icon = composeU(
    withIconTypeEdit,
    withIconTypeCheck,
    withIconTypeBan,
)(IconBase);

const iconEdit = (className: string) => (<Icon type="edit" className={className} />);
const iconCheck = (className: string) => (<Icon type="check" className={className} />);
const iconBan = (className: string) => (<Icon type="ban" className={className} />);

export const withEdit = withBemMod<WithEditProps, CallFormTitleProps>(
    cnCallFormTitle(),
    { edit: true },
    (CallFormTitle): FC<WithEditProps & CallFormTitleProps> => (
        (props: WithEditProps & CallFormTitleProps): ReactElement => {
            const {
                value,
                setValue,
            } = props;

            let {
                contentReplacer,
            } = props;

            let addonAfter;

            const [isEdit, setIsEdit] = useState<boolean>(false);
            const [savedValue, setSavedValue] = useState<string>(null);

            const onEditButtonClick = useCallback(() => {
                setIsEdit(true);
                setSavedValue(value);
            }, [value]);

            const onAcceptClick = useCallback(() => {
                setIsEdit(false);
                setValue(value.trim());
                setSavedValue(null);
            }, [setValue, value]);

            const onCancelClick = useCallback(() => {
                setIsEdit(false);
                setValue(savedValue);
                setSavedValue(null);
            }, [savedValue, setValue]);

            const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
                setValue(event.target.value);
            }, [setValue]);

            if (isEdit) {
                contentReplacer = (
                    <Textinput
                        view="default"
                        size="m"
                        pin="round-round"
                        onChange={onChange}
                        value={value || ''}
                        placeholder={i18nCallFormTitle('edit-field-placeholder')}
                        hasClear
                    />
                );

                addonAfter = (
                    <>
                        <Button
                            view="pseudo"
                            size="m"
                            onClick={onAcceptClick}
                            icon={iconCheck}
                            title={i18nCallFormTitle('edit-field-save')}
                            className={cnCallFormTitle('ActionButton', { type: 'accept' })}
                        />
                        <Button
                            view="pseudo"
                            size="m"
                            onClick={onCancelClick}
                            icon={iconBan}
                            title={i18nCallFormTitle('edit-field-cancel')}
                            className={cnCallFormTitle('ActionButton', { type: 'cancel' })}
                        />
                    </>
                );
            }

            if (!isEdit) {
                addonAfter = (
                    <Button
                        view="clear"
                        size="s"
                        onClick={onEditButtonClick}
                        icon={iconEdit}
                        title={i18nCallFormTitle('edit-button-title')}
                        className={cnCallFormTitle('EditButton')}
                    />
                );
            }

            return (
                <CallFormTitle
                    {...props}
                    contentReplacer={contentReplacer}
                    addonAfter={addonAfter}
                />
            );
        }
    ),
);
