import apiRxjs, { ApiErrorI } from 'util/api.rxjs';
import { Observable } from 'rxjs';
import { ActiveCallI } from 'components/ActiveCalls/ActiveCalls.interface';

import {
    DeleteRecordPayload,
    DeleteRecordSuccessPayload,
} from './callsHistory';

export const getHistoryCalls = (query: string): Observable<ActiveCallI[] | ApiErrorI> =>
    apiRxjs.get(`/frontapi/calls?history=true&${query}`);

export const deleteRecord = (payload: DeleteRecordPayload): Observable<DeleteRecordSuccessPayload | ApiErrorI> =>
    apiRxjs.delete(`/frontapi/calls/${payload.id}/record/`);

export interface HistoryCallsApi {
    getHistoryCalls: typeof getHistoryCalls;
    deleteRecord: typeof deleteRecord;
}

export default {
    getHistoryCalls,
    deleteRecord,
};
