import { compose, composeU, withBemMod } from '@bem-react/core';
import { useComponentRegistry } from '@bem-react/di';
import i18n from '@yandex-int/i18n';

import { Icon as IconBase } from '@yandex-lego/components/Icon';
import React, { FC, MouseEventHandler, ReactElement } from 'react';

import CopyToClipboard from 'react-copy-to-clipboard';

import { registryId } from 'components/GlobalContextRegistry/GlobalContextRegistry.constants';
import { Duration } from 'components/Duration';

import {
    withSizeM as withIconSizeM,
    withTypeRecord as withIconTypeRecord,
    withTypeCamera as withIconTypeCamera,
} from 'components/Icon';

import { TableCellProps } from '../TableCell.interface';
import { cnTableCell } from '../TableCell';
import * as keyset from '../TableCell.i18n';
import './TableCell_type_activeCallParameters.css';
import { INotificationMessageType } from '../../Notifications/Notifications.interface';

export interface WithTypeActiveCallParametersProps {
  type?: 'activeCallParameters';
  id?: string;
  name?: string;
  templateId?: number;
  startTime?: string;
  authorLogin?: string;
  stream?: boolean;
  record?: boolean;
  onCopyLink?: (payload: INotificationMessageType) => void;
  onActiveCallParametersClick?: MouseEventHandler;
}

const i18nTableCell = i18n(keyset);

const Icon = compose(
    withIconSizeM,
    composeU(
        withIconTypeRecord,
        withIconTypeCamera,
    ),
)(IconBase);

export const withTypeActiveCallParameters = withBemMod<WithTypeActiveCallParametersProps, TableCellProps>(
    cnTableCell(),
    { type: 'activeCallParameters' },
    (TableCell): FC<WithTypeActiveCallParametersProps & TableCellProps> => (
        (props: WithTypeActiveCallParametersProps & TableCellProps): ReactElement => {
            const {
                id,
                name,
                templateId,
                authorLogin,
                startTime,
                stream,
                record,
                onCopyLink,
                onActiveCallParametersClick,
            } = props;

            const { StaffName } = useComponentRegistry(registryId);

            const streamPath = templateId ? `/template/${templateId}/stream` : `/streams/${id}`;
            const streamUrl = new URL(`${window.location.origin}${streamPath}`);

            return (
                <TableCell {...props}>
                    <div className={cnTableCell('CallName')} title={name}>{name}</div>
                    <div
                        className={cnTableCell('ActiveCallParameters')}
                        onClick={onActiveCallParametersClick}
                    >
                        <Duration
                            startingPointISOString={startTime as string}
                            className={cnTableCell('ActiveCallParameter')}
                        />
                        <StaffName
                            id={authorLogin}
                            name={authorLogin}
                            className={cnTableCell('ActiveCallParameter')}
                        />
                        {
                          stream && (
                              <div className={cnTableCell('ActiveCallParameter', { type: 'camera' })}>
                                  <CopyToClipboard
                                      onCopy={onCopyLink}
                                      text={`${streamUrl}`}
                                      >
                                      <Icon size="m" type="camera" title={i18nTableCell('table-active-calls-copy-stream-link')} />
                                  </CopyToClipboard>
                              </div>
                          )
                        }
                        {
                          record && (
                              <Icon size="m" type="record" title={i18nTableCell('call-is-recorded')} className={cnTableCell('ActiveCallParameter', { type: 'recorded' })} />
                          )
                        }
                    </div>
                </TableCell>
            );
        }
    ),
);

export default withTypeActiveCallParameters;
