import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import { cnStreamPoster } from '../StreamPoster';

import './StreamPoster_size_m.css';

export interface WithSizeMProps {
    size?: 'm';
}

export const withSizeM = withBemMod<WithSizeMProps>(
    cnStreamPoster(),
    { size: 'm' },
    (StreamPoster): FC => ((props): ReactElement => (<StreamPoster {...props} />)),
);

export default withSizeM;
