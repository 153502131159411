import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import { cnDuration } from '../Duration';
import { DurationProps } from '../Duration.interface';

import './Duration_tone_black.css';

export interface WithToneBlackProps extends DurationProps {
  tone?: 'black'
}

export const withToneBlack = withBemMod<WithToneBlackProps>(
    cnDuration(),
    { tone: 'black' },
    (Duration): FC<WithToneBlackProps> => (props): ReactElement => (<Duration {...props} />),
);

export default withToneBlack;
