import React, { FC, useMemo } from 'react';
import dayjs from 'dayjs';

import { useTitle } from 'hooks/useTitle';

import { MessageBar } from 'shared/ui/components/MessageBar';

import { BackLink } from 'components/BackLink';

import { GoToCalendar } from './GoToCalendar';
import { PermanentLinkScreenProps, PERMANENT_LINK_TYPE } from './PermanentLinkScreen.interface';
import { cnPermanentLinkScreen } from './PermanentLinkScreen.cn';
import { i18nPermanentLinkScreen } from './PermanentLinkScreen.i18n';
import './PermanentLinkScreen.css';

export const PermanentLinkScreen: FC<PermanentLinkScreenProps> = props => {
    const {
        permanentLinkType,
        event,
        replacer,
        className,
    } = props;

    const [infoMessageTextKey, titleKey] = useMemo(() => {
        switch (permanentLinkType) {
            case PERMANENT_LINK_TYPE.STREAM:
                return ['next-event', 'title-stream'];
            case PERMANENT_LINK_TYPE.CHAT:
                return ['next-chat', 'title-chat'];
        }
    }, [permanentLinkType]);

    useTitle([i18nPermanentLinkScreen(titleKey)]);

    return (
        <div className={cnPermanentLinkScreen(null, [className])}>
            {
                replacer || (
                    <div className={cnPermanentLinkScreen('Content')}>
                        <BackLink
                            title={i18nPermanentLinkScreen('all-streams-link')}
                            url="/streams/"
                            className={cnPermanentLinkScreen('BackLink')}
                        />
                        <h1 className={cnPermanentLinkScreen('Title')}>
                            <GoToCalendar
                                eventName={event.name}
                                eventId={event.id}
                            />
                        </h1>
                        <MessageBar
                            type="info"
                            size="m"
                            className={cnPermanentLinkScreen('AdditionalInformation')}
                        >
                            {i18nPermanentLinkScreen(infoMessageTextKey)}
                            &nbsp;
                            {dayjs(event.startTs).format(`DD MMMM YYYY [${i18nPermanentLinkScreen('at')}] HH:mm`)}
                        </MessageBar>
                    </div>
                )
            }
        </div>
    );
};
