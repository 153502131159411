import React, { FC } from 'react';
import { cnTheme } from '@yandex-lego/components/Theme';
import { theme } from '@yandex-int/hr-components/Theme/presets/light';

import { KhFooter } from 'khural-components/KhFooter/KhFooter';
import { KhHeader } from 'khural-components/KhHeader/KhHeader';

import { PageVisibility } from 'components/PageVisibility';
import { TitleManager } from 'components/TitleManager';
import YandexBug from 'components/YandexBug';
import Notifications from 'components/Notifications';

import { cnKhPage } from './KhPage.cn';
import { KhPageProps } from './KhPage.interface';

import { KhPageContent } from './Content/KhPage-Content';

import './KhPage.css';

/**
 * Каркас страниц хурала
 *
 * ☝️ Страница должна лежать в `body` с `height: 100%`.
 * Причем если страница не прямой потомок `body`,
 * то у всех узлов между `body` и страницей тоже должна быть `height: 100%`
 */
export const KhPage: FC<KhPageProps> = ({
    replacer,
    children,
    contentTheme = 'default',
    className,
}) => {
    return (
        <div className={cnKhPage({ contentTheme }, [className, cnTheme(theme)])}>
            <PageVisibility />
            <TitleManager />
            {
                replacer || (
                    <>
                        <KhHeader />
                        <KhPageContent>
                            {children}
                        </KhPageContent>
                        <KhFooter />
                        <Notifications />
                        <YandexBug />
                    </>
                )
            }
        </div>
    );
};
