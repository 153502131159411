import { cn } from '@bem-react/classname';
import React, { FC, ReactElement } from 'react';

import { Participants } from './Participants/Template-Participants';
import { TemplateProps } from './Template.interface';
import './Template.css';

export const cnTemplate = cn('Template');

export const Template: FC<TemplateProps> = (props: TemplateProps): ReactElement => {
    const {
        title,
        participants,
        onTemplateClick,
        onTemplateKeyDown,
        onParticipantsClick,
        active,
        disabled,
        className,
        id,
    } = props;

    return (
        <div
            className={cnTemplate({ active, disabled }, [className])}
            onClick={onTemplateClick}
            onKeyDown={onTemplateKeyDown}
            role="button"
            tabIndex={0}
            data-template-id={id}
        >
            <div className={cnTemplate('Title')}>
                {title}
            </div>
            <Participants
                participants={participants}
                onParticipantsClick={onParticipantsClick}
            />
        </div>
    );
};

export default Template;
