import { compose, composeU } from '@bem-react/core';
import { Registry, withRegistry } from '@bem-react/di';
import {
    withTypeError as iconWithTypeError,
    withTypeInfo as iconWithTypeInfo,
    withTypeWarning as iconWithTypeWarning,
} from '@yandex-int/tools-components/Icon/desktop';
import {
    cnMessageBar,
    MessageBar as MessageBarDesktop,
    withSizeM,
    withSizeS,
    withTypeError,
    withTypeInfo,
    withTypeWarning,
} from '@yandex-int/tools-components/MessageBar/desktop';
import {
    Icon as IconDesktop,
    withGlyphTypeCross,
} from '@yandex-lego/components/Icon/desktop';

import { withFullWidth } from './_fullWidth/MessageBar_fullWidth';

const messageBarRegistry = new Registry({ id: cnMessageBar() });

const Icon = compose(
    withGlyphTypeCross,
    iconWithTypeInfo,
    iconWithTypeWarning,
    iconWithTypeError,
)(IconDesktop);

messageBarRegistry
    .set('Icon', Icon);

export const MessageBar = compose(
    composeU(
        withSizeM,
        withSizeS,
    ),
    composeU(
        withTypeInfo,
        withTypeWarning,
        withTypeError,
    ),
    withFullWidth,
    withRegistry(messageBarRegistry),
)(MessageBarDesktop);
