import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import { cnParticipantAvatar } from '../ParticipantAvatar';
import { ParticipantAvatarProps } from '../ParticipantAvatar.interface';
import { WithTypeRoomProps } from './ParticipantAvatar_type_room.interface';

import roomAvavtar from './assets/room.svg';

export const withTypeRoom = withBemMod<WithTypeRoomProps, ParticipantAvatarProps>(
    cnParticipantAvatar(),
    { type: 'room' },
    (ParticipantAvatar): FC<ParticipantAvatarProps & WithTypeRoomProps> => (
        (props: ParticipantAvatarProps & WithTypeRoomProps): ReactElement => {
            const image = (
                <img
                    className={cnParticipantAvatar('Image')}
                    src={roomAvavtar}
                    alt="avatar"
        />
            );

            return (
                <ParticipantAvatar
                    {...props}
                    replacer={image}
        />
            );
        }
    ),
);

export default withTypeRoom;
