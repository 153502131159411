import { Registry, withRegistry } from '@bem-react/di';

import { CreateCallScreenContentMobile } from './Content/CreateCallScreen-Content.container@mobile';

import {
    CreateCallScreen as CreateCallScreenBase,
    registryId,
} from './CreateCallScreen';

const registry = new Registry({
    id: registryId,
});

registry.set('Content', CreateCallScreenContentMobile);

export const CreateCallScreenMobile = withRegistry(registry)(CreateCallScreenBase);

export default CreateCallScreenMobile;
