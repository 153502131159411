import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';
import './Icon_type_yandexCalendar.css';

export interface WithTypeYandexCalendarProps {
  type?: 'yandexCalendar';
}

export const withTypeYandexCalendar = withBemMod<WithTypeYandexCalendarProps>(
    'Icon',
    { type: 'yandexCalendar' },
    (Icon): FC => ((props): ReactElement => (<Icon {...props} />)),
);

export default withTypeYandexCalendar;
