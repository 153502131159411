import { compose } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import React, { FC, ReactElement } from 'react';

import Unit from 'components/Unit';
import {
    Table as TableBase,
    withTypeActiveCalls as withTableTypeActiveCalls,
    withLoading as withTableLoading,
} from 'components/Table';

import { ActiveCallsProps } from './ActiveCalls.interface';

const Table = compose(
    withTableTypeActiveCalls,
    withTableLoading,
)(TableBase);

const cnActiveCalls = cn('ActiveCalls');

export const ActiveCalls: FC<ActiveCallsProps> = (props: ActiveCallsProps): ReactElement => {
    const {
        className,
        activeCalls,
        onNavigateToActiveCallScreenClick,
        checkedAdminCheckbox,
        isActiveCallsRequesting,
        isAdmin,
        onCopyLink,
        onRowClick,
        onActiveCallParametersClick,
        onBubbleParticipantsClick,
        onTableCellActiveCallActionsClick,
        onShowAllChanged,
    } = props;

    return (
        <Unit className={cnActiveCalls(null, [className])}>
            <Table
                type="activeCalls"
                calls={activeCalls}
                onNavigateToActiveCallScreenClick={onNavigateToActiveCallScreenClick}
                checkedAdminCheckbox={checkedAdminCheckbox}
                loading
                isRequesting={isActiveCallsRequesting}
                isTableEmpty={false}
                isAdmin={isAdmin}
                onCopyLink={onCopyLink}
                onRowClick={onRowClick}
                onActiveCallParametersClick={onActiveCallParametersClick}
                onBubbleParticipantsClick={onBubbleParticipantsClick}
                onTableCellActiveCallActionsClick={onTableCellActiveCallActionsClick}
                onShowAllChanged={onShowAllChanged}
            />
        </Unit>
    );
};

export default ActiveCalls;
