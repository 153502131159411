export const ru = {
    'error': 'Проблемка',
    'error.smth-went-wrong.title': 'Ой, что-то пошло не так...',
    'error.smth-went-wrong.description': 'Попробуйте создать обращение через жука',
    'error.not-found.title': 'Ой, трансляции нет',
    'error.not-found.description': 'Мы не смогли найти предстоящую трансляцию, возможно вы ошиблись в адресе страницы',
    'next-stream': 'Трансляция начнётся',
    'next-chat': 'Чат появится',
    at: 'в',
    'title-chat': 'Здесь будет чат',
    'title-stream': 'Здесь будет Хурал',
    'add-to-calendar': 'Добавить себе в календарь',
};
