export const en = {
    'duration-label': 'Duration',
    'end-conference': 'End call for all',
    'end': 'End',
    'cancel': 'Cancel',
    'suggest-placeholder': 'Enter name, room or external number',
    'active-calls-show-calendar-awesome': 'Go to calendar event',
    'active-calls-copy-call-link': 'Copy call link',
    'title-copy-uri': 'Copy call`s URI',
    'active-call-title': 'Active call',
    'call-is-absent-title': 'Call is absent',
    'to-main-page': 'To main',
    'call-is-not-available': 'Active call is not available',
    'create-call': 'Create call',
    'streaming': 'Streaming',
    'no-streaming': 'No streaming',
    'recording': 'Recording',
    'no-recording': 'No recording',
    'share-stream': 'Share stream',
    'disconnect-list-title': 'Disconnected',
    'recall-all-button': 'Recall to all',
    'participants-title': 'Participants',
    'browser-invite-link-title': 'Join via browser',
    'browser-invite-link-copy-title': 'Copy the link to join via the browser',
    'browser-invite-link-copy-text': 'The link to join via the browser has been copied',
};
