import { ApiErrorI } from 'util/api.rxjs';
import { createSelector } from 'reselect';
import { Observable, of } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Action, createAction, handleActions } from 'redux-actions';

import { ActiveCallI } from 'components/ActiveCalls/ActiveCalls.interface';

import { INotificationMessageType } from 'components/Notifications/Notifications.interface';
import { notyError } from 'components/Notifications/Notifications.actions';
import {
    InjectedDependencies,
    StoreI,
} from '../store';

export interface RequestActiveCallsPayload {
  showAll: boolean;
}
export type ReceiveActiveCallsSuccessPayload = ActiveCallI[];
export type SetShowAllCallsPayload = boolean;

const SET_SHOW_ALL_CALLS = 'vconf/activeCalls/SET_SHOW_ALL_CALLS';
const REQUEST_ACTIVE_CALLS = 'vconf/activeCalls/REQUEST_ACTIVE_CALLS';
const RECEIVE_ACTIVE_CALLS_SUCCESS = 'vconf/activeCalls/RECEIVE_ACTIVE_CALLS_SUCCESS';
const RECEIVE_ACTIVE_CALLS_ERROR = 'vconf/activeCalls/RECEIVE_ACTIVE_CALLS_ERROR';
const CLEAR_ACTIVE_CALLS = 'vconf/activeCalls/CLEAR_ACTIVE_CALLS';

export const setShowAllCalls = createAction<SetShowAllCallsPayload>(SET_SHOW_ALL_CALLS);
export const requestActiveCalls = createAction<RequestActiveCallsPayload>(REQUEST_ACTIVE_CALLS);
export const receiveActiveCallsSuccess = createAction<ReceiveActiveCallsSuccessPayload>(RECEIVE_ACTIVE_CALLS_SUCCESS);
export const receiveActiveCallsError = createAction<null>(RECEIVE_ACTIVE_CALLS_ERROR);
export const clearActiveCalls = createAction(CLEAR_ACTIVE_CALLS);

export interface ActiveCallsState {
  isShowAllCalls: boolean;
  activeCalls: ActiveCallI[];
  isActiveCallsRequesting: boolean;
}

export const initialState: ActiveCallsState = {
    isShowAllCalls: false,
    activeCalls: [],
    isActiveCallsRequesting: false,
};

export const reducer = handleActions<ActiveCallsState, ReceiveActiveCallsSuccessPayload | SetShowAllCallsPayload>(
    {
        [REQUEST_ACTIVE_CALLS]: (state): ActiveCallsState => ({
            ...state,
            isActiveCallsRequesting: true,
        }),

        [RECEIVE_ACTIVE_CALLS_SUCCESS]: (state, action: Action<ReceiveActiveCallsSuccessPayload>): ActiveCallsState => {
            const { payload } = action;

            return {
                ...state,
                isActiveCallsRequesting: false,
                activeCalls: payload,
            };
        },

        [RECEIVE_ACTIVE_CALLS_ERROR]: (state): ActiveCallsState => ({
            ...state,
            isActiveCallsRequesting: false,
        }),

        [SET_SHOW_ALL_CALLS]: (state, action: Action<SetShowAllCallsPayload>): ActiveCallsState => {
            const { payload } = action;

            return {
                ...state,
                isShowAllCalls: payload,
            };
        },

        [CLEAR_ACTIVE_CALLS]: (state): ActiveCallsState => ({
            ...state,
            activeCalls: [],
        }),
    },
    initialState,
);

type IActiveCallsPayload = ReceiveActiveCallsSuccessPayload | INotificationMessageType | null;

export const epic = combineEpics(
    (action$: Observable<Action<RequestActiveCallsPayload>>,
        store$: StoreI,
        { activeCallsApi: { getActiveCalls } }: InjectedDependencies): Observable<Action<IActiveCallsPayload>> =>
        action$.pipe(
            ofType(REQUEST_ACTIVE_CALLS),
            mergeMap(({ payload }): Observable<Action<IActiveCallsPayload>> =>
                getActiveCalls(payload).pipe(
                    map((response): Action<ReceiveActiveCallsSuccessPayload> => {
                        const possiblyErrorResponse = response as ApiErrorI;

                        if (possiblyErrorResponse.error) {
                            throw possiblyErrorResponse;
                        }

                        return receiveActiveCallsSuccess(response as ReceiveActiveCallsSuccessPayload);
                    }),
                    catchError((error: ApiErrorI): Observable<Action<INotificationMessageType | null>> => of(
                        notyError(error.message),
                        receiveActiveCallsError(null),
                    )),
                ),
            ),
        ),
);

const selectActiveCallsState = (store: StoreI): ActiveCallsState => store.activeCalls;

export const selectIsShowAllCalls = createSelector(
    selectActiveCallsState,
    ({ isShowAllCalls }): boolean => isShowAllCalls,
);

export const selectActiveCalls = createSelector(
    selectActiveCallsState,
    ({ activeCalls }): ActiveCallI[] => activeCalls,
);

export const selectIsActiveCallsRequesting = createSelector(
    selectActiveCallsState,
    ({ isActiveCallsRequesting }): boolean => isActiveCallsRequesting,
);
