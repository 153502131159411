import { cn } from '@bem-react/classname';
import i18n from '@yandex-int/i18n';
import React, { FC, ReactElement } from 'react';

import { FooterProps } from './Footer.interface';
import { FooterCopyright } from './Copyright';
import * as keyset from './Footer.i18n';
import './Footer.css';

export const cnFooter = cn('Footer');
const i18nFooter = i18n(keyset);

export const Footer: FC<FooterProps> = (props: FooterProps): ReactElement => {
    const {
        user,
        isFooterVisible,
        className,
    } = props;

    if (!isFooterVisible) return null;

    return (
        <div className={cnFooter(null, [className])}>
            <div className={cnFooter('Content')}>
                {
                    user && (
                        <div className={cnFooter('Nav')}>
                            <a href="https://wiki.yandex-team.ru/staff/vconf/">
                                {i18nFooter('docs')}
                            </a>
                        </div>
                    )
                }
                <FooterCopyright />
            </div>
        </div>
    );
};
