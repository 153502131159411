import React, { ChangeEvent, FC, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { compose } from '@bem-react/core';
import dayjs from 'dayjs';

import { withLoading } from './_loading';
import { withActions } from './_actions';
import { withSuccess } from './_success';
import { withError } from './_error';
import { RateInformer as RateInformerViewBase } from './KhStreamScreen-RateInformer';
import { KhStreamScreenRateInformerContainerProps, InformerState } from './KhStreamScreen-RateInformer.interface';
import { shouldHideInformer, setInformerState, clearInformerState } from './KhStreamScreen-RateInformer.utils';
import './KhStreamScreen-RateInformer.css';

const RateInformerView = compose(
    withLoading,
    withActions,
    withSuccess,
    withError,
)(RateInformerViewBase);

const FORM_ID_TEST = '10846';
const FORM_ID_PROD = '70355';

export const REMAINING_MINUTES = 15;
export const CHECK_REMAINING_TIME_INTERVAL = 60000;
export const HIDE_TIMEOUT = 3000;

export const RateInformer: FC<KhStreamScreenRateInformerContainerProps> = (props): ReactElement => {
    const {
        name,
        uri,
        stopTime,
        env,
    } = props;
    const [rating, setRating] = useState(0);
    const [message, setMessage] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    const [isRatingSending, setIsRatingSending] = useState(false);
    const [isRatingSendSuccess, setIsRatingSendSuccess] = useState(false);
    const [isRatingSendError, setIsRatingSendError] = useState(false);

    const handleRatingChange = useCallback(value => {
        setRating(value);
    }, []);

    const handleMessageChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
        setMessage(e.target.value);
    }, []);

    const handleRateInformerClose = useCallback(() => {
        setIsVisible(false);
        setInformerState(uri, InformerState.VIEWED);
    }, [uri]);

    const formId = useMemo(() => env === 'production' ? FORM_ID_PROD : FORM_ID_TEST, [env]);

    const handleActionButtonClick = useCallback(async() => {
        setIsRatingSending(true);

        const response = await fetch(`/forms/v1/surveys/${formId}/form/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                rating,
                message,
                name,
                uri,
            }),
        });

        if (response.ok) {
            setIsRatingSendSuccess(true);
        } else {
            setIsRatingSendError(true);
        }

        setTimeout(() => {
            handleRateInformerClose();
        }, HIDE_TIMEOUT);

        setIsRatingSending(false);
    }, [formId, message, name, rating, uri, handleRateInformerClose]);

    const checkRemainingTime = useCallback(() => {
        const streamStopTime = dayjs(stopTime);

        if (streamStopTime.diff(dayjs(), 'minute') < REMAINING_MINUTES) {
            setIsVisible(true);
        }
    }, [stopTime]);

    useEffect(() => {
        checkRemainingTime();

        const checkRemainingTimeInterval = setInterval(() => {
            checkRemainingTime();
        }, CHECK_REMAINING_TIME_INTERVAL);

        return () => clearInterval(checkRemainingTimeInterval);
    }, [stopTime, checkRemainingTime]);

    useEffect(() => {
        clearInformerState();
    }, []);

    if (!isVisible || shouldHideInformer(uri)) {
        return null;
    }

    const isRated = rating > 0;
    const isActionButtonsVisible = !isRatingSendSuccess && !isRatingSendError;
    const isActionButtonDisabled = isRatingSending || isRatingSendError || !isRated;
    const isClearButtonDisabled = isRatingSending;

    return (
        <RateInformerView
            rating={rating}
            message={message}
            onClose={handleRateInformerClose}
            onMessageChange={handleMessageChange}
            onRatingChange={handleRatingChange}
            actions={isActionButtonsVisible}
            isActionButtonDisabled={isActionButtonDisabled}
            isClearButtonDisabled={isClearButtonDisabled}
            loading={isRatingSending}
            onActionButtonClick={handleActionButtonClick}
            onClearButtonClick={handleRateInformerClose}
            success={isRatingSendSuccess}
            error={isRatingSendError}
        />
    );
};
