import { withBemMod, compose } from '@bem-react/core';
import i18n from '@yandex-int/i18n';
import React, { FC, ReactElement, useCallback } from 'react';

import {
    Yabble as YabbleBase,
    withFixed as withYabbleFixed,
    withTypeText as withYabbleTypeText,
    withFullClickable as withYabbleFullClickable,
} from 'components/Yabble';

import { getParticipantFromList } from '../ParticipantsList.util';
import { cnParticipantsList } from '../ParticipantsList';
import { ParticipantsListProps } from '../ParticipantsList.interface';
import { WithAddmeProps } from './ParticipantsList_addme.interface';
import * as keyset from '../ParticipantsList.i18n';

const i18nParticipantsList = i18n(keyset);

const Yabble = compose(
    withYabbleFixed,
    withYabbleTypeText,
    withYabbleFullClickable,
)(YabbleBase);

export const withAddme = withBemMod<WithAddmeProps, ParticipantsListProps>(cnParticipantsList(),
    { addme: true },
    (ParticipantsList): FC<ParticipantsListProps & WithAddmeProps> => (
        (props: ParticipantsListProps & WithAddmeProps): ReactElement => {
            const {
                onSuggestSelect,
                user,
                withAddMeButton,
                data,
            } = props;

            const onAddMeButtonClick = useCallback((): void => {
                onSuggestSelect(user);
            }, [onSuggestSelect, user]);

            let addMeButton;

            if (withAddMeButton && user && !getParticipantFromList(data, 'main', user.id)) {
                addMeButton = (
                    <Yabble
                        key={user.id}
                        participantType="person"
                        type="text"
                        login={user.id}
                        title={i18nParticipantsList('addme-button-text')}
                        actionType="plus"
                        onClick={onAddMeButtonClick}
                        fixed
                        fullClickable
                        className={cnParticipantsList('AddMeButton')}
                    />
                );
            }

            return (
                <ParticipantsList
                    {...props}
                    addMeButton={addMeButton}
                />
            );
        }
    ),
);

export default withAddme;
