import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';
import './Icon_type_update.css';

export interface WithTypeUpdateProps {
  type?: 'update';
}

export const withTypeUpdate = withBemMod<WithTypeUpdateProps>('Icon', { type: 'update' }, (Icon): FC => ((props): ReactElement => (<Icon {...props} />)));

export default withTypeUpdate;
