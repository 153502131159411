import { withBemMod } from '@bem-react/core';
import React, { ReactElement, FC } from 'react';

import { WithAutoupdateProps } from './Table_autoudpate.interface';
import { cnTable } from '../Table';

export const withAutoupdate = withBemMod<WithAutoupdateProps>(
    cnTable(),
    { autoupdate: true },
    (Table): FC<WithAutoupdateProps> => (props: WithAutoupdateProps): ReactElement => {
        const { onUpdate } = props;

        React.useEffect((): () => void => {
            const timer = setInterval((): void => {
                onUpdate();
            }, 10 * 1000);

            return (): void => {
                clearInterval(timer);
            };
        }, [onUpdate]);

        return (
            <Table {...props} />
        );
    },
);

export default withAutoupdate;
