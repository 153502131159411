import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

export interface WithToneBlackProps {
  tone?: 'black';
}

export const withToneBlack = withBemMod<WithToneBlackProps>(
    'Icon',
    { tone: 'black' },
    (Icon): FC => ((props): ReactElement => (<Icon {...props} />)),
);
