import React, { FC, ReactElement } from 'react';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';

import {
    cnInviteCallLinkScreen,
    i18nInviteCallLinkScreen,
} from '../InviteCallLinkScreen';
import { InviteCallLinkScreenCallInfoProps } from './InviteCallLinkScreen-CallInfo.interface';
import './InviteCallLinkScreen-CallInfo.css';

dayjs.extend(calendar);

export const CallInfo: FC<InviteCallLinkScreenCallInfoProps> = (props): ReactElement => {
    const {
        event,
        addonAfter,
        addonBefore,
        className,
    } = props;

    const startDateParsed = dayjs(event.startTs);

    const date = startDateParsed.calendar(null, {
        sameDay: `[${i18nInviteCallLinkScreen('today')}] HH:mm`,
        nextDay: `[${i18nInviteCallLinkScreen('tomorrow')}] HH:mm`,
        lastDay: 'dddd DD MMM HH:mm',
        lastWeek: 'dddd DD MMM HH:mm',
        nextWeek: 'dddd DD MMM HH:mm',
        sameElse: 'dddd DD MMM HH:mm',
    });

    return (
        <div className={cnInviteCallLinkScreen('CallInfo', [className])}>
            {addonBefore}
            <div className={cnInviteCallLinkScreen('CallDate')}>{date}</div>
            {addonAfter}
        </div>
    );
};
