import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';
import './Icon_type_telemost.css';

export interface WithTypeTelemostProps {
  type?: 'telemost';
}

export const withTypeTelemost = withBemMod<WithTypeTelemostProps>(
    'Icon',
    { type: 'telemost' },
    (Icon): FC => ((props): ReactElement => (<Icon {...props} />)),
);
