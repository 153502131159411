import { compose } from '@bem-react/core';
import React, { FC, MutableRefObject, ReactElement, useCallback, useEffect, useState } from 'react';
import { getLocation, push } from 'connected-react-router';
import { connect } from 'react-redux';

import {
    HeaderContainerProps,
    HeaderMapDispatchToProps,
    HeaderStateToProps,
    MenuItemClickEvent,
} from 'components/Header/Header.interface';

import { selectCurrentUser } from 'ducks/user';
import { StoreI } from 'ducks/store';
import { getIsHeaderVisible } from 'ducks/header';

import { HeaderMobile as HeaderMobileViewBase } from './Header@mobile';
import { withNoAuthMobile } from './_noAuth/Header_noAuth@mobile';
import { menu } from './Header.constants';
import {
    appendStaticScript,
    removeStaticScript,
} from './Header.util';
import './Header.css';

const { scripts } = require('./scripts');

const {
    notifier,
    settings,
    toolsCrossNav,
    toolsMenu,
} = scripts;

const HeaderMobileView = compose(
    withNoAuthMobile,
)(HeaderMobileViewBase);

const HeaderContainerMobile: FC<HeaderContainerProps> =
    (props: HeaderContainerProps): ReactElement => {
        const {
            location,
            user,
            isHeaderVisible,
            noAuth,
            push: pushAction,
        } = props;

        const [isSettingsVisible, setIsSettingsVisible] = useState<boolean>(false);
        const [settingsRef, setSettingsRef] = useState<MutableRefObject<HTMLDivElement>>(React.createRef());

        const handleCloseSettings = useCallback(() => {
            setIsSettingsVisible(false);
        }, []);

        const handleLogoServiceClick = useCallback((event: MouseEvent) => {
            event.preventDefault();
            const { url } = menu.main;

            pushAction(url);
        }, [pushAction]);

        const handleSettingsClick = useCallback(() => {
            setIsSettingsVisible(!isSettingsVisible);
        }, [isSettingsVisible]);

        const handleMenuClick = useCallback((event: MenuItemClickEvent) => {
            const toolsCrossNavigationPopup = document.querySelector('.tools-cross-navigation__popup');
            const pageKey = event.detail.type;
            const { url, target } = menu[pageKey];

            if (target === '_blank') {
                window.open(url, '_blank');
            } else {
                pushAction(url);
            }

            const toolsCrossNavigationButtonBack = toolsCrossNavigationPopup.querySelector<HTMLButtonElement>('.tools-cross-navigation__button_type_back');

            // Эмуляция клика на кнопку закрытия попапа с меню
            // Нужно для того, чтобы отработала дефолтная логика serp-header (удаление "overflow: hidden" для страницы)
            toolsCrossNavigationButtonBack && toolsCrossNavigationButtonBack.click();
            // Удаление попапа с меню
            // В serp-header предполагается, что страница перезагрузится после перехода по ссылкам в меню
            // У нас же SPA, перезагрузки страницы не должно быть, поэтому попап нужно удалить руками
            toolsCrossNavigationPopup.remove();
        }, [pushAction]);

        const handleBurgerMenuButtonClick = useCallback(() => {
            const toolsCrossNavigationPopup = document.querySelector('.tools-cross-navigation__popup');

            toolsCrossNavigationPopup && toolsCrossNavigationPopup.addEventListener('tools-menu:item-click', handleMenuClick, true);
        }, [handleMenuClick]);

        const preventDefault = useCallback((event: MouseEvent) => {
            event.preventDefault();
        }, []);

        useEffect(() => {
            appendStaticScript(toolsCrossNav.mobile({ content: 'js' }), 'mobile-nav');
            appendStaticScript(notifier.mobile({ content: 'js' }), 'mobile-notifier');
            appendStaticScript(settings.mobile({ content: 'js' }), 'mobile-settings');
            appendStaticScript(toolsMenu.mobile({ content: 'js' }), 'mobile-menu');

            return () => {
                removeStaticScript('mobile-nav');
                removeStaticScript('mobile-notifier');
                removeStaticScript('mobile-settings');
                removeStaticScript('mobile-menu');
            };
        }, [isHeaderVisible, location.pathname]);

        useEffect(() => {
            const menuElement = document.querySelector('.tools-menu');

            menuElement && menuElement.querySelectorAll('.tools-menu__link').forEach(link => {
                link.addEventListener('click', preventDefault);
            });
        }, [isHeaderVisible, preventDefault, user, location]);

        useEffect(() => {
            const logoService = document.querySelector('.tools-header__logo-service');
            logoService && logoService.addEventListener('click', handleLogoServiceClick);

            return () => {
                logoService && logoService.removeEventListener('click', handleLogoServiceClick);
            };
        }, [isHeaderVisible, preventDefault, user, location, handleLogoServiceClick]);

        useEffect(() => {
            const header = document.querySelector('.Header');

            header && header.addEventListener('settingsClick', handleSettingsClick, true);

            return () => {
                header && header.removeEventListener('settingsClick', handleSettingsClick, true);
            };
        }, [isHeaderVisible, handleSettingsClick, handleMenuClick]);

        useEffect(() => {
            const burgerMenu = document.querySelector('.tools-cross-navigation__button');
            burgerMenu && burgerMenu.addEventListener('click', handleBurgerMenuButtonClick);

            return () => {
                burgerMenu && burgerMenu.removeEventListener('click', handleBurgerMenuButtonClick);
            };
        });

        useEffect(() => {
            const settings = document.querySelector<HTMLDivElement>('.Header .tools-settings');
            setSettingsRef({
                current: settings,
            });
        }, [isHeaderVisible, handleSettingsClick, handleMenuClick]);

        return (
            <HeaderMobileView
                user={user}
                isHeaderVisible={isHeaderVisible}
                onSettingsClose={handleCloseSettings}
                isSettingsVisible={isSettingsVisible}
                settingsRef={settingsRef}
                noAuth={noAuth}
            />
        );
    };

export const HeaderMobile = connect<HeaderStateToProps, HeaderMapDispatchToProps>(
    (store: StoreI): HeaderStateToProps => ({
        user: selectCurrentUser(store),
        isHeaderVisible: getIsHeaderVisible(store),
        location: getLocation(store),
    }),
    {
        push,
    },
)(HeaderContainerMobile);
