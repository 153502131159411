export const en = {
    'player-tumbler': 'Player Yandex.Ephir',
    'chat-button': 'Go to chat',
    'all-streams-link': 'All streams',
    'no-data-text': 'No data',
    'show-calendar-awesome': 'Go to calendar event',
    'stream-title': 'Stream',
    'stream-error': 'Error',
    'viewers-counter-title': 'Number of viewers',
    player_type_protected: 'Protected stream',
    player_type_audio: 'Audio only',
    'rate-informer-title': 'Evaluate the quality of the stream',
    'rate-informer-textarea-placeholder': 'Please write your review',
    'rate-informer-success-title': 'Thank you for your feedback!',
    'rate-informer-error-title': 'An error occurred while sending',
    'rate-informer-action-clear': 'Later',
    'rate-informer-action-submit': 'Send',
    'stream-language-ru': 'RU',
    'stream-language-en': 'EN',
    'stream-language-title': 'Stream language',
    'error.smth-went-wrong.title': 'Oh, something went wrong...',
    'error.smth-went-wrong.description': 'Try to create a report through a bug',
    'error.not-found.title': 'Oops, stream has already passed',
    'error.not-found.description': 'Or maybe you made a mistake in the address of the page',
    'error': 'Problem',
    'text-stream': 'Stream text translation',
    'add-to-calendar': 'Add to your calendar',
    'alt-settings': 'Stream settings',
    'alt-viewers': 'Audience',
    'button.troubleshooting': 'There is a problem with stream',
    'solution.first': 'Make sure you have a stable network connection and sound is turned on',
    'solution.second': 'Restart your PC',
    'solution.third': 'Make sure that protected content (DRM) is allowed to be played in your browser',
    'solution.fourth': 'If it doesn\'t help, write to us via the bug button on this page',
};
