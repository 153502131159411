import React, { FC, ReactElement } from 'react';
import i18n from '@yandex-int/i18n';

import { Chat } from 'components/Chat';
import { BackLink } from 'components/BackLink';

import { StreamScreenProps } from './StreamScreen.interface';
import { Description } from './Description';
import { Title } from './Title';
import { RateInformer } from './RateInformer';
import { Controls } from './Controls/StreamScreen-Controls';
import { cnStreamScreen } from './StreamScreen.cn';
import * as keyset from './StreamScreen.i18n';

import './StreamScreen.css';

export const i18nStreamScreen = i18n(keyset);

export const registryId = cnStreamScreen();

const chatFlags = ['disableNavigation'];

export const StreamScreen: FC<StreamScreenProps> = (props: StreamScreenProps): ReactElement => {
    const {
        name,
        addonBefore,
        description,
        chatInviteHash,
        env,
        replacer,
        player,
        viewersCount,
        platform,
        uri,
        stopTime,
        className,
    } = props;

    return (
        <div className={cnStreamScreen({}, [className])}>
            {addonBefore}
            <div className={cnStreamScreen('Content')}>
                <BackLink
                    title={i18nStreamScreen('all-streams-link')}
                    url="/streams/"
                    className={cnStreamScreen('BackLink')}
                />
                {
                    replacer || (
                        <>
                            <div className={cnStreamScreen('Wrapper')}>
                                <Title
                                    name={name}
                                    viewersCount={viewersCount}
                                />
                                <Controls {...props} />
                            </div>
                            <div className={cnStreamScreen('Sides')}>
                                <div className={cnStreamScreen('Left')}>
                                    {player}
                                    <Description
                                        description={description}
                                        platform={platform}
                                    />
                                </div>
                                <div className={cnStreamScreen('Right')}>
                                    <Chat
                                        className={cnStreamScreen('Chat')}
                                        chatInviteHash={chatInviteHash}
                                        env={env}
                                        flags={chatFlags}
                                    />
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
            <RateInformer
                uri={uri}
                stopTime={stopTime}
                name={name}
                env={env}
            />
        </div>
    );
};

export default StreamScreen;
