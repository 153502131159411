import { cn } from '@bem-react/classname';
import { useLocation } from 'react-router';
import React, { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { theme } from '@yandex-int/hr-components/Theme/presets/light';
import Notifications from 'components/Notifications';
import { cnTheme } from '@yandex-lego/components/Theme';
import { useAppHeight } from 'hooks/useAppHeight';
import { useUserError } from '../../hooks/useUserError';
import { ClickerPage, ClickerProps } from './Clicker.interface';
import { usePlatformContext } from '../../hooks';
import { ManagementPage } from './Clicker.components/ManagementPage/ManagementPage';
import { StartPage } from './Clicker.components/StartPage/StartPage';
import { InstructionPage } from './Clicker.components/InstructionPage/InstructionPage';
import './Clicker.css';

const cnClicker = cn('Clicker');

const Clicker = (props: ClickerProps): ReactElement => {
    const {
        messages,
        isLoading,
        env,
        userError,
        authRequired,
        currentPage,
        onChangeSlide,
        onChangePage,
        onConnect,
        getUser,
        showInactivityWarning,
    } = props;

    const isManaging = currentPage === ClickerPage.MAIN;
    const platform = usePlatformContext();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const id = query.get('id');

    useEffect(() => getUser(), []);
    useEffect(() => env && onConnect(id, env), [env, id]);
    useAppHeight();

    useUserError(userError, authRequired);

    const onManagementStart = useCallback(() => onChangePage(ClickerPage.MAIN, id, env), [env]);
    const onManagementFinish = useCallback(() => onChangePage(ClickerPage.START, id, env), [env]);
    const onOpenInstruction = useCallback(() => onChangePage(ClickerPage.INSTRUCTION_INTRODUCTION), []);

    const content = useMemo(() => {
        switch (currentPage) {
            case ClickerPage.START: {
                return (
                    <StartPage
                        id={id}
                        isLoading={isLoading}
                        startManagement={onManagementStart}
                        openInstruction={onOpenInstruction}
                    />
                );
            }

            case ClickerPage.MAIN: {
                return (
                    <ManagementPage
                        id={id}
                        env={env}
                        messages={messages}
                        isLoading={isLoading}
                        isManaging={isManaging}
                        onChangeSlide={onChangeSlide}
                        stopManagement={onManagementFinish}
                        showInactivityWarning={showInactivityWarning}
                    />
                );
            }

            case ClickerPage.INSTRUCTION_INTRODUCTION:
            case ClickerPage.INSTRUCTION_IMPORTANT:
            case ClickerPage.INSTRUCTION_FINISH: {
                return (
                    <InstructionPage
                        currentPage={currentPage}
                        onChangePage={onChangePage}
                    />
                );
            }
        }
    }, [currentPage, env, id, isLoading, isManaging, messages, onChangePage, onChangeSlide,
        onManagementFinish, onManagementStart, onOpenInstruction, showInactivityWarning]);

    return (
        <div className={cnClicker(null, [cnTheme(theme)])}>
            <div className={cnClicker('ContentWrapper', { platform })}>
                {content}
            </div>
            <Notifications />
        </div>
    );
};

export default Clicker;
