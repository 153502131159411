import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import { cnBubble, BubbleProps } from '../Bubble';

export interface BubbleTypeRoomData {
  id: string
  title: string
}

export interface WithTypeRoom {
  type?: 'room'
  data: BubbleTypeRoomData
}

export const withTypeRoom = withBemMod<WithTypeRoom, BubbleProps>(
    cnBubble(),
    { type: 'room' },
    (Bubble): FC<WithTypeRoom & BubbleProps> => (props: WithTypeRoom & BubbleProps): ReactElement => {
        const { data: { title } } = props;
        return (
            <Bubble {...props}>
                { title }
            </Bubble>
        );
    },
);

export default withTypeRoom;
