import { ApiErrorI } from 'util/api.rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { createSelector } from 'reselect';
import { Action, createAction, handleActions } from 'redux-actions';

import { StoreI } from 'ducks/store';
import { CallTemplateAPI } from 'ducks/templateForm';
import { CallFormAPI } from 'ducks/createCallForm';
import { convertCalendarEventApiResponse } from 'ducks/calendarEvents/calendarEvents.util';

import { CallTemplate } from 'components/CallTemplate';
import { CallForm } from 'components/CreateCallForm/CreateCallForm.interface';
import { CalendarEvent, CalendarEventApi } from 'components/CalendarEvents/CalendarEvents.interface';
import { notyError } from 'components/Notifications/Notifications.actions';
import { INotificationMessageType } from 'components/Notifications/Notifications.interface';

const REQUEST = 'vconf/calendarEventMeta/REQUEST';
const CLEAR = 'vconf/calendarEventMeta/CLEAR';
const RECEIVE_SUCCESS = 'vconf/calendarEventMeta/RECEIVE_SUCCESS';
const RECEIVE_ERROR = 'vconf/calendarEventMeta/RECEIVE_ERROR';

export interface RequestPayload {
  eventId: number;
  templateId: number;
}

export const request = createAction<RequestPayload>(REQUEST);
export const clear = createAction(CLEAR);
export const receiveSuccess = createAction<CalendarEventMetaApi>(RECEIVE_SUCCESS);
export const receiveError = createAction<ApiErrorI>(RECEIVE_ERROR);

export interface CalendarEventMeta {
    call: CallForm;
    template: CallTemplate;
    event: CalendarEvent;
    relevantTemplates: CallTemplate[];
}

export interface CalendarEventMetaApi {
    call: CallFormAPI;
    template: CallTemplateAPI;
    event: CalendarEventApi;
    relevant_templates: CallTemplateAPI[];
}

export interface CalendarEventMetaState {
    isRequesting: boolean;
    eventMeta: CalendarEventMeta;
    error: ApiErrorI;
}

export const initialState: CalendarEventMetaState = {
    isRequesting: false,
    eventMeta: null,
    error: null,
};

type IPayload = RequestPayload | CalendarEventMetaApi | ApiErrorI;

export const reducer = handleActions<CalendarEventMetaState, IPayload>({
    [REQUEST]: (state: CalendarEventMetaState): CalendarEventMetaState => ({
        ...state,
        isRequesting: true,
    }),

    [RECEIVE_SUCCESS]: (
        state: CalendarEventMetaState,
        action: Action<CalendarEventMetaApi>,
    ): CalendarEventMetaState => {
        const { payload } = action;
        const {
            call,
            template,
            event,
        } = payload;

        let eventMetaTemplate: CallTemplate = null;

        if (template) {
            eventMetaTemplate = {
                id: template.id,
                name: template.name,
                duration: template.duration,
                owners: template.owners,
                participants: template.participants,
                eventExternalId: template.event_external_id,
                stream: template.stream,
                streamPicture: template.stream_picture,
                streamDescription: template.stream_description,
                record: template.record,
                eventId: template.event_id,
                event: template.event,
            };
        }

        return {
            ...state,
            isRequesting: false,
            eventMeta: {
                call: {
                    name: call.name,
                    duration: call.duration,
                    participants: call.participants,
                    record: call.record,
                    stream: call.stream,
                    templateId: call.template_id,
                    eventId: call.event_id,
                    isPrivateStream: call.is_private_stream,
                },
                template: eventMetaTemplate,
                event: convertCalendarEventApiResponse(event),
                relevantTemplates: payload.relevant_templates.map((template): CallTemplate => ({
                    id: template.id,
                    name: template.name,
                    duration: template.duration,
                    owners: template.owners,
                    participants: template.participants,
                    eventExternalId: template.event_external_id,
                    stream: template.stream,
                    streamPicture: template.stream_picture,
                    streamDescription: template.stream_description,
                    record: template.record,
                    eventId: template.event_id,
                    event: template.event,
                })),
            },
        };
    },

    [CLEAR]: (): CalendarEventMetaState => ({
        ...initialState,
    }),

    [RECEIVE_ERROR]: (
        state: CalendarEventMetaState,
        action: Action<ApiErrorI>,
    ): CalendarEventMetaState => {
        const { payload } = action;

        return {
            ...state,
            isRequesting: false,
            error: payload,
        };
    },

}, initialState);

type IEventPayload = CalendarEventMetaApi | ApiErrorI;

export const epic = combineEpics(
    (action$: Observable<Action<RequestPayload>>,
        store$: StoreI,
        { calendarEventMetaApi: { getEventMeta } }): Observable<Action<IEventPayload>> =>
        action$.pipe(
            ofType(REQUEST),
            mergeMap(({ payload }): Observable<Action<IEventPayload>> =>
                getEventMeta(payload).pipe(
                    map((response): Action<IEventPayload> => {
                        const possiblyErrorResponse = response as ApiErrorI;

                        if (possiblyErrorResponse.error) {
                            throw possiblyErrorResponse;
                        }

                        return receiveSuccess(response as CalendarEventMetaApi);
                    }),
                    catchError((error: ApiErrorI): Observable<Action<INotificationMessageType | ApiErrorI>> => of(
                        notyError(error.message),
                        receiveError(error),
                    )),
                ),
            ),
        ),
);

const selectCalendarEventMetaState = (store: StoreI): CalendarEventMetaState => store.calendarEventMeta;

export const selectCalendarEventMetaIsRequesting = createSelector(
    selectCalendarEventMetaState,
    ({ isRequesting }): boolean => isRequesting,
);

export const selectCalendarEventMeta = createSelector(
    selectCalendarEventMetaState,
    ({ eventMeta }): CalendarEventMeta => eventMeta,
);

export const selectCalendarEventMetaError = createSelector(
    selectCalendarEventMetaState,
    ({ error }): ApiErrorI => error,
);
