import { ApiErrorI } from 'util/api.rxjs';
import HttpError from 'util/httpError';
import { combineEpics, ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { mergeMap, catchError, map } from 'rxjs/operators';
import { Action, createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

import { INotificationMessageType } from 'components/Notifications/Notifications.interface';
import { notyError } from 'components/Notifications/Notifications.actions';

import { InjectedDependencies, StoreI } from 'ducks/store';

export interface ErrorDetailsEvent {
    startTs: string,
    id: string,
    master_id: string,
    name: string,
}

export interface ErrorDetails {
    event: ErrorDetailsEvent,
    is_authenticated: boolean;
}

export interface InviteCallLinkError {
    code: number;
    errorCode: string;
    details: ErrorDetails;
}

export type InviteCallLinkState = {
    inviteLink: string;
    cmsLink: string;
    error: InviteCallLinkError;
    isAuthenticated: boolean;
};

export const initialState: InviteCallLinkState = {
    inviteLink: null,
    cmsLink: null,
    error: null,
    isAuthenticated: false,
};

export interface GenerateLinkPayload {
    eventId: number;
    force?: boolean;
}
export type SetLinkPayload = string;
export interface CheckLinkPayload {
    eventId: number;
    masterId: number;
    secret: string;
}
export interface GetInviteCallLinkParamsSuccessResponse {
    event_id: string,
    master_id: string,
    secret: string,
}
export interface CheckLinkSuccessPayload {
    invite_link: string;
    is_authenticated: boolean;
}
export type CheckLinkErrorPayload = HttpError<ErrorDetails>;

export const GENERATE = 'vconf/inviteCallLink/GENERATE';
export const SET = 'vconf/inviteCallLink/SET';
export const CHECK = 'vconf/inviteCallLink/CHECK';
export const CHECK_SUCCESS = 'vconf/inviteCallLink/CHECK_SUCCESS';
export const CHECK_ERROR = 'vconf/inviteCallLink/CHECK_ERROR';

export const generate = createAction<GenerateLinkPayload>(GENERATE);
export const set = createAction<SetLinkPayload>(SET);
export const check = createAction<CheckLinkPayload>(CHECK);
export const checkSuccess = createAction<CheckLinkSuccessPayload>(CHECK_SUCCESS);
export const checkError = createAction<CheckLinkErrorPayload>(CHECK_ERROR);

type Payload = SetLinkPayload | CheckLinkSuccessPayload | CheckLinkErrorPayload;

export const reducer = handleActions<InviteCallLinkState, Payload>({
    [SET]: (state, action: Action<SetLinkPayload>): InviteCallLinkState => {
        const {
            payload,
        } = action;

        return {
            ...state,
            inviteLink: payload,
        };
    },

    [CHECK_SUCCESS]: (state, action: Action<CheckLinkSuccessPayload>): InviteCallLinkState => {
        const {
            payload,
        } = action;

        return {
            ...state,
            cmsLink: payload.invite_link,
            isAuthenticated: payload.is_authenticated,
        };
    },

    [CHECK_ERROR]: (state, action: Action<CheckLinkErrorPayload>): InviteCallLinkState => {
        const {
            payload,
        } = action;

        return {
            ...state,
            error: {
                ...state.error,
                code: payload.code,
                errorCode: payload.errorCode,
                details: payload.errorDetails,
            },
        };
    },
}, initialState);

type GenerateEpicPayload = INotificationMessageType | SetLinkPayload;
type CheckEpicPayload = CheckLinkSuccessPayload | CheckLinkErrorPayload;

export const epic = combineEpics(
    (
        action$: Observable<Action<GenerateLinkPayload>>,
        $state: StoreI,
        { inviteCallLinkApi }: InjectedDependencies,
    ): Observable<Action<GenerateEpicPayload>> =>
        action$.pipe(
            ofType(GENERATE),
            mergeMap(({ payload }): Observable<Action<GenerateEpicPayload>> =>
                inviteCallLinkApi.getInviteLinkParams(payload).pipe(
                    map((response): Action<SetLinkPayload> => {
                        const possiblyErrorResponse = response as ApiErrorI;

                        if (possiblyErrorResponse.error) {
                            throw possiblyErrorResponse;
                        }

                        const data = response as GetInviteCallLinkParamsSuccessResponse;

                        const link = new URL('invite-link', window.location.origin);
                        link.searchParams.set('event_id', data.event_id);
                        link.searchParams.set('master_id', data.master_id);
                        link.searchParams.set('secret', data.secret);

                        return set(String(link));
                    }),
                    catchError((error: ApiErrorI): Observable<Action<INotificationMessageType>> => of(
                        notyError(error.message),
                    )),
                ),
            ),
        ),

    (
        action$: Observable<Action<CheckLinkPayload>>,
        $state: StoreI,
        { inviteCallLinkApi }: InjectedDependencies,
    ): Observable<Action<CheckEpicPayload>> =>
        action$.pipe(
            ofType(CHECK),
            mergeMap(({ payload }): Observable<Action<CheckEpicPayload>> =>
                inviteCallLinkApi.checkInviteLink(payload).pipe(
                    map((response): Action<CheckEpicPayload> => {
                        const possiblyErrorResponse = response as ApiErrorI;

                        if (possiblyErrorResponse.error) {
                            throw possiblyErrorResponse;
                        }

                        return checkSuccess(response as CheckLinkSuccessPayload);
                    }),
                    catchError((error: ApiErrorI): Observable<Action<CheckLinkErrorPayload>> => {
                        const code = error.response_code;
                        const message = error.message;
                        const errorCode = error.error_code;
                        const errorDetails = error.error_details as ErrorDetails;

                        return of(
                            checkError(
                                new HttpError<ErrorDetails>(
                                    code,
                                    message,
                                    errorCode,
                                    errorDetails,
                                ),
                            ),
                        );
                    }),
                ),
            ),
        ),
);

export const selectInviteCallLinkState = (store: StoreI): InviteCallLinkState => store.inviteCallLink;

export const selectLink = createSelector(
    selectInviteCallLinkState,
    ({ inviteLink }): string => inviteLink,
);

export const selectCmsLink = createSelector(
    selectInviteCallLinkState,
    ({ cmsLink }): string => cmsLink,
);

export const selectError = createSelector(
    selectInviteCallLinkState,
    ({ error }): InviteCallLinkError => error,
);
