import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import { Suggest } from 'components/Suggest';

import { cnParticipantsList } from '../ParticipantsList';
import { ParticipantsListProps } from '../ParticipantsList.interface';
import { WithSuggestProps } from './ParticipantsList_suggest.interface';
import './ParticipantsList_suggest.css';

export const withSuggest = withBemMod<WithSuggestProps, ParticipantsListProps>(cnParticipantsList(),
    { suggest: true },
    (ParticipantsList): FC<ParticipantsListProps & WithSuggestProps> => (
        (props: ParticipantsListProps & WithSuggestProps): ReactElement => {
            const {
                onSuggestSelect,
                placeholder = '',
                autofocusable,
                addMeButton,
                addEventAttendeesButton,
            } = props;

            let suggestButtons;

            if (addMeButton || addEventAttendeesButton) {
                suggestButtons = (
                    <div className={cnParticipantsList('SuggestButtons')}>
                        {addMeButton}
                        {addEventAttendeesButton}
                    </div>
                );
            }

            const addonBefore = onSuggestSelect && (
                <>
                    <Suggest
                        layers={['invite', 'people']}
                        onChange={onSuggestSelect}
                        shouldProcessArbitrary
                        placeholder={placeholder}
                        autofocusable={autofocusable}
                    />
                    {suggestButtons}
                </>
            );

            return (
                <ParticipantsList
                    {...props}
                    addonBefore={addonBefore}
                />
            );
        }
    ),
);

export default withSuggest;
