import React, { FC, ReactElement } from 'react';
import { Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { configureRootTheme } from '@yandex-lego/components/Theme';
import { theme } from '@yandex-lego/components/Theme/presets/default';

import Routes from 'components/Routes/Routes';
import { KhRoutes } from 'khural-components/KhRoutes/KhRoutes';

import { history } from 'ducks/store';

configureRootTheme({ theme });

export const GlobalContextRegistry: FC = (): ReactElement => {
    const isKhural = window.location.host.includes('khural');

    return (
        <ConnectedRouter history={history}>
            <Route component={isKhural ? KhRoutes : Routes} />
        </ConnectedRouter>
    );
};

export default GlobalContextRegistry;
