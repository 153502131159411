import { cn } from '@bem-react/classname';
import React, { FC, ReactElement } from 'react';

import { ParticipantProps } from './Participant.interface';
import './Participant@desktop.css';

export const cnParticipant = cn('Participant');

export const Participant: FC<ParticipantProps> = (props: ParticipantProps): ReactElement => {
    const {
        className,
        name,
        recallButton,
        icon,
        addon,
        deleteButton,
        controlButtons,
        subtitle,
    } = props;

    return (
        <div className={cnParticipant({}, [className])}>
            <div className={cnParticipant('LeftSide')}>
                {recallButton}
                {icon}
                <div className={cnParticipant('MetaInfo')}>
                    <div className={cnParticipant('Title')}>{ name }</div>
                    <div className={cnParticipant('Subtitle')}>{ subtitle }</div>
                </div>
            </div>
            <div className={cnParticipant('RightSide')}>
                {controlButtons}
                {addon}
                {deleteButton}
            </div>
        </div>
    );
};

export default Participant;
