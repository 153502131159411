import { withBemMod } from '@bem-react/core';
import { Spin } from '@yandex-lego/components/Spin/bundle';
import React, { ReactElement, FC } from 'react';

import { cnTemplateList } from '../TemplateList';
import { WithLoadingProps } from './TemplateList_loading.interface';
import { TemplateListProps } from '../TemplateList.interface';

export const withLoading = withBemMod<WithLoadingProps, TemplateListProps>(
    cnTemplateList(),
    { loading: true },
    (StreamScreen): FC<WithLoadingProps & TemplateListProps> => (
        (props: WithLoadingProps & TemplateListProps): ReactElement => {
            const replacer = (
                <Spin
                    className={cnTemplateList('Spin')}
                    size="l"
                    view="default"
                    progress
                />
            );

            return (
                <StreamScreen
                    {...props}
                    replacer={replacer}
                />
            );
        }
    ),
);
