import { withBemMod } from '@bem-react/core';

import React, { FC, ReactElement } from 'react';

import { ParticipantAvatar } from 'components/ParticipantAvatar';

import { cnParticipant } from '../Participant@desktop';
import { ParticipantProps } from '../Participant.interface';
import { WithTypeCmsProps } from './Participant_type_cms.interface';

export const withTypeCms = withBemMod<WithTypeCmsProps, ParticipantProps>(
    cnParticipant(),
    { type: 'cms' },
    (Participant): FC<ParticipantProps & WithTypeCmsProps> => (
        (props: ParticipantProps & WithTypeCmsProps): ReactElement => {
            const icon = (
                <ParticipantAvatar />
            );

            return (
                <Participant
                    {...props}
                    icon={icon}
                />
            );
        }
    ),
);
