import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import { cnParticipantAvatar } from '../ParticipantAvatar';
import { ParticipantAvatarProps } from '../ParticipantAvatar.interface';
import { WithTypePersonProps } from './ParticipantAvatar_type_person.interface';

export const withTypePerson = withBemMod<WithTypePersonProps, ParticipantAvatarProps>(
    cnParticipantAvatar(),
    { type: 'person' },
    (ParticipantAvatar): FC<ParticipantAvatarProps & WithTypePersonProps> => (
        (props: ParticipantAvatarProps & WithTypePersonProps): ReactElement => {
            const {
                id,
            } = props;

            const image = (
                <img
                    className={cnParticipantAvatar('Image')}
                    src={`https://center.yandex-team.ru/api/v1/user/${id}/avatar/80.jpg`}
                    alt="avatar"
        />
            );

            return (
                <ParticipantAvatar
                    {...props}
                    replacer={image}
        />
            );
        }
    ),
);

export default withTypePerson;
