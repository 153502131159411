import { withBemMod, compose } from '@bem-react/core';
import i18n from '@yandex-int/i18n';
import React, { FC, ReactElement } from 'react';
import {
    Yabble as YabbleBase,
    withFixed as withYabbleFixed,
    withTypeText as withYabbleTypeText,
    withFullClickable as withYabbleFullClickable,
} from 'components/Yabble';

import { cnParticipantsList } from '../ParticipantsList';
import { ParticipantsListProps } from '../ParticipantsList.interface';
import { WithAddEventAttendeesProps } from './ParticipantsList_addEventAttendees.interface';
import * as keyset from '../ParticipantsList.i18n';

const i18nParticipantsList = i18n(keyset);

const Yabble = compose(
    withYabbleFixed,
    withYabbleTypeText,
    withYabbleFullClickable,
)(YabbleBase);

export const withAddEventAttendees = withBemMod<WithAddEventAttendeesProps, ParticipantsListProps>(
    cnParticipantsList(),
    { addEventAttendees: true },
    (ParticipantsList): FC<ParticipantsListProps & WithAddEventAttendeesProps> => (
        (props: ParticipantsListProps & WithAddEventAttendeesProps): ReactElement => {
            const {
                onGetEventAttendeesButtonClick,
            } = props;

            const addEventAttendeesButton = (
                <Yabble
                    actionType="plus"
                    participantType="attendees"
                    type="text"
                    title={i18nParticipantsList('add-participants-from-event-button-text')}
                    fixed
                    fullClickable
                    className={cnParticipantsList('AddEventAttendeesButton')}
                    onClick={onGetEventAttendeesButtonClick}
                />
            );

            return (
                <ParticipantsList
                    {...props}
                    addEventAttendeesButton={addEventAttendeesButton}
                />
            );
        }
    ),
);
