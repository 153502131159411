import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';
import './Icon_type_handset.css';

export interface WithTypeHandsetProps {
  type?: 'handset';
}

export const withTypeHandset = withBemMod<WithTypeHandsetProps>(
    'Icon',
    { type: 'handset' },
    (Icon): FC => ((props): ReactElement => (<Icon {...props} />)),
);

export default withTypeHandset;
