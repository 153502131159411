export const en = {
    main: 'Main',
    history: 'History',
    streams: 'Streams',
    staff: 'Staff',
    options: 'Options',
    logout: 'Logout',
    menu: 'Menu',
    docs: 'Documentation',
    yandex: 'Yandex',
};
