import React, { FC, ReactElement, useCallback } from 'react';

import { CreateCallScreenContentDesktopContainerProps } from './CreateCallScreen-Content.interface';
import { CreateCallScreenContentDesktop as CreateCallScreenContentDesktopView } from './CreateCallScreen-Content@desktop';

export const CreateCallScreenContentDesktop: FC<CreateCallScreenContentDesktopContainerProps> =
    (props: CreateCallScreenContentDesktopContainerProps): ReactElement => {
        const {
            calendarEventMeta,
            data,
            onSetNameValue,
        } = props;

        const handleTemplateClick = useCallback((): void => {
            window.scroll({
                behavior: 'smooth',
                top: 0,
            });
        }, []);

        return (
            <CreateCallScreenContentDesktopView
                onTemplateClick={handleTemplateClick}
                calendarEventMeta={calendarEventMeta}
                data={data}
                onSetNameValue={onSetNameValue}
            />
        );
    };
