import { composeU } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import i18n from '@yandex-int/i18n';
import React, { FC, ReactElement } from 'react';

import { Actions } from './Actions';
import { Background } from './Background';
import {
    StreamPosterDuration as DurationBase,
    withDurationTypeCurrent,
    withDurationTypeUpcoming,
} from './Duration';
import { Viewers } from './Viewers';
import { Title } from './Title';
import { Description } from './Description';
import * as keyset from './StreamPoster.i18n';
import { StreamPosterProps } from './StreamPoster.interface';
import './StreamPoster.css';

export const i18nStreamPoster = i18n(keyset);

export const cnStreamPoster = cn('StreamPoster');

const Duration = composeU(
    withDurationTypeCurrent,
    withDurationTypeUpcoming,
)(DurationBase);

export const StreamPoster: FC<StreamPosterProps> = (props: StreamPosterProps): ReactElement => {
    const {
        type,
        id,
        viewers,
        description,
        name,
        info,
        eventId,
        templateId,
        startTime,
        streamPosterUrl,
        className,
        onStreamPosterClick,
        onChatClick,
        onTvChatClick,
        onActionsStreamPosterClick,
        onViewersCounterClick,
        onStreamPosterDescriptionClick,
        onStreamPosterTitleTextClick,
        onHintClick,
        isTvButtonVisible = false,
    } = props;

    return (
        <div
            className={cnStreamPoster(null, [className])}
            onClick={onStreamPosterClick}
        >
            <div className={cnStreamPoster('Container')}>
                <div className={cnStreamPoster('Header')}>
                    <Duration
                        type={type as undefined}
                        startTime={startTime}
                    />
                    <Viewers
                        viewers={viewers}
                        onViewersCounterClick={onViewersCounterClick}
                    />
                </div>
                <Actions
                    id={id}
                    eventId={eventId}
                    templateId={templateId}
                    isTvButtonVisible={isTvButtonVisible}
                    onActionsStreamPosterClick={onActionsStreamPosterClick}
                    onChatClick={onChatClick}
                    onTvChatClick={onTvChatClick}
                />
                <div className={cnStreamPoster('Content')}>
                    <Title
                        name={name}
                        info={info}
                        onHintClick={onHintClick}
                        onStreamPosterTitleTextClick={onStreamPosterTitleTextClick}
                    />
                    <Description
                        description={description}
                        onStreamPosterDescriptionClick={onStreamPosterDescriptionClick}
                    />
                </div>
            </div>
            <Background
                streamPosterUrl={streamPosterUrl}
            />
        </div>
    );
};
