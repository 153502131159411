import i18n from '@yandex-int/i18n';
import { cn } from '@bem-react/classname';
import React, { FC, ReactElement } from 'react';

import { StreamsListProps } from './StreamsList.interface';
import './StreamsList.css';
import * as keyset from './StreamsList.i18n';

const i18nStreamsList = i18n(keyset);

export const cnStreamsList = cn('StreamsList');

export const StreamsList: FC<StreamsListProps> = (props: StreamsListProps): ReactElement => {
    const {
        onShowAllButtonClick,
        isShowAllButtonVisible,
        posters,
        title,
        amount,
    } = props;

    return (
        <div className={cnStreamsList()}>
            <div className={cnStreamsList('Title')}>
                {title}
                <span className={cnStreamsList('NumberStreams')}>{amount}</span>
            </div>
            <div className={cnStreamsList('List')}>
                <div className={cnStreamsList('StreamPosters')}>
                    {posters}
                </div>
                {
                    isShowAllButtonVisible && (
                        <div
                            className={cnStreamsList('ShowAll')}
                            onClick={onShowAllButtonClick}
                        >
                            {i18nStreamsList('show-all-text')}
                        </div>
                    )
                }
            </div>
        </div>
    );
};
