import { withBemMod, compose } from '@bem-react/core';
import {
    Spin as SpinBase,
    withSizeL,
    withViewDefault,
} from '@yandex-lego/components/Spin';
import React, { ReactElement, FC } from 'react';
import { WithLoadingProps } from './ChatScreen_loading.interface';
import { ChatScreenProps } from '../ChatScreen.interface';
import { cnChatScreen } from '../ChatScreen';

import './ChatScreen_loading.css';

const Spin = compose(
    withSizeL,
    withViewDefault,
)(SpinBase);

export const withLoading = withBemMod<WithLoadingProps, ChatScreenProps>(
    cnChatScreen(),
    { loading: true },
    (ChatScreen): FC<WithLoadingProps & ChatScreenProps> => (
        (props: WithLoadingProps & ChatScreenProps): ReactElement => {
            const replacer = (
                <Spin
                    className={cnChatScreen('Spin')}
                    size="l"
                    view="default"
                    progress
                />
            );

            return (
                <ChatScreen
                    {...props}
                    replacer={replacer}
                />
            );
        }
    ),
);
