import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    setTheme,
    Theme,
} from 'ducks/theme';

export const useTheme = (theme: Theme): void => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setTheme(theme));

        return (): void => {
            dispatch(setTheme('normal'));
        };
    }, [dispatch, theme]);
};
