import { cn } from '@bem-react/classname';
import React, { FC, ReactElement } from 'react';

import { MethodSelectorProps } from './MethodSelector.interface';
import './MethodSelector.css';

export const cnMethodSelector = cn('MethodSelector');

export const MethodSelector: FC<MethodSelectorProps> = (props: MethodSelectorProps): ReactElement => {
    const {
        children,
        className,
    } = props;

    return (
        <div className={cnMethodSelector(null, [className])}>
            { children }
        </div>
    );
};
