import { cn } from '@bem-react/classname';
import { compose } from '@bem-react/core';

import {
    Spin as SpinBase,
    withSizeM as withSpinSizeM,
    withViewDefault as withSpinViewDefault,
} from '@yandex-lego/components/Spin';
import { connect, useSelector } from 'react-redux';
import React, { FC, ReactElement, useEffect, useState } from 'react';

import { StoreI } from 'ducks/store';
import {
    selectReportId as selectPlayerReportId,
} from 'ducks/streamPlayer';

import {
    BugFrameContainerProps,
    BugFrameMapStateToProps,
} from './BugFrame.interface';

const cnBugFrame = cn('BugFrame');

const Spin = compose(
    withSpinSizeM,
    withSpinViewDefault,
)(SpinBase);

const BugFrame: FC<BugFrameContainerProps> = (props: BugFrameContainerProps): ReactElement => {
    const { calls, params } = props;

    const playerReportId = useSelector(selectPlayerReportId);

    const [iframeHeight, setIframeHeight] = useState(0);
    const [isFormIframeLoading, setIsFormIframeLoading] = useState(true);

    useEffect((): () => void => {
        function listener(event: MessageEvent): void {
            const { source, data } = event;

            if (source && data === 'ping') {
                (source as Window).postMessage('pong', '*');
            }

            try {
                const parsedData = JSON.parse(data);
                if (parsedData) {
                    if (parsedData['iframe-height']) {
                        setIframeHeight(parsedData['iframe-height']);
                    }

                    if (parsedData.type && parsedData.type === 'survey-iframe') {
                        setIsFormIframeLoading(false);
                    }

                    if (source && parsedData.message === 'ping') {
                        (source as Window).postMessage('pong', '*');
                    }
                }
            } catch (e) {
                // Do nothing
            }
        }

        window.addEventListener('message', listener);

        return (): void => {
            window.removeEventListener('message', listener);
        };
    }, []);

    const confIds = calls.map((call): string => call.id).join(',');
    const { userAgent } = window.navigator;

    const frameUrl = new URL('https://forms.yandex-team.ru/surveys/20695');

    frameUrl.searchParams.append('conf-ids', confIds);
    frameUrl.searchParams.append('iframe', '1');
    frameUrl.searchParams.append('user-agent', userAgent);

    Object.entries(params).forEach(([key, value]: [string, string]) => {
        frameUrl.searchParams.append(key, value);
    });

    if (playerReportId) {
        frameUrl.searchParams.append('player-report-id', playerReportId);
    }

    return (
        <div className={cnBugFrame()}>
            {
                isFormIframeLoading && (
                    <Spin
                        progress
                        size="m"
                        view="default"
                    />
                )
            }
            {
                <iframe
                    style={{ height: iframeHeight }}
                    className={cnBugFrame('Iframe')}
                    name="vconfBugReport"
                    frameBorder={0}
                    src={frameUrl.toString()}
                    title="VConf Bug Report"
                />
            }

        </div>
    );
};

export default connect<BugFrameMapStateToProps>(
    (store: StoreI): BugFrameMapStateToProps => ({
        calls: store.activeCalls.activeCalls,
        params: store.yandexBug.params,
    }),
)(BugFrame);
