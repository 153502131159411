import apiRxjs from 'util/api.rxjs';
import { Observable } from 'rxjs';
import { Payload } from 'ducks/settings';
import { IParticipantMethods } from 'components/Participant/Participant.interface';

export interface SettingsData {
    method: IParticipantMethods
}
export interface SaveSettings {
  (data: SettingsData): Observable<Payload>
}
export interface SettingsApi {
    saveSettings: SaveSettings
}

export const saveSettings: SaveSettings = ({ method }) =>
    apiRxjs.post('/frontapi/participants/current/settings', {
        body: JSON.stringify({ method }),
    });

export const settingsApi = {
    saveSettings,
};

export default saveSettings;
