import { connect } from 'react-redux';

import React, { FC, ReactElement, useCallback, useState, useEffect } from 'react';
import { StoreI } from 'ducks/store';
import { selectSettings, requestSave, setSaved } from 'ducks/settings';
import { Settings as SettingsView } from './Settings';
import {
    ContainerMapStateToProps,
    ContainerMapDispatchToProps,
    ContainerOwnProps,
    SettingsContainerProps,
} from './Settings.interface';

const SettingsContainer: FC<SettingsContainerProps> = (props: SettingsContainerProps): ReactElement => {
    const {
        settings,
        onClose,
        requestSave: requestSaveAction,
        setSaved: setSavedAction,
    } = props;

    useEffect(() => {
        if (settings.isSaved) {
            onClose();
            setSavedAction(false);
        }
    }, [settings.isSaved, onClose, setSavedAction]);

    const [method, setMethod] = useState(settings.currentMethod);
    const isButtonSaveDisabled = settings.isRequesting || method === settings.currentMethod;

    const handleSave = useCallback(() => {
        requestSaveAction({ method });
    }, [requestSaveAction, method]);

    const handleClose = useCallback(() => {
        onClose();
    }, [onClose]);

    const handleSelectChange = useCallback(event => {
        setMethod(event.target.value);
    }, []);

    return (
        <SettingsView
            method={method}
            settings={settings}
            onSelectChange={handleSelectChange}
            onSave={handleSave}
            onClose={handleClose}
            isButtonSaveDisabled={isButtonSaveDisabled}
        />
    );
};

export const Settings = connect<ContainerMapStateToProps, ContainerMapDispatchToProps, ContainerOwnProps>(
    (store: StoreI): ContainerMapStateToProps => ({
        settings: selectSettings(store),
    }),
    {
        requestSave,
        setSaved,
    },
)(SettingsContainer);

export default Settings;
