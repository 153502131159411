import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import { cnTextCollapse } from '../TextCollapse';
import { TextCollapseProps } from '../TextCollapse.interface';
import { WithTypeText } from './TextCollapse_type_text.interface';

export const withTypeText = withBemMod<WithTypeText, TextCollapseProps>(
    cnTextCollapse(),
    { type: 'text' },
    (TextCollapse): FC<WithTypeText & TextCollapseProps> => (
        (props): ReactElement => {
            const {
                text,
            } = props;

            return (
                <TextCollapse {...props}>
                    {text}
                </TextCollapse>
            );
        }
    ),
);
