import { cn } from '@bem-react/classname';
import { compose } from '@bem-react/core';
import i18n from '@yandex-int/i18n';
import React, { FC, ReactElement } from 'react';

import {
    CopyText as CopyTextBase,
    withIconChain,
    withReverse,
} from 'components/CopyText';

import { CallTemplateCopyLinkProps } from './CallTemplate.interface';
import * as keyset from './CallTemplate.i18n';

const i18nCallTemplate = i18n(keyset);

const CopyText = compose(
    withIconChain,
    withReverse,
)(CopyTextBase);

const cnCallTemplate = cn('CallTemplate');

export const CopyLink: FC<CallTemplateCopyLinkProps> = (props: CallTemplateCopyLinkProps): ReactElement => {
    const { isStreamSwitcherOn, eventId, templateId, type } = props;

    if (isStreamSwitcherOn && eventId) {
        const permanentLink = new URL(`${window.location.origin}/template/${templateId}/${type}`);

        return (
            <CopyText
                textToCopy={`${permanentLink}`}
                textToDisplay={i18nCallTemplate(`copy-permanent-${type}-link-text`)}
                onCopyText={i18nCallTemplate('permanent-link-copied')}
                icon="chain"
                className={`${cnCallTemplate('CopyPermanentLink')}`}
                reverse
            />
        );
    }

    return null;
};

export default CopyLink;
