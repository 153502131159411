import i18n from '@yandex-int/i18n';
import { compose, composeU } from '@bem-react/core';

import { Button as ButtonBase } from '@yandex-lego/components/Button/desktop/bundle';

import {
    Icon as IconBase,
    withTypeArrow as withIconTypeArrow,
} from '@yandex-lego/components/Icon/desktop';
import React, { FC, ReactElement, useCallback, useState } from 'react';

import { Duration } from 'components/Duration';

import { withResponsive as withButtonResponsive } from 'components/Button';
import {
    withTypeUpdate as withIconTypeUpdate,
    withTypeCalendarAwesome as withIconTypeCalendarAwesome,
    withSizeS as withIconSizeS,
} from 'components/Icon';
import {
    CopyText as CopyTextBase,
    withIconSquare,
    withReverse,
} from 'components/CopyText';

import { ActiveCallScreenParametersProps } from './ActiveCallScreen-Parameters.interface';
import './ActiveCallScreen-Parameters.css';
import { RecordInfo } from '../RecordInfo/ActiveCallScreen-RecordInfo';
import { StreamInfo } from '../StreamInfo/ActiveCallScreen-StreamInfo@mobile';

import { cnActiveCallScreen } from '../ActiveCallScreen';
import * as keyset from '../ActiveCallScreen.i18n';

const i18nActiveCallScreen = i18n(keyset);

const Icon = compose(
    composeU(
        withIconTypeUpdate,
        withIconTypeArrow,
        withIconTypeCalendarAwesome,
    ),
    withIconSizeS,
)(IconBase);

const CopyText = compose(
    withIconSquare,
    withReverse,
)(CopyTextBase);

const Button = withButtonResponsive(ButtonBase);

const iconCalendarAwesome = (iconClassName: string): ReactElement => (<Icon type="calendarAwesome" size="s" className={iconClassName} />);

export const ActiveCallScreenParameters: FC<ActiveCallScreenParametersProps> =
  (props: ActiveCallScreenParametersProps): ReactElement => {
      const {
          id,
          isAdmin,
          eventId,
          templateId,
          calendarEventUrl,
          startTime,
          uri,
          record,
          stream,
          onCopyStreamLinkText,
          onCopyURIText,
          className,
          duration,
          isDurationChanging,
          onDurationChange,
      } = props;

      const [isDetailsHidden, setIsDetailsHidden] = useState<boolean>(true);
      const onDetailsButtonClick = useCallback((): void => {
          setIsDetailsHidden(!isDetailsHidden);
      }, [isDetailsHidden]);

      const iconArrow = useCallback((iconClassName): ReactElement =>
          (<Icon type="arrow" direction={isDetailsHidden ? 'bottom' : 'top'} className={iconClassName} />), [isDetailsHidden]);

      return (
          <div className={cnActiveCallScreen('Parameters', { isDetailsHidden }, [className])}>
              <div className={cnActiveCallScreen('Row')}>
                  <Duration
                      startingPointISOString={startTime}
                      duration={duration}
                      isDurationChanging={isDurationChanging}
                      onChange={onDurationChange}
                  />
                  <Button
                      view="clear"
                      iconRight={iconArrow}
                      size="s"
                      onClick={onDetailsButtonClick}
                      className={cnActiveCallScreen('DetailsButton')}
                  >
                      Детали
                  </Button>
              </div>
              <div className={cnActiveCallScreen('Details')}>
                  {
                      eventId !== null &&
                          <Button
                              view="clear"
                              type="link"
                              url={calendarEventUrl}
                              icon={iconCalendarAwesome}
                              width="auto"
                              responsive
                              className={cnActiveCallScreen('ButtonCalendarAwesome')}
                          />
                  }
                  {
                      isAdmin &&
                          <CopyText
                              textToCopy={uri}
                              textToDisplay={uri}
                              onCopyText={onCopyURIText}
                              title={i18nActiveCallScreen('title-copy-uri')}
                              icon="square"
                              reverse
                          />
                  }
                  <RecordInfo record={record} />
                  <StreamInfo
                      id={id}
                      stream={stream}
                      onCopyStreamLinkText={onCopyStreamLinkText}
                      templateId={templateId}
                  />
              </div>
          </div>
      );
  };
