import React, { FC, ReactElement } from 'react';

import { cnMethodSelector } from '../MethodSelector';
import { MethodSelectorOptionContentProps } from './MethodSelector-OptionContent.interface';
import './MethodSelector-OptionContent.css';
import { getMethods } from '../MethodSelector.util';

const methods = getMethods();

export const OptionContent: FC<MethodSelectorOptionContentProps> =
    (props: MethodSelectorOptionContentProps): ReactElement => {
        const {
            children,
            method,
            className,
        } = props;

        return (
            <span className={cnMethodSelector('OptionContent', [className])}>
                {
                    children || methods[method]
                }
            </span>
        );
    };
