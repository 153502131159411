import { Spin } from '@yandex-lego/components/Spin/desktop/bundle';
import React, { FC, ReactElement } from 'react';

export const Spinner: FC = (): ReactElement => {
    return (
        <Spin
            view="default"
            size="l"
            progress
        />
    );
};
