import React, { ReactElement, FC } from 'react';
import { compose, withBemMod } from '@bem-react/core';
import {
    SvgIcon as SvgIconBase,
    withTypeError,
} from 'components/SvgIcon';

import { cnKhStreamScreen } from '../../KhStreamScreen.cn';
import { i18n } from '../../KhStreamScreen.translations';
import { KhStreamScreenRateInformerProps } from '../KhStreamScreen-RateInformer.interface';

import { WithErrorProps } from './KhStreamScreen-RateInformer_error.interface';

import './KhStreamScreen-RateInformer_error.css';

const SvgIcon = compose(withTypeError)(SvgIconBase);

export const withError = withBemMod<WithErrorProps, KhStreamScreenRateInformerProps>(
    cnKhStreamScreen('RateInformer'),
    { error: true },
    (KhStreamScreenRateInformer): FC<WithErrorProps & KhStreamScreenRateInformerProps> => (
        (props: WithErrorProps & KhStreamScreenRateInformerProps): ReactElement => {
            const replacer = (
                <div className={cnKhStreamScreen('RateInformerTitle', { state: 'error' })}>
                    <SvgIcon
                        type="error"
                        className={cnKhStreamScreen('RateInformerErrorIcon')}
                    />
                    <span className={cnKhStreamScreen('RateInformerErrorText')}>
                        {i18n('rate-informer-error-title')}
                    </span>
                </div>
            );

            return (
                <KhStreamScreenRateInformer
                    {...props}
                    replacer={replacer}
                />
            );
        }
    ),
);
