import { Icon as IconBase } from '@yandex-lego/components/Icon/desktop';
import { compose } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import { withTypeYandexCalendar as withIconTypeYandexCalendar } from 'components/Icon';

import { cnTemplateFormScreen } from '../TemplateFormScreen';
import { TemplateFormScreenGoToCalendarProps } from './TemplateFormScreen-GoToCalendar.interface';
import './TemplateFormScreen-GoToCalendar.css';

const Icon = compose(
    withIconTypeYandexCalendar,
)(IconBase);

export const GoToCalendar: FC<TemplateFormScreenGoToCalendarProps> =
    (props: TemplateFormScreenGoToCalendarProps): ReactElement => {
        const {
            eventName,
            eventId,
        } = props;

        if (eventId && eventName) {
            return (
                <a
                    className={cnTemplateFormScreen('GoToCalendar')}
                    href={`https://calendar.yandex-team.ru/event/${eventId}`}
                >
                    <Icon
                        size="s"
                        type="yandexCalendar"
                    />
                    <div className={cnTemplateFormScreen('EventName')}>{eventName}</div>
                </a>
            );
        }

        return null;
    };
