import React, { FC, ReactElement } from 'react';
import { cn } from '@bem-react/classname';
import i18n from '@yandex-int/i18n';

import { InviteCallLinkScreenProps } from './InviteCallLinkScreen.interface';
import * as keyset from './InviteCallLinkScreen.i18n';
import './InviteCallLinkScreen.css';

export const cnInviteCallLinkScreen = cn('InviteCallLinkScreen');

export const i18nInviteCallLinkScreen = i18n(keyset);

export const InviteCallLinkScreen: FC<InviteCallLinkScreenProps> = (props): ReactElement => {
    const {
        replacer,
        children,
        className,
    } = props;

    return (
        <div className={cnInviteCallLinkScreen(null, [className])}>
            { replacer || children }
        </div>
    );
};
