import { withBemMod, compose } from '@bem-react/core';

import {
    Icon as IconBase,
    withTypeArrow as withIconTypeArrow,
} from '@yandex-lego/components/Icon/desktop';
import React, { FC, ReactElement, useCallback, useState } from 'react';
import {
    withSizeXs as withIconSizeXs,
} from 'components/Icon';

import { cnParticipantsList } from '../ParticipantsList';
import { ParticipantsListProps } from '../ParticipantsList.interface';
import { WithCollapseProps } from './ParticipantsList_collapse.interface';

import './ParticipantsList_collapse.css';

const Icon = compose(
    withIconTypeArrow,
    withIconSizeXs,
)(IconBase);

export const withCollapse = withBemMod<WithCollapseProps, ParticipantsListProps>(cnParticipantsList(),
    { collapse: true },
    (ParticipantsList): FC<WithCollapseProps & ParticipantsListProps> => (
        (props: WithCollapseProps & ParticipantsListProps): ReactElement => {
            const {
                className,
                isCollapsible,
                isCollapsed: isCollapsedFromProps = true,
                onCollapseCallback = (): void => {},
            } = props;
            let collapsedToggleArrow;

            const [isCollapsed, setIsCollapsed] = useState(isCollapsedFromProps);

            const onCollapsedToggleClick = useCallback((): void => {
                if (isCollapsible) {
                    onCollapseCallback(!isCollapsed);
                    setIsCollapsed(!isCollapsed);
                }
            }, [isCollapsed, isCollapsible, onCollapseCallback]);

            if (isCollapsible) {
                collapsedToggleArrow = (
                    <div className={cnParticipantsList('CollapseArrow')}>
                        <Icon type="arrow" size="xs" />
                    </div>
                );
            }

            return (
                <ParticipantsList
                    {...props}
                    className={cnParticipantsList({ isCollapsible, isCollapsed }, [className])}
                    onCollapsedToggleClick={onCollapsedToggleClick}
                    collapsedToggleArrow={collapsedToggleArrow}
                    isHeaderDisabled={!isCollapsible}
        />
            );
        }
    ));

export default withCollapse;
