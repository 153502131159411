import { ActionsObservable, combineEpics } from 'redux-observable';
import { Observable } from 'rxjs-compat';
import { filter, map, mergeMap } from 'rxjs/operators';
import { Action, createAction } from 'redux-actions';

import { get as getCallTemplates } from 'ducks/templates';
import { deleteTemplateAllRequest, deleteTemplateRequest } from './CallTemplate.api';

const deleteTemplate = 'template:delete';
const deleteTemplateAll = 'template:deleteAll';

export const deleteTemplateAction = createAction<number>(deleteTemplate);
export const deleteTemplateAllAction = createAction<number>(deleteTemplateAll);

const deleteRequests = {
    [`${deleteTemplate}`]: deleteTemplateRequest,
    [`${deleteTemplateAll}`]: deleteTemplateAllRequest,
};

type DeleteTemplateObservable = Observable<Action<void>>;

export const epic = combineEpics(
    (action$: ActionsObservable<Action<number>>): DeleteTemplateObservable =>
        action$.pipe(
            filter((action): boolean =>
                action.type === deleteTemplate || action.type === deleteTemplateAll,
            ),
            mergeMap((action): Observable<DeleteTemplateObservable> =>
                deleteRequests[action.type](action.payload).pipe(
                    map((): DeleteTemplateObservable =>
                        Observable.of(
                            getCallTemplates(),
                        ),
                    ),
                ),
            ),
            mergeMap((action): DeleteTemplateObservable => action),
        ),
);
