import { ApiErrorI } from 'util/api.rxjs';
import { CallHistoryMethodAction, push } from 'connected-react-router';
import { combineEpics, ofType } from 'redux-observable';
import { catchError, filter, map, mapTo, mergeMap } from 'rxjs/operators';
import { Observable, of, EMPTY } from 'rxjs';
import { createSelector } from 'reselect';
import { Action, createAction, handleActions } from 'redux-actions';

import { notyError } from 'components/Notifications/Notifications.actions';
import { INotificationMessageType } from 'components/Notifications/Notifications.interface';
import { ActiveCallI } from 'components/ActiveCalls/ActiveCalls.interface';
import {
    IParticipantMethods,
    IParticipantType,
    ParticipantAPII,
    ParticipantBaseI,
    ParticipantI,
} from 'components/Participant/Participant.interface';
import { getConnectionMethod } from 'components/Participant/Participant.util';

import { CallFormDuration } from 'components/CreateCallForm/CreateCallForm.interface';
import {
    StoreI,
    InjectedDependencies,
} from '../store';

export type IActionType = 'toggle_camera' | 'remove' | 'toggle_microphone' | 'disconnect'

export interface ActiveActionI {
  action: IActionType
  callId: string
  participants: ParticipantBaseI[]
  secret?: string
}

const REQUEST = 'vconf/activeCall/REQUEST';
const RECEIVE = 'vconf/activeCall/RECEIVE';
const RECEIVE_ERROR = 'vconf/activeCall/RECEIVE_ERROR';
const REQUEST_PARTICIPANT = 'vconf/activeCall/REQUEST_PARTICIPANT';
const RECEIVE_PARTICIPANT = 'vconf/activeCall/RECEIVE_PARTICIPANT';
const CHANGE_NEW_PARTICIPANT_METHOD = 'vconf/activeCall/CHANGE_NEW_PARTICIPANT_METHOD';
const DELETE_NEW_PARTICIPANT = 'vconf/activeCall/DELETE_NEW_PARTICIPANT';
const CLEAR_NEW_PARTICIPANTS = 'vconf/activeCall/CLEAR_NEW_PARTICIPANTS';
const ADD_NEW_PARTICIPANTS = 'vconf/activeCall/ADD_NEW_PARTICIPANTS';
const ADDED_NEW_PARTICIPANTS = 'vconf/activeCall/ADDED_NEW_PARTICIPANTS';
const ADD_PARTICIPANTS = 'vconf/activeCall/ADD_PARTICIPANTS';
const ADDED_PARTICIPANTS = 'vconf/activeCall/ADDED_PARTICIPANTS';
const AFFECT_PARTICIPANTS = 'vconf/activeCall/AFFECT_PARTICIPANTS';
const AFFECTED_PARTICIPANTS = 'vconf/activeCall/AFFECTED_PARTICIPANTS';
const END_CALL = 'vconf/activeCall/END_CALL';
const ENDED_CALL = 'vconf/activeCall/ENDED_CALL';
const RESET_AFFECT_CALL_REQUESTING = 'vconf/activeCall/RESET_AFFECT_CALL_REQUESTING';
const UPDATE_CALL_DETAILS = 'vconf/activeCall/UPDATE_CALL_DETAILS';
const CHANGE_DURATION = 'vconf/activeCall/CHANGE_DURATION';
const CHANGE_DURATION_FINISH = 'vconf/activeCall/CHANGE_DURATION_FINISH';

export interface CallRequest {
    callId: string;
    secret?: string;
}
export type RequestActiveCallPayload = CallRequest;

export type ReceiveActiveCallPayload = ActiveCallI;
export interface RequestParticipantsData {
  id: string;
  type: IParticipantType
}
export type RequestParticipantPayload = RequestParticipantsData[];
export type ReceiveParticipantPayload = ParticipantI;
export interface ChangeParticipantMethodPayload {
  id: string;
  method: IParticipantMethods;
}
export interface DeleteParticipantPayload {
  id: string
}
export interface AddParticipantsPayload extends CallRequest {
  participants: ParticipantI[],
}
export type AddedParticipantsPayload = ActiveCallI;
export type AffectParticipantPayload = ActiveActionI;
export type AffectedParticipantsPayload = ActiveCallI;
export interface ChangeDurationPayload {
    callId: string;
    duration: CallFormDuration;
    previousDuration: CallFormDuration;
}
export type EndCallPayload = TerminateConferencePayload;
export type EndedCallPayload = ActiveCallI;
export type TerminateConferencePayload = CallRequest;
export interface AddParticipantPayload extends CallRequest {
    participants: ParticipantAPII[];
}

export const requestActiveCallDetails = createAction<RequestActiveCallPayload>(REQUEST);
export const receiveActiveCallDetails = createAction<ReceiveActiveCallPayload>(RECEIVE);
export const receiveErrorActiveCallDetails = createAction<null>(RECEIVE_ERROR);
export const requestParticipantDetails = createAction<RequestParticipantPayload>(REQUEST_PARTICIPANT);
export const receiveParticipantDetails = createAction<ReceiveParticipantPayload>(RECEIVE_PARTICIPANT);
export const changeNewParticipantMethod = createAction<ChangeParticipantMethodPayload>(CHANGE_NEW_PARTICIPANT_METHOD);
export const deleteNewParticipant = createAction<DeleteParticipantPayload>(DELETE_NEW_PARTICIPANT);
export const clearNewParticipants = createAction(CLEAR_NEW_PARTICIPANTS);
export const addNewParticipants = createAction<AddParticipantsPayload>(ADD_NEW_PARTICIPANTS);
export const addedNewParticipants = createAction<AddedParticipantsPayload>(ADDED_NEW_PARTICIPANTS);
export const addParticipantsToCall = createAction<AddParticipantsPayload>(ADD_PARTICIPANTS);
export const addedParticipants = createAction<AddedParticipantsPayload>(ADDED_PARTICIPANTS);
export const affectParticipantsInCall = createAction<AffectParticipantPayload>(AFFECT_PARTICIPANTS);
export const affectedParticipants = createAction<AffectedParticipantsPayload>(AFFECTED_PARTICIPANTS);
export const endCall = createAction<EndCallPayload>(END_CALL);
export const endedCall = createAction<EndedCallPayload>(ENDED_CALL);
export const resetAffectCallRequesting = createAction<null>(RESET_AFFECT_CALL_REQUESTING);
export const updateCallDetails = createAction<RequestActiveCallPayload>(UPDATE_CALL_DETAILS);
export const changeDuration = createAction<ChangeDurationPayload>(CHANGE_DURATION);
export const changeDurationFinish = createAction<ChangeDurationPayload>(CHANGE_DURATION_FINISH);

export interface ParticipantsHash {
  [id: string]: ParticipantI;
}

export interface ActiveCallState {
  isRequesting: boolean;
  isCallAffectRequesting: boolean;
  call: ActiveCallI;
  addParticipants: ParticipantsHash;
  isDurationChanging: boolean;
}

export const initialState: ActiveCallState = {
    isRequesting: false,
    isCallAffectRequesting: false,
    call: null,
    addParticipants: {},
    isDurationChanging: false,
};

type IPayload =
  RequestActiveCallPayload |
  ReceiveActiveCallPayload |
  ReceiveParticipantPayload |
  ChangeParticipantMethodPayload |
  DeleteParticipantPayload |
  ChangeDurationPayload |
  null;

export const reducer = handleActions<ActiveCallState, IPayload>(
    {
        [REQUEST]: (state): ActiveCallState => ({
            ...state,
            isRequesting: true,
        }),

        [RECEIVE]: (state, action: Action<ReceiveActiveCallPayload>): ActiveCallState => {
            const { payload } = action;
            return {
                ...state,
                isRequesting: false,
                call: {
                    ...payload,
                    duration: state.isDurationChanging ? state.call.duration : payload.duration,
                },
            };
        },

        [RECEIVE_ERROR]: (): ActiveCallState => ({
            ...initialState,
        }),

        [RECEIVE_PARTICIPANT]: (state, action: Action<ReceiveParticipantPayload>): ActiveCallState => {
            const { payload } = action;
            const { addParticipants } = state;

            return {
                ...state,
                addParticipants: {
                    ...addParticipants,
                    [payload.id]: {
                        ...payload,
                        method: getConnectionMethod(payload),
                    },
                },
            };
        },

        [CHANGE_NEW_PARTICIPANT_METHOD]: (state, action: Action<ChangeParticipantMethodPayload>): ActiveCallState => {
            const { payload } = action;
            const { addParticipants } = state;
            const participant = { ...addParticipants[payload.id] };

            return {
                ...state,
                addParticipants: {
                    ...addParticipants,
                    [payload.id]: {
                        ...participant,
                        method: payload.method,
                    },
                },
            };
        },

        [CLEAR_NEW_PARTICIPANTS]: (state): ActiveCallState => {
            return {
                ...state,
                addParticipants: {},
            };
        },

        [DELETE_NEW_PARTICIPANT]: (state, action: Action<DeleteParticipantPayload>): ActiveCallState => {
            const { payload } = action;
            const { addParticipants } = state;

            const participants = Object.assign(addParticipants, {});
            delete participants[payload.id];

            return {
                ...state,
                addParticipants: { ...participants },
            };
        },

        [ADD_PARTICIPANTS]: (state): ActiveCallState => {
            return {
                ...state,
                isCallAffectRequesting: true,
            };
        },

        [ADDED_PARTICIPANTS]: (state, action: Action<AddedParticipantsPayload>): ActiveCallState => {
            const { payload } = action;

            return {
                ...state,
                call: payload,
                isCallAffectRequesting: false,
            };
        },

        [ADD_NEW_PARTICIPANTS]: (state): ActiveCallState => {
            return {
                ...state,
                isCallAffectRequesting: true,
            };
        },

        [ADDED_NEW_PARTICIPANTS]: (state, action: Action<AddedParticipantsPayload>): ActiveCallState => {
            const { payload } = action;

            return {
                ...state,
                call: payload,
                addParticipants: {},
                isCallAffectRequesting: false,
            };
        },

        [AFFECT_PARTICIPANTS]: (state): ActiveCallState => {
            return {
                ...state,
                isCallAffectRequesting: true,
            };
        },

        [AFFECTED_PARTICIPANTS]: (state, action: Action<AddedParticipantsPayload>): ActiveCallState => {
            const { payload } = action;

            return {
                ...state,
                call: payload,
                isCallAffectRequesting: false,
            };
        },

        [ENDED_CALL]: (state, action: Action<EndedCallPayload>): ActiveCallState => {
            const { payload } = action;

            return {
                ...state,
                call: payload,
            };
        },

        [RESET_AFFECT_CALL_REQUESTING]: (state): ActiveCallState => {
            return {
                ...state,
                isCallAffectRequesting: false,
            };
        },

        [CHANGE_DURATION]: (state, action: Action<ChangeDurationPayload>): ActiveCallState => {
            const { payload } = action;

            return {
                ...state,
                call: {
                    ...state.call,
                    duration: payload.duration,
                },
                isDurationChanging: true,
            };
        },

        [CHANGE_DURATION_FINISH]: (state, action: Action<ChangeDurationPayload>): ActiveCallState => {
            const { payload } = action;

            return {
                ...state,
                call: {
                    ...state.call,
                    duration: payload.duration,
                },
                isDurationChanging: false,
            };
        },
    },
    initialState,
);

type IParticipantPayload = ReceiveParticipantPayload | INotificationMessageType;

type IAddParticipantPayload = ReceiveActiveCallPayload | INotificationMessageType;

type IAffectParticipantPayload = AffectedParticipantsPayload | INotificationMessageType;

type IActiveCallPayload = EndedCallPayload | INotificationMessageType;

type IRequestActiveCallPayload = ReceiveActiveCallPayload | INotificationMessageType | null;

export const epic = combineEpics(
    (action$: Observable<Action<RequestParticipantPayload>>,
        store$: StoreI,
        { activeCallApi: { getParticipantDetails } }: InjectedDependencies): Observable<Action<IParticipantPayload>> =>
        action$.pipe(
            ofType(REQUEST_PARTICIPANT),
            mergeMap(({ payload }): Observable<Action<IParticipantPayload>> =>
                getParticipantDetails(payload).pipe(
                    map((response): Action<ReceiveParticipantPayload> => {
                        const possiblyErrorResponse = response as ApiErrorI;

                        if (possiblyErrorResponse.error) {
                            throw possiblyErrorResponse;
                        }

                        return receiveParticipantDetails((response as ReceiveParticipantPayload[])[0]);
                    }),
                    catchError((error: ApiErrorI): Observable<Action<INotificationMessageType>> => of(
                        notyError(error.message),
                    )),
                ),
            ),
        ),

    (action$: Observable<Action<RequestActiveCallPayload>>,
        store$: StoreI,
        { activeCallApi: { getActiveCall } }: InjectedDependencies): Observable<Action<IRequestActiveCallPayload>> =>
        action$.pipe(
            ofType(REQUEST),
            mergeMap(({ payload }): Observable<Action<IRequestActiveCallPayload>> =>
                getActiveCall(payload).pipe(
                    map((response): Action<ReceiveActiveCallPayload> => {
                        const possiblyErrorResponse = response as ApiErrorI;

                        if (possiblyErrorResponse.error) {
                            throw possiblyErrorResponse;
                        }

                        return receiveActiveCallDetails(response as ReceiveActiveCallPayload);
                    }),
                    catchError((error: ApiErrorI): Observable<Action<INotificationMessageType | null>> => of(
                        notyError(error.message),
                        receiveErrorActiveCallDetails(null),
                    )),
                ),
            ),
        ),

    (action$: Observable<Action<AddParticipantsPayload>>,
        store$: StoreI,
        { activeCallApi }: InjectedDependencies): Observable<Action<IAddParticipantPayload>> =>
        action$.pipe(
            filter(({ type }): boolean => type === ADD_PARTICIPANTS || type === ADD_NEW_PARTICIPANTS),
            mergeMap(({ type, payload }): Observable<Action<IAddParticipantPayload>> =>
                activeCallApi.postActiveParticipant(payload).pipe(
                    map((response): Action<AddedParticipantsPayload> => {
                        const possiblyErrorResponse = response as ApiErrorI;

                        if (possiblyErrorResponse.error) {
                            throw possiblyErrorResponse;
                        }

                        return type === ADD_PARTICIPANTS ?
                            addedParticipants(response as AddedParticipantsPayload) :
                            addedNewParticipants(response as AddedParticipantsPayload);
                    }),
                    catchError((error: ApiErrorI): Observable<Action<INotificationMessageType | null>> => of(
                        notyError(error.message),
                        resetAffectCallRequesting(null),
                    )),
                ),
            ),
        ),

    (action$: Observable<Action<AffectParticipantPayload>>,
        store$: StoreI,
        { activeCallApi }: InjectedDependencies): Observable<Action<IAffectParticipantPayload>> =>
        action$.pipe(
            ofType(AFFECT_PARTICIPANTS),
            mergeMap(({ payload }): Observable<Action<IAffectParticipantPayload>> =>
                activeCallApi.participantPostAction(payload).pipe(
                    map((response): Action<AffectedParticipantsPayload> => {
                        const possiblyErrorResponse = response as ApiErrorI;

                        if (possiblyErrorResponse.error) {
                            throw possiblyErrorResponse;
                        }

                        return affectedParticipants(response as AffectedParticipantsPayload);
                    }),
                    catchError((error: ApiErrorI): Observable<Action<INotificationMessageType>> => of(
                        notyError(error.message),
                        resetAffectCallRequesting(null),
                    )),
                ),
            ),
        ),

    (action$: Observable<Action<RequestActiveCallPayload>>,
        store$: StoreI,
        { activeCallApi: { getActiveCall } }: InjectedDependencies): Observable<Action<IRequestActiveCallPayload>> =>
        action$.pipe(
            ofType(UPDATE_CALL_DETAILS),
            mergeMap(({ payload }): Observable<Action<IRequestActiveCallPayload>> =>
                getActiveCall(payload).pipe(
                    map((response): Action<ReceiveActiveCallPayload> => {
                        const possiblyErrorResponse = response as ApiErrorI;
                        if (possiblyErrorResponse.error) {
                            throw possiblyErrorResponse;
                        }

                        return receiveActiveCallDetails(response as ReceiveActiveCallPayload);
                    }),
                    catchError((error: ApiErrorI): Observable<Action<INotificationMessageType | null>> => {
                        if (error.response_code === 404) {
                            return of(
                                notyError(error.message),
                                receiveErrorActiveCallDetails(null),
                            );
                        }

                        return EMPTY;
                    }),
                ),
            ),
        ),

    (action$: Observable<Action<RequestActiveCallPayload>>,
        store$: StoreI,
        { activeCallApi: { terminateConference } }: InjectedDependencies): Observable<Action<IActiveCallPayload>> =>
        action$.pipe(
            ofType(END_CALL),
            mergeMap(({ payload }): Observable<Action<IActiveCallPayload>> =>
                terminateConference(payload).pipe(
                    map((response): Action<EndedCallPayload> => {
                        const possiblyErrorResponse = response as ApiErrorI;

                        if (possiblyErrorResponse.error) {
                            throw possiblyErrorResponse;
                        }

                        return endedCall(response as EndedCallPayload);
                    }),
                    catchError((error: ApiErrorI): Observable<Action<INotificationMessageType>> => of(
                        notyError(error.message),
                    )),
                ),
            ),
        ),

    (action$: Observable<Action<ChangeDurationPayload>>,
        store$: StoreI,
        { activeCallApi: { changeDuration } }: InjectedDependencies):
            Observable<Action<INotificationMessageType | ChangeDurationPayload>> =>
        action$.pipe(
            ofType(CHANGE_DURATION),
            mergeMap(({ payload }): Observable<Action<INotificationMessageType | ChangeDurationPayload>> =>
                changeDuration(payload).pipe(
                    map((response): Action<ChangeDurationPayload> => {
                        const possiblyErrorResponse = response as ApiErrorI;

                        if (possiblyErrorResponse.error) {
                            throw possiblyErrorResponse;
                        }

                        return changeDurationFinish(payload);
                    }),
                    catchError((error: ApiErrorI): Observable<Action<
                        INotificationMessageType
                        | ChangeDurationPayload
                    >> => of(
                        notyError(error.message),
                        changeDurationFinish({
                            ...payload,
                            duration: payload.previousDuration,
                        }),
                    )),
                ),
            ),
        ),

    (action$: Observable<Action<RequestActiveCallPayload>>): Observable<CallHistoryMethodAction> =>
        action$.pipe(
            ofType(ENDED_CALL),
            mapTo(push('/')),
        ),
);

const selectActiveCallState = (store: StoreI): ActiveCallState => store.activeCall;

export const selectActiveCallIsRequesting = createSelector(
    selectActiveCallState,
    ({ isRequesting }): boolean => isRequesting,
);

export const selectIsCallAffectRequesting = createSelector(
    selectActiveCallState,
    ({ isCallAffectRequesting }): boolean => isCallAffectRequesting,
);

export const selectIsDurationChanging = createSelector(
    selectActiveCallState,
    ({ isDurationChanging }): boolean => isDurationChanging,
);

export const selectActiveCall = createSelector(
    selectActiveCallState,
    ({ call }): ActiveCallI => call,
);

export const selectAddParticipants = createSelector(
    selectActiveCallState,
    ({ addParticipants }): ParticipantsHash => addParticipants,
);
