import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';
import './Icon_type_filter.css';

export interface WithTypeFilterProps {
  type?: 'filter';
}

export const withTypeFilter = withBemMod<WithTypeFilterProps>(
    'Icon',
    { type: 'filter' },
    (Icon): FC => ((props): ReactElement => (<Icon {...props} />)),
);

export default withTypeFilter;
