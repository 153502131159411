export const ru = {
    'of': 'из',
    'remaining': 'Осталось {minutes} мин',
    'passed-days': {
        many: '{count} дней',
        none: '',
        one: '{count} день',
        some: '{count} дня',
    },
    'passed-hours': '{passedHours} ч.',
    'passed-minutes': '{passedMinutes} мин',
    'less-than-minute': '< 1 мин',
};
