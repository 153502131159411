import { cn } from '@bem-react/classname';
import { compose } from '@bem-react/core';
import i18n from '@yandex-int/i18n';

import React, { ReactElement, FC } from 'react';
import {
    CopyText as CopyTextBase,
    withIconSquare as withCopyTextIconSquare,
    withReverse as withCopyTextReverse,
} from 'components/CopyText';

import { StreamInfoProps } from './ActiveCallScreen-StreamInfo.interface';

import * as keyset from '../ActiveCallScreen.i18n';
import './ActiveCallScreen-StreamInfo.css';

const i18nActiveCallScreen = i18n(keyset);

const CopyText = compose(
    withCopyTextIconSquare,
    withCopyTextReverse,
)(CopyTextBase);

const cnActiveCallScreen = cn('ActiveCallScreen');

export const StreamInfo: FC<StreamInfoProps> =
    (props: StreamInfoProps): ReactElement => {
        const {
            stream,
            templateId,
            id,
            onCopyStreamLinkText,
        } = props;

        if (!stream) return (<span className={cnActiveCallScreen('StreamInfo', { type: 'noStream' })}>{i18nActiveCallScreen('no-streaming')}</span>);

        const streamPath = templateId ? `/template/${templateId}/stream` : `/streams/${id}`;

        return (
            <CopyText
                className={cnActiveCallScreen('StreamInfo', { type: 'stream' })}
                textToCopy={`${window.location.origin}${streamPath}`}
                textToDisplay={i18nActiveCallScreen('streaming')}
                onCopyText={onCopyStreamLinkText}
                icon="square"
                reverse
            />
        );
    };
