import apiRxjs, { ApiErrorI } from 'util/api.rxjs';
import { Observable } from 'rxjs';

import {
    GenerateLinkPayload,
    GetInviteCallLinkParamsSuccessResponse,
    CheckLinkPayload,
    CheckLinkSuccessPayload,
} from './inviteCallLink';

export const getInviteLinkParams =
    (payload: GenerateLinkPayload): Observable<GetInviteCallLinkParamsSuccessResponse | ApiErrorI> => {
        const {
            eventId,
            force,
        } = payload;

        const url = new URL(`/frontapi/events/${eventId}/generate_secret/`, window.location.origin);

        if (force) {
            url.searchParams.set('force', `${force}`);
        }

        return apiRxjs.post(url);
    };

export const checkInviteLink =
    (payload: CheckLinkPayload): Observable<CheckLinkSuccessPayload | ApiErrorI> => {
        const {
            eventId,
            masterId,
            secret,
        } = payload;

        const url = new URL(`frontapi/events/${eventId}/invite/`, window.location.origin);

        url.searchParams.set('master_id', `${masterId}`);
        url.searchParams.set('secret', secret);

        return apiRxjs.get(url);
    };

export interface InviteCallLinkApi {
    getInviteLinkParams: typeof getInviteLinkParams;
    checkInviteLink: typeof checkInviteLink;
}

export const inviteCallLinkApi: InviteCallLinkApi = {
    getInviteLinkParams,
    checkInviteLink,
};
