import React, { FC, ReactElement } from 'react';
import { withBemMod } from '@bem-react/core';

import { cnHeader } from '../Header@desktop';
import { HeaderProps } from '../Header.interface';
import { serviceLogoSrc, serviceNameLogoSrc } from '../Header.constants';
const { scripts } = require('../scripts');

const { toolsHeader } = scripts;

export interface WithNoAuthDesktop {
    noAuth?: boolean;
}

const getHeaderNoAuthHTML = (): string => (toolsHeader.desktop({
    ctx: {
        formAction: '//yandex.ru/search/',
        serviceLogoUrl: '/',
        serviceNameLogoUrl: '/',
        serviceLogoSrc,
        serviceNameLogoSrc,
        tools: true,
    },
}));

export const withNoAuthDesktop = withBemMod<WithNoAuthDesktop, HeaderProps>(
    cnHeader(),
    { noAuth: true },
    (Header): FC<HeaderProps & WithNoAuthDesktop> => (
        (props): ReactElement => {
            const replacer = (
                <div
                    className={cnHeader()}
                    dangerouslySetInnerHTML={{
                        __html: getHeaderNoAuthHTML(),
                    }}
                />
            );

            return (
                <Header
                    {...props}
                    replacer={replacer}
                />
            );
        }),
);
