import { compose, withBemMod } from '@bem-react/core';
import React, { ReactElement, FC } from 'react';

import {
    Player as PlayerBase,
    withTypeEther as withPlayerTypeEther,
} from 'components/Player';

import { cnKhStreamScreen } from '../KhStreamScreen.cn';
import { KhStreamScreenProps } from '../KhStreamScreen.interface';

export interface WithTypeEther {
  type?: 'ether',
}

const Player = compose(
    withPlayerTypeEther,
)(PlayerBase);

export const withTypeEther = withBemMod<WithTypeEther, KhStreamScreenProps>(
    cnKhStreamScreen(),
    { type: 'ether' },
    (KhStreamScreen): FC<WithTypeEther & KhStreamScreenProps> => (
        (props: WithTypeEther & KhStreamScreenProps): ReactElement => {
            const {
                etherUrl,
                playerType,
            } = props;

            const player = (
                <Player
                    className={cnKhStreamScreen('Player')}
                    etherUrl={etherUrl}
                    type="ether"
                    audioOnly={playerType === 'audio'}
                />
            );

            return (
                <KhStreamScreen
                    {...props}
                    player={player}
                />
            );
        }
    ),
);

export default withTypeEther;
