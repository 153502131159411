import React, { FC, ReactElement } from 'react';
import { Route, Switch } from 'react-router-dom';

import { KhPage } from 'khural-components/KhPage';
import { KhAnnouncementScreen, ANNOUNCEMENT_TYPE } from 'khural-components/KhAnnouncementScreen';
import { KhStreamScreen } from 'khural-components/KhStreamScreen';
import { ChatScreen } from 'components/ChatScreen';

export const appRoutes = {
    announcement: '/',
    announcementChat: '/chat',
    stream: '/streams/:id',
    chat: '/streams/:id/chat',
};

export const KhRoutes: FC = (): ReactElement => {
    return (
        <Switch>
            <Route path={appRoutes.announcement} exact>
                <KhPage contentTheme="gray">
                    <KhAnnouncementScreen announcementType={ANNOUNCEMENT_TYPE.STREAM} />
                </KhPage>
            </Route>
            <Route path={appRoutes.announcementChat} exact>
                <KhPage contentTheme="gray">
                    <KhAnnouncementScreen announcementType={ANNOUNCEMENT_TYPE.CHAT} />
                </KhPage>
            </Route>
            <Route path={appRoutes.stream} exact>
                <KhPage contentTheme="gray">
                    <KhStreamScreen />
                </KhPage>
            </Route>
            <Route path={appRoutes.chat} exact>
                <ChatScreen />
            </Route>
        </Switch>
    );
};
