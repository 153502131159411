import { createClassNameModifier } from '@bem-react/core';

import './Button_responsive.css';

export interface WithResponsiveProps {
  responsive?: true;
}

export const withResponsive = createClassNameModifier<WithResponsiveProps>('Button2', { responsive: true });

export default withResponsive;
