import i18n from '@yandex-int/i18n';
import { compose, composeU, withBemMod } from '@bem-react/core';
import { useComponentRegistry } from '@bem-react/di';

import { Icon as IconBase } from '@yandex-lego/components/Icon/desktop';
import { Button as ButtonBase } from '@yandex-lego/components/Button/desktop/bundle';
import React, { FC, ReactElement } from 'react';

import { usePlatformContext } from 'hooks';

import {
    withTypeEndCall as withIconTypeEndCall,
    withToneWhite as withIconToneWhite,
} from 'components/Icon';

import {
    withResponsive as withButtonResponsive,
    withToneRedLight as withButtonToneRedLight,
    withToneGreen as withButtonToneGreen,
    withToneYellow as withButtonToneYellow,
} from 'components/Button';

import { UserCallControlProps } from '../UserCallControl.interface';
import {
    cnUserCallControl,
    registryId,
} from '../UserCallControl';
import * as keyset from '../UserCallControl.i18n';

export interface WithTypeDisconnectProps {
  type?: 'disconnect';
  onDisconnectCurrentUser?: () => void;
}

const i18nUserCallControl = i18n(keyset);

const Button = compose(
    withButtonResponsive,
    composeU(
        withButtonToneRedLight,
        withButtonToneGreen,
        withButtonToneYellow,
    ),
)(ButtonBase);

const Icon = compose(
    withIconToneWhite,
    withIconTypeEndCall,
)(IconBase);

const iconEndCall = (iconClassName: string): ReactElement => <Icon type="endCall" tone="white" className={iconClassName} />;

export const withTypeDisconnect = withBemMod<WithTypeDisconnectProps, UserCallControlProps>(
    cnUserCallControl('UserCallControl'),
    { type: 'disconnect' },
    (ActiveCallScreenUserCallControl): FC<WithTypeDisconnectProps & UserCallControlProps> => (
        (props): ReactElement => {
            const {
                currentUser,
                onDisconnectCurrentUser,
                isCallAffectRequesting,
            } = props;

            const {
                Participant,
            } = useComponentRegistry(registryId);

            const platform = usePlatformContext();

            return (
                <ActiveCallScreenUserCallControl {...props}>
                    <Participant
                        className={cnUserCallControl('Participant')}
                        type={currentUser.type}
                        id={currentUser.id}
                        title={currentUser.name}
                        value={currentUser.method}
                        options={currentUser.action}
                        methodSelect
                        changeSelectedDisabled
                    />
                    <Button
                        view="pseudo"
                        size={platform === 'mobile' ? 'm' : 's' as undefined}
                        tone="redLight"
                        icon={iconEndCall}
                        responsive
                        className={cnUserCallControl('Button')}
                        onClick={onDisconnectCurrentUser}
                        disabled={isCallAffectRequesting}
                    >
                        {i18nUserCallControl('disconnect-button')}
                    </Button>
                </ActiveCallScreenUserCallControl>
            );
        }
    ),
);

export default withTypeDisconnect;
