import i18n from '@yandex-int/i18n';
import { useRouteMatch } from 'react-router';
import React, { FC, ReactElement, useMemo } from 'react';

import { useTitle } from 'hooks';

import { ErrorScreenMatchParams } from './ErrorScreen.interface';
import { ErrorScreen as ErrorScreenView } from './ErrorScreen';
import * as keyset from './ErrorScreen.i18n';

const i18nErrorScreen = i18n(keyset);

export const ErrorScreen: FC = (): ReactElement => {
    const match = useRouteMatch<ErrorScreenMatchParams>();

    const { code } = match.params;

    const title = useMemo(() => {
        const parts = [i18nErrorScreen('error-screen-title')];

        if (code) {
            parts.unshift(`${code}`);
        }

        return parts;
    }, [code]);

    useTitle(title);

    return <ErrorScreenView code={Number(code)} />;
};
