import { cn } from '@bem-react/classname';
import React, { FC, ReactElement } from 'react';

import { StaffNameProps } from './StaffName.interface';
import './StaffName.css';

export const cnStaffName = cn('StaffName');

export const StaffName: FC<StaffNameProps> = React.forwardRef<HTMLSpanElement, StaffNameProps>(
    (props: StaffNameProps, ref): ReactElement => {
        const {
            name,
            className,
        } = props;

        return (
            <span className={cnStaffName(null, [className])} ref={ref}>{name}</span>
        );
    });

export default StaffName;
