import i18n from '@yandex-int/i18n';
import { compose } from '@bem-react/core';

import { Button as ButtonBase } from '@yandex-lego/components/Button/desktop/bundle';
import {
    Icon as IconBase,
} from '@yandex-lego/components/Icon/desktop';
import React, { FC, ReactElement } from 'react';
import { withResponsive as withButtonResponsive } from 'components/Button';
import {
    withTypeCalendarAwesome as withIconTypeCalendarAwesome,
} from 'components/Icon';

import { Duration } from 'components/Duration';

import {
    CopyText as CopyTextBase,
    withIconSquare,
    withReverse,
} from 'components/CopyText';

import { ActiveCallScreenParametersProps } from './ActiveCallScreen-Parameters.interface';
import { cnActiveCallScreen } from '../ActiveCallScreen';
import './ActiveCallScreen-Parameters.css';
import { StreamInfo } from '../StreamInfo/ActiveCallScreen-StreamInfo@desktop';
import { RecordInfo } from '../RecordInfo/ActiveCallScreen-RecordInfo';
import * as keyset from '../ActiveCallScreen.i18n';

const i18nActiveCallScreen = i18n(keyset);

const CopyText = compose(
    withIconSquare,
    withReverse,
)(CopyTextBase);

const Icon = withIconTypeCalendarAwesome(IconBase);

const Button = withButtonResponsive(ButtonBase);

const iconCalendarAwesome = (iconClassName: string): ReactElement => (<Icon type="calendarAwesome" className={iconClassName} />);

export const ActiveCallScreenParameters: FC<ActiveCallScreenParametersProps> =
  (props: ActiveCallScreenParametersProps): ReactElement => {
      const {
          id,
          isAdmin,
          eventId,
          templateId,
          calendarEventUrl,
          startTime,
          uri,
          record,
          stream,
          onCopyStreamLinkText,
          onCopyURIText,
          className,
          titleCalendarAwesome,
          duration,
          isDurationChanging,
          onDurationChange,
      } = props;

      return (
          <div className={cnActiveCallScreen('Parameters', [className])}>
              <div className={cnActiveCallScreen('Row')}>
                  <Duration
                      startingPointISOString={startTime}
                      duration={duration}
                      isDurationChanging={isDurationChanging}
                      onChange={onDurationChange}
                />
              </div>
              <div className={cnActiveCallScreen('Row')}>
                  {
                      isAdmin &&
                          <CopyText
                              textToCopy={uri}
                              textToDisplay={uri}
                              onCopyText={onCopyURIText}
                              title={i18nActiveCallScreen('title-copy-uri')}
                              icon="square"
                              reverse
                          />
                  }
                  <RecordInfo record={record} />
                  <StreamInfo
                      id={id}
                      stream={stream}
                      onCopyStreamLinkText={onCopyStreamLinkText}
                      templateId={templateId}
                  />
                  {
                      eventId !== null &&
                          <Button
                              size="s"
                              view="clear"
                              type="link"
                              url={calendarEventUrl}
                              icon={iconCalendarAwesome}
                              title={titleCalendarAwesome}
                              width="auto"
                              className={cnActiveCallScreen('ButtonCalendarAwesome')}
                          />
                  }
              </div>
          </div>
      );
  };
