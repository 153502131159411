import { useMediaMatch } from 'rooks';
import { Platform } from '@yandex-int/hr-components/utils';

export const useHrComponentsPlatform = () => {
    const isDesktop = useMediaMatch('(min-width: 768px)');

    return {
        platform: isDesktop ? Platform.DESKTOP : Platform.MOBILE,
        isMobile: !isDesktop,
        isDesktop,
    };
};
