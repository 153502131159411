import { ChangeEventHandler, ReactElement } from 'react';
import { IClassNameProps } from '@bem-react/core';

export enum InformerState {
    VIEWED = 'viewed'
}

export interface KhStreamScreenRateInformerLSItem {
    date: string;
    state: InformerState;
}

export interface KhStreamScreenRateInformerLS {
    [id: string]: KhStreamScreenRateInformerLSItem;
}

export interface KhStreamScreenRateInformerContainerProps {
    name: string;
    uri: string;
    stopTime: string;
    env: string;
}

export interface KhStreamScreenRateInformerProps extends IClassNameProps {
    rating: number;
    message: string;
    onClose: FunctionVoid;
    onRatingChange: (value: number) => void;
    onMessageChange: ChangeEventHandler<HTMLTextAreaElement>;
    replacer?: ReactElement;
    trailing?: ReactElement;
    actionButtons?: ReactElement;
}
