import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';
import './Icon_type_mail.css';

export interface WithTypeMailProps {
  type?: 'mail';
}

export const withTypeMail = withBemMod<WithTypeMailProps>(
    'Icon',
    { type: 'mail' },
    (Icon): FC => ((props): ReactElement => (<Icon {...props} />)),
);

export default withTypeMail;
