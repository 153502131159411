import { cn } from '@bem-react/classname';
import React, { FC, ReactElement } from 'react';

import { UnitProps } from './Unit.interface';

const cnUnit = cn('Unit');

const Unit: FC<UnitProps> = (props: UnitProps): ReactElement => {
    const { children, className } = props;
    return <div className={cnUnit(null, [className])}>{children}</div>;
};

export default Unit;
