import { IClassNameProps } from '@bem-react/core';
import React, { FC } from 'react';

import { Link } from 'shared/ui/components/Link';

import { cnKhFooter } from './KhFooter.cn';
import { i18n } from './KhFooter.translations';

import './KhFooter.css';

export type KhFooterProps = IClassNameProps

export const KhFooter: FC<KhFooterProps> = ({ className }) => {
    const currentYear = new Date().getFullYear();

    return (
        <div className={cnKhFooter(null, [className])}>
            <div className={cnKhFooter('Content')}>
                <div className={cnKhFooter('Section', { type: 'links' })}>
                    <Link
                        theme="normal"
                        href={'https://wiki.yandex-team.ru/askdir/'}
                        className={cnKhFooter('Link')}
                        target="_blank"
                    >
                        AskDir
                    </Link>
                    <Link
                        theme="normal"
                        href={'https://clubs.at.yandex-team.ru/mag/posts.xml?tag=14285'}
                        className={cnKhFooter('Link')}
                        target="_blank"
                    >
                        {i18n('last-streams')}
                    </Link>
                    <Link
                        theme="normal"
                        href={'https://q.yandex-team.ru/#/join/531ecedb-942e-4816-8d2f-450546f51b7e'}
                        className={cnKhFooter('Link')}
                        target="_blank"
                    >
                        {i18n('text-stream')}
                    </Link>
                </div>
                <div className={cnKhFooter('Section', { type: 'copyright' })}>
                    {i18n('yandex')} {currentYear} ©
                </div>
            </div>
        </div>
    );
};
