import { withBemMod } from '@bem-react/core';

import { Spin } from '@yandex-lego/components/Spin/bundle';
import React, { FC, ReactElement } from 'react';

import { cnKhAnnouncementScreen } from '../KhAnnouncementScreen.cn';
import { KhAnnouncementScreenProps } from '../KhAnnouncementScreen.interface';

import './KhAnnouncementScreen_loading.css';

export interface WithLoading {
    loading?: boolean;
}

export const withLoading = withBemMod<WithLoading, KhAnnouncementScreenProps>(
    cnKhAnnouncementScreen(),
    { loading: true },
    (KhAnnouncementScreen): FC<WithLoading & KhAnnouncementScreenProps> => (
        (props: WithLoading & KhAnnouncementScreenProps): ReactElement => {
            const replacer = (
                <div className={cnKhAnnouncementScreen('Spinner')}>
                    <Spin
                        size="l"
                        view="default"
                        progress
                    />
                </div>
            );

            return (
                <KhAnnouncementScreen
                    {...props}
                    replacer={replacer}
                />
            );
        }
    ),
);
