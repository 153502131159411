export const ru = {
    'collect-call': 'Собрать звонок',
    'call-is-broadcast': 'Транслируется',
    'call-is-recorded': 'Записывается',
    'show-call-details': 'Показать детали звонка',
    'table-active-calls-copy': 'Скопировать ссылку',
    'table-active-calls-copy-call-link': 'Скопировать ссылку на звонок',
    'table-active-calls-copy-stream-link': 'Транслируется (кликните для копирования ссылки на трансляцию)',
    'table-active-calls-go-to-stream-page': 'Перейти на страницу трансляции',
    'table-active-calls-stream-copy-message': 'Ссылка на трансляцию скопирована',
    'table-active-calls-share-link-copy-message': 'Ссылка на звонок скопирована',
    'show-calendar-awesome': 'Перейти ко встрече в календаре',
    'delete-record-title': 'Удалить запись',
    'zoom-conf': 'Перейти к ZOOM конференции',
    'telemost-conf': 'Перейти к Telemost конференции',
};
