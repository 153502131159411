import React, { FC, ReactElement } from 'react';

import { cnStreamPoster } from '../StreamPoster';
import { StreamPosterDurationProps } from './StreamPoster-Duration.interface';
import './StreamPoster-Duration.css';

export const StreamPosterDuration: FC<StreamPosterDurationProps> = (props: StreamPosterDurationProps): ReactElement => {
    const {
        startTime,
        children,
        className,
    } = props;

    if (startTime) {
        return (
            <div className={cnStreamPoster('Duration', [className])}>
                {children}
            </div>
        );
    }

    return null;
};
