import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';
import './Icon_type_messengerQ.css';

export interface WithTypeMessengerQProps {
  type?: 'messengerQ';
}

export const withTypeMessengerQ = withBemMod<WithTypeMessengerQProps>(
    'Icon',
    { type: 'messengerQ' },
    (Icon): FC => ((props): ReactElement => (<Icon {...props} />)),
);

export default withTypeMessengerQ;
