import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import { cnBubble, BubbleProps } from '../Bubble';

import './Bubble_theme_clear.css';

export interface BubbleThemeClear extends BubbleProps {
  theme: 'clear'
}

export const withThemeClear = withBemMod<BubbleThemeClear>(
    cnBubble(),
    { theme: 'clear' },
    (Bubble): FC<BubbleThemeClear> => (props: BubbleThemeClear): ReactElement => (
        <Bubble {...props} />
    ),
);

export default withThemeClear;
