import { createAction, handleActions, Action } from 'redux-actions';
import { StoreI } from 'ducks/store';

export type TitleState = string;

export const initialState: TitleState = 'vconf';

export type RequestTitlePayload = string;

const SET_TITLE = 'vconf/title/SET_TITLE';

export const setTitle = createAction<RequestTitlePayload>(SET_TITLE);

export const reducer = handleActions<TitleState, RequestTitlePayload>({
    [SET_TITLE]: (state, action: Action<RequestTitlePayload>): TitleState => {
        const {
            payload,
        } = action;

        return payload;
    },
}, initialState);

export const selectTitle = (store: StoreI): TitleState => store.title;
