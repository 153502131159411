import apiRxjs, { ApiErrorI } from 'util/api.rxjs';
import { Observable } from 'rxjs';
import { Payload } from 'ducks/stream';

interface GetStreamDetails {
  (id: string): Observable<Payload | ApiErrorI>
}
export interface StreamApi {
    getStreamDetails: GetStreamDetails
    getStreamDetailsPermanent: GetStreamDetails
}

export const getStreamDetails: GetStreamDetails = (id): Observable<Payload | ApiErrorI> =>
    apiRxjs.get(`/frontapi/calls/${id}/`);

export const getStreamDetailsPermanent: GetStreamDetails = (id): Observable<Payload | ApiErrorI> =>
    apiRxjs.get(`/frontapi/templates/${id}/stream/`);

export const streamApi = {
    getStreamDetails,
    getStreamDetailsPermanent,
};

export default getStreamDetails;
