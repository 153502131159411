import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import { cnParticipantAvatar } from '../ParticipantAvatar';
import { ParticipantAvatarProps } from '../ParticipantAvatar.interface';

import arbitraryAvatar from './assets/phone.svg';

interface WithTypeArbitraryProps {
  type?: 'arbitrary';
}

export const withTypeArbitrary = withBemMod<WithTypeArbitraryProps, ParticipantAvatarProps>(
    cnParticipantAvatar(),
    { type: 'arbitrary' },
    (ParticipantAvatar): FC<ParticipantAvatarProps & WithTypeArbitraryProps> => (
        (props: ParticipantAvatarProps & WithTypeArbitraryProps): ReactElement => {
            const image = (
                <img
                    className={cnParticipantAvatar('Image')}
                    src={arbitraryAvatar}
                    alt="avatar"
        />
            );

            return (
                <ParticipantAvatar
                    {...props}
                    replacer={image}
        />
            );
        }
    ),
);

export default withTypeArbitrary;
