import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import { ErrorDetailsEvent } from 'ducks/inviteCallLink';

import { InviteCallLinkScreenCallInfoProps } from '../InviteCallLinkScreen-CallInfo.interface';
import { cnInviteCallLinkScreen, i18nInviteCallLinkScreen } from '../../InviteCallLinkScreen';

export interface WithTypeUnauthorized {
    type?: 'unauthorized';
    event?: ErrorDetailsEvent;
}

export const withTypeUnauthorized = withBemMod<WithTypeUnauthorized, InviteCallLinkScreenCallInfoProps>(
    cnInviteCallLinkScreen('CallInfo'),
    { type: 'unauthorized' },
    (CallInfo): FC<WithTypeUnauthorized & InviteCallLinkScreenCallInfoProps> => (
        (props: WithTypeUnauthorized & InviteCallLinkScreenCallInfoProps): ReactElement => {
            const {
                event,
            } = props;

            const addonBefore = (
                <div className={'EventName'}>
                    {event.name}
                </div>
            );

            const addonAfter = (
                <div className={cnInviteCallLinkScreen('Annotation')}>{i18nInviteCallLinkScreen('annotation')}</div>
            );

            return (
                <CallInfo
                    {...props}
                    addonBefore={addonBefore}
                    addonAfter={addonAfter}
                />
            );
        }
    ),
);
