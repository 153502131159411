export const en = {
    'table-events-title': 'Nearest from Calendar',
    'button-collect-call': 'Collect call',
    'history-button-download-record': 'Download record',
    'history-button-play-record': 'Play record',
    'history-button-recollect-call': 'Recollect call',
    'table-active-calls-title': 'Active',
    'show-all-active-calls-label': 'Show all',
    'empty-active-calls-table-text': 'You have no calls',
    'empty-calendar-events-table-text': 'You have no meetings',
    'show-calendar-awesome': 'Go to calendar event',
    'show-share': 'Share',
    'show-go-to-chat': 'Go to chat',
    'show-TV': "TV",
    'show-open-stream': "Open stream",
    'on': 'On',
    'off': 'Off',
    'table-streams-copy-link-title-permanent': 'Permanent link to the stream',
    'table-streams-copy-link-title': 'Link to the stream',
    'copy': 'Copy',
    'stream-start-time': 'Start time',
    'stream-lasts': 'Lasts',
    'stream-name': 'Name',
    'stream-viewers': 'Viewers',
    'table-show-more': 'Show more',
    'delete-record-title': 'Delete record',
    'delete-record-confirm-text': 'Delete',
    'delete-record-cancel-text': 'Cancel',
    'filters-all': 'All',
    'filters-with-records': 'With records',
    'column-title-datetime': 'Datetime',
    'column-title-name': 'Name and code',
    'column-title-organizer': 'Organizer',
    'column-title-participants': 'Participants',
    'column-title-record': 'Record',
};
