import { Icon as IconBase } from '@yandex-lego/components/Icon/desktop';
import { Link } from '@yandex-lego/components/Link/desktop/bundle';
import { compose } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import {
    withTypeYandexCalendar as withIconTypeYandexCalendar,
    withSizeM as withIconSizeM,
} from 'components/Icon';

import { cnPermanentLinkScreen } from '../PermanentLinkScreen.cn';

import { GoToCalendarProps } from './PermanentLinkScreen-GoToCalendar.interface';
import './PermanentLinkScreen-GoToCalendar.css';

const Icon = compose(
    withIconTypeYandexCalendar,
    withIconSizeM,
)(IconBase);

export const GoToCalendar: FC<GoToCalendarProps> = (props): ReactElement => {
    const {
        eventName,
        eventId,
    } = props;
    if (eventId && eventName) {
        return (
            <Link
                className={cnPermanentLinkScreen('GoToCalendar')}
                href={`https://calendar.yandex-team.ru/event/${eventId}`}
                theme="black"
            >
                <div className={cnPermanentLinkScreen('EventName')}>{eventName}</div>
                <Icon
                    size="m"
                    type="yandexCalendar"
                />
            </Link>
        );
    }

    return null;
};

export default GoToCalendar;
