import { cn } from '@bem-react/classname';
import i18n from '@yandex-int/i18n';

import React, { ReactElement, FC } from 'react';

import { RecordInfoProps } from './ActiveCallScreen-RecordInfo.interface';

import * as keyset from '../ActiveCallScreen.i18n';
import './ActiveCallScreen-RecordInfo.css';

const i18nActiveCallScreen = i18n(keyset);

const cnActiveCallScreen = cn('ActiveCallScreen');

export const RecordInfo: FC<RecordInfoProps> =
    (props: RecordInfoProps): ReactElement => {
        const { record } = props;

        if (!record) {
            return (
                <span className={cnActiveCallScreen('RecordInfo', { type: 'noRecord' })}>
                    {i18nActiveCallScreen('no-recording')}
                </span>
            );
        }

        return (
            <span className={cnActiveCallScreen('RecordInfo', { type: 'record' })}>
                {i18nActiveCallScreen('recording')}
            </span>
        );
    };
