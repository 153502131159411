import { connect } from 'react-redux';
import { StoreI } from 'ducks/store';
import { changeSlideAction, connectAction, changePageAction } from 'ducks/clicker';
import { Dispatch, Action } from 'redux';
import ClickerView from './Clicker';
import { ClickerMapDispatchToProps, ClickerMapStateToProps } from './Clicker.interface';
import { getUser, selectCurrentUser, selectError } from '../../ducks/user';
import { getSortedMessages } from './Clicker.selectors/getSortedMessages';
import { notyWarning } from '../Notifications/Notifications.actions';

const clickerMapStateToProps = (store: StoreI): ClickerMapStateToProps => {
    return {
        env: selectCurrentUser(store)?.environment,
        messages: getSortedMessages(store),
        userError: selectError(store),
        isLoading: store.clicker.isLoading,
        currentPage: store.clicker.currentPage,
    };
};

const clickerMapDispatchToProps = (dispatch: Dispatch<Action>): ClickerMapDispatchToProps => ({
    onChangeSlide: (id, env, slide) => dispatch(changeSlideAction({ id, env, slide })),
    onConnect: (id, env) => dispatch(connectAction({ id, env })),
    getUser: () => dispatch(getUser()),
    showInactivityWarning: message => dispatch(notyWarning(message)),
    onChangePage: (page, id, env) => dispatch(changePageAction({ id, env, page })),
});

export const Clicker = connect<ClickerMapStateToProps, ClickerMapDispatchToProps>(
    clickerMapStateToProps,
    clickerMapDispatchToProps,
)(ClickerView);
