import { cn } from '@bem-react/classname';
import React, { FC, ReactElement } from 'react';

import { TableProps } from './Table.interface';
import './Table.css';

export const cnTable = cn('Table');

export const Table: FC<TableProps> = (props: TableProps): ReactElement => {
    const {
        className,
        header,
        addonBefore,
        addonAfter,
    } = props;

    return (
        <div className={cnTable({}, [className])}>
            { header }
            { addonBefore }
            { addonAfter }
        </div>
    );
};

export default Table;
