import { compose, composeU } from '@bem-react/core';
import {
    Icon as IconBase,
    withTypeClose as withIconTypeClose,
} from '@yandex-lego/components/Icon';
import { useComponentRegistry } from '@bem-react/di';
import i18n from '@yandex-int/i18n';
import React, { FC, ReactElement, useCallback, useState } from 'react';

import {
    withToneWhite as withIconToneWhite,
    withTypeEndCall as withIconTypeEndCall,
    withTypeUpdate as withIconTypeUpdate,
} from 'components/Icon';

import { DisconnectedParticipantsProps } from './DisconnectedParticipants.interface';
import { cnActiveCallScreen, registryId } from '../ActiveCallScreen';
import * as keyset from '../ActiveCallScreen.i18n';

const i18nActiveCallScreen = i18n(keyset);

const Icon = compose(
    withIconToneWhite,
    composeU(
        withIconTypeClose,
        withIconTypeUpdate,
        withIconTypeEndCall,
    ),
)(IconBase);

const iconUpdate = (iconClassName: string): ReactElement => (<Icon type="update" className={iconClassName} />);

export const DisconnectedParticipants: FC<DisconnectedParticipantsProps> = (props: DisconnectedParticipantsProps) => {
    const {
        disconnectedParticipantsList,
        onRecallToDisconnectedParticipant,
        onRemoveDisconnectedParticipant,
        onChangeDisconnectedParticipantSelectedMethod,
        disconnectedParticipantsCount,
        onRecallToAllDisconnectedParticipants,
    } = props;

    const [isDisconnectedParticipantsButtonsHidden, setIsDisconnectedParticipantsButtonsHidden] = useState(false);

    const onDisconnectedParticipantsCollapseCallback = useCallback((isCollapsed): void => {
        setIsDisconnectedParticipantsButtonsHidden(isCollapsed);
    }, []);

    const {
        ParticipantsList,
        FormControlButton,
    } = useComponentRegistry(registryId);

    return (
        <div className={cnActiveCallScreen('DisconnectedParticipants')}>
            <ParticipantsList
                title={i18nActiveCallScreen('disconnect-list-title')}
                data={disconnectedParticipantsList}
                type="recall"
                onRecallParticipant={onRecallToDisconnectedParticipant}
                onDeleteParticipant={onRemoveDisconnectedParticipant}
                onChangeParticipantSelectedMethod={onChangeDisconnectedParticipantSelectedMethod}
                isListHidden={disconnectedParticipantsCount === 0}
                onCollapseCallback={onDisconnectedParticipantsCollapseCallback}
                collapse
                isCollapsible
                isCollapsed={false}
            />
            {
                (disconnectedParticipantsCount !== 0 && !isDisconnectedParticipantsButtonsHidden) && (
                    <div className={cnActiveCallScreen('DisconnectedParticipantsButtons')}>
                        <FormControlButton
                            theme="normal"
                            view="classic"
                            size="s"
                            icon={iconUpdate}
                            onClick={onRecallToAllDisconnectedParticipants}
                            responsive
                        >
                            {i18nActiveCallScreen('recall-all-button')}
                        </FormControlButton>
                    </div>
                )
            }
        </div>
    );
};
