import { withBemMod } from '@bem-react/core';

import {
    Radiobox,
    RadioOptionProps,
} from '@yandex-lego/components/Radiobox/desktop/bundle';
import React, { FC, ReactElement } from 'react';

import { getMethods } from '../MethodSelector.util';
import { WithTypeRadio } from './MethodSelector_type_radio.interface';
import { MethodSelectorProps } from '../MethodSelector.interface';
import { cnMethodSelector } from '../MethodSelector';

export const withTypeRadio = withBemMod<WithTypeRadio, MethodSelectorProps>(
    cnMethodSelector(),
    { type: 'radio' },
    (MethodSelector): FC<MethodSelectorProps & WithTypeRadio> => (
        (props: MethodSelectorProps & WithTypeRadio): ReactElement => {
            const {
                value,
                options: inputOptions = [],
                onChange,
                size,
                disabled,
            } = props;

            const methods = getMethods();

            const options = inputOptions.map((option): RadioOptionProps => ({
                label: methods[option],
                value: option,
            }));

            return (
                <MethodSelector
                    {...props}
                >
                    <Radiobox
                        size={size}
                        onChange={onChange}
                        value={value}
                        options={options}
                        view="default"
                        disabled={disabled}
                    />
                </MethodSelector>
            );
        }
    ),
);

export default withTypeRadio;
