import { withBemMod, compose } from '@bem-react/core';
import i18n from '@yandex-int/i18n';
import { Button } from '@yandex-lego/components/Button/desktop/bundle';
import { Icon as IconBase } from '@yandex-lego/components/Icon/desktop';
import React, { FC, ReactElement } from 'react';
import {
    withTypeHandset,
} from 'components/Icon';

import {
    Stub as StubBase,
    withTypeNoConnection,
} from 'components/Stub';

import { cnActiveCallScreen } from '../ActiveCallScreen';
import { ActiveCallScreenProps } from '../ActiveCallScreen.interface';
import { WithEmpty } from './ActiveCallScreen_empty.interface';
import './ActiveCallScreen_empty.css';
import * as keyset from '../ActiveCallScreen.i18n';

const i18nActiveCallScreenEmpty = i18n(keyset);

const Icon = compose(
    withTypeHandset,
)(IconBase);

const Stub = compose(
    withTypeNoConnection,
)(StubBase);

const iconHandset = (iconClassName: string): ReactElement => <Icon type="handset" className={iconClassName} />;

export const withEmpty = withBemMod<WithEmpty, ActiveCallScreenProps>(
    cnActiveCallScreen(),
    { empty: true },
    (ActiveCallScreen): FC<WithEmpty & ActiveCallScreenProps> => (
        (props: WithEmpty & ActiveCallScreenProps): ReactElement => {
            const {
                onStubButtonClick,
            } = props;

            const replacer = (
                <div className={cnActiveCallScreen('Stub')}>
                    <Stub
                        type="noConnection"
                        title={i18nActiveCallScreenEmpty('call-is-not-available')}
                        button={(
                            <Button
                                size="m"
                                view="action"
                                iconLeft={iconHandset}
                                onClick={onStubButtonClick}
                            >
                                {i18nActiveCallScreenEmpty('create-call')}
                            </Button>
                        )}
                    />
                </div>
            );

            return (
                <ActiveCallScreen
                    {...props}
                    replacer={replacer}
                />
            );
        }
    ),
);

export default withEmpty;
