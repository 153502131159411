import { cn } from '@bem-react/classname';
import { getI18nLang } from '@yandex-int/i18n';
import React, { FC, ReactElement } from 'react';

const toolsLamp = require('@yandex-lego/serp-header/dist/base/tools-lamp.desktop');

const cnYandexLamp = cn('YandexLamp');

export const YandexLamp: FC = (): ReactElement => {
    return (
        <div
            className={cnYandexLamp()}
            dangerouslySetInnerHTML={{
                __html: toolsLamp.getContent({
                    lang: getI18nLang(),
                    ctx: {
                        url: 'https://st.yandex-team.ru/dashboard/32977',
                    },
                }),
            }}
        />
    );
};
