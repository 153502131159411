import { cn } from '@bem-react/classname';
import React, { FC, ReactElement } from 'react';

import { Footer } from 'components/Footer';
import { PageVisibility } from 'components/PageVisibility';
import { TitleManager } from 'components/TitleManager';
import YandexBug from 'components/YandexBug';
import { YandexLamp } from 'components/YandexLamp';
import Notifications from 'components/Notifications';

import { Header } from './Header';
import { PageProps } from './Page.interface';
import './Page.css';

export const cnPage = cn('Page');

export const Page: FC<PageProps> = (props): ReactElement => {
    const {
        theme,
        isAuthorized,
        replacer,
        children,
        className,
    } = props;

    return (
        <div className={cnPage({ theme }, [className])}>
            <PageVisibility />
            <TitleManager />
            {
                replacer || (
                    <>
                        <Header isAuthorized={isAuthorized} />
                        {
                            children && (
                                <div className={cnPage('Content')}>
                                    {children}
                                </div>
                            )
                        }
                        <Footer />
                        <Notifications />
                        <YandexBug />
                        <YandexLamp />
                    </>
                )
            }
        </div>
    );
};
