import apiRxjs, { ApiErrorI } from 'util/api.rxjs';
import { Observable } from 'rxjs';

import { User } from './user';

export const getUserData = (): Observable<User | ApiErrorI> => {
    return apiRxjs.get('/frontapi/participants/current/');
};

export interface UserApi {
    getUserData: () => Observable<User | ApiErrorI>;
}

export const userApi: UserApi = {
    getUserData,
};
