import apiRxjs, { ApiErrorI } from 'util/api.rxjs';
import { Observable } from 'rxjs';

import { ParticipantI } from 'components/Participant/Participant.interface';
import { ActiveCallI } from 'components/ActiveCalls/ActiveCalls.interface';
import {
    ActiveActionI,
    RequestActiveCallPayload,
    RequestParticipantPayload,
    TerminateConferencePayload,
    AddParticipantPayload,
    ChangeDurationPayload,
} from './activeCall';

export const getActiveCall = ({ callId, secret }: RequestActiveCallPayload): Observable<ActiveCallI | ApiErrorI> => {
    const url: URL = new URL(`frontapi/calls/${callId}`, window.location.origin);

    if (secret) {
        url.searchParams.set('secret', secret);
    }

    return apiRxjs.get(url);
};

type IParticipantDetails = ParticipantI[] | ApiErrorI;

export const getParticipantDetails = (participants: RequestParticipantPayload): Observable<IParticipantDetails> =>
    apiRxjs.post('/frontapi/calls/meta/', {
        body: JSON.stringify({
            participants: [...participants],
        }),
    });

const changeDuration = ({ callId, duration }: ChangeDurationPayload): Observable<ActiveCallI | ApiErrorI> => {
    const url: URL = new URL(`frontapi/calls/${callId}/duration/`, window.location.origin);

    return apiRxjs.post<ActiveCallI>(url, {
        body: JSON.stringify({ duration }),
    });
};

const terminateConference = ({ callId, secret }: TerminateConferencePayload): Observable<ActiveCallI | ApiErrorI> => {
    const url: URL = new URL(`frontapi/calls/${callId}/stop/`, window.location.origin);

    if (secret) {
        url.search = new URLSearchParams({ secret }).toString();
    }

    return apiRxjs.post<ActiveCallI>(url);
};

const postActiveParticipant =
    ({ callId, participants, secret }: AddParticipantPayload): Observable<ActiveCallI | ApiErrorI> => {
        const url: URL = new URL(`frontapi/calls/${callId}/add/`, window.location.origin);

        if (secret) {
            url.searchParams.set('secret', secret);
        }

        return apiRxjs.post<ActiveCallI>(url, {
            body: JSON.stringify({ participants }),
        });
    };

const participantPostAction =
    ({ action, callId, participants, secret }: ActiveActionI): Observable<ActiveCallI | ApiErrorI> => {
        const url: URL = new URL(`frontapi/calls/${callId}/${action}/`, window.location.origin);

        if (secret) {
            url.searchParams.set('secret', secret);
        }

        return apiRxjs.post<ActiveCallI>(url, {
            body: JSON.stringify(participants[0]),
        });
    };

export interface ActiveCallApi {
    getActiveCall: (payload: RequestActiveCallPayload) => Observable<ActiveCallI | ApiErrorI>;
    getParticipantDetails: (participants: RequestParticipantPayload) => Observable<ParticipantI[] | ApiErrorI>;
    terminateConference: (payload: TerminateConferencePayload) => Observable<ActiveCallI | ApiErrorI>;
    postActiveParticipant: ({ callId, participants, secret }: AddParticipantPayload) =>
        Observable<ActiveCallI | ApiErrorI>;
    participantPostAction: ({ action, callId, participants }: ActiveActionI) => Observable<ActiveCallI | ApiErrorI>;
    changeDuration: typeof changeDuration,
}

export const activeCallApi = {
    getActiveCall,
    getParticipantDetails,
    terminateConference,
    postActiveParticipant,
    participantPostAction,
    changeDuration,
};
