import React, { FC, ReactElement } from 'react';
import { cn } from '@bem-react/classname';

import { User } from 'ducks/user';

import { HeaderSettings } from './Settings/Header-Settings';

import { HeaderProps } from './Header.interface';
import { getMenuCtx } from './Header.util';
import {
    colorMenuActive,
    colorMenuActiveHover,
    serviceLogoSrc,
    serviceNameLogoSrc,
} from './Header.constants';

export const cnHeader = cn('Header');

const { scripts } = require('./scripts');

const {
    notifier,
    settings,
    toolsCrossNav,
    toolsHeader,
    toolsMenu,
    toolsUserAccount,
} = scripts;

const getMenuHTML = () => toolsMenu.mobile({ ctx: { items: getMenuCtx() } });

const getHeaderHTML = (user: User): string => {
    return toolsHeader.mobile({
        ctx: {
            tools: true,
            colorMenuActive,
            colorMenuActiveHover,
            serviceLogoSrc,
            serviceNameLogoSrc,
            serviceLogoUrl: '/',
            serviceNameLogoUrl: '/',
            formAction: '//yandex.ru/search/',
            burgerHTML: toolsCrossNav.mobile({
                ctx: {
                    env: 'yateam',
                    crossNavPopupHTML: getMenuHTML(),
                },
            }),
            userHTML: toolsUserAccount.mobile({
                ctx: {
                    login: user.id,
                },
            }),
            // https://st.yandex-team.ru/VCONF-914
            // chatHTML: chat.mobile.getContent({ ctx: { isYandexTeam: true } }),
            notifierHTML: notifier.mobile({ ctx: { utmSourceService: 'internal_vconf' } }),
            settingsHTML: settings.mobile({}),
        },
    });
};

export const HeaderMobile: FC<HeaderProps> = (props: HeaderProps): ReactElement | null => {
    const {
        user,
        isHeaderVisible,
        isSettingsVisible,
        settingsRef,
        onSettingsClose,
        replacer,
    } = props;

    if (!isHeaderVisible) return null;

    return (
        <>
            {
                replacer || (
                    <>
                        <div
                            className={cnHeader()}
                            dangerouslySetInnerHTML={{
                                __html: getHeaderHTML(user),
                            }}
                        />
                        <HeaderSettings
                            isVisible={isSettingsVisible}
                            onClose={onSettingsClose}
                            targetRef={settingsRef}
                        />
                    </>
                )
            }
        </>
    );
};
