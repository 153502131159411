import { cn } from '@bem-react/classname';
import React, { ReactElement } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import { CopyTextViewProps } from './CopyText.interface';
import './CopyText.css';

export const cnCopyText = cn('CopyText');

export const CopyText = (props: CopyTextViewProps): ReactElement => {
    const {
        textToCopy,
        textToDisplay,
        onCopy,
        className,
        addon,
        title,
        children,
    } = props;

    return (
        <CopyToClipboard
            onCopy={onCopy}
            text={textToCopy}
        >
            {
                children || (
                    <div className={cnCopyText('', [className])} title={title}>
                        { addon }
                        {
                          textToDisplay && (
                          <span className={cnCopyText('Text')}>{ textToDisplay }</span>
                          )
                        }
                    </div>
                )
          }
        </CopyToClipboard>
    );
};

export default CopyText;
