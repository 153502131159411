import React, { FC, ReactElement } from 'react';
import { connect } from 'react-redux';

import { StoreI } from 'ducks/store';
import {
    selectIsHidden,
} from 'ducks/yandexLamp';

import { YandexLamp as YandexLampView } from './YandexLamp';
import {
    YandexLampContainerProps,
    YandexLampMapStateToProps,
} from './YandexLamp.interface';

const YandexLampContainer: FC<YandexLampContainerProps> = (props: YandexLampContainerProps): ReactElement => {
    const {
        isHidden,
    } = props;

    if (isHidden) {
        return null;
    }

    return (
        <YandexLampView />
    );
};

export const YandexLamp = connect<YandexLampMapStateToProps>(
    (state: StoreI): YandexLampMapStateToProps => ({
        isHidden: selectIsHidden(state),
    }),
)(YandexLampContainer);
