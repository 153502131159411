import i18n from '@yandex-int/i18n';
import React, { FC, ReactElement } from 'react';

import { DurationSelector } from 'components/DurationSelector';

import { CreateCallFormDurationProps } from './CreateCallForm-Duration.interface';
import { cnCreateCallForm } from '../CreateCallForm';
import * as keyset from '../CreateCallForm.i18n';
import './CreateCallForm-Duration.css';

const i18nCallForm = i18n(keyset);

export const Duration: FC<CreateCallFormDurationProps> = (props): ReactElement => {
    const {
        duration,
        onDurationChange,
    } = props;

    return (
        <div className={cnCreateCallForm('Duration')}>
            {i18nCallForm('duration-label')}
            <DurationSelector
                value={duration}
                onChange={onDurationChange}
                size="s"
            />
        </div>
    );
};
