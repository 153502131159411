import { compose } from '@bem-react/core';
import i18n from '@yandex-int/i18n';
import { Button } from '@yandex-lego/components/Button/desktop/bundle';
import { Icon as IconBase } from '@yandex-lego/components/Icon';
import React, { ReactElement } from 'react';

import {
    Table as BaseTable,
    withLoading,
    withPagination,
    withStub,
    withTypeHistory,
} from 'components/Table';
import { withTypeHandset as withIconTypeHandset } from 'components/Icon';

import { CallsHistoryProps } from './CallsHistory.interface';
import * as keyset from './CallsHistory.i18n';

const i18nCallsHistory = i18n(keyset);

const HistoryTable = compose(
    withTypeHistory,
    withPagination,
    withLoading,
    withStub,
)(BaseTable);

const Icon = compose(
    withIconTypeHandset,
)(IconBase);

const iconHandset = (iconClassName: string): ReactElement => <Icon type="handset" size="m" className={iconClassName} />;

export const CallsHistory = (props: CallsHistoryProps): ReactElement => {
    const {
        data,
        isRequesting,
        user,
        onStubButtonClick,
        onChangeWithRecordFilter,
        getOnCollectCallClick,
        onRequestMore,
        isFirstMount,
        withRecords,
        isDeleteRecordModalVisible,
        onConfirmDeleteRecord,
        onCancelDeleteRecord,
        onDeleteRecordClick,
    } = props;

    const stubButton: ReactElement = (
        <Button
            size="m"
            view="action"
            iconLeft={iconHandset}
            onClick={onStubButtonClick}
        >
            {i18nCallsHistory('collect-call')}
        </Button>
    );

    return (
        <HistoryTable
            type="history"
            onChangeWithRecordFilter={onChangeWithRecordFilter}
            getOnCollectCallClick={getOnCollectCallClick}
            onRequestMore={onRequestMore}
            isFirstLoading={isFirstMount}
            isRequesting={isRequesting}
            stubButton={stubButton}
            data={data}
            isTableEmpty={data.length === 0}
            stub
            loading
            pagination
            withRecordsFilter={withRecords ? 'withRecords' : 'all'}
            stubTitle={i18nCallsHistory(`stub-title${withRecords && '-with-records'}`)}
            stubDescription={i18nCallsHistory('stub-description')}
            user={user}
            isDeleteRecordModalVisible={isDeleteRecordModalVisible}
            onConfirmDeleteRecord={onConfirmDeleteRecord}
            onCancelDeleteRecord={onCancelDeleteRecord}
            onDeleteRecordClick={onDeleteRecordClick}
        />
    );
};

export default CallsHistory;
