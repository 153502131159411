import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import {
    Stub as StubBase,
    withTypeNoConnection as withStubTypeNoConnection,
} from 'components/Stub';

import { InviteCallLinkScreenProps } from '../InviteCallLinkScreen.interface';
import {
    cnInviteCallLinkScreen,
    i18nInviteCallLinkScreen,
} from '../InviteCallLinkScreen';
import './InviteCallLinkScreen_error_unavailable.css';

export interface WithErrorUnavailable {
    error?: 'unavailable';
}

const Stub = withStubTypeNoConnection(StubBase);

export const withErrorUnavailable = withBemMod<WithErrorUnavailable, InviteCallLinkScreenProps>(
    cnInviteCallLinkScreen(),
    { error: 'unavailable' },
    (InviteCallLinkScreen): FC<WithErrorUnavailable & InviteCallLinkScreenProps> => (
        (props: WithErrorUnavailable & InviteCallLinkScreenProps): ReactElement => {
            const replacer = (
                <Stub
                    type="noConnection"
                    title={i18nInviteCallLinkScreen('unavailable-title')}
                    description={i18nInviteCallLinkScreen('unavailable-description')}
                />
            );

            return (
                <InviteCallLinkScreen
                    {...props}
                    replacer={replacer}
                />
            );
        }
    ),
);
