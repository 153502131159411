import { cn } from '@bem-react/classname';
import { compose } from '@bem-react/core';
import i18n from '@yandex-int/i18n';
import {
    Button as ButtonBase,
    withViewDefault as withButtonViewDefault,
    withSizeM as withButtonSizeM,
    withWidthMax as withButtonWidthMax,
} from '@yandex-lego/components/Button/desktop';
import {
    Icon as IconBase,
} from '@yandex-lego/components/Icon/desktop';

import React, { ReactElement, FC } from 'react';
import {
    CopyText as CopyTextBase,
    withIconChain,
    withReverse,
} from 'components/CopyText';

import { withResponsive as withButtonResponsive } from 'components/Button';
import {
    withSizeS as withIconSizeS,
    withTypeShare as withIconTypeShare,
} from 'components/Icon';
import { StreamInfoProps } from './ActiveCallScreen-StreamInfo.interface';
import * as keyset from '../ActiveCallScreen.i18n';
import './ActiveCallScreen-StreamInfo.css';

const i18nActiveCallScreen = i18n(keyset);

const Button = compose(
    withButtonSizeM,
    withButtonViewDefault,
    withButtonWidthMax,
    withButtonResponsive,
)(ButtonBase);

const Icon = compose(
    withIconTypeShare,
    withIconSizeS,
)(IconBase);

const CopyText = compose(
    withIconChain,
    withReverse,
)(CopyTextBase);

const cnActiveCallScreen = cn('ActiveCallScreen');

const iconShare = (iconClassName: string): ReactElement => (<Icon type="share" className={iconClassName} />);

export const StreamInfo: FC<StreamInfoProps> =
    (props: StreamInfoProps): ReactElement => {
        const {
            stream,
            templateId,
            id,
            onCopyStreamLinkText,
        } = props;

        const streamPath = templateId ? `/template/${templateId}/stream` : `/streams/${id}`;

        if (!stream) return (<span className={cnActiveCallScreen('StreamInfo', { type: 'noStream' })}>{i18nActiveCallScreen('no-streaming')}</span>);

        return (
            <>
                <span className={cnActiveCallScreen('StreamInfo', { type: 'streamMobile' })}>{i18nActiveCallScreen('streaming')}</span>
                <CopyText
                    textToCopy={`${window.location.origin}${streamPath}`}
                    onCopyText={onCopyStreamLinkText}
                >
                    <Button
                        size="m"
                        view="default"
                        width="max"
                        icon={iconShare}
                        responsive
                        className={cnActiveCallScreen('ShareStreamButton')}
                    >
                        {i18nActiveCallScreen('share-stream')}
                    </Button>
                </CopyText>
            </>
        );
    };
