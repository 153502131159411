import { cn } from '@bem-react/classname';
import React, { FC, ReactElement } from 'react';

import ErrorBox from 'components/ErrorBox';

import { ErrorScreenProps } from './ErrorScreen.interface';
import './ErrorScreen.css';

const cnErrorScreen = cn('ErrorScreen');

export const ErrorScreen: FC<ErrorScreenProps> = (props): ReactElement => {
    const {
        code,
    } = props;

    return (
        <ErrorBox className={cnErrorScreen('ErrorBox')} code={code} />
    );
};

export default ErrorScreen;
