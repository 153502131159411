import { CallTemplate } from 'components/CallTemplate/CallTemplate.interface';

/**
 * Сортировка шаблонов
 *
 * В начале массива должны быть шаблоны, привязанные к встрече с eventId и
 * у которых id владельца шаблона совпадает с id текущего пользователя
 *
 * Затем идут шаблоны, привязанные к встрече с eventId, где id пользователя и владельца не совпадает
 *
 * В конце все остальные шаблоны
 *
 * @param {CallTemplate[]} templates
 * @param {String} eventExternalId
 * @param {String} userId
 *
 * @returns {CallTemplate[]} Отсортированный массив шаблонов
 */
export const sortRelatedTemplates = (
    templates: CallTemplate[],
    eventExternalId: string,
    userId: string,
): CallTemplate[] => {
    const relatedToEventOwner: CallTemplate[] = [];
    const relatedToEventStranger: CallTemplate[] = [];
    const rest: CallTemplate[] = [];

    for (const template of templates) {
        if (template.eventExternalId === eventExternalId && template.owners.includes(userId)) {
            relatedToEventOwner.push(template);
        } else if (template.eventExternalId === eventExternalId) {
            relatedToEventStranger.push(template);
        } else {
            rest.push(template);
        }
    }

    return [
        ...relatedToEventOwner,
        ...relatedToEventStranger,
        ...rest,
    ];
};
