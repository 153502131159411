import { compose, composeU, withBemMod } from '@bem-react/core';

import { Button as ButtonBase } from '@yandex-lego/components/Button/desktop/bundle';
import { Icon as IconBase } from '@yandex-lego/components/Icon';
import React, { FC, ReactElement } from 'react';
import { withResponsive as withButtonResponsive } from 'components/Button';

import {
    withTypeHandset as withIconTypeHandset,
    withTypePlay as withIconTypePlay,
    withTypeCalendarAwesome as withIconTypeCalendarAwesome,
} from 'components/Icon';

import { User } from 'ducks/user';

import {
    Button as TableCellButtonBase,
    withTypeRecord as withTableCellButtonTypeRecord,
} from '../Button';

import { TableCellProps } from '../TableCell.interface';
import { cnTableCell } from '../TableCell';
import './TableCell_type_historyActions.css';

export interface WithTypeHistoryActionsProps {
    type?: 'historyActions';
    downloadRecordLink?: string;
    downloadRecordText?: string;
    record?: boolean;
    playRecordLink?: string;
    playRecordText?: string;
    onCollectCallClick?: FunctionVoid;
    collectCallText?: string;
    eventId?: number;
    calendarEventText?: string;
    user?: User;
    isDeleteRecordModalVisible?: boolean;
    onDeleteRecordClick?: FunctionVoid;
}

const Icon = composeU(
    withIconTypeHandset,
    withIconTypePlay,
    withIconTypeCalendarAwesome,
)(IconBase);

const Button = withButtonResponsive(ButtonBase);

const TableCellButton = compose(
    withTableCellButtonTypeRecord,
)(TableCellButtonBase);

const iconPlay = (iconClassName: string): ReactElement => <Icon type="play" size="m" className={iconClassName} />;
const iconHandset = (iconClassName: string): ReactElement => <Icon type="handset" size="m" className={iconClassName} />;
const iconCalendarAwesome = (iconClassName: string): ReactElement => (<Icon type="calendarAwesome" size="m" className={iconClassName} />);

export const withTypeHistoryActions = withBemMod<WithTypeHistoryActionsProps, TableCellProps>(
    cnTableCell(),
    { type: 'historyActions' },
    (TableCell): FC<WithTypeHistoryActionsProps & TableCellProps> => (
        (props: WithTypeHistoryActionsProps & TableCellProps): ReactElement => {
            const {
                downloadRecordLink,
                playRecordLink,
                downloadRecordText,
                playRecordText,
                record,
                onCollectCallClick,
                collectCallText,
                eventId,
                calendarEventText,
                user,
                isDeleteRecordModalVisible,
                onDeleteRecordClick,
            } = props;

            const calendarEventUrl = new URL('https://calendar.yandex-team.ru/event/');
            calendarEventUrl.searchParams.set('event_id', `${eventId}`);

            return (
                <TableCell {...props}>
                    {
                        eventId !== null &&
                            <Button
                                size="s"
                                view="pseudo"
                                pin="circle-circle"
                                type="link"
                                url={calendarEventUrl.toString()}
                                icon={iconCalendarAwesome}
                                title={calendarEventText}
                                width="max"
                                responsive
                                className={cnTableCell('Button')}
                            >
                                {calendarEventText}
                            </Button>
                    }
                    <TableCellButton
                        type="record"
                        isDisabled={!record || user.is_ip_external}
                        isModalVisible={isDeleteRecordModalVisible}
                        downloadRecordLink={downloadRecordLink}
                        downloadRecordText={downloadRecordText}
                        onDeleteRecordClick={onDeleteRecordClick}
                    />
                    <Button
                        size="s"
                        view="pseudo"
                        pin="circle-circle"
                        disabled={!record}
                        icon={iconPlay}
                        type="link"
                        url={playRecordLink}
                        target="_blank"
                        responsive
                        width="max"
                        className={cnTableCell('Button', { type: 'playRecord' })}
                    >
                        {playRecordText}
                    </Button>
                    <Button
                        size="s"
                        view="pseudo"
                        pin="circle-circle"
                        icon={iconHandset}
                        onClick={onCollectCallClick}
                        responsive
                        width="max"
                        className={cnTableCell('Button', { type: 'collectCall' })}
                    >
                        {collectCallText}
                    </Button>
                </TableCell>
            );
        }
    ),
);

export default withTypeHistoryActions;
