import {
    CalendarEvent,
    CalendarEventApi,
    CalendarEventAttendee,
} from './CalendarEvents.interface';

export const calendarEventAttendeeMock: CalendarEventAttendee = {
    name: 'Name',
    email: 'name@email.com',
    login: 'login',
    decision: 'yes',
};

export const calendarEventMock: CalendarEvent = {
    id: 2,
    name: 'EventName',
    startTs: '2024-01-24T10:25:19.524Z',
    endTs: '2024-01-24T11:25:19.524Z',
    rooms: [],
    call: null,
    eventExternalId: 'ExternalId',
    attendees: [{ ...calendarEventAttendeeMock }],
    organizer: { ...calendarEventAttendeeMock },
    conferenceUrl: 'conferenceUrl',
    description: 'description',
    descriptionHtml: 'descriptionHtml',
};

export const calendarEventApiMock: CalendarEventApi = {
    id: 2,
    name: 'EventName',
    startTs: '2024-01-24T10:25:19.524Z',
    endTs: '2024-01-24T11:25:19.524Z',
    rooms: [],
    call: null,
    externalId: 'ExternalId',
    attendees: [{ ...calendarEventAttendeeMock }],
    organizer: { ...calendarEventAttendeeMock },
    conferenceUrl: 'conferenceUrl',
    description: 'description',
    descriptionHtml: 'descriptionHtml',
};
