import React, { ReactElement } from 'react';
import { cn } from '@bem-react/classname';
import i18n from '@yandex-int/i18n';
import { compose } from '@bem-react/core';
import { Button } from '@yandex-lego/components/Button/desktop/bundle';
import { Icon as IconBase } from '@yandex-lego/components/Icon/desktop';

import { withTypeHandset as withIconTypeHandset } from 'components/Icon';
import CallTemplateList from 'components/CallTemplateList';
import { CalendarEvents } from 'components/CalendarEvents';
import { ActiveCalls } from 'components/ActiveCalls';

import { HomeScreenProps } from './HomeScreen.interface';
import './HomeScreen.css';
import * as keyset from './HomeScreen.i18n';

const i18nHomeScreen = i18n(keyset);

const cnHomeScreen = cn('HomeScreen');

const Icon = compose(
    withIconTypeHandset,
)(IconBase);

const iconPhoneAwesome = (iconClassName: string): ReactElement => <Icon type="handset" className={iconClassName} />;
const createButtonTextKey = window.innerWidth > 320 ? 'create-call-button' : 'create-call-button-short';

export const HomeScreen = (props: HomeScreenProps): ReactElement => {
    const {
        onCreateCallClick,
    } = props;

    return (
        <div className={cnHomeScreen()}>
            <div className={cnHomeScreen('Content')}>
                <div className={cnHomeScreen('Main')}>
                    <div className={cnHomeScreen('Head')}>
                        <h2>{i18nHomeScreen('home-screen-sub-title')}</h2>
                        <Button
                            view="action"
                            onClick={onCreateCallClick}
                            className={cnHomeScreen('CreateCallButton')}
                            size="m"
                            icon={iconPhoneAwesome}
                        >
                            {i18nHomeScreen(createButtonTextKey)}
                        </Button>
                    </div>
                    <CalendarEvents className={cnHomeScreen('CalendarEvents')} />
                    <ActiveCalls className={cnHomeScreen('ActiveCalls')} />
                </div>
                <div className={cnHomeScreen('Secondary')}>
                    <CallTemplateList />
                </div>
            </div>
        </div>
    );
};

export default HomeScreen;
