import { withBemMod, compose } from '@bem-react/core';
import i18n from '@yandex-int/i18n';
import { Button as ButtonBase } from '@yandex-lego/components/Button/desktop/bundle';
import { Icon as IconBase } from '@yandex-lego/components/Icon/desktop';
import React, { FC, ReactElement } from 'react';
import {
    withResponsive as withButtonResponsive,
} from 'components/Button';

import {
    withTypeUpdate as withIconTypeUpdate,
} from 'components/Icon';

import { cnParticipant } from '../Participant@desktop';
import { ParticipantProps } from '../Participant.interface';
import { WithRecallProps } from './Participant_recall.interface';
import * as keyset from '../Participant.i18n';
import './Participant_recall@mobile.css';

const i18nParticipant = i18n(keyset);

const Icon = compose(
    withIconTypeUpdate,
)(IconBase);

const Button = withButtonResponsive(ButtonBase);

const iconUpdate = (iconClassName: string): ReactElement => (<Icon type="update" className={iconClassName} />);

export const withRecall = withBemMod<WithRecallProps, ParticipantProps>(
    cnParticipant(), { recall: true }, (Participant): FC<WithRecallProps & ParticipantProps> => (
        (props: WithRecallProps & ParticipantProps): ReactElement => {
            const {
                onRecall,
            } = props;

            const recallButton = (
                <div className={cnParticipant('RecallButton')}>
                    <Button
                        onClick={onRecall}
                        view="pseudo"
                        size="m"
                        icon={iconUpdate}
                        width="max"
                        responsive
                    >
                        {i18nParticipant('recall')}
                    </Button>
                </div>
            );

            return (
                <Participant
                    {...props}
                    recallButton={recallButton}
                />
            );
        }
    ),
);

export default withRecall;
