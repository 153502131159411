import { cn } from '@bem-react/classname';
import React, { FC, ReactElement } from 'react';

import {
    Template,
} from 'components/Template';

import { TemplateListProps } from './TemplateList.interface';
import './TemplateList.css';

export const cnTemplateList = cn('TemplateList');

export const TemplateList: FC<TemplateListProps> = (props: TemplateListProps): ReactElement => {
    const {
        templates,
        getTemplateClickHandler,
        getTemplateKeyDownHandler,
        onParticipantsClick,
        appliedTemplateID,
        disabledTemplateId,
        replacer,
        className,
    } = props;

    const templatesList = templates.map(template => (
        <>
            <Template
                onTemplateClick={getTemplateClickHandler(template)}
                onTemplateKeyDown={getTemplateKeyDownHandler(template)}
                onParticipantsClick={onParticipantsClick}
                participants={template.participants}
                title={template.name}
                active={appliedTemplateID === template.id}
                disabled={disabledTemplateId === template.id}
                key={template.id}
                id={template.id}
            />
            <div className={cnTemplateList('Delimiter')} />
        </>
    ));

    return (
        <div className={cnTemplateList(null, [className])}>
            {
                replacer || templatesList
            }
        </div>
    );
};

export default TemplateList;
