import { withBemMod } from '@bem-react/core';

import { Spin } from '@yandex-lego/components/Spin/bundle';
import React, { FC, ReactElement } from 'react';

import { cnCreateCallScreen } from '../CreateCallScreen';
import { CreateCallScreenProps } from '../CreateCallScreen.interface';

import './CreateCallScreen_loading.css';

export interface WithLoading {
    loading?: boolean;
}

export const withLoading = withBemMod<WithLoading, CreateCallScreenProps>(
    cnCreateCallScreen(),
    { loading: true },
    (CreateCallScreen): FC<WithLoading & CreateCallScreenProps> => (
        (props: WithLoading & CreateCallScreenProps): ReactElement => {
            const replacer = (
                <div className={cnCreateCallScreen('Spinner')}>
                    <Spin
                        size="l"
                        view="default"
                        progress
                    />
                </div>
            );

            return (
                <CreateCallScreen
                    {...props}
                    replacer={replacer}
                />
            );
        }
    ),
);
