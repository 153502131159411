import i18n from '@yandex-int/i18n';
import React, { FC, ReactElement, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';

import {
    usePlatformContext,
    useTitle,
} from 'hooks';

import { StoreI } from 'ducks/store';
import {
    selectCalendarEventMeta,
    selectCalendarEventMetaIsRequesting,
    selectCalendarEventMetaError,
    request as requestCalendarEventMeta,
    clear as clearCalendarEventMeta,
} from 'ducks/calendarEventMeta';
import {
    selectData,
    setCallForm,
} from 'ducks/createCallForm';

import { parseQuery } from 'components/CreateCallForm/CreateCallForm.util';

import {
    CreateCallScreenContainerProps,
    CreateCallScreenContainerMapStateToProps,
    CreateCallScreenContainerMapDispatchToProps,
} from './CreateCallScreen.interface';
import { CreateCallScreenMobile as CreateCallScreenMobileBase } from './CreateCallScreen@mobile';
import { CreateCallScreenDesktop as CreateCallScreenDesktopBase } from './CreateCallScreen@desktop';
import { withLoading } from './_loading/CreateCallScreen_loading';
import * as keyset from './CreateCallScreen.i18n';

const i18nCreateCallScreen = i18n(keyset);

const CreateCallScreenMobile = withLoading(CreateCallScreenMobileBase);
const CreateCallScreenDesktop = withLoading(CreateCallScreenDesktopBase);

export const CreateCallScreenContainer: FC<CreateCallScreenContainerProps> =
    (props: CreateCallScreenContainerProps): ReactElement => {
        const {
            calendarEventMeta,
            isRequesting,
            data,
            error,
            requestCalendarEventMeta: requestCalendarEventMetaAction,
            clearCalendarEventMeta: clearCalendarEventMetaAction,
            setCallForm: setCallFormAction,
        } = props;

        const { eventId } = parseQuery();

        useTitle([i18nCreateCallScreen('create-call-screen-title')]);

        useEffect((): FunctionVoid => {
            if (eventId) {
                requestCalendarEventMetaAction({
                    eventId,
                    templateId: null,
                });
            }

            return (): void => {
                clearCalendarEventMetaAction();
            };
        }, [clearCalendarEventMetaAction, eventId, requestCalendarEventMetaAction]);

        const platform = usePlatformContext();

        const CreateCallScreenView = platform === 'mobile' ? CreateCallScreenMobile : CreateCallScreenDesktop;

        const isLoading = isRequesting || (eventId && !calendarEventMeta && !error);

        const handleSetNameValue = useCallback((value: string): void => {
            setCallFormAction({
                ...data,
                name: value,
            });
        }, [data, setCallFormAction]);

        return (
            <CreateCallScreenView
                loading={isLoading}
                calendarEventMeta={calendarEventMeta}
                onSetNameValue={handleSetNameValue}
                data={data}
            />
        );
    };

export const CreateCallScreen =
    connect<CreateCallScreenContainerMapStateToProps, CreateCallScreenContainerMapDispatchToProps>(
        (store: StoreI) => ({
            calendarEventMeta: selectCalendarEventMeta(store),
            isRequesting: selectCalendarEventMetaIsRequesting(store),
            data: selectData(store),
            error: selectCalendarEventMetaError(store),
        }),
        {
            requestCalendarEventMeta,
            clearCalendarEventMeta,
            setCallForm,
        },
    )(CreateCallScreenContainer);
