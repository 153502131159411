import { withBemMod } from '@bem-react/core';

import { Spin } from '@yandex-lego/components/Spin/bundle';
import React, { FC, ReactElement } from 'react';

import { cnStreamsScreen } from 'components/StreamsScreen/StreamsScreen';
import { StreamsScreenProps } from 'components/StreamsScreen/StreamsScreen.interface';

import './StreamsScreen_loading.css';

export interface WithLoading {
    loading?: boolean;
}

export const withLoading = withBemMod<WithLoading, StreamsScreenProps>(
    cnStreamsScreen(),
    { loading: true },
    (StreamsScreen): FC<WithLoading & StreamsScreenProps> => (
        (props: WithLoading & StreamsScreenProps): ReactElement => {
            const replacer = (
                <div className={cnStreamsScreen('Spinner')}>
                    <Spin
                        size="l"
                        view="default"
                        progress
                    />
                </div>
            );

            return (
                <StreamsScreen
                    {...props}
                    replacer={replacer}
                />
            );
        }
    ),
);
