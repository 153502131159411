import React, { ChangeEventHandler, ReactElement, useCallback } from 'react';
import { Button } from '@yandex-int/hr-components/Button/desktop/bundle';
import { Textinput } from '@yandex-int/hr-components/Textinput/desktop/bundle';
import { cn } from '@bem-react/classname';
import i18n from '@yandex-int/i18n';
import * as keyset from '../../ClickerZoom.i18n';
import { ClickerIcon } from '../ClickerIcon/ClickerIcon';

import './StartHostPage.css';

const cnStartPage = cn('StartPage');
const i18nClicker = i18n(keyset);

export interface StartHostPageProps {
    onPinChange: ChangeEventHandler<HTMLInputElement>;
    pinValue: string;
    openInstruction: () => void;
    initHost: (pin: string) => void;
}

export const StartHostPage = (props: StartHostPageProps): ReactElement => {
    const {
        pinValue,
        onPinChange,
        initHost,
        openInstruction,
    } = props;

    const onInitClick = useCallback(() => {
        initHost(pinValue);
    }, [initHost, pinValue]);

    return (
        <>
            <div className={cnStartPage('Id')} />
            <div className={cnStartPage('TitleWrapper')}>
                <ClickerIcon />
                <div className={cnStartPage('Title')}>{i18nClicker('clicker')}</div>
                <div className={cnStartPage('Subtitle')}>{i18nClicker('subtitle')}</div>
                <Textinput
                    className={cnStartPage('PinInput')}
                    placeholder={i18nClicker('enterPin')}
                    onChange={onPinChange}
                    value={pinValue}
                    view="default"
                    size="m"
                />
            </div>
            <div className={cnStartPage('Buttons')}>
                <Button
                    className={cnStartPage('ButtonInstruction')}
                    view="default"
                    size="l"
                    onClick={openInstruction}
                >
                    {i18nClicker('instruction')}
                </Button>
                <Button
                    className={cnStartPage('ButtonInit')}
                    view="action"
                    size="l"
                    onClick={onInitClick}
                    width="max"
                >
                    {i18nClicker('initButton')}
                </Button>
            </div>
        </>
    );
};
