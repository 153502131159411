import apiRxJs, { ApiErrorI } from 'util/api.rxjs';
import { Observable } from 'rxjs';

import { CallForm } from 'components/CreateCallForm/CreateCallForm.interface';
import { ActiveCallI } from 'components/ActiveCalls/ActiveCalls.interface';
import {
    ReceiveCreateCallSuccessPayload,
    CallFormAPI,
} from './createCallForm';

type ResultCreateCall = ReceiveCreateCallSuccessPayload | ApiErrorI;

export const createCall = (payload: CallForm): Observable<ResultCreateCall> => {
    const {
        record,
        stream,
        isPrivateStream,
        participants,
        name,
        duration,
        eventId,
        templateId,
    } = payload;

    const body: CallFormAPI = {
        record,
        stream,
        participants,
        duration,
        is_private_stream: isPrivateStream,
        event_id: eventId,
        template_id: templateId,
    };

    if (name) {
        body.name = name;
    }

    return apiRxJs.post<ActiveCallI>('/frontapi/calls/create/', {
        body: JSON.stringify(body),
    });
};

export interface CreateCallFormApi {
  createCall: typeof createCall;
}

export const createCallFormApi: CreateCallFormApi = {
    createCall,
};
