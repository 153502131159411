import React, { FC, ReactElement } from 'react';
import { MessageBox, Wrapper } from '@yandex-lego/components/MessageBox/desktop/bundle';
import { Textarea } from '@yandex-lego/components/Textarea/desktop/bundle';
import { RatingDynamic } from '@yandex-int/serp-components/RatingDynamic/desktop';

import { cnKhStreamScreen } from '../KhStreamScreen.cn';
import { i18n } from '../KhStreamScreen.translations';
import { KhStreamScreenRateInformerProps } from './KhStreamScreen-RateInformer.interface';

import './KhStreamScreen-RateInformer.css';

export const RateInformer: FC<KhStreamScreenRateInformerProps> = (props): ReactElement => {
    const {
        rating,
        message,
        onRatingChange,
        onMessageChange,
        onClose,
        actionButtons,
        trailing,
        replacer,
    } = props;

    const isStreamRated = rating > 0;

    return (
        <MessageBox
            view="promo"
            size="l"
            layout="functional"
            className={cnKhStreamScreen('RateInformer')}
            actions={actionButtons}
            onClose={onClose}
        >
            <Wrapper trailing={trailing}>
                <div className={cnKhStreamScreen('RateInformerContent')}>
                    {
                        replacer || (
                            <>
                                <div className={cnKhStreamScreen('RateInformerTitle')}>
                                    {i18n('rate-informer-title')}
                                </div>
                                <div className={cnKhStreamScreen('RateInformerRating')}>
                                    <RatingDynamic
                                        size="m"
                                        value={rating}
                                        onChange={onRatingChange}
                                    />
                                </div>
                                {
                                    isStreamRated && (
                                        <Textarea
                                            value={message}
                                            onChange={onMessageChange}
                                            view="default"
                                            size="m"
                                            placeholder={i18n('rate-informer-textarea-placeholder')}
                                            className={cnKhStreamScreen('RateInformerMessage')}
                                        />
                                    )
                                }
                            </>
                        )
                    }
                </div>
            </Wrapper>
        </MessageBox>
    );
};
