import { connectRouter, routerMiddleware, RouterState } from 'connected-react-router';
import { applyMiddleware, combineReducers, createStore, Reducer } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { createBrowserHistory, History } from 'history';

import { reducer as notifications } from 'components/Notifications';
import { NotificationPayloadI } from 'components/Notifications/Notifications.interface';
import { epic as deleteTemplateEpic } from 'components/CallTemplate/CallTemplate.actions';

import {
    CallsHistory as CallsHistoryState,
    reducer as callsHistoryReducer,
    epic as callsHistoryEpic,
    historyCallsApiMethods,
} from 'ducks/callsHistory';
import {
    reducer as streamsReducer,
    Streams as StreamsState,
    epic as streamsEpic,
    streamsApi,
    StreamsApi,
} from 'ducks/streams';
import {
    Stream as StreamState,
    reducer as streamReducer,
    epic as streamEpic,
    streamApi,
    StreamApi,
} from 'ducks/stream';
import {
    Header as HeaderState,
    reducer as headerReducer,
} from 'ducks/header';
import {
    FooterState,
    reducer as footerReducer,
} from 'ducks/footer';
import {
    ActiveCallState,
    epic as activeCallEpic,
    reducer as activeCallReducer,
    activeCallApi,
    ActiveCallApi,
} from 'ducks/activeCall';
import {
    ActiveCallsState,
    reducer as activeCallsReducer,
    activeCallsApi,
    ActiveCallsApi,
    epic as activeCallsEpic,
} from 'ducks/activeCalls';
import {
    YandexBug as YandexBugState,
    reducer as yandexBugReducer,
} from 'ducks/yandexBug';
import {
    YandexLampState,
    reducer as yandexLampReducer,
} from 'ducks/yandexLamp';
import {
    TemplateFormState,
    epic as templateFormEpic,
    reducer as templateFormReducer,
    templateFormApi,
    TemplateFormApi,
} from 'ducks/templateForm';
import {
    CalendarEventsState,
    epic as calendarEventsEpic,
    reducer as calendarEventsReducer,
    calendarEventsApi,
    CalendarEventsApi,
} from 'ducks/calendarEvents';
import {
    CalendarEventMetaState,
    epic as calendarEventMetaEpic,
    reducer as calendarEventMetaReducer,
    calendarEventMetaApi,
    CalendarEventMetaApiDep,
} from 'ducks/calendarEventMeta';
import {
    CreateCallFormState,
    epic as createCallFormEpic,
    reducer as createCallFormReducer,
    createCallFormApi,
    CreateCallFormApi,
} from 'ducks/createCallForm';
import {
    TemplatesState,
    reducer as templatesReducer,
    epic as templatesEpic,
    templatesApi,
    TemplatesApi,
} from 'ducks/templates';
import {
    Settings as SettingsState,
    reducer as settingsReducer,
    epic as settingsEpic,
    settingsApi,
    SettingsApi,
} from 'ducks/settings';
import {
    TitleState,
    reducer as titleReducer,
} from 'ducks/title';
import {
    ThemeState,
    reducer as themeReducer,
} from 'ducks/theme';
import {
    UserState,
    reducer as userReducer,
    epic as userEpic,
    UserApi,
    userApi,
} from 'ducks/user';
import {
    PageVisibilityState,
    reducer as pageVisibilityReducer,
} from 'ducks/pageVisibility';
import {
    InviteCallLinkApi,
    inviteCallLinkApi,
    epic as inviteCallLinkEpic,
    reducer as inviteCallLinkReducer,
    InviteCallLinkState,
} from 'ducks/inviteCallLink';
import {
    uploadPictureApi,
    UploadPictureApi,
} from 'ducks/uploadPicture';
import {
    StreamPlayerState,
    reducer as streamPlayerReducer,
} from 'ducks/streamPlayer';
import {
    ClickerApi,
    clickerApi,
    epic as clickerEpic,
    reducer as clickerReducer,
    ClickerState,
} from 'ducks/clicker';
import {
    ClickerZoomApi,
    clickerZoomApi,
    epic as clickerZoomEpic,
    reducer as clickerZoomReducer,
    ClickerZoomState,
} from 'ducks/clickerZoom';

export const history = createBrowserHistory();

export interface StoreI {
    notifications: NotificationPayloadI;
    router: RouterState;
    user: UserState;
    templates: TemplatesState;
    callsHistory: CallsHistoryState;
    streams: StreamsState;
    stream: StreamState;
    header: HeaderState;
    footer: FooterState;
    yandexBug: YandexBugState;
    yandexLamp: YandexLampState;
    activeCall: ActiveCallState;
    activeCalls: ActiveCallsState;
    templateForm: TemplateFormState;
    calendarEvents: CalendarEventsState;
    calendarEventMeta: CalendarEventMetaState;
    createCallForm: CreateCallFormState;
    settings: SettingsState;
    title: TitleState;
    theme: ThemeState;
    pageVisibility: PageVisibilityState;
    inviteCallLink: InviteCallLinkState;
    streamPlayer: StreamPlayerState;
    clicker: ClickerState;
    clickerZoom: ClickerZoomState;
}

export interface InjectedDependencies {
    streamsApi: StreamsApi;
    activeCallApi: ActiveCallApi;
    streamApi: StreamApi;
    templateFormApi: TemplateFormApi;
    calendarEventsApi: CalendarEventsApi;
    activeCallsApi: ActiveCallsApi;
    calendarEventMetaApi: CalendarEventMetaApiDep;
    templatesApi: TemplatesApi;
    createCallFormApi: CreateCallFormApi;
    uploadPictureApi: UploadPictureApi;
    settingsApi: SettingsApi;
    userApi: UserApi;
    inviteCallLinkApi: InviteCallLinkApi;
    clickerApi: ClickerApi,
    clickerZoomApi: ClickerZoomApi;

}

const epicList = [
    userEpic,
    templateFormEpic,
    deleteTemplateEpic,
    callsHistoryEpic,
    streamsEpic,
    streamEpic,
    activeCallEpic,
    calendarEventsEpic,
    activeCallsEpic,
    calendarEventMetaEpic,
    templatesEpic,
    createCallFormEpic,
    settingsEpic,
    inviteCallLinkEpic,
    clickerEpic,
    clickerZoomEpic,
];

export const rootReducer = (historyObj: History): Reducer<StoreI> => combineReducers<StoreI>({
    notifications,
    router: connectRouter(historyObj),
    templates: templatesReducer,
    user: userReducer,
    callsHistory: callsHistoryReducer,
    streams: streamsReducer,
    stream: streamReducer,
    header: headerReducer,
    footer: footerReducer,
    activeCall: activeCallReducer,
    activeCalls: activeCallsReducer,
    yandexBug: yandexBugReducer,
    yandexLamp: yandexLampReducer,
    templateForm: templateFormReducer,
    calendarEvents: calendarEventsReducer,
    calendarEventMeta: calendarEventMetaReducer,
    createCallForm: createCallFormReducer,
    settings: settingsReducer,
    title: titleReducer,
    theme: themeReducer,
    pageVisibility: pageVisibilityReducer,
    inviteCallLink: inviteCallLinkReducer,
    streamPlayer: streamPlayerReducer,
    clicker: clickerReducer,
    clickerZoom: clickerZoomReducer,
});

const epicMiddleware = createEpicMiddleware({
    dependencies: {
        historyCallsApiMethods,
        streamsApi,
        activeCallApi,
        streamApi,
        templateFormApi,
        calendarEventsApi,
        activeCallsApi,
        calendarEventMetaApi,
        templatesApi,
        createCallFormApi,
        uploadPictureApi,
        settingsApi,
        userApi,
        inviteCallLinkApi,
        clickerApi,
        clickerZoomApi,
    },
});

export default createStore(
    rootReducer(history),
    {} as undefined,
    composeWithDevTools(applyMiddleware(routerMiddleware(history), epicMiddleware)),
);

epicMiddleware.run(combineEpics(...epicList));
