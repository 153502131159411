import { compose, composeU, withBemMod } from '@bem-react/core';
import {
    Link as LinkBase,
    withThemeBlack,
} from '@yandex-lego/components/Link/desktop';
import { Icon as IconBase } from '@yandex-lego/components/Icon/desktop';
import React, { FC, ReactElement } from 'react';
import {
    withTypeYandexCalendar as withIconTypeYandexCalendar,
    withTypePlus as withIconTypePlus,
    withSizeM as withIconSizeM,
} from 'components/Icon';

import { WithTypeEventProps } from './CallFormTitle_type_event.interface';
import { CallFormTitleProps } from '../CallFormTitle.interface';
import { cnCallFormTitle } from '../CallFormTitle';
import './CallFormTitle_type_event.css';

const Icon = compose(
    withIconSizeM,
    composeU(
        withIconTypePlus,
        withIconTypeYandexCalendar,
    ),
)(IconBase);

const Link = compose(
    withThemeBlack,
)(LinkBase);

export const withTypeEvent = withBemMod<WithTypeEventProps, CallFormTitleProps>(
    cnCallFormTitle(),
    { type: 'event' },
    (CallFormTitle): FC<WithTypeEventProps & CallFormTitleProps> => (
        (props: WithTypeEventProps & CallFormTitleProps): ReactElement => {
            const {
                title,
                eventId,
            } = props;

            const calendarEventUrl = new URL('https://calendar.yandex-team.ru/event/');
            calendarEventUrl.searchParams.set('event_id', `${eventId}`);

            const contentReplacer = (
                <>
                    <Icon
                        type="yandexCalendar"
                        size="m"
                        className={cnCallFormTitle('Icon')}
                    />
                    <Link
                        theme="black"
                        href={calendarEventUrl.toString()}
                        className={cnCallFormTitle('Link')}
                        target="_blank"
                    >
                        {title}
                    </Link>
                </>
            );

            return (
                <CallFormTitle
                    {...props}
                    contentReplacer={contentReplacer}
                />
            );
        }
    ),
);
