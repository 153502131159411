import React, { FC, ReactElement } from 'react';
import { cn } from '@bem-react/classname';
import { useComponentRegistry } from '@bem-react/di';
import { compose } from '@bem-react/core';
import i18n from '@yandex-int/i18n';
import { Tumbler } from '@yandex-lego/components/Tumbler/desktop/bundle';

import { Textinput } from '@yandex-lego/components/Textinput/desktop/bundle';
import { Button } from '@yandex-lego/components/Button/desktop/bundle';

import { Suggest } from 'components/Suggest';
import {
    Yabble as YabbleBase,
    withTypeStaffName as withYabbleTypeStaffName,
} from 'components/Yabble';

import { GoToCalendar } from './GoToCalendar';
import { TemplateFormScreenProps } from './TemplateFormScreen.interface';
import * as keyset from './TemplateFormScreen.i18n';
import './TemplateFormScreen.css';

const i18nTemplateFormScreen = i18n(keyset);

const Yabble = compose(
    withYabbleTypeStaffName,
)(YabbleBase);

export const cnTemplateFormScreen = cn('TemplateFormScreen');
export const registryId = cnTemplateFormScreen();

export const TemplateFormScreen: FC<TemplateFormScreenProps> = (props): ReactElement => {
    const {
        className,
        actionButton,
        deleteTemplate,
        title,
        replacer,
        onSubmit,
        onFieldNameChange,
        onFieldEventChange,
        onFieldRecordChange,
        onFieldStreamChange,
        onDeleteOwner,
        onOwnersSuggestSelect,
        onCancelButtonClick,
        onChangeParticipantSelectedMethod,
        onDeleteParticipant,
        onParticipantsSuggestSelect,
        onFieldStreamPictureChange,
        onFieldStreamPictureReset,
        onFieldStreamDescriptionChange,
        templateName,
        templateEventId,
        templateParticipants,
        templateStream,
        templateStreamPicture,
        templateStreamDescription,
        templateRecord,
        ownersMeta,
        participantsList,
        isTemplateActionRequesting,
        isStreamPictureUploading,
        templateId,
        eventId,
        eventName,
    } = props;

    const {
        ParticipantsList,
        Header,
        Stream,
    } = useComponentRegistry(registryId);

    return (
        <div className={cnTemplateFormScreen(null, [className])}>
            {
        replacer || (
        <div className={cnTemplateFormScreen('Container')}>
            <form className={cnTemplateFormScreen('Content')} onSubmit={onSubmit}>
                <Header
                    title={title}
                />
                <div className={cnTemplateFormScreen('Name')}>
                    <div className={cnTemplateFormScreen('NameLabel')}>{i18nTemplateFormScreen('name-label')}</div>
                    <div className={cnTemplateFormScreen('NameField')}>
                        <Textinput
                            view="default"
                            size="m"
                            onChange={onFieldNameChange}
                            value={templateName}
                            placeholder={i18nTemplateFormScreen('name-field-placeholder')}
                        />
                    </div>
                </div>
                <div className={cnTemplateFormScreen('Owners')}>
                    <div className={cnTemplateFormScreen('OwnersLabel')}>{i18nTemplateFormScreen('owners-label')}</div>
                    <div className={cnTemplateFormScreen('OwnersField')}>
                        <Suggest
                            layers={['people']}
                            onChange={onOwnersSuggestSelect}
                            className={cnTemplateFormScreen('OwnersSuggest')}
                            placeholder={i18nTemplateFormScreen('owners-suggest-placeholder')}
                        />
                        <div className={cnTemplateFormScreen('OwnersList')}>
                            { Object.keys(ownersMeta).map((owner): ReactElement => {
                                const yabbleOwner = ownersMeta[owner];
                                return (
                                    <Yabble
                                        key={yabbleOwner.id}
                                        participantType="person"
                                        type="staffName"
                                        login={yabbleOwner.id}
                                        title={yabbleOwner.name}
                                        actionType="close"
                                        onClick={onDeleteOwner(yabbleOwner.id)}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className={cnTemplateFormScreen('Event')}>
                    <div className={cnTemplateFormScreen('EventLabel')}>{i18nTemplateFormScreen('eventId-label')}</div>
                    <div className={cnTemplateFormScreen('EventField')}>
                        <Textinput
                            view="default"
                            size="m"
                            onChange={onFieldEventChange}
                            value={templateEventId}
                            placeholder={i18nTemplateFormScreen('eventId-field-placeholder')}
                        />
                        <GoToCalendar
                            eventId={eventId}
                            eventName={eventName}
                        />
                    </div>
                </div>
                <div className={cnTemplateFormScreen('Participants')}>
                    <div className={cnTemplateFormScreen('ParticipantsTitle')}>
                        {i18nTemplateFormScreen('participants-title')}
                    </div>
                    <ParticipantsList
                        data={participantsList}
                        onSuggestSelect={onParticipantsSuggestSelect}
                        onChangeParticipantSelectedMethod={onChangeParticipantSelectedMethod}
                        onDeleteParticipant={onDeleteParticipant}
                        isListHidden={templateParticipants.length === 0}
                        type="common"
                        suggest
                        placeholder={i18nTemplateFormScreen('participants-suggest-placeholder')}
                    />
                </div>

                <Stream
                    isStreamOn={templateStream}
                    isStreamPictureUploading={isStreamPictureUploading}
                    eventId={eventId}
                    templateId={templateId}
                    templateStreamPicture={templateStreamPicture}
                    templateName={templateName}
                    templateStreamDescription={templateStreamDescription}
                    onFieldStreamChange={onFieldStreamChange}
                    onFieldStreamPictureChange={onFieldStreamPictureChange}
                    onFieldStreamPictureReset={onFieldStreamPictureReset}
                    onFieldStreamDescriptionChange={onFieldStreamDescriptionChange}
                />

                <div className={cnTemplateFormScreen('Record')}>
                    <div className={cnTemplateFormScreen('RecordLabel')}>
                        <div className={cnTemplateFormScreen('RecordLabelTitle')}>{i18nTemplateFormScreen('record-label-title')}</div>
                        <div className={cnTemplateFormScreen('RecordLabelSubTitle')}>{i18nTemplateFormScreen('record-label-subtitle')}</div>
                    </div>
                    <div className={cnTemplateFormScreen('RecordControl')}>
                        <Tumbler
                            onChange={onFieldRecordChange}
                            size="s"
                            view="default"
                            checked={templateRecord}
                        />
                    </div>
                </div>

                <div className={cnTemplateFormScreen('ControlButtons')}>
                    {actionButton}
                    <Button
                        view="default"
                        size="m"
                        disabled={isTemplateActionRequesting}
                        onClick={onCancelButtonClick}
                        className={cnTemplateFormScreen('ControlButton', { type: 'cancel' })}
                    >
                        {i18nTemplateFormScreen('cancel')}
                    </Button>
                    {deleteTemplate}
                </div>
            </form>
        </div>
        )
      }
        </div>
    );
};
