import { compose } from '@bem-react/core';
import { replace } from 'connected-react-router';
import React, { FC, ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router';

import {
    request as requestCalendarEventMeta,
    clear as clearCalendarEventMeta,
    selectCalendarEventMeta,
    selectCalendarEventMetaError,
    selectCalendarEventMetaIsRequesting,
} from 'ducks/calendarEventMeta';
import { StoreI } from 'ducks/store';

import { CalendarEventRouterScreen as CalendarEventRouterScreenBase } from './CalendarEventRouterScreen';
import { withError as withCalendarEventRouterScreenError } from './_error/CalendarEventRouterScreen_error';
import {
    CalendarEventRouterScreenContainerMatchParams,
    CalendarEventRouterScreenContainerProps as ContainerProps,
    CalendarEventRouterScreenMapDispatchToProps as MapDispatchToProps,
    CalendarEventRouterScreenMapStateToProps as MapStateToProps,
} from './CalendarEventRouterScreen.interface';

const CalendarEventRouterScreenView = compose(
    withCalendarEventRouterScreenError,
)(CalendarEventRouterScreenBase);

const CalendarEventRouterScreenContainer: FC<ContainerProps> = (props: ContainerProps): ReactElement => {
    const {
        eventMeta,
        error,
        requestCalendarEventMeta: requestCalendarEventMetaAction,
        clearCalendarEventMeta: clearCalendarEventMetaAction,
        replace: replaceAction,
    } = props;

    const match = useRouteMatch<CalendarEventRouterScreenContainerMatchParams>();

    const eventId = match.params.id;

    useEffect((): FunctionVoid => {
        requestCalendarEventMetaAction({
            eventId: Number(eventId),
            templateId: null,
        });

        return (): void => {
            clearCalendarEventMetaAction();
        };
    }, [clearCalendarEventMetaAction, eventId, requestCalendarEventMetaAction]);

    useEffect((): void => {
        if (eventMeta) {
            const { event } = eventMeta;
            const { call } = event;

            if (call) {
                const { secret } = call;
                const query = new URLSearchParams();

                if (secret) {
                    query.set('secret', secret);
                }

                replaceAction(`/active-call/${call.id}?${query}`);
            } else {
                replaceAction(`/create-call/?eventId=${event.id}`);
            }
        }
    }, [eventMeta, replaceAction]);

    return (
        <CalendarEventRouterScreenView
            error={error && error.error}
            errorDetails={error}
        />
    );
};

export const CalendarEventRouterScreen = connect<MapStateToProps, MapDispatchToProps>(
    (store: StoreI): MapStateToProps => ({
        isRequesting: selectCalendarEventMetaIsRequesting(store),
        error: selectCalendarEventMetaError(store),
        eventMeta: selectCalendarEventMeta(store),
    }),
    {
        requestCalendarEventMeta,
        clearCalendarEventMeta,
        replace,
    },
)(CalendarEventRouterScreenContainer);
