import React, { FC } from 'react';
import { Select as SelectBase, SelectProps as SelectPropsBase } from '@yandex-int/hr-components/Select/desktop/bundle';

const iconProps = {
    glyph: 'arrowShortDown',
};

export const Select: FC<SelectPropsBase> = props => {
    return (
        <SelectBase
            {...props}
            iconProps={iconProps}
        />
    );
};

export type SelectProps = SelectPropsBase;
