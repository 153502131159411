import i18n from '@yandex-int/i18n';
import React, { FC, ReactElement } from 'react';

import { Hint } from 'components/Hint';

import { StreamPosterTitleProps } from './StreamPoster-Title.interface';
import { cnStreamPoster } from '../StreamPoster';
import * as keyset from '../StreamPoster.i18n';
import './StreamPoster-Title.css';

const i18nStreamPoster = i18n(keyset);

export const Title: FC<StreamPosterTitleProps> = (props: StreamPosterTitleProps): ReactElement => {
    const {
        name,
        info,
        onHintClick,
        onStreamPosterTitleTextClick,
    } = props;

    return (
        <div className={cnStreamPoster('Title')}>
            <span
                className={cnStreamPoster('TitleText')}
                title={name}
                onClick={onStreamPosterTitleTextClick}
            >
                {name}
            </span>
            {
                false && info && // false можно выпилить после https://st.yandex-team.ru/VCONF-757
                    <Hint
                        text={info}
                        className={cnStreamPoster('Hint')}
                        onHintClick={onHintClick}
                        title={i18nStreamPoster('stream-information')}
                    />
            }
        </div>
    );
};
