import { push } from 'connected-react-router';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { PERMANENT_LINK_TYPE, STATE } from '../PermanentLinkScreen.interface';

export const useRedirect = (state: STATE, id: string, permanentLinkType: PERMANENT_LINK_TYPE) => {
    const dispatch = useDispatch();

    const navigateToChat = useCallback((): void => {
        dispatch(push(`/streams/${id}/chat`));
    }, [dispatch, id]);

    const navigateToStream = useCallback((): void => {
        dispatch(push(`/streams/${id}`));
    }, [dispatch, id]);

    useEffect(() => {
        if (state === STATE.REDIRECT) {
            switch (permanentLinkType) {
                case PERMANENT_LINK_TYPE.STREAM:
                    navigateToStream();
                    break;
                case PERMANENT_LINK_TYPE.CHAT:
                    navigateToChat();
            }
        }
    }, [permanentLinkType, state]);
};
