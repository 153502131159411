import { withBemMod, composeU } from '@bem-react/core';
import React, { ReactElement, FC } from 'react';

import { IntrasearchSuggestion, SuggestionPeople } from 'components/Suggest/Suggest.interface';
import {
    Participant as ParticipantBase,
    withTypePerson as withParticipantTypePerson,
} from 'components/Participant/desktop';
import {
    cnSuggestItem,
    SuggestItemProps,
} from '../SuggestItem';

const Participant = composeU(withParticipantTypePerson)(ParticipantBase);

export interface WithTypePersonProps {
  type?: 'person'
  suggest?: IntrasearchSuggestion
}

export const withTypePerson = withBemMod<WithTypePersonProps, SuggestItemProps>(
    cnSuggestItem(),
    { type: 'person' },
    (SuggestItem): FC<WithTypePersonProps & SuggestItemProps> =>
        (props: WithTypePersonProps & SuggestItemProps): ReactElement => {
            const { suggest } = props;
            const {
                login,
                title,
            } = suggest as SuggestionPeople;

            const subtitle = `@${login}`;
            const participant = (
                <Participant
                    type="person"
                    id={login}
                    isStaffCard={false}
                    title={title}
                    subtitle={subtitle}
      />
            );
            return (
                <SuggestItem {...props}>
                    { participant }
                </SuggestItem>
            );
        },
);

export default withTypePerson;
