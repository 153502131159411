import { cn } from '@bem-react/classname';
import { compose } from '@bem-react/core';

import { Modal } from '@yandex-lego/components/Modal/desktop/bundle';
import { Button } from '@yandex-lego/components/Button/desktop/bundle';

import {
    Icon as IconBase,
    withTypeClose as withIconTypeClose,
} from '@yandex-lego/components/Icon/desktop';
import { connect } from 'react-redux';
import React, { ReactElement } from 'react';

import { StoreI } from 'ducks/store';
import { YandexBugProps, YandexBugStateToProps } from './YandexBug.interface';
import BugFrame from './BugFrame';

const cnYandexBug = cn('YandexBug');

const Icon = compose(
    withIconTypeClose,
)(IconBase);

const iconClose = (iconClassName: string): ReactElement => (<Icon type="close" className={iconClassName} />);

const YandexBug = (props: YandexBugProps): ReactElement => {
    const {
        isHidden,
    } = props;

    const [modalVisible, setModalVisible] = React.useState(false);
    const showModal = React.useCallback((): void => {
        setModalVisible(true);
    }, []);
    const hideModal = React.useCallback((): void => {
        setModalVisible(false);
    }, []);

    return (
        <div className={cnYandexBug('', { isHidden })}>
            <button
                type="button"
                onClick={showModal}
                className={cnYandexBug('Insect')}
            />
            <Modal
                theme="normal"
                visible={modalVisible}
                className={cnYandexBug('Modal')}
                onClose={hideModal}
                keepMounted={false}
            >
                <div className={cnYandexBug('ModalContent')}>
                    <Button
                        size="m"
                        view="clear"
                        onClick={hideModal}
                        className={cnYandexBug('CloseButton')}
                        icon={iconClose}
                    />
                    <BugFrame />
                </div>
            </Modal>
        </div>
    );
};

export default connect(
    (state: StoreI): YandexBugStateToProps => ({
        isHidden: state.yandexBug.isHidden,
    }),
)(YandexBug);
