import React, { FC, ReactElement, useCallback, useState } from 'react';
import { connect } from 'react-redux';

import { selectData as selectCreateCallFormData } from 'ducks/createCallForm';
import { StoreI } from 'ducks/store';

import {
    CreateCallScreenContentMobileContainerProps,
    CreateCallScreenContentMobileMapStateToProps,
} from './CreateCallScreen-Content.interface';
import { CreateCallScreenContentMobile as CreateCallScreenContentMobileView } from './CreateCallScreen-Content@mobile';

export const CreateCallScreenContentMobileContainer: FC<CreateCallScreenContentMobileContainerProps> =
    (props: CreateCallScreenContentMobileContainerProps): ReactElement => {
        const {
            createCallForm,
            calendarEventMeta,
            data,
            onSetNameValue,
        } = props;

        const [isTemplatesVisible, setIsTemplatesVisible] = useState<boolean>(false);

        const handleTemplatesSwitcherClick = useCallback((): void => {
            setIsTemplatesVisible(!isTemplatesVisible);
        }, [isTemplatesVisible]);

        const handleTemplateClick = useCallback(() => {
            setIsTemplatesVisible(false);
        }, []);

        return (
            <CreateCallScreenContentMobileView
                isTemplatesVisible={isTemplatesVisible}
                onTemplatesSwitcherClick={handleTemplatesSwitcherClick}
                onTemplateClick={handleTemplateClick}
                appliedTemplateId={createCallForm.templateId}
                calendarEventMeta={calendarEventMeta}
                data={data}
                onSetNameValue={onSetNameValue}
            />
        );
    };

export const CreateCallScreenContentMobile = connect<CreateCallScreenContentMobileMapStateToProps>(
    (store: StoreI) => ({
        createCallForm: selectCreateCallFormData(store),
    }),
)(CreateCallScreenContentMobileContainer);
