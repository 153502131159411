import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';
import './Icon_type_clock.css';

export interface WithTypeClockProps {
  type?: 'clock';
}

export const withTypeClock = withBemMod<WithTypeClockProps>(
    'Icon',
    { type: 'clock' },
    (Icon): FC => ((props): ReactElement => (<Icon {...props} />)),
);

export default withTypeClock;
