import { withBemMod, compose } from '@bem-react/core';
import React, { FC, MouseEventHandler, ReactElement } from 'react';

import { ParticipantI } from 'components/Participant/Participant.interface';
import { withPopup } from 'components/BubbleParticipants';
import BaseBubbleParticipants from 'components/BubbleParticipants/BubbleParticipants';

import { TableCellProps } from '../TableCell.interface';
import { cnTableCell } from '../TableCell';
import './TableCell_type_callParticipants.css';

export interface WithTypeCallParticipantsProps {
  type?: 'callParticipants';
  participants?: ParticipantI[];
  onBubbleParticipantsClick?: MouseEventHandler;
}

const BubbleParticipants = compose(
    withPopup,
)(BaseBubbleParticipants);

export const withTypeCallParticipants = withBemMod<WithTypeCallParticipantsProps, TableCellProps>(
    cnTableCell(),
    { type: 'callParticipants' },
    (TableCell): FC<WithTypeCallParticipantsProps & TableCellProps> => (
        (props: WithTypeCallParticipantsProps & TableCellProps): ReactElement => {
            const {
                participants,
                onBubbleParticipantsClick,
            } = props;

            return (
                <TableCell {...props}>
                    <div
                        className={cnTableCell('Participants')}
                        onClick={onBubbleParticipantsClick}
                    >
                        {
                            participants.length > 0 && (
                            <BubbleParticipants
                                participants={participants}
                                popup
                            />
                            )
                        }
                    </div>
                </TableCell>
            );
        }
    ),
);

export default withTypeCallParticipants;
