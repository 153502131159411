import { composeU, withBemMod } from '@bem-react/core';
import i18n from '@yandex-int/i18n';

import { Icon as IconBase } from '@yandex-lego/components/Icon/desktop';
import React, { FC, ReactElement } from 'react';
import { withTypeYandexCalendar } from 'components/Icon';

import {
    TableCell as TableCellBase,
    withTypeCallParticipants as withTableCellTypeCallParticipants,
    withTypeCallTime as withTableCellTypeCallTime,
    withTypeCallName as withTableCellTypeCallName,
    withTypeEventCallActions as withTableCellTypeEventCallActions,
} from 'components/TableCell';

import { CalendarEvent } from 'components/CalendarEvents/CalendarEvents.interface';

import { cnTable } from '../Table';
import { TableProps } from '../Table.interface';
import * as keyset from '../Table.i18n';
import './Table_type_events.css';
import { INotificationMessageType } from '../../Notifications/Notifications.interface';

const Icon = composeU(withTypeYandexCalendar)(IconBase);

export interface WithTypeEventsProps {
  type: 'events';
  events: CalendarEvent[];
  onCollectCallButtonClick: (data: CalendarEvent) => FunctionVoid;
  onNavigateToCallButtonClick: (id: string, secret: string) => FunctionVoid;
  onCopyLink: (payload: INotificationMessageType) => void;
}

const i18nTable = i18n(keyset);

const TableCell = composeU(
    withTableCellTypeEventCallActions,
    withTableCellTypeCallParticipants,
    withTableCellTypeCallTime,
    withTableCellTypeCallName,
)(TableCellBase);

export const withTypeEvents = withBemMod<WithTypeEventsProps, TableProps>(
    cnTable(),
    { type: 'events' },
    (Table): FC<TableProps & WithTypeEventsProps> => (
        (props: TableProps & WithTypeEventsProps): ReactElement => {
            const {
                events,
                onCollectCallButtonClick,
                onNavigateToCallButtonClick,
                onCopyLink,
            } = props;

            const header = (
                <div className={cnTable('Header')}>
                    <Icon type="yandexCalendar" className={cnTable('CalendarIcon')} />
                    <h3 className={cnTable('Title')}>{i18nTable('table-events-title')}</h3>
                </div>
            );

            const rows = events.map((event): ReactElement => {
                return (
                    <div
                        key={event.id}
                        className={cnTable('Row')}
                    >
                        <TableCell
                            type="callTime"
                            startTime={event.startTs}
                            endTime={event.endTs}
                            withTime
                            className={cnTable('Cell')}
                        />

                        <TableCell
                            type="callName"
                            name={event.name}
                            call={event.call}
                            onCopyLink={onCopyLink}
                            className={cnTable('Cell')}
                        />

                        <TableCell
                            type="callParticipants"
                            participants={event.rooms}
                            className={cnTable('Cell')}
                        />

                        <TableCell
                            type="eventCallActions"
                            event={event}
                            onNavigateToCallButtonClick={onNavigateToCallButtonClick}
                            onCollectCallButtonClick={onCollectCallButtonClick}
                            className={cnTable('Cell')}
                        />

                    </div>
                );
            });

            const emptyText = (<div className={cnTable('EmptyText')}>{i18nTable('empty-calendar-events-table-text')}</div>);

            const addonAfter = events.length === 0 ? emptyText : rows;

            return (
                <Table
                    {...props}
                    header={header}
                    addonAfter={addonAfter}
                />
            );
        }
    ),
);

export default withTypeEvents;
