import { Registry, withRegistry } from '@bem-react/di';
import React, { ReactElement } from 'react';

import {
    StaffName,
    StaffNameProps,
} from 'components/StaffName';
import { HeaderMobile } from 'components/Header';

import {
    GlobalContextRegistry as GlobalContextRegistryBase,
} from './GlobalContextRegistry';
import { registryId } from './GlobalContextRegistry.constants';

const globalRegistry = new Registry({ id: registryId });
globalRegistry.set('StaffName', (props: StaffNameProps): ReactElement => (
    <StaffName {...props} />
));

globalRegistry.set('Header', HeaderMobile);

export const GlobalContextRegistryMobile = withRegistry(globalRegistry)(GlobalContextRegistryBase);

export default GlobalContextRegistryMobile;
