import i18n from '@yandex-int/i18n';
import React, { FC, ReactElement } from 'react';

import { DurationSelector } from 'components/DurationSelector';

import { DurationSelectorProps } from './Duration-Selector.interface';
import { cnDuration } from '../Duration';
import * as keyset from '../Duration.i18n';
import './Duration-Selector.css';

const i18nDuration = i18n(keyset);

export const Selector: FC<DurationSelectorProps> = (props: DurationSelectorProps): ReactElement => {
    const {
        minutesPassed,
        duration,
        isDurationChanging,
        onChange,
    } = props;

    if (!duration) return null;

    return (
        <>
            {' '}
            {i18nDuration('of')}
            <DurationSelector
                className={cnDuration('Select')}
                value={duration}
                minValue={minutesPassed}
                onChange={onChange}
                size="s"
                disabled={isDurationChanging}
            />
            {
                minutesPassed < duration && (
                    <span className={cnDuration('Remaining')}>
                        {i18nDuration('remaining', { minutes: duration - minutesPassed })}
                    </span>
                )
            }
        </>
    );
};
