import apiRxjs, { ApiErrorI } from 'util/api.rxjs';
import { Observable } from 'rxjs';

import { CallTemplate } from 'components/CallTemplate';

import {
    CallTemplateAPIRequest,
    CallTemplateAPI,
    RequestTemplatePayload,
    RequestTemplateSavePayload,
    RequestTemplateUpdatePayload,
} from './templateForm';

type SaveTemplateRequestMapperData = RequestTemplateSavePayload | RequestTemplateUpdatePayload | CallTemplate;
export const saveTemplateRequestMapper = (data: SaveTemplateRequestMapperData): CallTemplateAPIRequest => {
    const {
        id,
        name,
        duration,
        owners,
        participants,
        record,
        stream,
        streamPicture,
        streamDescription,
        eventExternalId,
        eventId,
        event,
    } = data;

    const parsedData: CallTemplateAPIRequest = {
        id,
        name,
        duration,
        owners,
        participants,
        record,
        stream,
        stream_picture: streamPicture,
        stream_description: streamDescription,
        event_id: null,
        event_external_id: null,
        event,
    };

    if (eventId) {
        if (typeof eventId === 'number') {
            parsedData.event_id = String(eventId);
        } else {
            parsedData.event_id = eventId;
        }
        parsedData.event_external_id = eventExternalId;
    }

    return parsedData;
};

export const saveTemplateRequest = (data: RequestTemplateSavePayload): Observable<CallTemplateAPI | ApiErrorI> => {
    const body = saveTemplateRequestMapper(data);

    return apiRxjs.post<CallTemplateAPI | ApiErrorI>('/frontapi/templates/create/', {
        body: JSON.stringify(body),
    });
};

export const updateTemplateRequest = (data: RequestTemplateUpdatePayload): Observable<CallTemplateAPI | ApiErrorI> => {
    const body = saveTemplateRequestMapper(data);

    return apiRxjs.post<CallTemplateAPI | ApiErrorI>(`/frontapi/templates/${data.id}/`, {
        body: JSON.stringify(body),
    });
};

export const getCallTemplate = (payload: RequestTemplatePayload): Observable<CallTemplateAPI | ApiErrorI> =>
    apiRxjs.get<CallTemplateAPI>(`/frontapi/templates/${payload.id}/`);

export interface TemplateFormApi {
  saveTemplateRequest: (data: RequestTemplateSavePayload) => Observable<CallTemplateAPI | ApiErrorI>;
  updateTemplateRequest: (data: RequestTemplateUpdatePayload) => Observable<CallTemplateAPI | ApiErrorI>;
  getCallTemplate: (payload: RequestTemplatePayload) => Observable<CallTemplateAPI | ApiErrorI>;
}

export const templateFormApi: TemplateFormApi = {
    saveTemplateRequest,
    updateTemplateRequest,
    getCallTemplate,
};
