import React, { FC, ReactElement } from 'react';
import i18n from '@yandex-int/i18n';
import { Tumbler } from '@yandex-lego/components/Tumbler/desktop/bundle';

import { PrivateStreamProps } from './CreateCallForm-PrivateStream.interface';
import { cnCreateCallForm } from '../CreateCallForm';
import * as keyset from '../CreateCallForm.i18n';
import './CreateCallForm-PrivateStream.css';

const i18nCallForm = i18n(keyset);

export const PrivateStream: FC<PrivateStreamProps> = (props: PrivateStreamProps): ReactElement => {
    const {
        onFieldIsPrivateStreamChange,
        isPrivateStream,
        isAdmin,
        stream,
    } = props;

    if (isAdmin && stream) {
        return (
            <div className={cnCreateCallForm('PrivateStream')}>
                <div className={cnCreateCallForm('PrivateStreamLabel')}>
                    <div className={cnCreateCallForm('PrivateStreamLabelTitle')}>{i18nCallForm('is-private-stream-label-title')}</div>
                    <div className={cnCreateCallForm('PrivateStreamLabelSubTitle')}>{i18nCallForm('is-private-stream-label-subtitle')}</div>
                </div>
                <div className={cnCreateCallForm('PrivateStreamControl')}>
                    <Tumbler
                        onChange={onFieldIsPrivateStreamChange}
                        size="s"
                        view="default"
                        checked={isPrivateStream}
                    />
                </div>
            </div>
        );
    }

    return null;
};
