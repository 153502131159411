import { cn } from '@bem-react/classname';
import React, { FC, ReactElement } from 'react';

import { ParticipantAvatarProps } from './ParticipantAvatar.interface';
import './ParticipantAvatar.css';
import defaultAvatar from './assets/default.svg';

export const cnParticipantAvatar = cn('ParticipantAvatar');

export const ParticipantAvatar: FC<ParticipantAvatarProps> = (props: ParticipantAvatarProps): ReactElement => {
    const {
        replacer,
        className,
    } = props;

    return (
        <div className={cnParticipantAvatar({}, [className])}>
            {
                replacer || (
                    <img
                        className={cnParticipantAvatar('Image')}
                        src={defaultAvatar}
                        alt="avatar"
                    />
                )
            }
        </div>
    );
};

export default ParticipantAvatar;
