export const ru = {
    'all-streams-link': 'Все трансляции',
    'smth-went-wrong-description': 'Что-то пошло не так...',
    'not-found-description': 'К сожалению не удалось найти данные для трансляции',
    'next-event': 'Трансляция начнется',
    'next-chat': 'Чат будет доступен как только начнется трансляция',
    at: 'в',
    'title-chat': 'Здесь будет чат',
    'title-stream': 'Здесь будет трансляция',
};
