import React, { FC, ReactElement } from 'react';

import { DescriptionProps } from './StreamScreen-Description.interface';
import { cnStreamScreen } from '../StreamScreen.cn';

import './StreamScreen-Description.css';

export const Description: FC<DescriptionProps> = (props: DescriptionProps): ReactElement => {
    const {
        description,
        platform,
    } = props;

    if (description && platform === 'desktop') {
        return (
            <div className={cnStreamScreen('Description')}>
                {description}
            </div>
        );
    }

    return null;
};
