import i18n from '@yandex-int/i18n';
import { cn } from '@bem-react/classname';
import React, { FC, ReactElement } from 'react';

import { UserCallControlProps } from './UserCallControl.interface';
import * as keyset from './UserCallControl.i18n';
import './UserCallControl.css';

const i18nUserCallControl = i18n(keyset);

export const cnUserCallControl = cn('UserCallControl');
export const registryId = cnUserCallControl();

export const UserCallControl: FC<UserCallControlProps> = (props): ReactElement => {
    const {
        children,
        currentUser,
        className,
    } = props;

    return (
        <div className={cnUserCallControl(null, [className])}>
            <div className={cnUserCallControl('Title')}>
                <div className={cnUserCallControl('TitleText')}>{i18nUserCallControl(`status-${currentUser.state}`)}</div>
            </div>
            <div className={cnUserCallControl('Content')}>
                {children}
            </div>
        </div>
    );
};

export default UserCallControl;
