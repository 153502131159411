import { withBemMod, compose, composeU } from '@bem-react/core';

import { Button } from '@yandex-lego/components/Button/desktop/bundle';

import { Icon as IconBase } from '@yandex-lego/components/Icon/desktop';
import React, { FC, ReactElement } from 'react';
import {
    withTypeUpdate as withIconTypeUpdate,
    withSizeXs as withIconSizeXs,
    withSizeS as withIconSizeS,
    withSizeM as withIconSizeM,
} from 'components/Icon';

import { cnParticipant } from '../Participant@desktop';
import { ParticipantProps } from '../Participant.interface';
import { WithRecallProps } from './Participant_recall.interface';
import './Participant_recall@desktop.css';

const Icon = compose(
    composeU(
        withIconSizeXs,
        withIconSizeS,
        withIconSizeM,
    ),
    withIconTypeUpdate,
)(IconBase);

const iconUpdate = (iconClassName: string): ReactElement => (<Icon type="update" size="s" className={iconClassName} />);

export const withRecall = withBemMod<WithRecallProps, ParticipantProps>(
    cnParticipant(), { recall: true }, (Participant): FC<WithRecallProps & ParticipantProps> => (
        (props: WithRecallProps & ParticipantProps): ReactElement => {
            const {
                onRecall,
            } = props;

            const recallButton = (
                <div className={cnParticipant('RecallButton')}>
                    <Button
                        onClick={onRecall}
                        view="clear"
                        icon={iconUpdate}
                    />
                </div>
            );

            return (
                <Participant
                    {...props}
                    recallButton={recallButton}
                />
            );
        }
    ),
);

export default withRecall;
