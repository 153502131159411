import { cn } from '@bem-react/classname';
import React, { FC, ReactElement } from 'react';
import { BackLink } from 'components/BackLink';
import { Chat } from 'components/Chat';

import './ChatScreen.css';

import { ChatScreenProps } from './ChatScreen.interface';

export const cnChatScreen = cn('ChatScreen');

const flags = ['disableNavigation'];
const tvFlags = flags.concat('compactView', 'disableChatHeader', 'tvView', 'readOnly');

export const ChatScreen: FC<ChatScreenProps> = (props: ChatScreenProps): ReactElement => {
    const {
        className,
        callId,
        isTvView,
        replacer,
        chatInviteHash,
        env,
    } = props;

    const url = `/streams/${callId}`;

    return (
        <div
            className={cnChatScreen({ isTvView }, [className])}
        >
            {
                !isTvView && (
                    <BackLink
                        title="Вернуться к трансляции"
                        url={url}
                        className={cnChatScreen('BackLink')}
                    />
                )
            }
            {
                replacer || (
                    <Chat
                        className={cnChatScreen('Chat')}
                        chatInviteHash={chatInviteHash}
                        env={env}
                        flags={isTvView ? tvFlags : flags}
                    />
                )
            }
        </div>
    );
};

export default ChatScreen;
