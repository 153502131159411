import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import './Button_tone_redLight.css';

export interface WithToneRedLightProps {
  tone?: 'redLight';
}

export const withToneRedLight = withBemMod<WithToneRedLightProps>('Button2', { tone: 'redLight' }, (Button): FC => ((props): ReactElement => (<Button {...props} />)));

export default withToneRedLight;
