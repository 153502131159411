import React, { FC, ReactElement } from 'react';

import { cnStreamPoster } from '../StreamPoster';

import { StreamPosterBackgroundProps } from './StreamPoster-Background.interface';
import './StreamPoster-Background.css';

export const Background: FC<StreamPosterBackgroundProps> = (props: StreamPosterBackgroundProps): ReactElement => {
    const {
        streamPosterUrl,
    } = props;

    return (
        <>
            {
                streamPosterUrl && (
                    <div
                        style={{ backgroundImage: `url('${streamPosterUrl}')` }}
                        className={cnStreamPoster('PosterBackground')}
                    />
                )
            }
            <div className={cnStreamPoster('Background')} />
        </>
    );
};
