import { cn } from '@bem-react/classname';

import { Button } from '@yandex-lego/components/Button/desktop/bundle';
import React, { ChangeEvent, FC, ReactElement, ReactNode } from 'react';

import { ParticipantsListProps, ParticipantsListGroup } from './ParticipantsList.interface';
import './ParticipantsList.css';

export const cnParticipantsList = cn('ParticipantsList');

export const registryId = cnParticipantsList();

export const ParticipantsList: FC<ParticipantsListProps> = (props: ParticipantsListProps): ReactElement => {
    const {
        className,
        title,
        isListHidden,
        isHeaderDisabled = true,
        data = new Map<string, ParticipantsListGroup>(),
        onCollapsedToggleClick,
        collapsedToggleArrow,
        addonBefore,
        participantTypeComponents,
        isChangeParticipantSelectedMethodDisabled = false,
        /* istanbul ignore next */
        onDeleteParticipant = (): void => {},
        /* istanbul ignore next */
        onRecallParticipant = (): void => {},
        onChangeParticipantSelectedMethod = (): void => {},
        /* istanbul ignore next */
        onToggleParticipantCamera = (): void => {},
        /* istanbul ignore next */
        onToggleParticipantMicrophone = (): void => {},
    } = props;

    const groups: ReactNode[] = [];
    let totalItems = 0;

    data.forEach((group, groupKey): void => {
        const items: ReactNode[] = [];

        group.content.forEach((item): void => {
            items.push(participantTypeComponents[item.type]({
                item,
                onDelete: (): void => { onDeleteParticipant(groupKey, item.id) },
                onRecall: (): void => { onRecallParticipant(groupKey, item.id) },
                onChangeSelectedMethod: (event: ChangeEvent<HTMLSelectElement>): void => {
                    onChangeParticipantSelectedMethod(groupKey, item.id, event);
                },
                onToggleMicrophone: (): void => { onToggleParticipantMicrophone(groupKey, item.id) },
                onToggleCamera: (): void => { onToggleParticipantCamera(groupKey, item.id) },
                isDisabledChangeSelectedMethod: isChangeParticipantSelectedMethodDisabled,
            }));
            totalItems += 1;
        });

        groups.push((
            <div className={cnParticipantsList('Group')} key={groupKey}>
                <div className={cnParticipantsList('GroupTitle')}>{group.title}</div>
                <div className={cnParticipantsList('GroupContent')}>{items}</div>
            </div>
        ));
    });

    return (
        <div className={cnParticipantsList({ listHidden: isListHidden }, [className])}>
            {addonBefore}
            {
                title && (
                    <Button
                        className={cnParticipantsList('Header')}
                        onClick={onCollapsedToggleClick}
                        size="m"
                        view="clear"
                        disabled={isHeaderDisabled}
                      >
                        <div className={cnParticipantsList('Title')}>{title}</div>
                        <div className={cnParticipantsList('ElementsAmount')}>{totalItems}</div>
                        {collapsedToggleArrow}
                    </Button>
                )
            }
            <div className={cnParticipantsList('Content')}>
                {groups}
            </div>
        </div>
    );
};
