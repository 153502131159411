import { compose, composeU } from '@bem-react/core';
import { Stub as StubBase } from '../Stub';

import { withTypeNoCalls } from '../_type/Stub_type_noCalls';
import { withTypeNoConnection } from '../_type/Stub_type_noConnection';

export const Stub = compose(
    composeU(
        withTypeNoCalls,
        withTypeNoConnection,
    ),
)(StubBase);
