import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';
import './Icon_type_microphone.css';

export interface WithTypeMicrophoneProps {
  type?: 'microphone';
}

export const withTypeMicrophone = withBemMod<WithTypeMicrophoneProps>(
    'Icon',
    { type: 'microphone' },
    (Icon): FC => ((props): ReactElement => (<Icon {...props} />)),
);

export default withTypeMicrophone;
