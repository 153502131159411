import { ApiErrorI } from 'util/api.rxjs';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap, map } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { createSelector } from 'reselect';
import { push } from 'connected-react-router';
import { Action, createAction, handleAction, handleActions, ReduxCompatibleReducer } from 'redux-actions';

import { CallFormDuration } from 'components/CreateCallForm/CreateCallForm.interface';
import { ParticipantI } from 'components/Participant/Participant.interface';
import { INotificationMessageType } from 'components/Notifications/Notifications.interface';
import { notyError } from 'components/Notifications/Notifications.actions';
import { CallTemplate } from 'components/CallTemplate';

import { RequestParticipantPayload } from 'ducks/activeCall';
import { InjectedDependencies, StoreI } from 'ducks/store';

export interface OwnersMetaMap {
  [id: string]: ParticipantI
}

export interface TemplateFormState {
  isTemplateRequesting: boolean;
  isTemplateActionRequesting: boolean;
  isStreamPictureUploading: boolean;
  data: CallTemplate;
  ownersMeta: OwnersMetaMap;
}

export interface CallTemplateAPI {
  id: number;
  name: string;
  duration: CallFormDuration;
  owners: string[];
  participants: ParticipantI[];
  record: boolean;
  stream: boolean;
  stream_picture: string;
  stream_description: string;
  event_external_id: string;
  event_id: number;
  event: {
      name: string,
  };
}

export type CallTemplateAPIRequest = Omit<CallTemplateAPI, 'event_id'> & { event_id: string };

export type CallTemplateRequest = Omit<CallTemplate, 'eventId'> & { eventId: string };

export const initialCallTemplate: CallTemplate = {
    id: null,
    name: '',
    duration: 60 as CallFormDuration,
    owners: [],
    participants: [],
    record: false,
    stream: false,
    streamPicture: '',
    streamDescription: '',
    eventExternalId: null,
    eventId: null,
    event: {
        name: null,
    },
};

export const initialCallTemplateRequest: CallTemplateRequest = {
    ...initialCallTemplate,
    eventId: '1123',
};

export const initialState: TemplateFormState = {
    isTemplateRequesting: false,
    isTemplateActionRequesting: false,
    isStreamPictureUploading: false,
    data: initialCallTemplate,
    ownersMeta: {},
};

export interface RequestTemplatePayload {
  id: string;
}
export type ChangeStreamPicturePayload = File;
export type ChangeStreamPictureSuccessPayload = string;
export type ChangeStreamPictureErrorPayload = ApiErrorI;
export type ReceiveTemplateSuccessPayload = CallTemplateAPI;
export type RequestTemplateSavePayload = CallTemplateRequest;
export type RequestTemplateUpdatePayload = CallTemplateRequest;
export type SetCallTemplatePayload = CallTemplate;
export type AddOwnersPayload = ParticipantI[];
export interface DeleteOwnerPayload {
  id: string;
}
export type RequestOwnersMetaPayload = RequestParticipantPayload;

const RESET_STREAM_PICTURE = 'vconf/templateForm/resetStreamPicture';
const CHANGE_STREAM_PICTURE = 'vconf/templateForm/changeStreamPicture';
const CHANGE_STREAM_PICTURE_SUCCESS = 'vconf/templateForm/changeStreamPictureSuccess';
const CHANGE_STREAM_PICTURE_ERROR = 'vconf/templateForm/changeStreamPictureError';
const REQUEST_TEMPLATE = 'vconf/templateForm/requestTemplate';
const RECEIVE_TEMPLATE_SUCCESS = 'vconf/templateForm/requestTemplateSuccess';
const RECEIVE_TEMPLATE_ERROR = 'vconf/templateForm/requestTemplateError';
const REQUEST_TEMPLATE_SAVE = 'vconf/templateForm/requestTemplateSave';
const RECEIVE_TEMPLATE_SAVE = 'vconf/templateForm/receiveTemplateSave';
const REQUEST_TEMPLATE_UPDATE = 'vconf/templateForm/requestTemplateUpdate';
const RECEIVE_TEMPLATE_UPDATE = 'vconf/templateForm/receiveTemplateUpdate';
const SET_CALL_TEMPLATE = 'vconf/templateForm/setCallTemplate';
const ADD_OWNERS = 'vconf/templateForm/addOwners';
const DELETE_OWNER = 'vconf/templateForm/deleteOwner';
const REQUEST_OWNERS_META = 'vconf/templateForm/requestOwnersMeta';
const CLEAR_TEMPLATE_FORM = 'vconf/templateForm/clearTemplateForm';

export const resetStreamPicture = createAction<null>(RESET_STREAM_PICTURE);
export const changeStreamPicture = createAction<ChangeStreamPicturePayload>(CHANGE_STREAM_PICTURE);
export const changeStreamPictureSuccess = createAction<ChangeStreamPictureSuccessPayload>(
    CHANGE_STREAM_PICTURE_SUCCESS,
);
export const changeStreamPictureError = createAction<null>(CHANGE_STREAM_PICTURE_ERROR);
export const requestTemplate = createAction<RequestTemplatePayload>(REQUEST_TEMPLATE);
export const receiveTemplateSuccess = createAction<ReceiveTemplateSuccessPayload>(RECEIVE_TEMPLATE_SUCCESS);
export const receiveTemplateError = createAction<null>(RECEIVE_TEMPLATE_ERROR);
export const requestTemplateSave = createAction<RequestTemplateSavePayload>(REQUEST_TEMPLATE_SAVE);
export const receiveTemplateSave = createAction<null>(RECEIVE_TEMPLATE_SAVE);
export const requestTemplateUpdate = createAction<RequestTemplateUpdatePayload>(REQUEST_TEMPLATE_UPDATE);
export const receiveTemplateUpdate = createAction<null>(RECEIVE_TEMPLATE_UPDATE);
export const setCallTemplate = createAction<SetCallTemplatePayload>(SET_CALL_TEMPLATE);
export const addOwners = createAction<AddOwnersPayload>(ADD_OWNERS);
export const deleteOwner = createAction<DeleteOwnerPayload>(DELETE_OWNER);
export const requestOwnersMeta = createAction<RequestOwnersMetaPayload>(REQUEST_OWNERS_META);
export const clearTemplateForm = createAction<null>(CLEAR_TEMPLATE_FORM);

interface HandleRequestReceive {
  [key: string]: ReduxCompatibleReducer<TemplateFormState, null>
}

const handleRequestReceive = (request: string, receive: string): HandleRequestReceive => ({
    [request]: handleAction<TemplateFormState, null>(
        request,
        (state): TemplateFormState => ({ ...state, isTemplateActionRequesting: true }),
        initialState,
    ),
    [receive]: handleAction<TemplateFormState, null>(
        receive,
        (state): TemplateFormState => ({ ...state, isTemplateActionRequesting: false }),
        initialState,
    ),
});

type IPayload =
  RequestTemplateSavePayload |
  RequestTemplateUpdatePayload |
  AddOwnersPayload |
  DeleteOwnerPayload |
  ReceiveTemplateSuccessPayload |
  ChangeStreamPicturePayload |
  ChangeStreamPictureSuccessPayload |
  SetCallTemplatePayload |
  null;

export const reducer = handleActions<TemplateFormState, IPayload>({
    [REQUEST_TEMPLATE]: (state): TemplateFormState => ({
        ...state,
        isTemplateRequesting: true,
    }),

    [RECEIVE_TEMPLATE_SUCCESS]: (state, action: Action<ReceiveTemplateSuccessPayload>): TemplateFormState => {
        const { payload } = action;
        const {
            id,
            name,
            duration,
            owners,
            participants,
            record,
            stream,
            stream_picture: streamPicture,
            stream_description: streamDescription,
            event_external_id: eventExternalId,
            event_id: eventId,
            event,
        } = payload;

        const payloadMap: CallTemplate = {
            id,
            name,
            duration,
            owners,
            participants,
            record,
            stream,
            streamPicture,
            streamDescription,
            eventExternalId,
            eventId,
            event,
        };

        return {
            ...state,
            data: payloadMap,
            isTemplateRequesting: false,
        };
    },

    [RECEIVE_TEMPLATE_ERROR]: (state): TemplateFormState => ({
        ...state,
        isTemplateRequesting: false,
    }),

    [RESET_STREAM_PICTURE]: (state): TemplateFormState => ({
        ...state,
        data: {
            ...state.data,
            streamPicture: '',
        },
        isStreamPictureUploading: false,
    }),

    [CHANGE_STREAM_PICTURE]: (state): TemplateFormState => {
        return ({
            ...state,
            isStreamPictureUploading: true,
        });
    },

    [CHANGE_STREAM_PICTURE_ERROR]: (state): TemplateFormState => ({
        ...state,
        isStreamPictureUploading: false,
    }),

    [CHANGE_STREAM_PICTURE_SUCCESS]: (
        state,
        action: Action<ChangeStreamPictureSuccessPayload>,
    ): TemplateFormState => {
        const { payload } = action;
        return {
            ...state,
            isStreamPictureUploading: false,
            data: {
                ...state.data,
                streamPicture: payload,
            },
        };
    },

    [SET_CALL_TEMPLATE]: (state, action: Action<SetCallTemplatePayload>): TemplateFormState => {
        const { payload } = action;

        return {
            ...state,
            data: payload,
        };
    },

    [ADD_OWNERS]: (state, action: Action<AddOwnersPayload>): TemplateFormState => {
        const { payload } = action;
        const { ownersMeta } = state;

        const newOwners = payload.reduce((owners, owner): OwnersMetaMap => ({
            ...owners,
            [owner.id]: owner,
        }), {});

        return {
            ...state,
            ownersMeta: {
                ...ownersMeta,
                ...newOwners,
            },
        };
    },

    [DELETE_OWNER]: (state, action: Action<DeleteOwnerPayload>): TemplateFormState => {
        const { payload } = action;
        const { ownersMeta } = state;

        const newOwnersMeta = Object.assign(ownersMeta, {});
        delete newOwnersMeta[payload.id];

        return {
            ...state,
            ownersMeta: { ...newOwnersMeta },
        };
    },

    [CLEAR_TEMPLATE_FORM]: (): TemplateFormState => ({
        ...initialState,
    }),

    ...handleRequestReceive(REQUEST_TEMPLATE_SAVE, RECEIVE_TEMPLATE_SAVE),
    ...handleRequestReceive(REQUEST_TEMPLATE_UPDATE, RECEIVE_TEMPLATE_UPDATE),
}, initialState);

type ITemplatePayload = INotificationMessageType | ReceiveTemplateSuccessPayload;

type ISavePayload = INotificationMessageType | null;

type IUpdatePayload = INotificationMessageType | null;

type IOwnersMetaPayload = INotificationMessageType | AddOwnersPayload;

type IUploadPicturePayload = INotificationMessageType |
    ChangeStreamPictureSuccessPayload | ChangeStreamPictureErrorPayload;

export const epic = combineEpics(
    (action$: Observable<Action<RequestTemplatePayload>>,
        state$: StoreI,
        { templateFormApi: { getCallTemplate } }: InjectedDependencies): Observable<Action<ITemplatePayload>> =>
        action$.pipe(
            ofType(REQUEST_TEMPLATE),
            mergeMap(({ payload }): Observable<Action<ITemplatePayload>> =>
                getCallTemplate(payload).pipe(
                    map((response): Action<ITemplatePayload> => {
                        const possiblyErrorResponse = response as ApiErrorI;

                        if (possiblyErrorResponse.error) {
                            throw possiblyErrorResponse;
                        }

                        return receiveTemplateSuccess(response as ReceiveTemplateSuccessPayload);
                    }),
                    catchError((error: ApiErrorI): Observable<Action<INotificationMessageType | null>> => of(
                        notyError(error.message),
                        receiveTemplateError(null),
                    )),
                ),
            ),
        ),

    (action$: Observable<Action<RequestTemplateSavePayload>>,
        state$: StoreI,
        { templateFormApi: { saveTemplateRequest } }: InjectedDependencies): Observable<Action<ISavePayload>> =>
        action$.pipe(
            ofType(REQUEST_TEMPLATE_SAVE),
            mergeMap(({ payload }): Observable<Action<ISavePayload>> =>
                saveTemplateRequest(payload).pipe(
                    mergeMap((response): Observable<Action<ISavePayload>> => {
                        const possiblyErrorResponse = response as ApiErrorI;

                        if (possiblyErrorResponse.error) {
                            throw possiblyErrorResponse;
                        }

                        return of(
                            receiveTemplateSave(null),
                            push('/'),
                        );
                    }),
                    catchError((error: ApiErrorI): Observable<Action<INotificationMessageType>> => of(
                        notyError(error.message),
                        receiveTemplateSave(null),
                    )),
                ),
            ),
        ),

    (action$: Observable<Action<RequestTemplateUpdatePayload>>,
        state$: StoreI,
        { templateFormApi: { updateTemplateRequest } }: InjectedDependencies): Observable<Action<IUpdatePayload>> =>
        action$.pipe(
            ofType(REQUEST_TEMPLATE_UPDATE),
            mergeMap(({ payload }): Observable<Action<IUpdatePayload>> =>
                updateTemplateRequest(payload).pipe(
                    mergeMap((response): Observable<Action<IUpdatePayload>> => {
                        const possiblyErrorResponse = response as ApiErrorI;

                        if (possiblyErrorResponse.error) {
                            throw possiblyErrorResponse;
                        }

                        return of(
                            receiveTemplateUpdate(null),
                            push('/'),
                        );
                    }),
                    catchError((error: ApiErrorI): Observable<Action<INotificationMessageType | null>> => of(
                        notyError(error.message),
                        receiveTemplateUpdate(null),
                    )),
                ),
            ),
        ),

    (action$: Observable<Action<RequestOwnersMetaPayload>>,
        state$: StoreI,
        { activeCallApi: { getParticipantDetails } }: InjectedDependencies): Observable<Action<IOwnersMetaPayload>> =>
        action$.pipe(
            ofType(REQUEST_OWNERS_META),
            mergeMap(({ payload }): Observable<Action<IOwnersMetaPayload>> =>
                getParticipantDetails(payload).pipe(
                    mergeMap((response): Observable<Action<IOwnersMetaPayload>> => {
                        const possiblyErrorResponse = response as ApiErrorI;

                        if (possiblyErrorResponse.error) {
                            throw possiblyErrorResponse;
                        }

                        return of(
                            addOwners(response as ParticipantI[]),
                        );
                    }),
                    catchError((error: ApiErrorI): Observable<Action<INotificationMessageType>> => of(
                        notyError(error.message),
                    )),
                ),
            ),
        ),

    (
        action$: Observable<Action<ChangeStreamPicturePayload>>,
        state$: StoreI,
        api: InjectedDependencies,
    ): Observable<Action<IUploadPicturePayload>> =>
        action$.pipe(
            ofType(CHANGE_STREAM_PICTURE),
            mergeMap(({ payload }): Observable<Action<IUploadPicturePayload>> => {
                return api.uploadPictureApi.uploadPicture(payload).pipe(
                    map((response): Action<IUploadPicturePayload> => {
                        const possiblyErrorResponse = response as ApiErrorI;

                        if (possiblyErrorResponse.error) {
                            throw possiblyErrorResponse;
                        }

                        return changeStreamPictureSuccess(response as ChangeStreamPictureSuccessPayload);
                    }),
                    catchError((error: ApiErrorI): Observable<Action<INotificationMessageType>> => of(
                        notyError(error.message),
                        changeStreamPictureError(null),
                    )),
                );
            }),
        ),
);

const selectTemplateForm = (store: StoreI): TemplateFormState => store.templateForm;

export const selectIsTemplateActionRequesting = createSelector(
    selectTemplateForm,
    ({ isTemplateActionRequesting }): boolean => isTemplateActionRequesting,
);

export const selectIsTemplateRequesting = createSelector(
    selectTemplateForm,
    ({ isTemplateRequesting }): boolean => isTemplateRequesting,
);

export const selectIsTemplateStreamPictureUploading = createSelector(
    selectTemplateForm,
    ({ isStreamPictureUploading }): boolean => isStreamPictureUploading,
);

export const selectData = createSelector(
    selectTemplateForm,
    ({ data }): CallTemplate => data,
);

export const selectOwnersMeta = createSelector(
    selectTemplateForm,
    ({ ownersMeta }): OwnersMetaMap => ownersMeta,
);
