import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import { YabblePropsI } from '../Yabble.interface';
import { cnYabble } from '../Yabble';
import { WithFullClickableProps } from './Yabble_fullClickable.interface';
import './Yabble_fullClickable.css';

export const withFullClickable = withBemMod<WithFullClickableProps, YabblePropsI>(
    cnYabble(),
    { fullClickable: true },
    (Yabble): FC<WithFullClickableProps & YabblePropsI> => (
        (props: WithFullClickableProps & YabblePropsI): ReactElement => {
            const {
                onClick,
            } = props;

            const addonBefore = (
                <div
                    className={cnYabble('ClickableArea')}
                    onClick={onClick}
                />
            );

            return (
                <Yabble
                    {...props}
                    addonBefore={addonBefore}
                />
            );
        }),
);

export default withFullClickable;
