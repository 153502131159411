export const ru = {
    'copy-permanent-stream-link-text': 'Постоянная ссылка на трансляцию',
    'copy-permanent-chat-link-text': 'Постоянная ссылка на чат',
    'permanent-link-copied': 'Ссылка скопирована',
    select: 'Выбрать',
    edit: 'Редактировать',
    cancel: 'Отменить',
    'delete-all': 'Удалить для всех владельцев',
    'template-deleting': 'Удаление шаблона',
    'delete-template': 'Удалить шаблон',
    'to-calendar': 'Перейти к календарю',
    participants: 'Участники',
};
