import { compose } from '@bem-react/core';
import React, { FC, ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    getUser,
    selectCurrentUser,
    selectError as selectUserError,
} from 'ducks/user';
import { selectTheme } from 'ducks/theme';

import { PageContainerProps } from './Page.interface';
import { Page as PageBase } from './Page';
import { withLoading } from './_loading/Page_loading';
import { withAuthRequired } from './_authRequired/Page_authRequired';

const PageView = compose(
    withLoading,
    withAuthRequired,
)(PageBase);

export const Page: FC<PageContainerProps> = (props): ReactElement => {
    const {
        authRequired,
        children,
    } = props;

    const dispatch = useDispatch();
    const user = useSelector(selectCurrentUser);
    const userError = useSelector(selectUserError);
    const theme = useSelector(selectTheme);

    useEffect(() => {
        dispatch(getUser());
    }, [dispatch]);

    const isDataRequested = user || userError;
    const isLoading = !isDataRequested;
    const isAuthorized = Boolean(user);

    return (
        <PageView
            loading={isLoading}
            authRequired={authRequired}
            isAuthorized={isAuthorized}
            userError={userError}
            theme={theme}
        >
            {children}
        </PageView>
    );
};
