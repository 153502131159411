import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';
import './Icon_type_calendarGray.css';

export interface WithTypeCalendarGrayProps {
    type?: 'calendarGray';
}

export const withTypeCalendarGray = withBemMod<WithTypeCalendarGrayProps>(
    'Icon',
    { type: 'calendarGray' },
    (Icon): FC => ((props): ReactElement => (<Icon {...props} />)),
);

export default withTypeCalendarGray;
