import { cn } from '@bem-react/classname';
import { compose } from '@bem-react/core';
import i18n from '@yandex-int/i18n';
import { Button } from '@yandex-lego/components/Button/desktop/bundle';
import {
    Icon as IconBase,
    withTypeClose as withIconTypeClose,
} from '@yandex-lego/components/Icon';
import React, { FC, ReactElement } from 'react';
import {
    withSizeM as withIconSizeM,
} from 'components/Icon';

import {
    MethodSelector as MethodSelectorBase,
    withTypeSelect as withMethodSelectorTypeSelect,
} from 'components/MethodSelector';

import * as keyset from './Settings.i18n';
import { SettingsProps } from './Settings.interface';

import './Settings.css';

export const cnSettings = cn('Settings');
const i18nSettings = i18n(keyset);

const Icon = compose(
    withIconTypeClose,
    withIconSizeM,
)(IconBase);

const MethodSelector = compose(
    withMethodSelectorTypeSelect,
)(MethodSelectorBase);

const iconCross = (iconClassName: string): ReactElement => (<Icon size="m" type="close" className={iconClassName} />);

export const Settings: FC<SettingsProps> = (props: SettingsProps): ReactElement => {
    const {
        settings,
        onSelectChange,
        onSave,
        onClose,
        method,
        isButtonSaveDisabled,
    } = props;

    return (
        <div className={cnSettings()}>
            <h4 className={cnSettings('DefaultConnectionType')}>
                {i18nSettings('default-connection-type')}
            </h4>
            <MethodSelector
                className={cnSettings('Select')}
                onChange={onSelectChange}
                options={settings.availableMethods}
                value={method}
                type="select"
                size="m"
            />
            <div className={cnSettings('Controls')}>
                <Button
                    view="action"
                    size="s"
                    disabled={isButtonSaveDisabled}
                    onClick={onSave}
                    className={cnSettings('ButtonSave')}
                >
                    {i18nSettings('save')}
                </Button>
                <Button
                    view="pseudo"
                    size="s"
                    onClick={onClose}
                    className={cnSettings('ButtonCancel')}
                >
                    {i18nSettings('cancel')}
                </Button>
                <Button
                    view="clear"
                    size="s"
                    onClick={onClose}
                    icon={iconCross}
                    className={cnSettings('ButtonCross')}
                />
            </div>
        </div>
    );
};
