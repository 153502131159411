import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';
import './Icon_type_arrowRight.css';

export interface WithTypeArrowRightProps {
  type?: 'arrowRight';
}

export const withTypeArrowRight = withBemMod<WithTypeArrowRightProps>(
    'Icon',
    { type: 'arrowRight' },
    (Icon): FC => ((props): ReactElement => (<Icon {...props} />)),
);

export default withTypeArrowRight;
