import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import { cnStreamPoster } from '../StreamPoster';
import './StreamPoster_size_l.css';

export interface WithSizeLProps {
    size?: 'l';
}

export const withSizeL = withBemMod<WithSizeLProps>(
    cnStreamPoster(),
    { size: 'l' },
    (StreamPoster): FC => ((props): ReactElement => (<StreamPoster {...props} />)),
);

export default withSizeL;
