import React, { FC, ReactElement } from 'react';
import { cn } from '@bem-react/classname';
import { useComponentRegistry } from '@bem-react/di';
import i18n from '@yandex-int/i18n';

import { Tumbler } from '@yandex-lego/components/Tumbler/desktop/bundle';
import { Button } from '@yandex-lego/components/Button/desktop/bundle';

import { InviteCallLink } from 'components/InviteCallLink';

import { Participants } from './Participants';
import { Duration } from './Duration';
import { PrivateStream } from './PrivateStream/CreateCallForm-PrivateStream';
import { CreateCallFormProps } from './CreateCallForm.interface';
import * as keyset from './CreateCallForm.i18n';
import './CreateCallForm.css';

export const cnCreateCallForm = cn('CreateCallForm');
export const registryId = cnCreateCallForm();

export const i18nCallForm = i18n(keyset);

export const CreateCallForm: FC<CreateCallFormProps> = (props: CreateCallFormProps): ReactElement => {
    const {
        participantsList,
        participantsCount,
        stream,
        record,
        isPrivateStream,
        duration,
        user,
        currentUser,
        calendarEventMeta,
        isActionRequesting,
        isCallButtonEnable,
        inviteCallLink,
        onSubmit,
        onFieldRecordChange,
        onFieldStreamChange,
        onFieldIsPrivateStreamChange,
        onDurationChange,
        onParticipantsSuggestSelect,
        onDeleteParticipant,
        onChangeParticipantSelectedMethod,
        onCreateCallButtonClick,
        onCreateTemplateButtonClick,
        onClearButtonClick,
        onGetEventAttendeesButtonClick,
        onAddCurrentUser,
        onChangeCurrentUserSelectedMethod,
        onReGenerateInviteCallLinkClick,
        className,
    } = props;

    const {
        UserCallControl,
    } = useComponentRegistry(registryId);

    return (
        <form className={cnCreateCallForm(null, [className])} onSubmit={onSubmit}>
            <Duration
                duration={duration}
                onDurationChange={onDurationChange}
            />

            <UserCallControl
                type="join"
                currentUser={currentUser}
                isCallAffectRequesting={isActionRequesting}
                onAddCurrentUser={onAddCurrentUser}
                onChangeCurrentUserSelectedMethod={onChangeCurrentUserSelectedMethod}
                className={cnCreateCallForm('UserCallControl')}
            />

            <InviteCallLink
                calendarEventMeta={calendarEventMeta}
                inviteCallLink={inviteCallLink}
                onReGenerateInviteCallLinkClick={onReGenerateInviteCallLinkClick}
                className={cnCreateCallForm('InviteCallLink')}
            />

            <Participants
                participantsList={participantsList}
                participantsCount={participantsCount}
                user={user}
                calendarEventMeta={calendarEventMeta}
                onParticipantsSuggestSelect={onParticipantsSuggestSelect}
                onDeleteParticipant={onDeleteParticipant}
                onChangeParticipantSelectedMethod={onChangeParticipantSelectedMethod}
                onGetEventAttendeesButtonClick={onGetEventAttendeesButtonClick}
            />

            <div className={cnCreateCallForm('Stream')}>
                <div className={cnCreateCallForm('StreamLabel')}>
                    <div className={cnCreateCallForm('StreamLabelTitle')}>{i18nCallForm('stream-label-title')}</div>
                    <div className={cnCreateCallForm('StreamLabelSubTitle')}>{i18nCallForm('stream-label-subtitle')}</div>
                </div>
                <div className={cnCreateCallForm('StreamControl')}>
                    <Tumbler
                        onChange={onFieldStreamChange}
                        size="s"
                        view="default"
                        checked={stream}
                    />
                    {
                        stream && !isPrivateStream && (
                            <div className={cnCreateCallForm('StreamNotification')}>{i18nCallForm('stream-notification')}</div>
                        )
                    }
                </div>
            </div>

            <PrivateStream
                onFieldIsPrivateStreamChange={onFieldIsPrivateStreamChange}
                isPrivateStream={isPrivateStream}
                stream={stream}
                isAdmin={user.is_admin}
            />

            <div className={cnCreateCallForm('Record')}>
                <div className={cnCreateCallForm('RecordLabel')}>
                    <div className={cnCreateCallForm('RecordLabelTitle')}>{i18nCallForm('record-label-title')}</div>
                    <div className={cnCreateCallForm('RecordLabelSubTitle')}>{i18nCallForm('record-label-subtitle')}</div>
                </div>
                <div className={cnCreateCallForm('RecordControl')}>
                    <Tumbler
                        onChange={onFieldRecordChange}
                        size="s"
                        view="default"
                        checked={record}
                    />
                </div>
            </div>

            <div className={cnCreateCallForm('ControlButtons')}>
                <Button
                    view="action"
                    size="m"
                    disabled={isActionRequesting || !isCallButtonEnable}
                    onClick={onCreateCallButtonClick}
                    className={cnCreateCallForm('ControlButton', { type: 'create-call' })}
                >
                    {i18nCallForm('create-call-button')}
                </Button>
                <Button
                    view="pseudo"
                    size="m"
                    onClick={onCreateTemplateButtonClick}
                    className={cnCreateCallForm('ControlButton', { type: 'create-template' })}
                >
                    {i18nCallForm('create-template-button')}
                </Button>
                <Button
                    view="pseudo"
                    size="m"
                    onClick={onClearButtonClick}
                    className={cnCreateCallForm('ControlButton', { type: 'clear' })}
                >
                    {i18nCallForm('clear-button')}
                </Button>
            </div>
        </form>
    );
};
