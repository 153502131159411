import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';
import './Icon_type_endCall.css';

export interface WithTypeEndCallProps {
  type?: 'endCall';
}

export const withTypeEndCall = withBemMod<WithTypeEndCallProps>(
    'Icon',
    { type: 'endCall' },
    (Icon): FC => ((props): ReactElement => (<Icon {...props} />)),
);

export default withTypeEndCall;
