import { compose, composeU } from '@bem-react/core';
import React, { FC, ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { push } from 'connected-react-router';

import { selectCurrentUser } from 'ducks/user';
import {
    selectCmsLink,
    selectError as selectInviteCallLinkError,
    check as checkInviteCallLink,
} from 'ducks/inviteCallLink';
import { hideYandexBug, showYandexBug } from 'ducks/yandexBug';
import { hideYandexLamp, showYandexLamp } from 'ducks/yandexLamp';

import { InviteCallLinkScreen as InviteCallLinkScreenBase } from './InviteCallLinkScreen';
import { withLoading as withInviteCallLinkScreenLoading } from './_loading/InviteCallLinkScreen_loading';
import { withErrorUnavailable as withInviteCallLinkScreenErrorUnavailable } from './_error/InviteCallLinkScreen_error_unavailable';
import { withErrorNotFound as withInviteCallLinkScreenErrorNotFound } from './_error/InviteCallLinkScreen_error_notFound';

const InviteCallLinkScreenView = compose(
    withInviteCallLinkScreenLoading,
    composeU(
        withInviteCallLinkScreenErrorUnavailable,
        withInviteCallLinkScreenErrorNotFound,
    ),
)(InviteCallLinkScreenBase);

export const InviteCallLinkScreen: FC = (): ReactElement => {
    const location = useLocation();
    const dispatch = useDispatch();

    const userData = useSelector(selectCurrentUser);
    const cmsLink = useSelector(selectCmsLink);
    const errorData = useSelector(selectInviteCallLinkError);

    const isLoading = !errorData;
    const [errorType, setErrorType] = useState<'unavailable' | 'notFound'>();

    useEffect(() => {
        const query = new URLSearchParams(location.search);

        const masterId = Number(query.get('master_id'));
        const eventId = Number(query.get('event_id'));
        const secret = query.get('secret');

        dispatch(checkInviteCallLink({
            masterId,
            eventId,
            secret,
        }));
    }, [location, dispatch]);

    useEffect(() => {
        if (cmsLink) {
            window.location.href = cmsLink;
        }
    }, [cmsLink]);

    useEffect(() => {
        if (errorData) {
            if (errorData.code === 403) setErrorType('unavailable');
            if (errorData.code === 404) {
                if (!errorData?.details) {
                    setErrorType('unavailable');
                } else {
                    setErrorType('notFound');
                }
            }
        }
    }, [errorData, setErrorType]);

    useEffect((): FunctionVoid => {
        // скрываем жука и лампу для внешних (не интранет) пользователей этой страницы
        if (!userData) {
            dispatch(hideYandexBug());
            dispatch(hideYandexLamp());
        }

        return () => {
            dispatch(showYandexBug());
            dispatch(showYandexLamp());
        };
    }, [dispatch, userData]);

    const handleCreateCallButtonClick = useCallback(() => {
        const query = new URLSearchParams();
        query.set('eventId', errorData?.details?.event?.id);

        dispatch(push(`/create-call?${query}`));
    }, [errorData, dispatch]);

    return (
        <InviteCallLinkScreenView
            loading={isLoading}
            error={errorType as undefined}
            errorDetails={errorData?.details}
            onCreateCallButtonClick={handleCreateCallButtonClick}
        />
    );
};
