import { compose, withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import {
    ParticipantAvatar as ParticipantAvatarBase,
    withTypeArbitrary as withParticipantAvatarTypeArbitrary,
} from 'components/ParticipantAvatar';

import { cnParticipant } from '../Participant@desktop';
import { ParticipantProps } from '../Participant.interface';
import { WithTypeArbitraryProps } from './Participant_type_arbitrary.interface';

const ParticipantAvatar = compose(
    withParticipantAvatarTypeArbitrary,
)(ParticipantAvatarBase);

export const withTypeArbitrary = withBemMod<WithTypeArbitraryProps, ParticipantProps>(
    cnParticipant(),
    { type: 'arbitrary' },
    (Participant): FC<ParticipantProps & WithTypeArbitraryProps> => (
        (props: ParticipantProps & WithTypeArbitraryProps): ReactElement => {
            const {
                title,
            } = props;

            const icon = (
                <ParticipantAvatar type="arbitrary" />
            );

            const name = (
                <div className={cnParticipant('Name')}>{title}</div>
            );

            return (
                <Participant
                    {...props}
                    icon={icon}
                    name={name}
        />
            );
        }
    ),
);

export default withTypeArbitrary;
