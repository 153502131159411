import { createAction, handleActions } from 'redux-actions';
import { StoreI } from '../store';

const HIDE = 'vconf/footer/HIDE';
const SHOW = 'vconf/footer/SHOW';

export const hideFooter = createAction(HIDE);
export const showFooter = createAction(SHOW);

export interface FooterState {
    isVisible: boolean
}

export const initialState = {
    isVisible: true,
};

export const reducer = handleActions<FooterState>(
    {
        [HIDE]: (state): FooterState => ({
            ...state,
            isVisible: false,
        }),

        [SHOW]: (state): FooterState => ({
            ...state,
            isVisible: true,
        }),
    },
    initialState,
);

export const selectFooter = (state: StoreI): FooterState => state.footer;
