import { IParticipantType } from 'components/Participant/Participant.interface';
import { CallFormDuration } from 'components/CreateCallForm/CreateCallForm.interface';

import { CallFormQueryParams } from './CreateCallForm.interface';

export const parseQuery = (): CallFormQueryParams => {
    const query = new URLSearchParams(window.location.search);

    const result: CallFormQueryParams = {
        participants: [],
    };

    if (query.has('templateId')) {
        result.templateId = Number(query.get('templateId'));
    }

    if (query.has('eventId')) {
        result.eventId = Number(query.get('eventId'));
    }

    if (query.has('stream')) {
        result.stream = query.get('stream') === 'true';
    }

    if (query.has('record')) {
        result.record = query.get('record') === 'true';
    }

    if (query.has('duration')) {
        result.duration = parseInt(query.get('duration'), 10) as CallFormDuration;
    }

    if (query.has('room')) {
        result.participants.push(...query.getAll('room').map(id => ({
            id,
            type: 'room' as IParticipantType,
        })));
    }

    if (query.has('person')) {
        result.participants.push(...query.getAll('person').map(id => ({
            id,
            type: 'person' as IParticipantType,
        })));
    }

    if (query.has('arbitrary')) {
        result.participants.push(...query.getAll('arbitrary').map(id => ({
            id,
            type: 'arbitrary' as IParticipantType,
        })));
    }

    return result;
};

export const formatQuery = (call: CallFormQueryParams): string => {
    const { stream, record, name, participants, templateId, eventId, duration } = call;

    const query = new URLSearchParams({
        record: record ? 'true' : 'false',
        stream: stream ? 'true' : 'false',
        duration: `${duration}`,
    });

    if (name) {
        query.set('name', name);
    }

    if (templateId) {
        query.set('templateId', String(templateId));
    }

    if (eventId) {
        query.set('eventId', String(eventId));
    }

    participants.forEach((participant): void => {
        query.append(participant.type, participant.id);
    });

    return query.toString();
};
