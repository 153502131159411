import { Menu } from './Header.interface';

export const serviceLogoSrc = 'https://yastatic.net/s3/frontend/yandex-lego/serp-header/_/tools-logo/vconf.svg';
export const serviceNameLogoSrc = 'https://yastatic.net/q/logoaas/v2/VConf.svg';

export const colorMenuActive = '#010681';
export const colorMenuActiveHover = '#000467';

export const menu: Menu = {
    main: {
        textKey: 'main',
        active: false,
        url: '/',
    },

    history: {
        textKey: 'history',
        active: false,
        url: '/calls-history',
    },

    streams: {
        textKey: 'streams',
        active: false,
        url: '/streams',
    },

    docs: {
        textKey: 'docs',
        active: false,
        url: 'https://wiki.yandex-team.ru/staff/vconf',
        target: '_blank',
    },
};
