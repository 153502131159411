import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

export interface WithTypeErrorProps {
    type?: 'error';
}

export const withTypeError = withBemMod<WithTypeErrorProps>(
    'SvgIcon',
    { type: 'error' },
    (SvgIcon): FC => ((props): ReactElement => (
        <SvgIcon {...props}>
            <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10zm-2 0a8 8 0 11-16 0 8 8 0 0116 0zm-9.25 4.243c0-.723.55-1.243 1.243-1.243.708 0 1.257.52 1.257 1.243 0 .722-.55 1.257-1.257 1.257a1.228 1.228 0 01-1.243-1.257zM11.322 6a.5.5 0 00-.5.522l.307 7a.5.5 0 00.5.478h.742a.5.5 0 00.5-.478l.306-7a.5.5 0 00-.5-.522h-1.355z" /></svg>
        </SvgIcon>
    )),
);

export default withTypeError;
