import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import { cnBubble, BubbleProps } from '../Bubble';

import './Bubble_type_counter.css';

export interface BubbleTypeCounterData {
  id: 'counter'
  count: number
}

export interface WithTypeCounter {
  type?: 'counter'
  data: BubbleTypeCounterData
}

export const withTypeCounter = withBemMod<WithTypeCounter, BubbleProps>(
    cnBubble(),
    { type: 'counter' },
    (Bubble): FC<WithTypeCounter & BubbleProps> => (props: WithTypeCounter & BubbleProps): ReactElement | null => {
        const { data: { count } } = props;

        if (count <= 0) {
            return null;
        }

        return (
            <Bubble {...props}>
                { Math.min(count, 99) }
            </Bubble>
        );
    },
);

export default withTypeCounter;
