import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import i18n from '@yandex-int/i18n';
import React, { FC, ReactElement } from 'react';

import cnFormatTime, { FormatTimeProps } from './FormatTime.interface';
import * as keyset from './FormatTime.i18n';

const i18nFormatTime = i18n(keyset);

dayjs.extend(calendar);

const FormatTime: FC<FormatTimeProps> = (props: FormatTimeProps): ReactElement => {
    const {
        startDate,
        endDate,
        withTime,
        withDate,
    } = props;

    const EM_DASH = '—';

    if (!startDate) {
        return (
            <span className={cnFormatTime('Date', { empty: true })}>
                {EM_DASH}
            </span>
        );
    }

    const startDateParsed = dayjs(startDate);
    const endDateParsed = dayjs(endDate);

    const time = `${startDateParsed.format('HH:mm')} – ${endDateParsed.format('HH:mm')}`;
    const date = startDateParsed.calendar(null, {
        sameDay: `[${i18nFormatTime('today')}]`,
        nextDay: 'DD MMM',
        nextWeek: 'DD MMM',
        lastDay: `[${i18nFormatTime('yesterday')}]`,
        lastWeek: 'DD MMM',
        sameElse: 'DD MMM',
    });

    return (
        <>
            {
                withDate && (
                    <span className={cnFormatTime('Date')}>
                        {date}
                        {' '}
                    </span>
                )
            }
            {
                withTime && (
                    <span className={cnFormatTime('Time')}>
                        {time}
                    </span>
                )
            }
        </>
    );
};

export default FormatTime;
