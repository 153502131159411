import {
    Button as ButtonBase,
    withThemeClear as withButtonThemeClear,
    withSizeM as withButtonSizeM,
} from '@yandex-lego/components/Button/desktop';
import { Icon as IconBase } from '@yandex-lego/components/Icon/desktop';
import { cn } from '@bem-react/classname';
import { compose } from '@bem-react/core';
import React, { ReactElement } from 'react';
import {
    withTypeArrowLeft as withIconTypeArrowLeft,
    withSizeM as withIconSizeM,
} from 'components/Icon';
import { BackLinkProps } from './BackLink.interface';

import './BackLink.css';

const Button = compose(
    withButtonThemeClear,
    withButtonSizeM,
)(ButtonBase);
const Icon = compose(
    withIconTypeArrowLeft,
    withIconSizeM,
)(IconBase);
const cnBackLink = cn('BackLink');

const iconArrowLeft = (iconClassName: string): ReactElement => <Icon type="arrowLeft" size="m" className={iconClassName} />;

export const BackLink = (props: BackLinkProps): ReactElement => {
    const {
        className,
        title,
        onClick,
    } = props;

    return (
        <div className={cnBackLink(null, [className])}>
            <Button
                theme="clear"
                size="m"
                iconLeft={iconArrowLeft}
                onClick={onClick}
        >
                {title}
            </Button>
        </div>
    );
};

export default BackLink;
