import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';
import './Icon_type_record.css';

export interface WithTypeRecordProps {
  type?: 'record';
}

export const withTypeRecord = withBemMod<WithTypeRecordProps>(
    'Icon',
    { type: 'record' },
    (Icon): FC => ((props): ReactElement => (<Icon {...props} />)),
);

export default withTypeRecord;
