import { useComponentRegistry } from '@bem-react/di';
import React, { FC, ReactElement } from 'react';

import { registryId } from 'components/GlobalContextRegistry/GlobalContextRegistry.constants';

import { PageHeaderProps } from './Page-Header.interface';

export const Header: FC<PageHeaderProps> = (props): ReactElement => {
    const {
        isAuthorized,
    } = props;

    const {
        Header,
    } = useComponentRegistry(registryId);

    return <Header noAuth={!isAuthorized} />;
};
