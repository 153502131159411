import { composeU } from '@bem-react/core';

import React, { FC, ReactElement } from 'react';

import {
    CallFormTitle as CallFormTitleBase,
    withTypeEvent as withCallFormTitleTypeEvent,
} from 'components/CallFormTitle';

import { ActiveCallScreenHeaderProps } from './ActiveCallScreen-Header.interface';
import { cnActiveCallScreen } from '../ActiveCallScreen';
import './ActiveCallScreen-Header.css';

const CallFormTitle = composeU(
    withCallFormTitleTypeEvent,
)(CallFormTitleBase);

export const Header: FC<ActiveCallScreenHeaderProps> =
  (props: ActiveCallScreenHeaderProps): ReactElement => {
      const {
          title,
          eventId,
          className,
      } = props;

      let titleType;

      if (eventId) titleType = 'event';

      return (
          <CallFormTitle
              type={titleType as undefined}
              title={title}
              eventId={eventId}
              className={cnActiveCallScreen('Header', [className])}
          />
      );
  };
