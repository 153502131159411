import { connect } from 'react-redux';
import { FC, ReactElement, useCallback, useEffect } from 'react';

import { setVisibility as setPageVisibility } from 'ducks/pageVisibility';

import {
    PageVisibilityContainerMapDispatchToProps,
    PageVisibilityContainerProps,
} from './PageVisibility.interface';

export const PageVisibilityContainer: FC<PageVisibilityContainerProps> =
    (props: PageVisibilityContainerProps): ReactElement => {
        const {
            setPageVisibility: setPageVisibilityAction,
        } = props;

        const handleVisibilityChange = useCallback(() => {
            switch (document.visibilityState) {
                case 'visible':
                    setPageVisibilityAction(true);
                    break;
                case 'hidden':
                    setPageVisibilityAction(false);
            }
        }, [setPageVisibilityAction]);

        useEffect(() => {
            document.addEventListener('visibilitychange', handleVisibilityChange);

            return () => {
                document.removeEventListener('visibilitychange', handleVisibilityChange);
            };
        }, [handleVisibilityChange]);

        return null;
    };

export const PageVisibility = connect<null, PageVisibilityContainerMapDispatchToProps>(
    null,
    {
        setPageVisibility,
    },
)(PageVisibilityContainer);
