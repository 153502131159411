import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';
import './Icon_size_xs.css';

export interface WithSizeXsProps {
  size?: 'xs';
}

export const withSizeXs = withBemMod<WithSizeXsProps>(
    'Icon',
    { size: 'xs' },
    (Icon): FC => ((props): ReactElement => (<Icon {...props} />)),
);

export default withSizeXs;
