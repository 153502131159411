import React, { FC } from 'react';
import dayjs from 'dayjs';
import { composeU } from '@bem-react/core';

import { Icon as IconBase } from '@yandex-lego/components/Icon/desktop';
import { withCalendarIcon } from '@yandex-int/hr-components/Icon/_glyph/Calendar';
import { withInNewTabIcon } from '@yandex-int/hr-components/Icon/_glyph/InNewTab';

import { useKhTitle } from 'hooks/useKhTitle';

import { Button } from 'shared/ui/components/Button';

import { KhErrorState } from 'khural-components/KhErrorState';

import { KhAnnouncementScreenProps } from './KhAnnouncementScreen.interface';
import { cnKhAnnouncementScreen } from './KhAnnouncementScreen.cn';
import { i18n } from './KhAnnouncementScreen.translations';

import gooseNext from './assets/goose-next.png';

import './KhAnnouncementScreen.css';

const Icon = composeU(
    withCalendarIcon,
    withInNewTabIcon,
)(IconBase);

const calendarIconProvider = (className: string) => (
    <Icon glyph="calendar" className={className} />
);

export const KhAnnouncementScreen: FC<KhAnnouncementScreenProps> = props => {
    const {
        announcementType,
        event,
        replacer,
        className,
    } = props;

    useKhTitle([i18n(`title-${announcementType}`)]);

    const khuralStartTitle = (
        <>
            {dayjs(event.startTs).format('DD MMMM YYYY')}
            <br />
            {dayjs(event.startTs).format(`[${i18n('at')}] HH:mm`)}
        </>
    );

    const calendarEventUrl = new URL(`https://calendar.yandex-team.ru/event/${event.id}/`);

    const addToCalenarAction = (
        <Button
            size="m"
            view="action"
            iconLeft={calendarIconProvider}
            url={calendarEventUrl.toString()}
            type="link"
            target="_blank"
            as="a"
        >
            {i18n('add-to-calendar')}
        </Button>
    );

    const actions = [addToCalenarAction];

    return (
        <div className={cnKhAnnouncementScreen(null, [className])}>
            <div className={cnKhAnnouncementScreen('Content')}>
                {
                    replacer || (
                        <KhErrorState
                            size="m"
                            actions={actions}
                            image={gooseNext}
                            title={khuralStartTitle}
                            description={i18n(`next-${announcementType}`)}
                            className={cnKhAnnouncementScreen('ErrorState', { type: 'announcement' })}
                        />
                    )
                }
            </div>
        </div>
    );
};
