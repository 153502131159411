import React, { ReactElement, FC } from 'react';
import { withBemMod } from '@bem-react/core';
import { Spin } from '@yandex-lego/components/Spin/bundle';

import { cnKhStreamScreen } from '../../KhStreamScreen.cn';

import { KhStreamScreenRateInformerProps } from '../KhStreamScreen-RateInformer.interface';

import { WithLoadingProps } from './KhStreamScreen-RateInformer_loading.interface';

export const withLoading = withBemMod<WithLoadingProps, KhStreamScreenRateInformerProps>(
    cnKhStreamScreen('RateInformer'),
    { loading: true },
    (KhStreamScreenRateInformer): FC<WithLoadingProps & KhStreamScreenRateInformerProps> => (
        (props: WithLoadingProps & KhStreamScreenRateInformerProps): ReactElement => {
            const trailing = (
                <Spin
                    className={cnKhStreamScreen('Spin')}
                    size="l"
                    view="default"
                    progress
                />
            );

            return (
                <KhStreamScreenRateInformer
                    {...props}
                    trailing={trailing}
                />
            );
        }
    ),
);
