import { compose } from '@bem-react/core';
import { Registry, withRegistry } from '@bem-react/di';
import i18n from '@yandex-int/i18n';
import { Button } from '@yandex-lego/components/Button/desktop/bundle';
import {
    cnAttach,
    Attach as AttachBase,
    withSizeS as withAttachSizeS,
} from '@yandex-lego/components/Attach/desktop';
import {
    Textarea as TextareaBase,
    withViewDefault as withTextareaViewDefault,
    withSizeM as withTextareaSizeM,
} from '@yandex-lego/components/Textarea/desktop';
import { Tumbler } from '@yandex-lego/components/Tumbler/desktop/bundle';
import { PERMANENT_LINK_TYPE } from 'components/PermanentLinkScreen/PermanentLinkScreen.interface';

import React, { FC, ReactElement } from 'react';

import { TemplateFormScreenStreamProps } from './TemplateFormScreen-Stream.interface';
import { cnTemplateFormScreen } from '../TemplateFormScreen';
import { CopyLink } from '../TemplateFormScreen-CopyLink';
import * as keyset from '../TemplateFormScreen.i18n';
import './TemplateFormScreen-Stream.css';

const i18nTemplateFormScreen = i18n(keyset);

const Textarea = compose(
    withTextareaViewDefault,
    withTextareaSizeM,
)(TextareaBase);

const attachRegistry = new Registry({ id: cnAttach() });
attachRegistry.set('Button', props => (
    <Button
        {...props}
        className={cnTemplateFormScreen('StreamAttachButton')}
    />
));
const Attach = compose(
    withAttachSizeS,
    withRegistry(attachRegistry),
)(AttachBase);

export const StreamMobile: FC<TemplateFormScreenStreamProps> = (props): ReactElement => {
    const {
        templateId,
        eventId,
        templateStreamPicture,
        templateStreamDescription,
        isStreamOn,
        onFieldStreamChange,
        onFieldStreamPictureChange,
        onFieldStreamDescriptionChange,
    } = props;

    // Название картинки на бэке - timestamp + имя исходного файла. Обрезаем timestamp, чтобы показывать только имя
    const templateStreamPictureFileName = decodeURI(templateStreamPicture.split('/').pop().replace(/^\d+-/, ''));

    const isStreamSettingsVisible = isStreamOn;

    return (
        <div className={cnTemplateFormScreen('Stream', { streamSettingsVisible: isStreamSettingsVisible })}>
            <div className={cnTemplateFormScreen('StreamField')}>
                <div className={cnTemplateFormScreen('StreamContent')}>
                    <div className={cnTemplateFormScreen('StreamLabel')}>
                        <div className={cnTemplateFormScreen('StreamLabelTitle')}>{i18nTemplateFormScreen('stream-label-title')}</div>
                        <div className={cnTemplateFormScreen('StreamLabelSubTitle')}>{i18nTemplateFormScreen('stream-label-subtitle')}</div>
                    </div>
                    <div className={cnTemplateFormScreen('StreamControl')}>
                        <Tumbler
                            onChange={onFieldStreamChange}
                            size="s"
                            view="default"
                            checked={isStreamOn}
                        />
                    </div>
                </div>
                <div className={cnTemplateFormScreen('StreamPermanentLinks')}>
                    <CopyLink
                        eventId={eventId}
                        templateId={templateId}
                        isStreamSwitcherOn={isStreamOn}
                        type={PERMANENT_LINK_TYPE.STREAM}
                 />
                    <CopyLink
                        eventId={eventId}
                        templateId={templateId}
                        isStreamSwitcherOn={isStreamOn}
                        type={PERMANENT_LINK_TYPE.CHAT}
                 />
                </div>
            </div>
            { isStreamSettingsVisible && (
                <>
                    <div className={cnTemplateFormScreen('StreamPicture')}>
                        <div className={cnTemplateFormScreen('StreamPictureLabel')}>
                            <div className={cnTemplateFormScreen('StreamPictureLabelTitle')}>
                                {i18nTemplateFormScreen('stream-picture-label-title')}
                            </div>
                            <div className={cnTemplateFormScreen('StreamPictureLabelSubTitle')}>
                                {i18nTemplateFormScreen('stream-picture-label-subtitle', {
                                    posterSize: '960x256',
                                })}
                            </div>
                        </div>
                        <div className={cnTemplateFormScreen('StreamPictureField')}>
                            <Attach
                                view="default"
                                size="s"
                                onChange={onFieldStreamPictureChange}
                                disabled
                                accept="image/*"
                                className={cnTemplateFormScreen('StreamPictureAttach')}
                            >
                                {templateStreamPictureFileName || undefined}
                            </Attach>
                        </div>
                        <div className={cnTemplateFormScreen('StreamPictureMobileAlert')}>
                            {i18nTemplateFormScreen('stream-picture-mobile-alert')}
                        </div>
                    </div>
                    <div className={cnTemplateFormScreen('StreamDescription')}>
                        <div className={cnTemplateFormScreen('StreamDescriptionLabel')}>
                            <div className={cnTemplateFormScreen('StreamDescriptionLabelTitle')}>{i18nTemplateFormScreen('stream-description-label-title')}</div>
                            <div className={cnTemplateFormScreen('StreamDescriptionLabelSubTitle')}>{i18nTemplateFormScreen('stream-description-label-subtitle')}</div>
                        </div>
                        <div className={cnTemplateFormScreen('StreamDescriptionField')}>
                            <Textarea
                                view="default"
                                size="m"
                                placeholder={i18nTemplateFormScreen('stream-description-placeholder')}
                                value={templateStreamDescription}
                                onChange={onFieldStreamDescriptionChange}
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};
