import { createSelector } from 'reselect';
import { createAction, handleActions } from 'redux-actions';

import { StoreI } from 'ducks/store';

const HIDE = 'vconf/yandexLamp/HIDE';
const SHOW = 'vconf/yandexLamp/SHOW';

export const hideYandexLamp = createAction(HIDE);
export const showYandexLamp = createAction(SHOW);

export interface YandexLampState {
  isHidden: boolean
}

export const initialState = {
    isHidden: false,
};

export const reducer = handleActions<YandexLampState>(
    {
        [HIDE]: (state): YandexLampState => ({
            ...state,
            isHidden: true,
        }),

        [SHOW]: (state): YandexLampState => ({
            ...state,
            isHidden: false,
        }),
    },
    initialState,
);

export const selectYandexLamp = (store: StoreI): YandexLampState => store.yandexLamp;

export const selectIsHidden = createSelector(
    selectYandexLamp,
    ({ isHidden }): boolean => isHidden,
);
