import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';
import './Icon_type_square.css';

export interface WithTypeSquareProps {
  type?: 'square';
}

export const withTypeSquare = withBemMod<WithTypeSquareProps>(
    'Icon',
    { type: 'square' },
    (Icon): FC => ((props): ReactElement => (<Icon {...props} />)),
);

export default withTypeSquare;
