export const en = {
    'name-label': 'Name',
    'duration-label': 'Duration',
    'participants-title': 'Participants',
    'participants-suggest-placeholder': 'Enter name, room or external number',
    'stream-label-title': 'Stream',
    'stream-label-subtitle': 'The ability to monitor a call without the participation of',
    'record-label-title': 'Record',
    'record-label-subtitle': 'The ability to view call in history',
    'create-call-button': 'Call',
    'cancel-button': 'Cancel',
    'create-template-button': 'Create template',
    'clear-button': 'Clear',
    'stream-notification': 'The stream will be available for all staff',
    'is-private-stream-label-subtitle': 'This stream will not be displayed in the list of streams and will only be available via a direct link',
    'is-private-stream-label-title': 'Private stream',
    'add-participants-from-event-button-text': 'Add participants to the call',
};
