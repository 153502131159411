import { withBemMod } from '@bem-react/core';

import { Spin } from '@yandex-lego/components/Spin/bundle';
import React, { FC, ReactElement } from 'react';

import { cnActiveCallScreen } from '../ActiveCallScreen';
import { ActiveCallScreenProps } from '../ActiveCallScreen.interface';
import { WithLoading } from './ActiveCallScreen_loading.interface';
import './ActiveCallScreen_loading.css';

export const withLoading = withBemMod<WithLoading, ActiveCallScreenProps>(
    cnActiveCallScreen(),
    { loading: true },
    (ActiveCallScreen): FC<WithLoading & ActiveCallScreenProps> => (
        (props: WithLoading & ActiveCallScreenProps): ReactElement => {
            const replacer = (
                <div className={cnActiveCallScreen('Spinner')}>
                    <Spin
                        size="l"
                        view="default"
                        progress
                    />
                </div>
            );

            return (
                <ActiveCallScreen
                    {...props}
                    replacer={replacer}
                />
            );
        }
    ),
);

export default withLoading;
