import { withBemMod, compose } from '@bem-react/core';

import { useComponentRegistry } from '@bem-react/di';
import React, { FC, ReactElement } from 'react';
import { registryId } from 'components/GlobalContextRegistry/GlobalContextRegistry.constants';

import {
    ParticipantAvatar as ParticipantAvatarBase,
    withTypePerson as withParticipantAvatarTypePerson,
} from 'components/ParticipantAvatar';

import { cnParticipant } from '../Participant@desktop';
import { ParticipantProps } from '../Participant.interface';
import { WithTypePersonProps } from './Participant_type_person.interface';

const ParticipantAvatar = compose(
    withParticipantAvatarTypePerson,
)(ParticipantAvatarBase);

export const withTypePerson = withBemMod<WithTypePersonProps, ParticipantProps>(
    cnParticipant(),
    { type: 'person' },
    (Participant): FC<ParticipantProps & WithTypePersonProps> => (
        (props: ParticipantProps & WithTypePersonProps): ReactElement => {
            const {
                id,
                title,
                isStaffCard = true,
            } = props;

            const { StaffName } = useComponentRegistry(registryId);

            const icon = (
                <ParticipantAvatar type="person" id={id} />
            );

            let name: string | ReactElement = title;

            if (isStaffCard) {
                name = (
                    <div className={cnParticipant('Name')}>
                        <StaffName id={id} name={title as string} />
                    </div>
                );
            }

            return (
                <Participant
                    {...props}
                    icon={icon}
                    name={name}
        />
            );
        }
    ),
);

export default withTypePerson;
