import { Registry, withRegistry } from '@bem-react/di';
import React, { ReactElement } from 'react';
import StaffCard from 'components/StaffCard';

import {
    StaffName,
    StaffNameProps,
} from 'components/StaffName';
import { HeaderDesktop } from 'components/Header';

import {
    GlobalContextRegistry as GlobalContextRegistryBase,
} from './GlobalContextRegistry';
import { registryId } from './GlobalContextRegistry.constants';

const globalRegistry = new Registry({ id: registryId });
globalRegistry.set('StaffName', (props: StaffNameProps): ReactElement => (
    <StaffCard login={props.id}>
        <StaffName {...props} />
    </StaffCard>
));
globalRegistry.set('Header', HeaderDesktop);

export const GlobalContextRegistryDesktop = withRegistry(globalRegistry)(GlobalContextRegistryBase);

export default GlobalContextRegistryDesktop;
