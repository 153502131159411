import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';
import './Icon_tone_white.css';

export interface WithToneWhiteProps {
  tone?: 'white';
}

export const withToneWhite = withBemMod<WithToneWhiteProps>(
    'Icon',
    { tone: 'white' },
    (Icon): FC => ((props): ReactElement => (<Icon {...props} />)),
);

export default withToneWhite;
