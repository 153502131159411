import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import { Stub } from 'components/Stub/desktop/bundle';
import YandexBug from 'components/YandexBug';
import { KhFooter } from 'khural-components/KhFooter/KhFooter';

import { KhPageContent } from '../Content/KhPage-Content';

import { KhPageProps } from '../KhPage.interface';
import { cnKhPage } from '../KhPage.cn';
import { i18n } from '../KhPage.translations';

import './KhPage_error.css';

export interface WithError {
    error?: boolean;
}

export const withError = withBemMod<WithError, KhPageProps>(
    cnKhPage(),
    { error: true },
    (Page): FC<WithError & KhPageProps> => (
        (props: WithError & KhPageProps): ReactElement => {
            const replacer = (
                <>
                    <KhPageContent>
                        <div className={cnKhPage('Error')}>
                            <Stub
                                type="noConnection"
                                title={i18n('error.user-get.title')}
                                description={i18n('error.user-get.description')}
                            />
                        </div>
                    </KhPageContent>
                    <KhFooter />
                    <YandexBug />
                </>
            );

            return (
                <Page
                    {...props}
                    replacer={replacer}
                />
            );
        }
    ),
);
