import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';
import './Icon_type_chain.css';

export interface WithTypeChainProps {
  type?: 'chain';
}

export const withTypeChain = withBemMod<WithTypeChainProps>(
    'Icon',
    { type: 'chain' },
    (Icon): FC => ((props): ReactElement => (<Icon {...props} />)),
);

export default withTypeChain;
