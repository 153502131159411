import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import { CopyTextProps } from '../CopyText.interface';
import { WithReverseProps } from './CopyText_reverse.interface';
import { cnCopyText } from '../CopyText';

import './CopyText_reverse.css';

export const withReverse = withBemMod<WithReverseProps, CopyTextProps>(
    cnCopyText(),
    { reverse: true },
    (CopyText): FC<WithReverseProps & CopyTextProps> => (props: WithReverseProps & CopyTextProps): ReactElement => {
        return (<CopyText {...props} />);
    },
);

export default withReverse;
