import i18n from '@yandex-int/i18n';
import { compose } from '@bem-react/core';
import useInterval from '@use-it/interval';
import React, { FC, ReactElement, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';

import { useTitle } from 'hooks/useTitle';

import { StoreI } from 'ducks/store';
import {
    requestStreams,
    requestUpcomingStreams,
    clearStreams,
} from 'ducks/streams';
import {
    selectStreamsList,
    selectUpcomingStreams,
} from 'ducks/streams/streams';
import { selectIsPageVisible } from 'ducks/pageVisibility';

import { withLoading } from './_loading/StreamsScreen_loading';
import { withEmpty } from './_empty/StreamsScreen_empty';
import { StreamsScreen as StreamsScreenBase } from './StreamsScreen';
import {
    StreamsScreenContainerMapStateProps,
    StreamsScreenContainerProps,
    StreamsScreenContainerMapDispatchToProps,
} from './StreamsScreen.interface';
import * as keyset from './StreamsScreen.i18n';

const StreamsScreenView = compose(
    withEmpty,
    withLoading,
)(StreamsScreenBase);

const i18nStreamsScreenContainer = i18n(keyset);

export const StreamsScreenContainer: FC<StreamsScreenContainerProps> =
    (props: StreamsScreenContainerProps): ReactElement => {
        const {
            streams,
            upcomingStreams,
            isPageVisible,
            requestStreams: requestStreamsAction,
            requestUpcomingStreams: requestUpcomingStreamsAction,
            clearStreams: clearStreamsAction,
        } = props;

        useTitle([i18nStreamsScreenContainer('title-streams')]);

        const isLoading = !streams || !upcomingStreams.list;
        const isEmpty = streams?.length === 0 && upcomingStreams.list?.length === 0;

        useEffect((): FunctionVoid => {
            requestStreamsAction();
            requestUpcomingStreamsAction();

            return (): void => {
                clearStreamsAction();
            };
        }, [clearStreamsAction, requestStreamsAction, requestUpcomingStreamsAction]);

        const onUpdateStreamsScreen = useCallback((): void => {
            if (isPageVisible) {
                requestStreamsAction({
                    refresh: true,
                });
                requestUpcomingStreamsAction({
                    refresh: true,
                });
            }
        }, [isPageVisible, requestStreamsAction, requestUpcomingStreamsAction]);

        useInterval(onUpdateStreamsScreen, 10 * 1000);

        return (
            <StreamsScreenView
                streams={streams}
                upcomingStreams={upcomingStreams.list}
                empty={isEmpty}
                loading={isLoading}
            />
        );
    };

function mapStateToProps(store: StoreI): StreamsScreenContainerMapStateProps {
    return {
        streams: selectStreamsList(store),
        upcomingStreams: selectUpcomingStreams(store),
        isPageVisible: selectIsPageVisible(store),
    };
}

export const StreamsScreen = connect<StreamsScreenContainerMapStateProps, StreamsScreenContainerMapDispatchToProps>(
    mapStateToProps,
    {
        requestStreams,
        clearStreams,
        requestUpcomingStreams,
    },
)(StreamsScreenContainer);
