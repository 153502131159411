import { ApiErrorI } from 'util/api.rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { createSelector, Selector } from 'reselect';
import dayjs from 'dayjs';
import ru from 'dayjs/locale/ru';

import { setI18nLang, Language } from '@yandex-int/i18n';
import { Locale } from 'dayjs/locale/*';
import { Action, createAction, handleActions } from 'redux-actions';

import { InjectedDependencies, StoreI } from 'ducks/store';

import { ParticipantI } from 'components/Participant/Participant.interface';
import { notyError } from 'components/Notifications/Notifications.actions';
import { INotificationMessageType } from 'components/Notifications/Notifications.interface';

type LocalesMap = {
    [lang in Language]?: Locale;
}

const dayjsLocales: LocalesMap = {
    ru,
};

export interface User extends ParticipantI {
    is_ip_external: boolean;
    is_external: boolean;
    lang: Language;
    is_admin: boolean;
    environment: string;
    uid: number;
}

export interface UserError {
    code: number;
    message: string;
}

export type UserState = {
    data: User;
    error: UserError;
    isRequesting: boolean;
};

export const initialState: UserState = {
    data: null,
    error: null,
    isRequesting: false,
};

export type SetUserPayload = Partial<User>;
export type GotUserPayload = User;
export type GotUserErrorPayload = UserError;

export const GET = 'vconf/user/GET';
export const GOT = 'vconf/user/GOT';
export const GOT_ERROR = 'vconf/user/GOT_ERROR';
export const SET = 'vconf/user/SET';

export const getUser = createAction(GET);
export const gotUser = createAction<GotUserPayload>(GOT);
export const gotUserError = createAction<GotUserErrorPayload>(GOT_ERROR);
export const setUser = createAction<SetUserPayload>(SET);

export const reducer = handleActions<UserState, SetUserPayload | GotUserPayload | GotUserErrorPayload>(
    {
        [GET]: (state): UserState => ({
            ...state,
            isRequesting: true,
        }),

        [GOT]: (state, action: Action<GotUserPayload>): UserState => {
            const { payload } = action;

            return {
                ...state,
                data: payload,
                isRequesting: false,
            };
        },

        [GOT_ERROR]: (state, action: Action<GotUserErrorPayload>): UserState => {
            const { payload } = action;

            return {
                ...state,
                error: payload,
                isRequesting: false,
            };
        },

        [SET]: (state, action: Action<SetUserPayload>): UserState => {
            const { payload } = action;

            return {
                ...state,
                data: {
                    ...state.data,
                    ...payload,
                },
            };
        },
    },
    initialState,
);

type GetUserResponsePayload = GotUserPayload | GotUserErrorPayload | INotificationMessageType;

export const epic = combineEpics(
    (action$, $state: StoreI, { userApi }: InjectedDependencies): Observable<Action<GetUserResponsePayload>> =>
        action$.pipe(
            ofType(GET),
            mergeMap((): Observable<Action<GetUserResponsePayload>> =>
                userApi.getUserData().pipe(
                    map((response): Action<GetUserResponsePayload> => {
                        const possiblyErrorResponse = response as ApiErrorI;

                        if (possiblyErrorResponse.error) {
                            throw possiblyErrorResponse;
                        }

                        const user = response as GotUserPayload;

                        setI18nLang(user.lang);
                        dayjs.locale(user.lang, dayjsLocales[user.lang]);

                        return gotUser(user);
                    }),
                    catchError((error: ApiErrorI): Observable<Action<
                      INotificationMessageType
                      | GotUserErrorPayload
                    >> => {
                        const lang = (window.navigator?.language || 'ru') as Language;

                        setI18nLang(lang);
                        dayjs.locale(lang, dayjsLocales[lang]);

                        return of(
                            notyError(error.message),
                            gotUserError({
                                code: error.response_code,
                                message: error.message,
                            }),
                        );
                    }),
                ),
            ),
        ),
);

export const selectUserState: Selector<StoreI, UserState> = (store): UserState => store.user;

export const selectCurrentUser = createSelector(
    selectUserState,
    ({ data }): User => data,
);

export const selectIsRequesting = createSelector(
    selectUserState,
    ({ isRequesting }): boolean => isRequesting,
);

export const selectError = createSelector(
    selectUserState,
    ({ error }): UserError => error,
);
