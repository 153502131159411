import { compose } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import React, { ReactElement } from 'react';

import {
    Table as TableBase,
    withTypeEvents as withTableTypeEvents,
    withAutoupdate as withTableAutoupdate,
} from 'components/Table';
import Unit from 'components/Unit';

import { CalendarEventsProps } from './CalendarEvents.interface';

const Table = compose(
    withTableTypeEvents,
    withTableAutoupdate,
)(TableBase);

const cnCalendarEvents = cn('CalendarEvents');

export const CalendarEvents = (props: CalendarEventsProps): ReactElement => {
    const {
        events,
        onNavigateToCallButtonClick,
        onCollectCallButtonClick,
        onUpdate,
        className,
        onCopyLink,
    } = props;

    return (
        <Unit className={cnCalendarEvents(null, [className])}>
            <Table
                type="events"
                events={events}
                onNavigateToCallButtonClick={onNavigateToCallButtonClick}
                onCollectCallButtonClick={onCollectCallButtonClick}
                autoupdate
                onUpdate={onUpdate}
                onCopyLink={onCopyLink}
      />
        </Unit>
    );
};

export default CalendarEvents;
