import i18n from '@yandex-int/i18n';
import * as keyset from './MethodSelector.i18n';
import { MethodsMap } from './MethodSelector.interface';

const i18nMethodSelector = i18n(keyset);

export const getMethods = (): MethodsMap => ({
    cisco: i18nMethodSelector('cisco'),
    email: i18nMethodSelector('email'),
    messenger_q: i18nMethodSelector('messenger_q'),
    mobile: i18nMethodSelector('mobile'),
    placeholder: i18nMethodSelector('placeholder'),
    zoom: i18nMethodSelector('zoom'),
    cms: i18nMethodSelector('cms'),
});

export default getMethods;
