import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    setTitle,
    RequestTitlePayload,
} from 'ducks/title';

const EM_DASH = '—';
const DEFAULT_TITLE = 'vconf';

/**
 * Выставляет title страницы в формате `payload[0] – payload[1] - ... — vconf`.
 * Может принимать любое количество аргументов.
 * Пример: `useTitle(['Хурал', 'Трансляции'])` выставит title для страницы "Хурал — Трансляции — vconf"
 *
 * @param payload
 */
export const useTitle = (payload: RequestTitlePayload[]): void => {
    const dispatch = useDispatch();

    const title = payload.concat(DEFAULT_TITLE).join(` ${EM_DASH} `);

    useEffect(() => {
        dispatch(setTitle(title));
    }, [dispatch, title]);
};
