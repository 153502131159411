import i18n from '@yandex-int/i18n';
import { push } from 'connected-react-router';
import React, { FC, ReactElement, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';

import throttle from 'lodash/throttle';

import {
    requestCalendarEvents,
    clearCalendarEvents,
    selectEvents,
    selectIsRequesting,
} from 'ducks/calendarEvents';
import { StoreI } from 'ducks/store';
import { selectIsPageVisible } from 'ducks/pageVisibility';

import { notySuccess } from 'components/Notifications/Notifications.actions';

import * as keyset from './CalendarEvents.i18n';
import {
    CalendarEvent,
    CalendarEventsContainerProps,
    CalendarEventsMapStateToProps,
    CalendarEventsMapDispatchToProps,
} from './CalendarEvents.interface';
import { CalendarEvents as CalendarEventsView } from './CalendarEvents';

const i18nCalendarEvents = i18n(keyset);

const CalendarEventsContainer: FC<CalendarEventsContainerProps> =
  (props: CalendarEventsContainerProps): ReactElement => {
      const {
          events,
          className,
          isPageVisible,
          requestCalendarEvents: requestCalendarEventsAction,
          clearCalendarEvents: clearCalendarEventsAction,
          push: pushAction,
          notySuccess: notySuccessActions,
      } = props;

      useEffect((): FunctionVoid => {
          requestCalendarEventsAction(null);

          return (): void => {
              clearCalendarEventsAction(null);
          };
      }, [clearCalendarEventsAction, requestCalendarEventsAction]);

      const handleUpdate = useCallback((): void => {
          if (isPageVisible) {
              requestCalendarEventsAction(null);
          }
      }, [isPageVisible, requestCalendarEventsAction]);

      const handleNavigateToCallButtonClick = useCallback((id, secret): FunctionVoid => {
          const query = new URLSearchParams({ secret });

          return (): void => {
              pushAction(`/active-call/${id}?${query}`);
          };
      }, [pushAction]);

      const handleCollectCallButtonClick = useCallback((data: CalendarEvent): FunctionVoid => {
          const query = new URLSearchParams({
              eventId: String(data.id),
          });

          return (): void => {
              window.scroll({
                  behavior: 'smooth',
                  top: 0,
              });

              pushAction(`/create-call?${query}`);
          };
      }, [pushAction]);

      const handleCopyLink = useCallback(throttle((): void => {
          notySuccessActions(i18nCalendarEvents('on-handle-copy-link'));
      }, 1000), []);

      return (
          <CalendarEventsView
              events={events}
              onNavigateToCallButtonClick={handleNavigateToCallButtonClick}
              onCollectCallButtonClick={handleCollectCallButtonClick}
              onUpdate={handleUpdate}
              className={className}
              onCopyLink={handleCopyLink}
          />
      );
  };

export const CalendarEvents = connect<CalendarEventsMapStateToProps, CalendarEventsMapDispatchToProps>(
    (store: StoreI): CalendarEventsMapStateToProps => ({
        isRequesting: selectIsRequesting(store),
        events: selectEvents(store),
        isPageVisible: selectIsPageVisible(store),
    }),
    {
        requestCalendarEvents,
        clearCalendarEvents,
        notySuccess,
        push,
    },
)(CalendarEventsContainer);

export default CalendarEvents;
