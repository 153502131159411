import {
    ParticipantsListGroup,
} from 'components/ParticipantsList/ParticipantsList.interface';
import {
    ActiveCallParticipantI,
    IParticipantState,
} from 'components/ActiveCalls/ActiveCalls.interface';
import { ParticipantI } from 'components/Participant/Participant.interface';
import {
    isActiveCallParticipant,
    extendParticipant,
} from 'components/Participant/Participant.util';

export const getParticipantFromList =
  (
      list: Map<string, ParticipantsListGroup>,
      groupID: string,
      participantID: string,
  ): ActiveCallParticipantI => {
      if (!list) return null;

      const group = list.get(groupID);
      if (!group) return null;

      const participant = group.content.get(participantID);
      if (!participant) return null;

      return participant;
  };

export const generateParticipantsListData =
  (
      participants?: ActiveCallParticipantI[] | ParticipantI[],
      state?: IParticipantState,
      prevList?: Map<string, ParticipantsListGroup>,
  ): [number, Map<string, ParticipantsListGroup>] => {
      if (!participants) return [0, new Map()];

      const participantsMap = new Map();
      let count = 0;

      participants.forEach((participant): void => {
          if (!isActiveCallParticipant(participant)) {
              participant = extendParticipant(participant);
          }

          if (!state || (participant as ActiveCallParticipantI).state === state) {
              const prevParticipant = prevList ? getParticipantFromList(prevList, 'main', participant.id) : null;
              const newParticipant = prevParticipant ? {
                  ...participant,
                  method: prevParticipant.method,
              } : { ...participant };

              participantsMap.set(participant.id, newParticipant);
              count += 1;
          }
      });

      return [
          count,
          new Map([
              ['main', {
                  content: participantsMap,
              }],
          ]),
      ];
  };

export const getAllParticipantsFromList = (groups: Map<string, ParticipantsListGroup>): ActiveCallParticipantI[] => {
    let participants: ActiveCallParticipantI[] = [];
    groups.forEach((group): void => {
        participants = participants.concat(Array.from(group.content.values()));
    });
    return participants;
};
