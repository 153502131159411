export const ru = {
  'cisco_phone': 'Рабочий телефон',
  'email': 'Email',
  'messenger_q': 'Мессенджер',
  'mobile': 'Мобильный',
  'placeholder': 'Уведомление',
  'zoom': 'Zoom',
  'cisco': 'Cisco Jabber',
  'cms': 'Браузер',
};
