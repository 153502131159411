import { withBemMod, compose } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';
import {
    Stub as StubBase,
    withTypeNoCalls,
} from 'components/Stub';

import { cnTable } from '../Table';
import { TableProps } from '../Table.interface';

import { WithStubProps } from './Table_stub.interface';
import './Table_stub.css';

const Stub = compose(
    withTypeNoCalls,
)(StubBase);

const withStub = withBemMod<WithStubProps, TableProps>(
    cnTable(),
    { stub: true },
    (Table): FC<WithStubProps & TableProps> => (
        (props: WithStubProps & TableProps): ReactElement => {
            const {
                isFirstLoading,
                isRequesting,
                isTableEmpty,
                stubTitle = '',
                stubDescription = '',
                stubButton = '',
                addonBefore,
                addonAfter,
            } = props;

            const stub = (
                <div className={cnTable('Stub')}>
                    <Stub
                        type="noCalls"
                        title={stubTitle}
                        description={stubDescription}
                        button={stubButton}
          />
                </div>
            );

            const isStubVisible = isTableEmpty && !isRequesting && !isFirstLoading;

            return (
                <Table
                    {...props}
                    addonBefore={isStubVisible ? stub : addonBefore}
                    addonAfter={isStubVisible ? null : addonAfter}
        />
            );
        }
    ),
);

export default withStub;
