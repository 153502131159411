import React, { ReactElement, FC } from 'react';
import { withBemMod } from '@bem-react/core';

import { cnStreamScreen } from '../../StreamScreen.cn';
import { i18nStreamScreen } from '../../StreamScreen';
import { StreamScreenRateInformerProps } from '../StreamScreen-RateInformer.interface';

import { WithSuccessProps } from './StreamScreen-RateInformer_success.interface';

export const withSuccess = withBemMod<WithSuccessProps, StreamScreenRateInformerProps>(
    cnStreamScreen('RateInformer'),
    { success: true },
    (StreamScreenRateInformer): FC<WithSuccessProps & StreamScreenRateInformerProps> => (
        (props: WithSuccessProps & StreamScreenRateInformerProps): ReactElement => {
            const replacer = (
                <div className={cnStreamScreen('RateInformerTitle', { state: 'success' })}>
                    {i18nStreamScreen('rate-informer-success-title')}
                </div>
            );

            return (
                <StreamScreenRateInformer
                    {...props}
                    replacer={replacer}
                />
            );
        }
    ),
);
