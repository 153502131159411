import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import { cnBubble, BubbleProps } from '../Bubble';

import arbitraryAvatar from './assets/phone.svg';

export interface WithTypeArbitrary {
  type?: 'arbitrary'
}

export const withTypeArbitrary = withBemMod<WithTypeArbitrary, BubbleProps>(
    cnBubble(),
    { type: 'arbitrary' },
    (Bubble): FC => (props): ReactElement => {
        return (
            <Bubble {...props}>
                <img
                    className={cnBubble('Avatar')}
                    src={arbitraryAvatar}
                    alt="avatar"
                />
            </Bubble>
        );
    },
);

export default withTypeArbitrary;
