import apiRxjs, { ApiErrorI } from 'util/api.rxjs';
import { Observable } from 'rxjs';

import { ActiveCallI } from 'components/ActiveCalls/ActiveCalls.interface';
import { RequestActiveCallsPayload } from './activeCalls';

export const getActiveCalls = ({ showAll }: RequestActiveCallsPayload): Observable<ActiveCallI[] | ApiErrorI> => {
    const url: URL = new URL('frontapi/calls/', window.location.origin);

    url.searchParams.set('show_all', `${showAll}`);

    if (showAll) {
        url.searchParams.set('limit', '1000');
    }

    return apiRxjs.get<ActiveCallI[]>(url);
};

export interface ActiveCallsApi {
  getActiveCalls: (payload: RequestActiveCallsPayload) => Observable<ActiveCallI[] | ApiErrorI>;
}

export const activeCallsApi = {
    getActiveCalls,
};
