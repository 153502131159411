import { createSelector } from 'reselect';
import { StoreI } from '../../../ducks/store';

const MAX_MESSAGES_NUMBER = 15; // чтобы не рендерить слишком много пунктов за пределами экрана

export const getSortedMessages = createSelector(
    (store: StoreI) => store.clickerZoom.messages,
    messages => {
        return Object.values(messages)
            .sort((a, b) => a.timestamp - b.timestamp)
            .filter((_, i, array) => (array.length - i) < MAX_MESSAGES_NUMBER);
    },
);
