import { ApiErrorI } from 'util/api.rxjs';
import HttpError from 'util/httpError';
import i18n from '@yandex-int/i18n';
import { combineEpics, ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { CallHistoryMethodAction, replace, push, LocationActionPayload } from 'connected-react-router';
import { createSelector } from 'reselect';
import { Action, createAction, handleActions } from 'redux-actions';

import { ActiveCallI } from 'components/ActiveCalls/ActiveCalls.interface';
import { CallForm, CallFormDuration } from 'components/CreateCallForm/CreateCallForm.interface';
import { formatQuery, parseQuery } from 'components/CreateCallForm/CreateCallForm.util';
import { ParticipantBaseI, ParticipantI } from 'components/Participant/Participant.interface';
import { INotificationMessageType } from 'components/Notifications/Notifications.interface';
import { notyError, notyWarning } from 'components/Notifications/Notifications.actions';
import { getConnectionMethod } from 'components/Participant/Participant.util';

import { InjectedDependencies, StoreI } from 'ducks/store';
import { clear as clearCalendarEventMeta } from 'ducks/calendarEventMeta';

import * as keyset from './createCallForm.i18n';

const i18nCreateCallForm = i18n(keyset);

const SET_CALL_FORM = 'vconf/createCallForm/SET_CALL_FORM';
const SET_CALL_FORM_AND_SYNC = 'vconf/createCallForm/SET_CALL_FORM_WITH_SYNC';
const CLEAR_CALL_FORM = 'vconf/createCallForm/CLEAR_CALL_FORM';
const CLEAR_CALL_FORM_AND_SYNC = 'vconf/createCallForm/CLEAR_CALL_FORM_AND_SYNC';
const REQUEST_CREATE_CALL = 'vconf/createCallForm/REQUEST_CREATE_CALL';
const RECEIVE_CREATE_CALL_SUCCESS = 'vconf/createCallForm/RECEIVE_CREATE_CALL_SUCCESS';
const RECEIVE_CREATE_CALL_ERROR = 'vconf/createCallForm/RECEIVE_CREATE_CALL_ERROR';
const SYNC_CALL_FORM_WITH_URL = 'vconf/createCallForm/SYNC_CALL_FORM_WITH_URL';
const SYNC_URL_WITH_CALL_FORM = 'vconf/createCallForm/SYNC_URL_WITH_CALL_FORM';
const GET_EVENT_ATTENDEES = 'vconf/createCallForm/GET_EVENT_ATTENDEES';
const GOT_EVENT_ATTENDEES = 'vconf/createCallForm/GOT_EVENT_ATTENDEES';

export type SetCallFormPayload = CallForm;
export type SetCallFormAndSyncPayload = CallForm;
export type SyncUrlWithCallFormPayload = CallForm;
export type RequestCreateCallPayload = CallForm;
export type ReceiveCreateCallSuccessPayload = ActiveCallI;
export type GetEventAttendeesPayload = ParticipantBaseI[];
export type GotEventAttendeesPayload = ParticipantI[];
export type ErrorDetailsEventCallExists = {
    eventId: number;
}
export type ReceiveCreateCallErrorPayload = HttpError<ErrorDetailsEventCallExists>;

export const setCallForm = createAction<SetCallFormPayload>(SET_CALL_FORM);
export const setCallFormAndSync = createAction<SetCallFormAndSyncPayload>(SET_CALL_FORM_AND_SYNC);
export const clearCallForm = createAction(CLEAR_CALL_FORM);
export const clearCallFromAndSync = createAction(CLEAR_CALL_FORM_AND_SYNC);
export const requestCreateCall = createAction<RequestCreateCallPayload>(REQUEST_CREATE_CALL);
export const receiveCreateCallSuccess = createAction<ReceiveCreateCallSuccessPayload>(RECEIVE_CREATE_CALL_SUCCESS);
export const receiveCreateCallError = createAction<ReceiveCreateCallErrorPayload>(RECEIVE_CREATE_CALL_ERROR);
export const syncCallFormWithUrl = createAction(SYNC_CALL_FORM_WITH_URL);
export const syncUrlWithCallForm = createAction<SyncUrlWithCallFormPayload>(SYNC_URL_WITH_CALL_FORM);
export const getEventAttendees = createAction<GetEventAttendeesPayload>(GET_EVENT_ATTENDEES);
export const gotEventAttendees = createAction<GotEventAttendeesPayload>(GOT_EVENT_ATTENDEES);

type ParticipantsMap = {
    [id: string]: ParticipantI;
}

export interface CreateCallFormState {
  data: CallForm;
  isRequesting: boolean;
  isActionRequesting: boolean;
  error: HttpError<ErrorDetailsEventCallExists>;
}

export interface CallFormAPI {
  name?: string;
  record: boolean;
  stream: boolean;
  is_private_stream: boolean;
  template_id: number;
  event_id: number;
  duration: CallFormDuration;
  participants: ParticipantI[];
}

export const initialCallFormData: CallForm = {
    name: null,
    participants: [],
    stream: false,
    record: false,
    eventId: null,
    templateId: null,
    duration: 60,
    isPrivateStream: false,
};

export const initialState: CreateCallFormState = {
    data: { ...initialCallFormData },
    isRequesting: false,
    isActionRequesting: false,
    error: null,
};

type CallFormPayload =
    SetCallFormPayload |
    SetCallFormAndSyncPayload |
    RequestCreateCallPayload |
    ReceiveCreateCallSuccessPayload |
    ReceiveCreateCallErrorPayload |
    GotEventAttendeesPayload;

export const reducer = handleActions<CreateCallFormState, CallFormPayload>({
    [SET_CALL_FORM]: (state, action: Action<SetCallFormPayload>): CreateCallFormState => {
        const { payload } = action;

        return {
            ...state,
            data: payload,
        };
    },

    [SET_CALL_FORM_AND_SYNC]: (state, action: Action<SetCallFormAndSyncPayload>): CreateCallFormState => {
        const { payload } = action;

        return {
            ...state,
            data: payload,
        };
    },

    [CLEAR_CALL_FORM]: (state): CreateCallFormState => ({
        ...state,
        data: initialCallFormData,
    }),

    [CLEAR_CALL_FORM_AND_SYNC]: (state): CreateCallFormState => ({
        ...state,
        data: initialCallFormData,
    }),

    [REQUEST_CREATE_CALL]: (state): CreateCallFormState => ({
        ...state,
        isActionRequesting: true,
    }),

    [RECEIVE_CREATE_CALL_SUCCESS]: (state): CreateCallFormState => ({
        ...state,
        isActionRequesting: false,
    }),

    [RECEIVE_CREATE_CALL_ERROR]: (state, action: Action<ReceiveCreateCallErrorPayload>): CreateCallFormState => {
        const { payload } = action;

        return {
            ...state,
            isActionRequesting: false,
            error: payload,
        };
    },

    [GOT_EVENT_ATTENDEES]: (state, action: Action<GotEventAttendeesPayload>): CreateCallFormState => {
        const { payload: eventAttendees } = action;
        const participants: ParticipantsMap = {};

        for (const attendee of eventAttendees) {
            participants[attendee.id] = {
                ...attendee,
                method: getConnectionMethod(attendee),
            };
        }

        // Обеспечиваем отсутствие повторов в списке
        for (const participant of state.data.participants) {
            participants[participant.id] = participant;
        }

        return {
            ...state,
            data: {
                ...state.data,
                participants: Object.values(participants),
            },
        };
    },
}, initialState);

type ResultCallPayload = ReceiveCreateCallSuccessPayload | ReceiveCreateCallErrorPayload;
type ResultReceiveCreateCallError = LocationActionPayload | INotificationMessageType;

export const epic = combineEpics(
    (
        action$: Observable<Action<RequestCreateCallPayload>>,
        state$: StoreI,
        api: InjectedDependencies,
    ): Observable<Action<ResultCallPayload>> =>
        action$.pipe(
            ofType(REQUEST_CREATE_CALL),
            mergeMap(({ payload }): Observable<Action<ResultCallPayload>> =>
                api.createCallFormApi.createCall(payload).pipe(
                    map((response): Action<ResultCallPayload> => {
                        const possiblyErrorResponse = response as ApiErrorI;

                        if (possiblyErrorResponse.error) {
                            const errorDetails: ErrorDetailsEventCallExists = {
                                eventId: payload.eventId,
                            };

                            return receiveCreateCallError(
                                new HttpError<ErrorDetailsEventCallExists>(
                                    possiblyErrorResponse.response_code,
                                    possiblyErrorResponse.message,
                                    possiblyErrorResponse.error_code,
                                    errorDetails,
                                ),
                            );
                        }

                        return receiveCreateCallSuccess(response as ReceiveCreateCallSuccessPayload);
                    }),
                ),
            ),
        ),

    (action$: Observable<Action<ReceiveCreateCallSuccessPayload>>): Observable<Action<LocationActionPayload>> =>
        action$.pipe(
            ofType(RECEIVE_CREATE_CALL_SUCCESS),
            mergeMap(({ payload }): Observable<Action<LocationActionPayload>> => {
                const url: URL = new URL(`active-call/${payload.id}`, window.location.origin);

                url.searchParams.set('secret', payload.secret);

                return of(
                    push(`${url.pathname}${url.search}`),
                );
            }),
        ),

    (action$: Observable<Action<ReceiveCreateCallErrorPayload>>): Observable<Action<ResultReceiveCreateCallError>> =>
        action$.pipe(
            ofType(RECEIVE_CREATE_CALL_ERROR),
            mergeMap(({ payload }): Observable<Action<ResultReceiveCreateCallError>> => {
                if (payload.errorCode === 'call_already_exists') {
                    return of(
                        clearCalendarEventMeta(),
                        notyWarning(`${i18nCreateCallForm('call-already-exists-error')}`),
                        push(`/events/${payload.errorDetails.eventId}`),
                    );
                }
                return of(notyError(payload.message));
            }),
        ),

    (action$: Observable<Action<SetCallFormAndSyncPayload>>): Observable<Action<SyncUrlWithCallFormPayload>> =>
        action$.pipe(
            ofType(SET_CALL_FORM_AND_SYNC),
            mergeMap((
                { payload }: Action<SyncUrlWithCallFormPayload>,
            ): Observable<Action<SyncUrlWithCallFormPayload>> => {
                return Observable.of(
                    syncUrlWithCallForm(payload),
                );
            }),
        ),

    (action$: Observable<Action<void>>): Observable<Action<SyncUrlWithCallFormPayload>> =>
        action$.pipe(
            ofType(CLEAR_CALL_FORM_AND_SYNC),
            mergeMap((): Observable<Action<SyncUrlWithCallFormPayload>> =>
                Observable.of(
                    syncUrlWithCallForm({
                        ...initialCallFormData,
                    }),
                )),
        ),

    (action$: Observable<Action<SyncUrlWithCallFormPayload>>): Observable<CallHistoryMethodAction> =>
        action$.pipe(
            ofType(SYNC_URL_WITH_CALL_FORM),
            mergeMap(({ payload }: Action<SyncUrlWithCallFormPayload>): Observable<CallHistoryMethodAction> => {
                const query = formatQuery(payload);

                return Observable.of(
                    replace({
                        search: query,
                    }),
                );
            }),
        ),

    (
        action$: Observable<Action<GetEventAttendeesPayload>>,
        state$: Observable<StoreI>,
        api: InjectedDependencies,
    ): Observable<Action<GotEventAttendeesPayload | INotificationMessageType>> =>
        action$.pipe(
            ofType(GET_EVENT_ATTENDEES),
            mergeMap(({ payload }): Observable<Action<GotEventAttendeesPayload | INotificationMessageType>> => {
                return api.activeCallApi.getParticipantDetails(payload).pipe(
                    map((response): Action<GotEventAttendeesPayload> => {
                        const possiblyErrorResponse = response as ApiErrorI;

                        if (possiblyErrorResponse.error) {
                            throw possiblyErrorResponse;
                        }

                        const eventAttendees = (response as GotEventAttendeesPayload).map(attendee => ({
                            ...attendee,
                            method: getConnectionMethod(attendee),
                        }));

                        return gotEventAttendees(eventAttendees);
                    }),
                    catchError((error: ApiErrorI): Observable<Action<INotificationMessageType>> => of(
                        notyError(error.message),
                    )),
                );
            }),
        ),

    (
        action$: Observable<Action<void>>,
        state$: StoreI,
        api: InjectedDependencies,
    ): Observable<Action<SetCallFormPayload>> =>
        action$.pipe(
            ofType(SYNC_CALL_FORM_WITH_URL),
            mergeMap((): Observable<Action<SetCallFormPayload>> => {
                const callFormQueryParams = parseQuery();

                return api.activeCallApi.getParticipantDetails(callFormQueryParams.participants).pipe(
                    map((response): Action<SetCallFormPayload> => {
                        const possiblyErrorResponse = response as ApiErrorI;

                        const callFormParams = {
                            ...initialCallFormData,
                            ...callFormQueryParams,
                        };

                        if (possiblyErrorResponse.error) {
                            callFormParams.participants = [];
                        } else {
                            callFormParams.participants = (response as ParticipantI[]).map(participant => ({
                                ...participant,
                                method: getConnectionMethod(participant),
                            }));
                        }

                        return setCallForm(callFormParams as CallForm);
                    }),
                );
            }),
        ),
);

const selectCreateCallForm = (store: StoreI): CreateCallFormState => store.createCallForm;

export const selectData = createSelector(
    selectCreateCallForm,
    ({ data }): CallForm => data,
);

export const selectIsRequesting = createSelector(
    selectCreateCallForm,
    ({ isRequesting }): boolean => isRequesting,
);

export const selectIsActionRequesting = createSelector(
    selectCreateCallForm,
    ({ isActionRequesting }): boolean => isActionRequesting,
);

export const selectError = createSelector(
    selectCreateCallForm,
    ({ error }): HttpError<ErrorDetailsEventCallExists> => error,
);
