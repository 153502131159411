import { composeU, withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import { Link } from '@yandex-lego/components/Link/desktop/bundle';
import { Button } from '@yandex-lego/components/Button/desktop/bundle';
import {
    Icon as IconBase,
} from '@yandex-lego/components/Icon/desktop';

import {
    withTypeHandset,
    withTypeYandexCalendar as withIconTypeYandexCalendar,
} from 'components/Icon';

import { ErrorDetailsEvent } from 'ducks/inviteCallLink';

import { InviteCallLinkScreenCallInfoProps } from '../InviteCallLinkScreen-CallInfo.interface';
import {
    cnInviteCallLinkScreen,
    i18nInviteCallLinkScreen,
} from '../../InviteCallLinkScreen';
import './InviteCallLinkScreen-CallInfo_type_authorized.css';

export interface WithTypeAuthorized {
    type?: 'authorized';
    event: ErrorDetailsEvent;
    onCreateCallButtonClick: FunctionVoid;
}

const Icon = composeU(
    withTypeHandset,
    withIconTypeYandexCalendar,
)(IconBase);

const iconHandset = (iconClassName: string): ReactElement => <Icon type="handset" className={iconClassName} />;

export const withTypeAuthorized = withBemMod<WithTypeAuthorized, InviteCallLinkScreenCallInfoProps>(
    cnInviteCallLinkScreen('CallInfo'),
    { type: 'authorized' },
    (CallInfo): FC<WithTypeAuthorized & InviteCallLinkScreenCallInfoProps> => (
        (props: WithTypeAuthorized & InviteCallLinkScreenCallInfoProps): ReactElement => {
            const {
                event,
                onCreateCallButtonClick,
            } = props;

            const calendarEventUrl = new URL('https://calendar.yandex-team.ru/event/');
            calendarEventUrl.searchParams.set('event_id', `${event.id}`);

            const addonBefore = (
                <div className={cnInviteCallLinkScreen('EventLink')}>
                    <Icon
                        type="yandexCalendar"
                        size="m"
                        className={cnInviteCallLinkScreen('Icon')}
                    />
                    <Link
                        theme="black"
                        href={calendarEventUrl.toString()}
                        className={cnInviteCallLinkScreen('Link')}
                        target="_blank"
                    >
                        {event.name}
                    </Link>
                </div>
            );

            const addonAfter = (
                <>
                    <div className={cnInviteCallLinkScreen('Annotation')}>{i18nInviteCallLinkScreen('annotation')}</div>
                    <Button
                        size="m"
                        view="action"
                        iconLeft={iconHandset}
                        onClick={onCreateCallButtonClick}
                        className={cnInviteCallLinkScreen('CreateCallButton')}
                    >
                        {i18nInviteCallLinkScreen('create-call')}
                    </Button>
                </>
            );

            return (
                <CallInfo
                    {...props}
                    addonBefore={addonBefore}
                    addonAfter={addonAfter}
                />
            );
        }
    ),
);
