export const en = {
    'title-calendar-awesome': 'Go to calendar',
    'show-share': 'Share',
    'copy': 'Copy',
    'show-go-to-chat': 'Go to chat',
    'stream-information': 'Stream information',
    'stream-is-on': 'Stream is on',
    'show-TV': 'Open chat in TV mode',
    'viewers-count': 'Viewers count',
    'started at': 'Started at',
    'ago': 'ago',
    'will-started-at': 'Starts in a ',
};
