import { cn } from '@bem-react/classname';

import { Spin } from '@yandex-lego/components/Spin/desktop/bundle';
import React, { FC, ReactElement } from 'react';

import { CalendarEventRouterScreenProps } from './CalendarEventRouterScreen.interface';
import './CalendarEventRouterScreen.css';

export const cnCalendarEventRouterScreen = cn('CalendarEventRouterScreen');

export const CalendarEventRouterScreen: FC<CalendarEventRouterScreenProps> =
  (props: CalendarEventRouterScreenProps): ReactElement => {
      const {
          className,
          children,
      } = props;

      return (
          <div className={cnCalendarEventRouterScreen(null, [className])}>
              {
                children || (
                    <div className={cnCalendarEventRouterScreen('Spin')}>
                        <Spin
                            view="default"
                            size="l"
                            progress
                        />
                    </div>
                )
            }
          </div>
      );
  };

export default CalendarEventRouterScreen;
