import { ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { StoreI } from 'ducks/store';
import { selectTitle } from 'ducks/title';
import { TitleManagerMapStateToProps, TitleManagerContainerProps } from './TitleManager.interface';

const TitleManagerContainer = (props: TitleManagerContainerProps): ReactElement => {
    const { title } = props;

    useEffect(() => {
        document.title = title;
    }, [title]);

    return null;
};

export const TitleManager = connect(
    (state: StoreI): TitleManagerMapStateToProps => ({
        title: selectTitle(state),
    }),
)(TitleManagerContainer);
