export const en = {
    clicker: 'Clicker',
    subtitle: 'Remote slide switching on the host\'s computer',
    manage: 'Manage',
    stopManagement: 'Stop management',
    next: 'Next',
    prev: 'Previous',
    instruction: 'Instructions',
    nextInstruction: 'Next instruction',
    skip: 'Skip',

    inactivityWarning: 'You don\'t switch slides for  {idleMinutes} minutes. After {promptTimeout} seconds management mode will be turned off.',
    socketNotConnected: 'Socket is not connected. Please try again.',
    serverUnavailableError: 'Service is unavailable. Please, reload the page.',
    connectionClosedError: 'Connection closed',
    socketError: 'Socket connection failed.',
    idError: 'Id is not defined.',

    nextSlide: 'Next slide',
    click: 'Click',
    change: 'Change',

    prevSlide: 'Previous slide',
    early: 'Early',
    back: 'Back',

    howItWorks: 'How it works?',
    howItWorksEmoji1: '🙂',
    howItWorksInfo1: 'Press «Manage»',
    howItWorksEmoji2: '😮',
    howItWorksInfo2: '«Next» and «Previous» buttons for flipping through the slides will appear',
    howItWorksEmoji3: '🤗',
    howItWorksInfo3: 'Start flipping through the slides',
    howItWorksEmoji4: '😍',
    howItWorksInfo4: 'If the slides are flipped through and a green checkmarks appears above the buttons — everything is ok',

    please: 'Please',
    pleaseEmoji1: '😭',
    pleaseInfo1: 'Don\'t flip through other people\'s slides, wait for your speech',
    pleaseEmoji2: '😎',
    pleaseInfo2: 'If the slides are not flipped remotely during a speech or are flipped too quickly, ask the host to switch them the old-fashioned way',
    pleaseEmoji3: '🌐',
    pleaseInfo3: 'Do not turn off the VPN during your speech. This will not affect the zoom, but it will disrupt the functioning of the clicker application.',

    whatCouldGoWrong: 'What could go wrong?',
    whatCouldGoWrongEmoji1: '🔌',
    whatCouldGoWrongInfo1: 'If no new actions appear in the list — you may have connection problems',
    whatCouldGoWrongEmoji2: '🚨️',
    whatCouldGoWrongInfo2: 'If red exclamation marks appear instead of green checkmarks — the host\'s application doesn\'t process commands',
    whatCouldGoWrongEmoji3: '😵‍💫',
    whatCouldGoWrongInfo3: 'If you see someone else\'s actions in the list — someone controls the switch at the same time as you',
};
