import i18n from '@yandex-int/i18n';
import { cn } from '@bem-react/classname';
import React, { FC, ReactElement } from 'react';

import {
    Stub as StubBase,
    withTypeNoConnection as withStubTypeNoConnection,
} from 'components/Stub';

import * as keyset from './NotFoundScreen.i18n';
import './NotFoundScreen.css';

const i18nNotFoundScreen = i18n(keyset);

const cnNotFoundScreen = cn('NotFoundScreen');

const Stub = withStubTypeNoConnection(StubBase);

export const NotFoundScreen: FC = (): ReactElement => {
    return (
        <div className={cnNotFoundScreen()}>
            <Stub
                type="noConnection"
                title="404"
                description={i18nNotFoundScreen('not-found-description')}
            />
        </div>
    );
};
