import { ApiErrorI } from 'util/api.rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { handleActions, Action, createAction } from 'redux-actions';
import { Selector } from 'react-redux';

import { IParticipantAction, IParticipantMethods } from 'components/Participant/Participant.interface';
import { notyError } from 'components/Notifications/Notifications.actions';
import { INotificationMessageType } from 'components/Notifications/Notifications.interface';

import {
    GOT as GOT_USER_ACTION_TYPE,
    setUser,
    User,
} from 'ducks/user';
import { StoreI, InjectedDependencies } from 'ducks/store';

import { SettingsData } from '.';

const SET_DATA = 'vconf/settings/SET_DATA';
const SET_SAVED = 'vconf/settings/SET_SAVED';
const REQUEST_SAVE = 'vconf/settings/REQUEST_SAVE';
const RECEIVE_SAVE = 'vconf/settings/RECEIVE_SAVE';
const ERROR_SAVE = 'vconf/settings/ERROR_SAVE';

export const setData = createAction(SET_DATA);
export const setSaved = createAction<boolean>(SET_SAVED);
export const requestSave = createAction<SettingsData>(REQUEST_SAVE);
export const receiveSave = createAction(RECEIVE_SAVE);
export const errorSave = createAction<null>(ERROR_SAVE);

export interface Settings {
    availableMethods: IParticipantAction
    currentMethod: IParticipantMethods
    isRequesting: boolean
    isSaved: boolean
}

export const initialState: Settings = {
    availableMethods: [],
    currentMethod: null,
    isRequesting: false,
    isSaved: false,
};

interface SaveSettingsPayload {
    login: string
    method: IParticipantMethods
}
export type Payload = User | SaveSettingsPayload | boolean | INotificationMessageType;
type EpicResult = Observable<Action<Payload>>;

export const reducer = handleActions<Settings, Payload>(
    {
        [SET_DATA]: (state, { payload }): Settings => ({
            ...state,
            availableMethods: (payload as User).action,
            currentMethod: (payload as User).settings.method || 'messenger_q',
        }),
        [SET_SAVED]: (state, { payload }): Settings => ({
            ...state,
            isSaved: payload as boolean,
        }),
        [REQUEST_SAVE]: (state): Settings => {
            return ({
                ...state,
                isRequesting: true,
            });
        },
        [RECEIVE_SAVE]: (state, { payload }): Settings => {
            return ({
                ...state,
                isRequesting: false,
                currentMethod: (payload as SaveSettingsPayload).method,
                isSaved: true,
            });
        },
        [ERROR_SAVE]: (state): Settings => {
            return ({
                ...state,
                isRequesting: false,
            });
        },
    },
    initialState,
);

export const epic = combineEpics(
    (action$: Observable<Action<User>>): EpicResult => {
        return action$.pipe(
            ofType(GOT_USER_ACTION_TYPE),
            map(({ payload }: Action<User>) => setData(payload)),
        );
    },
    (action$: Observable<Action<SettingsData>>, $state: StoreI, deps: InjectedDependencies): EpicResult => {
        const {
            settingsApi: { saveSettings },
        } = deps;

        return action$.pipe(
            ofType(REQUEST_SAVE),
            mergeMap(({ payload }): EpicResult =>
                saveSettings(payload).pipe(
                    mergeMap((response): Observable<Action<Payload>> => {
                        const possiblyErrorResponse = response as ApiErrorI;

                        if (possiblyErrorResponse.error) {
                            throw possiblyErrorResponse;
                        }

                        return of(
                            receiveSave(response),
                            setUser({
                                settings: {
                                    method: (payload as SaveSettingsPayload).method,
                                },
                            }),
                        );
                    }),
                    catchError((error: ApiErrorI): Observable<Action<INotificationMessageType | null>> => of(
                        notyError(error.message),
                        errorSave(null),
                    )),
                ),
            ),
        );
    },
);

export const selectSettings: Selector<StoreI, Settings> = ({ settings }): Settings => settings;
