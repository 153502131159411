import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import { Spinner } from '../Spinner';
import { PageProps } from '../Page.interface';
import { cnPage } from '../Page';
import './Page_loading.css';

export interface WithLoading {
    loading?: boolean;
}

export const withLoading = withBemMod<WithLoading, PageProps>(
    cnPage(),
    { loading: true },
    (Page): FC<WithLoading & PageProps> => (
        (props: WithLoading & PageProps): ReactElement => {
            const replacer = <Spinner />;

            return (
                <Page
                    {...props}
                    replacer={replacer}
                />
            );
        }
    ),
);
