import { withBemMod, compose } from '@bem-react/core';

import { Icon as IconBase } from '@yandex-lego/components/Icon/desktop';
import React, { FC, ReactElement } from 'react';
import FormatTime from 'components/FormatTime';

import {
    withTypeClock as withIconTypeClock,
} from 'components/Icon';

import { TableCellProps } from '../TableCell.interface';
import { cnTableCell } from '../TableCell';
import './TableCell_type_callTime.css';

export interface WithTypeCallTimeProps {
  type?: 'callTime';
  startTime?: string;
  endTime?: string;
  withTime?: boolean;
  withDate?: boolean;
}

const Icon = compose(
    withIconTypeClock,
)(IconBase);

export const withTypeCallTime = withBemMod<WithTypeCallTimeProps, TableCellProps>(
    cnTableCell(),
    { type: 'callTime' },
    (TableCell): FC<WithTypeCallTimeProps & TableCellProps> => (
        (props: WithTypeCallTimeProps & TableCellProps): ReactElement => {
            const {
                startTime,
                endTime,
                withDate,
                withTime,
            } = props;

            return (
                <TableCell {...props}>
                    <Icon type="clock" size="s" />
                    <div className={cnTableCell('Time', { empty: !startTime })}>
                        <FormatTime
                            startDate={startTime}
                            endDate={endTime}
                            withTime={withTime}
                            withDate={withDate}
                        />
                    </div>
                </TableCell>
            );
        }
    ),
);

export default withTypeCallTime;
