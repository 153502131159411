import i18n from '@yandex-int/i18n';
import { compose, composeU, withBemMod } from '@bem-react/core';
import { useComponentRegistry } from '@bem-react/di';

import { Icon as IconBase } from '@yandex-lego/components/Icon/desktop';
import { Button as ButtonBase } from '@yandex-lego/components/Button/desktop/bundle';
import React, { ChangeEvent, FC, ReactElement } from 'react';

import { usePlatformContext } from 'hooks';

import {
    withTypeHandset as withIconTypeHandset,
    withToneWhite as withIconToneWhite,
} from 'components/Icon';

import {
    withResponsive as withButtonResponsive,
    withToneRedLight as withButtonToneRedLight,
    withToneGreen as withButtonToneGreen,
    withToneYellow as withButtonToneYellow,
} from 'components/Button';

import { UserCallControlProps } from '../UserCallControl.interface';
import {
    cnUserCallControl,
    registryId,
} from '../UserCallControl';
import * as keyset from '../UserCallControl.i18n';

export interface WithTypeJoinProps {
  type?: 'join';
  onAddCurrentUser?: () => void;
  onChangeCurrentUserSelectedMethod?: (event: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => void;
}

const i18nUserCallControl = i18n(keyset);

const Button = compose(
    withButtonResponsive,
    composeU(
        withButtonToneRedLight,
        withButtonToneGreen,
        withButtonToneYellow,
    ),
)(ButtonBase);

const Icon = compose(
    withIconToneWhite,
    withIconTypeHandset,
)(IconBase);

const iconHandset = (iconClassName: string): ReactElement => <Icon type="handset" tone="white" className={iconClassName} />;

export const withTypeJoin = withBemMod<WithTypeJoinProps, UserCallControlProps>(
    cnUserCallControl('UserCallControl'),
    { type: 'join' },
    (ActiveCallScreenUserCallControl): FC<WithTypeJoinProps & UserCallControlProps> => (
        (props): ReactElement => {
            const {
                currentUser,
                onChangeCurrentUserSelectedMethod,
                onAddCurrentUser,
                isCallAffectRequesting,
            } = props;

            const {
                Participant,
            } = useComponentRegistry(registryId);

            const platform = usePlatformContext();

            return (
                <ActiveCallScreenUserCallControl {...props}>
                    <Participant
                        className={cnUserCallControl('Participant')}
                        type={currentUser.type}
                        id={currentUser.id}
                        title={currentUser.name}
                        value={currentUser.method}
                        options={currentUser.action}
                        methodSelect
                        onChangeSelectedMethod={onChangeCurrentUserSelectedMethod}
                    />
                    <Button
                        view="pseudo"
                        size={platform === 'mobile' ? 'm' : 's' as undefined}
                        tone="green"
                        icon={iconHandset}
                        responsive
                        className={cnUserCallControl('Button')}
                        onClick={onAddCurrentUser}
                        disabled={isCallAffectRequesting}
                    >
                        {i18nUserCallControl('join-button')}
                    </Button>
                </ActiveCallScreenUserCallControl>
            );
        }
    ),
);

export default withTypeJoin;
