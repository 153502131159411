import React, { ReactElement, useCallback, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { Button } from '@yandex-int/hr-components/Button/desktop/bundle';
import { Badge } from '@yandex-lego/components/Badge';
import { cn } from '@bem-react/classname';
import i18n from '@yandex-int/i18n';
import * as keyset from '../../Clicker.i18n';
import { ArrowKey, BadgeColor, ChangeSlideType, ClickerMessage } from '../../Clicker.interface';
import { MessageCard } from '../MessageCard/MessageCard';

import './ManagementPage.css';

const IDLE_MINUTES_ALLOWED = 5;
const PROMPT_TIMEOUT_IN_SECONDS = 5;
const IS_INACTIVITY_TIMER_ENABLED = false;

const cnManagementPage = cn('ManagementPage');
const i18nClicker = i18n(keyset);

export interface ManagementPageProps {
    id: string;
    env?: string;
    isLoading: boolean;
    isManaging: boolean;
    messages: ClickerMessage[];
    stopManagement: () => void;
    onChangeSlide: (id: string, env: string, slide: ChangeSlideType) => void;
    showInactivityWarning: (message: string) => void;
}

export const ManagementPage = (props: ManagementPageProps): ReactElement => {
    const {
        id,
        env,
        isLoading,
        isManaging,
        messages,
        stopManagement,
        onChangeSlide,
        showInactivityWarning,
    } = props;

    const badgeColor = isLoading ? BadgeColor.PENDING : BadgeColor.SUCCESS;

    const onInactivity = useCallback(() => {
        showInactivityWarning(i18nClicker('inactivityWarning', { idleMinutes: IDLE_MINUTES_ALLOWED, promptTimeout: PROMPT_TIMEOUT_IN_SECONDS }));
    }, []);

    const onPrevClick = useCallback(() => {
        onChangeSlide(id, env, ChangeSlideType.PREVIOUS);
    }, [env, id, onChangeSlide]);

    const onNextClick = useCallback(() => {
        onChangeSlide(id, env, ChangeSlideType.NEXT);
    }, [env, id, onChangeSlide]);

    const idleTimer = useIdleTimer({
        onPrompt: onInactivity,
        onIdle: stopManagement,
        promptTimeout: 1000 * PROMPT_TIMEOUT_IN_SECONDS,
        timeout: 1000 * 60 * IDLE_MINUTES_ALLOWED,
        startManually: true,
        stopOnIdle: true,
        onAction: () => IS_INACTIVITY_TIMER_ENABLED && idleTimer.start(),
    });

    useEffect(() => {
        if (!isManaging) {
            return;
        }

        function handleKeyDown(event: KeyboardEvent) {
            if (!isLoading && isManaging && env !== undefined) {
                switch (event.key) {
                    case ArrowKey.LEFT:
                        onPrevClick();
                        break;
                    case ArrowKey.RIGHT:
                        onNextClick();
                        break;
                }
            }
        }

        IS_INACTIVITY_TIMER_ENABLED && idleTimer.start();
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            idleTimer.pause();
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [env, isLoading, isManaging]);

    return (
        <>
            <div className={cnManagementPage('Id')}>
                <Badge
                    className={cnManagementPage('IdBadge')}
                    color={badgeColor}
                    outlineColor="var(--hr-color-page-background)"
                />
                {id}
            </div>
            <Button
                className={cnManagementPage('ButtonStopManagement')}
                view="default"
                size="l"
                onClick={stopManagement}
            >
                {i18nClicker('stopManagement')}
            </Button>
            <div className={cnManagementPage('MessagesWrapper')}>
                <div className={cnManagementPage('Messages')}>
                    {messages.map(value => (<MessageCard key={value.id} {...value} />))}
                </div>
            </div>
            <div className={cnManagementPage('Buttons')}>
                <Button
                    className={cnManagementPage('ButtonPrev')}
                    view="default"
                    size="l"
                    onClick={onPrevClick}
                    disabled={isLoading}
                >
                    {i18nClicker('prev')}
                </Button>
                <Button
                    className={cnManagementPage('ButtonNext')}
                    view="action"
                    size="l"
                    onClick={onNextClick}
                    progress={isLoading}
                >
                    {i18nClicker('next')}
                </Button>
            </div>
        </>
    );
};
