import { webSocket } from 'rxjs/webSocket';

import { Subject } from 'rxjs';
import { WebSocketData } from '../../components/Clicker/Clicker.interface';
import { notyError } from '../../components/Notifications/Notifications.actions';

const wsData: WebSocketData = {
    id: null,
    env: null,
    webSocketSubject: null,
    onOpenSubject: null,
    onCloseSubject: null,
};

const getSocketUrl = (id: string, env: string) => {
    const base = env === 'production' ?
        'wss://vconf.yandex-team.ru/clicker/ws' :
        'wss://vconf.test.yandex-team.ru/clicker/ws';

    return `${base}/${id}`;
};

const getWsData = (id: string) => {
    return wsData.id === id ? wsData : null;
};

const clearWsData = () => {
    wsData.onOpenSubject && wsData.onOpenSubject.complete();
    wsData.onCloseSubject && wsData.onCloseSubject.complete();
    wsData.webSocketSubject && wsData.webSocketSubject.complete();

    wsData.id = null;
    wsData.env = null;
    wsData.webSocketSubject = null;
    wsData.onOpenSubject = null;
    wsData.onCloseSubject = null;
};

const connectSocket = (id: string, env: string) => {
    clearWsData();

    wsData.id = id;
    wsData.env = env;
    wsData.onOpenSubject = new Subject();
    wsData.onCloseSubject = new Subject();

    wsData.webSocketSubject = webSocket({
        url: getSocketUrl(id, env),
        openObserver: wsData.onOpenSubject,
        closeObserver: wsData.onCloseSubject,
    });

    return wsData;
};

const getAdditionalFromEvent = (event: CloseEvent) => {
    const ws = (event.target || event.currentTarget || {}) as WebSocket;

    const url = ws.url || '';
    const code = event.code || '';
    const timeStamp = event.timeStamp || '';
    const type = event.type || '';
    const reason = event.reason || '';

    return { url, timeStamp, code, type, reason };
};

const logError = (error: Error, method: string, additional: Object = {}) => {
    console.error(error);
    console.error('additional:', additional);

    const env = process.env.NODE_ENV === 'test' ? 'testing' : process.env.NODE_ENV;
    const version = process.env.VERSION;

    if (window?.Ya?.Rum?.logError) {
        window.Ya.Rum.logError({
            message: error.message,
            block: 'clickerEpic',
            method: method,
            page: 'clicker',
            service: 'vconf',
            level: Ya.Rum.ERROR_LEVEL.ERROR,
            ignoreErrorMessage: true,
            additional,
        }, error, { env, version });
    }

    return notyError(error.message);
};

export interface ClickerApi {
    connectSocket: typeof connectSocket;
    getWsData: typeof getWsData;
    logError: typeof logError;
    getAdditionalFromEvent: typeof getAdditionalFromEvent;
}

export const clickerApi: ClickerApi = {
    connectSocket,
    getWsData,
    logError,
    getAdditionalFromEvent,
};
