import { Registry, withRegistry } from '@bem-react/di';
import { composeU, compose } from '@bem-react/core';

import {
    ParticipantsList as ParticipantsListBase,
    withSuggest,
    withTypeCommon,
    withAddme,
    withAddEventAttendees,
    registryId as participantsListRegistryId,
} from 'components/ParticipantsList';

import {
    Participant as ParticipantBase,
    withDelete as withParticipantDelete,
    withMethodSelect as withParticipantMethodSelect,
    withRecall as withParticipantRecall,
    withTypePerson as withParticipantTypePerson,
    withTypeRoom as withParticipantTypeRoom,
    withTypeArbitrary as withParticipantTypeArbitrary,
    withControls as withParticipantControls,
} from 'components/Participant/mobile';

import {
    registryId as userCallControlRegistryId,
    UserCallControl as UserCallControlBase,
    withTypeDisconnect as withUserCallControlTypeDisconnect,
    withTypeJoin as withUserCallControlTypeJoin,
} from 'components/UserCallControl';

import {
    CreateCallForm as CreateCallFormBase,
    cnCreateCallForm,
} from './CreateCallForm';

const Participant = compose(
    withParticipantMethodSelect,
    withParticipantDelete,
    withParticipantRecall,
    withParticipantControls,
    composeU(
        withParticipantTypeRoom,
        withParticipantTypePerson,
        withParticipantTypeArbitrary,
    ),
)(ParticipantBase);

const ParticipantsList = compose(
    withAddme,
    withAddEventAttendees,
    withSuggest,
    withTypeCommon,
)((ParticipantsListBase));

const participantsListRegistry = new Registry({ id: participantsListRegistryId });
participantsListRegistry.set('Participant', Participant);

const userCallControlRegistry = new Registry({ id: userCallControlRegistryId });

userCallControlRegistry.set('Participant', Participant);

const UserCallControl = composeU(
    withUserCallControlTypeDisconnect,
    withUserCallControlTypeJoin,
)(UserCallControlBase);

const registry = new Registry({ id: cnCreateCallForm() });
registry.set('ParticipantsList', withRegistry(participantsListRegistry)(ParticipantsList));
registry.set('UserCallControl', withRegistry(userCallControlRegistry)(UserCallControl));

export const CreateCallFormMobile = withRegistry(registry)(CreateCallFormBase);

export default CreateCallFormMobile;
