import { withBemMod } from '@bem-react/core';

import { Spin } from '@yandex-lego/components/Spin/bundle';
import React, { FC, ReactElement, ReactNode, useMemo } from 'react';

import { cnTable } from '../Table';
import { TableProps } from '../Table.interface';

import { WithLoadingProps } from './Table_loading.interface';
import './Table_loading.css';

const withLoading = withBemMod<WithLoadingProps, TableProps>(
    cnTable(),
    { loading: true },
    (Table): FC<WithLoadingProps & TableProps> => (
        (props: WithLoadingProps & TableProps): ReactElement => {
            const {
                isRequesting,
                isTableEmpty,
                addonAfter,
                addonBefore,
                isFirstLoading,
            } = props;

            const spinner = useMemo((): ReactNode => (
                <div className={cnTable('Spinner')}>
                    <Spin
                        size="l"
                        view="default"
                        progress
                    />
                </div>
            ), []);

            const paginationSpinner = useMemo((): ReactNode => (
                <div className={cnTable('Pagination')}>
                    <Spin
                        size="s"
                        view="default"
                        progress
                    />
                </div>
            ), []);

            const addonBeforeWithLoading = isRequesting && (isTableEmpty || isFirstLoading) ? spinner : addonBefore;
            const addonAfterWithLoading = isRequesting && !isTableEmpty ? paginationSpinner : addonAfter;

            return (
                <Table
                    {...props}
                    addonBefore={addonBeforeWithLoading}
                    addonAfter={addonAfterWithLoading}
                />
            );
        }
    ),
);

export default withLoading;
