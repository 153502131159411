import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import { useKhTitle } from 'hooks/useKhTitle';

import { KhErrorState } from 'khural-components/KhErrorState';

import { cnKhAnnouncementScreen } from '../KhAnnouncementScreen.cn';
import { KhAnnouncementScreenProps } from '../KhAnnouncementScreen.interface';
import { WithErrorProps } from './KhAnnouncementScreen_error.interface';
import { i18n } from '../KhAnnouncementScreen.translations';

import gooseError from '../assets/goose-error.png';

import './KhAnnouncementScreen_error.css';

export const withError = withBemMod<WithErrorProps, KhAnnouncementScreenProps>(
    cnKhAnnouncementScreen(),
    { error: true },
    (KhAnnouncementScreen): FC<WithErrorProps & KhAnnouncementScreenProps> => (
        (props): ReactElement => {
            const {
                responseError,
            } = props;

            let {
                replacer,
            } = props;

            if (responseError) {
                let errorCode = 'smth-went-wrong';

                if (responseError.code === 404) {
                    errorCode = 'not-found';
                }

                useKhTitle([i18n('error')]);

                replacer = (
                    <KhErrorState
                        size="m"
                        image={gooseError}
                        title={i18n(`error.${errorCode}.title`)}
                        description={i18n(`error.${errorCode}.description`)}
                        className={cnKhAnnouncementScreen('ErrorState')}
                    />
                );
            }

            return (
                <KhAnnouncementScreen
                    {...props}
                    replacer={replacer}
                />
            );
        }
    ),
);
