import { withBemMod } from '@bem-react/core';
import i18n from '@yandex-int/i18n';
import { compose, composeU } from '@bem-react/core';
import { Icon as IconBase } from '@yandex-lego/components/Icon/desktop';
import React, { FC, ReactElement } from 'react';
import {
    withTypeJabber as withIconTypeJabber,
    withTypeCisco as withIconTypeCisco,
    withSizeM as withIconSizeM,
} from 'components/Icon';

import { cnMethodSelector } from '../../MethodSelector';
import { MethodSelectorOptionContentProps } from '../MethodSelector-OptionContent.interface';
import './MethodSelector-OptionContent_type_cisco.css';
import * as keyset from '../../MethodSelector.i18n';

export interface WithTypeCisco {
    type?: 'cisco';
}

const Icon = compose(
    withIconSizeM,
    composeU(
        withIconTypeJabber,
        withIconTypeCisco,
    ),
)(IconBase);

const i18nMethodSelector = i18n(keyset);

export const withTypeCisco = withBemMod<WithTypeCisco, MethodSelectorOptionContentProps>(
    cnMethodSelector('OptionContent'),
    { type: 'cisco' },
    (OptionContent): FC<WithTypeCisco & MethodSelectorOptionContentProps> => (
        (props: WithTypeCisco & MethodSelectorOptionContentProps): ReactElement => {
            return (
                <OptionContent {...props}>
                    <span>
                        <Icon
                            type="jabber"
                            size="m"
                            className={cnMethodSelector('OptionContentIcon', { type: 'jabber' })}
                        />
                        <span className={cnMethodSelector('OptionContentText')}>Cisco Jabber</span>
                    </span>
                    <span>
                        <Icon
                            type="cisco"
                            size="m"
                            className={cnMethodSelector('OptionContentIcon', { type: 'cisco' })}
                        />
                        <span className={cnMethodSelector('OptionContentText')}>
                            {i18nMethodSelector('cisco_phone')}
                        </span>
                    </span>
                </OptionContent>
            );
        }
    ),
);
