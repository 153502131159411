import { Selector } from 'reselect';
import { createAction, handleActions } from 'redux-actions';
import { StoreI } from '../store';

const HIDE = 'vconf/header/HIDE';
const SHOW = 'vconf/header/SHOW';

export const hideHeader = createAction(HIDE);
export const showHeader = createAction(SHOW);

export interface Header {
    isVisible: boolean
}

export const initialState = {
    isVisible: true,
};

export const reducer = handleActions<Header>(
    {
        [HIDE]: (state): Header => ({
            ...state,
            isVisible: false,
        }),

        [SHOW]: (state): Header => ({
            ...state,
            isVisible: true,
        }),
    },
    initialState,
);

export const getIsHeaderVisible: Selector<StoreI, boolean> = ({ header }): boolean => header.isVisible;
