import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { unregister } from 'registerServiceWorker';

import store from 'ducks/store';

import { GlobalContextRegistry } from 'components/GlobalContextRegistry';

import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';

import './util/rum';
import './util/error-counter';
import './index.css';
import './shared/ui/font/fonts.css';

ReactDOM.render(
    <Provider store={store}>
        <GlobalContextRegistry />
    </Provider>,
  document.getElementById('root') as HTMLElement,
);

// FIXME удалить перед релизом
unregister();
