import { composeU, withBemMod } from '@bem-react/core';
import React, { FC, ReactElement, useMemo } from 'react';

import {
    StreamPoster as StreamPosterBase,
    withSizeL as withStreamPosterSizeL,
    withSizeM as withStreamPosterSizeM,
    withSizeS as withStreamPosterSizeS,
} from 'components/StreamPoster';

import { WithTypeUpcoming } from './StreamsList_type_upcoming.interface';
import { StreamsListProps } from '../StreamsList.interface';
import { cnStreamsList } from '../StreamsList';
import { getSize } from '../StreamsList.util';
import { MAX_STREAMS } from '../StreamsList.constants';

const StreamPoster = composeU(
    withStreamPosterSizeL,
    withStreamPosterSizeM,
    withStreamPosterSizeS,
)(StreamPosterBase);

export const withTypeUpcoming = withBemMod<WithTypeUpcoming, StreamsListProps>(
    cnStreamsList(),
    { type: 'upcoming' },
    (StreamsList): FC<WithTypeUpcoming & StreamsListProps> =>
        (props: WithTypeUpcoming & StreamsListProps): ReactElement => {
            const {
                streams,
                isAllStreamsVisible,
                getNavigationCallback,
                onActionsStreamPosterClick,
                onViewersCounterClick,
                onStreamPosterDescriptionClick,
                onStreamPosterTitleTextClick,
                onHintClick,
            } = props;

            const posters = useMemo(() => {
                const buffer = [];

                for (let i = 0; i < streams.length; i++) {
                    if (!isAllStreamsVisible && i >= MAX_STREAMS) break;

                    const stream = streams[i];
                    buffer.push((
                        <StreamPoster
                            type="upcoming"
                            key={stream.id}
                            size={getSize(i, streams.length) as undefined}
                            description={stream.streamDescription}
                            name={stream.name}
                            info={stream.streamDescription}
                            templateId={stream.id}
                            eventId={stream.nextEvent.id}
                            streamPosterUrl={stream.streamPicture}
                            startTime={stream.nextEvent.startTime}
                            onStreamPosterClick={getNavigationCallback(`/template/${stream.id}/stream`)}
                            onActionsStreamPosterClick={onActionsStreamPosterClick}
                            onViewersCounterClick={onViewersCounterClick}
                            onStreamPosterDescriptionClick={onStreamPosterDescriptionClick}
                            onStreamPosterTitleTextClick={onStreamPosterTitleTextClick}
                            onHintClick={onHintClick}
                        />
                    ));
                }

                return buffer;
            }, [
                getNavigationCallback,
                isAllStreamsVisible,
                onActionsStreamPosterClick,
                onHintClick,
                onStreamPosterDescriptionClick,
                onStreamPosterTitleTextClick,
                onViewersCounterClick,
                streams,
            ]);

            return (
                <StreamsList
                    {...props}
                    posters={posters}
                />
            );
        },
);
