import { toast, ToastContainer } from 'react-toastify';
import React, { ReactElement } from 'react';

import { connect } from 'react-redux';

import { StoreI } from 'ducks/store';
import {
    INotificationProps,
    NotificationStateToPropsI,
} from './Notifications.interface';
import { getNotification } from './Notifications.selector';

export class Notifications extends React.Component<INotificationProps> {
  public lastMessageTime = 0

  public componentDidUpdate(): void {
      const { props } = this;

      if (props.notification.ts !== this.lastMessageTime) {
          const { notification } = props;
          this.lastMessageTime = notification.ts;
          const { message } = notification;
          toast[notification.type](message);
      }
  }

  public render(): ReactElement {
      return <ToastContainer />;
  }
}

export default connect<NotificationStateToPropsI>(
    (store: StoreI): NotificationStateToPropsI => ({
        notification: getNotification(store),
    }),
)(Notifications);
