import dayjs from 'dayjs';
import { compose } from '@bem-react/core';
import React, {
    FC,
    KeyboardEventHandler,
    MouseEventHandler,
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { connect } from 'react-redux';

import {
    get as getCallTemplates,
    clear as clearCallTemplates,
    selectData as selectCallTemplatesData,
    selectIsRequesting as selectCallTemplatesIsRequesting,
} from 'ducks/templates';
import {
    setCallForm,
    selectData as selectDataCallForm,
} from 'ducks/createCallForm';
import {
    request as requestCalendarEventMeta,
    selectCalendarEventMeta,
    selectCalendarEventMetaIsRequesting,
} from 'ducks/calendarEventMeta';
import { StoreI } from 'ducks/store';
import { selectCurrentUser } from 'ducks/user';

import { CallTemplate } from 'components/CallTemplate/CallTemplate.interface';
import { parseQuery } from 'components/CreateCallForm/CreateCallForm.util';

import { TemplateList as TemplateListViewBase } from './TemplateList';
import { withLoading as withTemplateListLoading } from './_loading/TemplateList_loading';
import {
    TemplateListContainerProps,
    TemplateListContainerMapDispatchToProps,
    TemplateListContainerMapStateToProps,
} from './TemplateList.interface';
import { sortRelatedTemplates } from './TemplateList.util';

const TemplateListView = compose(
    withTemplateListLoading,
)(TemplateListViewBase);

const TemplateListContainer: FC<TemplateListContainerProps> = (props: TemplateListContainerProps): ReactElement => {
    const {
        templates,
        calendarEventMeta,
        isRequestingCalendarEventMeta,
        callFormData,
        user,
        isTemplatesRequesting,
        onTemplateClick,
        getCallTemplates: getCallTemplatesAction,
        setCallForm: setCallFormAction,
        clearCallTemplates: clearCallTemplatesAction,
        requestCalendarEventMeta: requestCalendarEventMetaAction,
        className,
    } = props;

    const { eventId } = parseQuery();

    const [disabledTemplateId, setDisabledTemplateId] = useState<number>(null);

    useEffect((): FunctionVoid => {
        if (!eventId) {
            getCallTemplatesAction();
        }

        return (): void => {
            clearCallTemplatesAction();
        };
    }, [clearCallTemplatesAction, eventId, getCallTemplatesAction]);

    const applyTemplate = useCallback((template: CallTemplate): void => {
        if (eventId && eventId === template.eventId) {
            setDisabledTemplateId(template.id);

            requestCalendarEventMetaAction({
                eventId,
                templateId: template.id,
            });
        } else {
            setCallFormAction({
                ...template,
                templateId: template.id,
                eventId: eventId || null,
                name: `${template.name} ${dayjs().format('DD.MM.YYYY')}`,
                isPrivateStream: false,
            });
        }
    }, [eventId, requestCalendarEventMetaAction, setCallFormAction]);

    const getTemplateClickHandler = useCallback((template: CallTemplate): MouseEventHandler => {
        return (): void => {
            const selection = window.getSelection();
            if (!selection.toString().length) {
                onTemplateClick();
                applyTemplate(template);
            }
        };
    }, [applyTemplate, onTemplateClick]);

    const getTemplateKeyDownHandler = useCallback((template: CallTemplate): KeyboardEventHandler => {
        return (event): void => {
            if (event.key !== 'Tab') {
                onTemplateClick();
                applyTemplate(template);
            }
        };
    }, [applyTemplate, onTemplateClick]);

    const handleParticipantsClick = useCallback((event): void => {
        event.stopPropagation();
    }, []);

    /**
     * Выбор источника для шаблонов
     * Если нет eventId в параметрах страницы, то выбираем шаблоны из ручки /templates
     * Если есть eventId, то выбираем шаблоны из руки /calls/create/meta и сортируем их
     */

    let templatesList = useMemo(() => {
        if (eventId && calendarEventMeta) {
            return sortRelatedTemplates(
                calendarEventMeta.relevantTemplates,
                calendarEventMeta.event.eventExternalId,
                user.id,
            );
        }

        return templates;
    }, [calendarEventMeta, eventId, templates, user.id]);

    return (
        <TemplateListView
            templates={templatesList}
            getTemplateClickHandler={getTemplateClickHandler}
            getTemplateKeyDownHandler={getTemplateKeyDownHandler}
            onParticipantsClick={handleParticipantsClick}
            className={className}
            appliedTemplateID={callFormData.templateId}
            disabledTemplateId={isRequestingCalendarEventMeta ? disabledTemplateId : null}
            loading={(isRequestingCalendarEventMeta || isTemplatesRequesting) && !templatesList.length}
        />
    );
};

export const TemplateList = connect<TemplateListContainerMapStateToProps, TemplateListContainerMapDispatchToProps>(
    (store: StoreI): TemplateListContainerMapStateToProps => ({
        templates: selectCallTemplatesData(store),
        calendarEventMeta: selectCalendarEventMeta(store),
        isRequestingCalendarEventMeta: selectCalendarEventMetaIsRequesting(store),
        isTemplatesRequesting: selectCallTemplatesIsRequesting(store),
        callFormData: selectDataCallForm(store),
        user: selectCurrentUser(store),
    }),
    {
        getCallTemplates,
        setCallForm,
        clearCallTemplates,
        requestCalendarEventMeta,
    },
)(TemplateListContainer);

export default TemplateList;
