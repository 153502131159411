import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';
import './Icon_type_share.css';

export interface WithTypeShareProps {
  type?: 'share';
}

export const withTypeShare = withBemMod<WithTypeShareProps>(
    'Icon',
    { type: 'share' },
    (Icon): FC => ((props): ReactElement => (<Icon {...props} />)),
);

export default withTypeShare;
