import { CallFormAPI } from 'ducks/createCallForm';
import { CallForm } from './CreateCallForm.interface';

export const createCallFormMock: CallForm = {
    name: 'CallFormName',
    record: false,
    stream: false,
    templateId: null,
    eventId: null,
    duration: 60,
    participants: [],
    isPrivateStream: false,
};

export const createCallFormApiMock: CallFormAPI = {
    name: 'CallFormName',
    record: false,
    stream: false,
    template_id: null,
    event_id: null,
    duration: 60,
    participants: [],
    is_private_stream: false,
};

export default createCallFormMock;
