import { ApiErrorI } from 'util/api.rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { createSelector } from 'reselect';
import { Action, createAction, handleActions } from 'redux-actions';

import { CalendarEvent, CalendarEventApi } from 'components/CalendarEvents/CalendarEvents.interface';

import { StoreI } from 'ducks/store';

import { INotificationMessageType } from 'components/Notifications/Notifications.interface';
import { notyError } from 'components/Notifications/Notifications.actions';
import { convertCalendarEventApiResponse } from './calendarEvents.util';

const REQUEST = 'vconf/calendarEvents/REQUEST';
const RECEIVE_SUCCESS = 'vconf/calendarEvents/RECEIVE_SUCCESS';
const RECEIVE_ERROR = 'vconf/calendarEvents/RECEIVE_ERROR';
const CLEAR = 'vconf/calendarEvents/CLEAR';

export type ReceiveCalendarEventsSuccessPayload = CalendarEventApi[];

export const requestCalendarEvents = createAction<null>(REQUEST);
export const receiveCalendarEventsSuccess = createAction<ReceiveCalendarEventsSuccessPayload>(RECEIVE_SUCCESS);
export const receiveCalendarEventsError = createAction<null>(RECEIVE_ERROR);
export const clearCalendarEvents = createAction<null>(CLEAR);

export interface CalendarEventsState {
  isRequesting: boolean;
  events: CalendarEvent[];
}

export const initialState: CalendarEventsState = {
    isRequesting: false,
    events: [],
};

export const reducer = handleActions<CalendarEventsState, ReceiveCalendarEventsSuccessPayload | null>(
    {
        [REQUEST]: (state): CalendarEventsState => ({
            ...state,
            isRequesting: true,
        }),

        [RECEIVE_SUCCESS]: (state, action: Action<ReceiveCalendarEventsSuccessPayload>): CalendarEventsState => {
            const { payload } = action;

            return {
                ...state,
                isRequesting: false,
                events: payload.map(event => convertCalendarEventApiResponse(event)),
            };
        },

        [RECEIVE_ERROR]: (state): CalendarEventsState => ({
            ...state,
            isRequesting: false,
        }),

        [CLEAR]: (): CalendarEventsState => ({
            ...initialState,
        }),
    },
    initialState,
);

export const epic = combineEpics(
    (action$: Observable<Action<null>>,
        $state: StoreI,
        { calendarEventsApi: { getCalendarEvents } }): Observable<Action<ReceiveCalendarEventsSuccessPayload>> =>
        action$.pipe(
            ofType(REQUEST),
            mergeMap((): Observable<Action<ReceiveCalendarEventsSuccessPayload>> =>
                getCalendarEvents().pipe(
                    map((response): Action<ReceiveCalendarEventsSuccessPayload> => {
                        const possiblyErrorResponse = response as ApiErrorI;

                        if (possiblyErrorResponse.error) {
                            throw possiblyErrorResponse;
                        }

                        return receiveCalendarEventsSuccess(response as ReceiveCalendarEventsSuccessPayload);
                    }),
                    catchError((error: ApiErrorI): Observable<Action<INotificationMessageType | null>> => of(
                        notyError(error.message),
                        receiveCalendarEventsError(null),
                    )),
                ),
            ),
        ),
);

export const selectCalendarEvents = (store: StoreI): CalendarEventsState => store.calendarEvents;

export const selectEvents = createSelector(
    selectCalendarEvents,
    ({ events }): CalendarEvent[] => events,
);

export const selectIsRequesting = createSelector(
    selectCalendarEvents,
    ({ isRequesting }): boolean => isRequesting,
);
