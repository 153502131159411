import React, { ReactElement, FC } from 'react';

import { cnTableCell } from '../TableCell';
import { ButtonProps } from './Button.interface';

export const Button: FC<ButtonProps> = (props: ButtonProps): ReactElement => {
    const {
        addonAfter,
        children,
        className,
    } = props;

    return (
        <div className={cnTableCell('Button', [className])}>
            {children}
            {addonAfter}
        </div>
    );
};
