import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import React, { useCallback, FC, ReactElement } from 'react';
import { BackLink as BackLinkView } from './BackLink';
import {
    BackLinkContainerProps,
} from './BackLink.interface';

const BackLinkContainer: FC<BackLinkContainerProps> = (props: BackLinkContainerProps): ReactElement => {
    const {
        className,
        title,
        push: routerPush,
        url,
    } = props;

    const HandleClick = useCallback((): void => {
        routerPush(url);
    }, [routerPush, url]);

    return (
        <BackLinkView
            title={title}
            onClick={HandleClick}
            className={className}
      />
    );
};

export const BackLink = connect(null, { push })(BackLinkContainer);

export default BackLink;
