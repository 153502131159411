import React, { FC, ReactElement } from 'react';

import { ViewersCounter } from 'components/ViewersCounter';

import { StreamScreenTitleProps } from './StreamScreen-Title.interface';
import { cnStreamScreen } from '../StreamScreen.cn';
import { i18nStreamScreen } from '../StreamScreen';
import './StreamScreen-Title.css';

export const Title: FC<StreamScreenTitleProps> = (props): ReactElement => {
    const {
        name,
        viewersCount,
    } = props;

    return (
        <div className={cnStreamScreen('Title')}>
            <h1 className={cnStreamScreen('Text')}>{name}</h1>
            <ViewersCounter
                iconTone="gray"
                count={viewersCount}
                title={i18nStreamScreen('viewers-counter-title')}
                className={cnStreamScreen('ViewersCounter')}
            />
        </div>
    );
};
