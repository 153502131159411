import { compose, withBemMod } from '@bem-react/core';
import i18n from '@yandex-int/i18n';
import { Icon as IconBase } from '@yandex-lego/components/Icon/desktop';
import React, { FC, ReactElement } from 'react';

import {
    withSizeS as withIconSizeS,
    withTypeStreamEllipse as withIconTypeStreamEllipse,
} from 'components/Icon';
import { Duration } from 'components/Duration';

import { WithTypeCurrentProps } from './Duration_type_current.interface';
import { StreamPosterDurationProps } from '../StreamPoster-Duration.interface';
import { cnStreamPoster } from '../../StreamPoster';
import * as keyset from '../../StreamPoster.i18n';
import './Duration_type_current.css';

const i18nStreamPoster = i18n(keyset);

const Icon = compose(
    withIconSizeS,
    withIconTypeStreamEllipse,
)(IconBase);

export const withDurationTypeCurrent = withBemMod<WithTypeCurrentProps, StreamPosterDurationProps>(
    'StreamPoster-Duration',
    { type: 'current' },
    (StreamPosterDuration): FC<WithTypeCurrentProps & StreamPosterDurationProps> =>
        (props: WithTypeCurrentProps & StreamPosterDurationProps): ReactElement => {
            const {
                startTime,
            } = props;

            return (
                <StreamPosterDuration {...props}>
                    <div className={cnStreamPoster('StartTime')}>
                        {i18nStreamPoster('started at')} <Duration startingPointISOString={startTime} /> {i18nStreamPoster('ago')}
                    </div>
                    <Icon
                        size="s"
                        type="streamEllipse"
                        className={cnStreamPoster('StreamEllipse')}
                        title={i18nStreamPoster('stream-is-on')}
                    />
                </StreamPosterDuration>
            );
        },
);
