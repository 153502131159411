import { Registry, withRegistry } from '@bem-react/di';
import { compose } from '@bem-react/core';

import {
    Button as ButtonBase,
    withThemeClear as withButtonThemeClear,
    withSizeM as withButtonSizeM,
} from '@yandex-lego/components/Button/desktop';
import { Icon as IconBase } from '@yandex-lego/components/Icon';
import React, { ReactElement } from 'react';

import {
    withSizeM as withIconSizeM,
    withTypeComment as withIconTypeComment,
} from 'components/Icon';

import {
    StreamScreen as StreamScreenBase,
    registryId,
} from './StreamScreen';
import { ButtonChatLinkProps } from './StreamScreen.interface';
import { cnStreamScreen } from './StreamScreen.cn';
import { i18nStreamScreen } from './StreamScreen';

const Button = compose(
    withButtonThemeClear,
    withButtonSizeM,
)(ButtonBase);

const Icon = compose(
    withIconTypeComment,
    withIconSizeM,
)(IconBase);

const iconRender = (iconClassName: string): ReactElement =>
    <Icon type="comment" size="m" className={iconClassName} />;

export const registry = new Registry({ id: registryId });
registry.set('ChatLink', (props: ButtonChatLinkProps): ReactElement =>(
    <Button
        theme="clear"
        size="m"
        iconLeft={iconRender}
        onClick={props.navigateToChat}
        className={cnStreamScreen('ChatLink')}
    >
        {i18nStreamScreen('chat-button')}
    </Button>
));
export const StreamScreenDesktop = withRegistry(registry)(StreamScreenBase);
