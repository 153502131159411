import { withBemMod } from '@bem-react/core';
import i18n from '@yandex-int/i18n';

import { Button } from '@yandex-lego/components/Button/desktop/bundle';
import React, { FC, ReactElement } from 'react';

import { TemplateFormScreenProps } from '../TemplateFormScreen.interface';
import { cnTemplateFormScreen } from '../TemplateFormScreen';
import * as keyset from '../TemplateFormScreen.i18n';

export interface WithTypeCreateProps {
  type?: 'create';
  onCreateButtonClick?: FunctionVoid;
}

const i18nTemplateFormScreen = i18n(keyset);

export const withTypeCreate = withBemMod<WithTypeCreateProps, TemplateFormScreenProps>(
    cnTemplateFormScreen(),
    { type: 'create' },
    (TemplateFormScreen): FC<TemplateFormScreenProps & WithTypeCreateProps> => (
        (props: TemplateFormScreenProps & WithTypeCreateProps): ReactElement => {
            const {
                onCreateButtonClick,
                isTemplateActionRequesting,
                templateName,
                templateParticipants,
                templateOwners,
                templateEventId,
            } = props;
            const isButtonDisabled =
              isTemplateActionRequesting ||
              templateName === '' ||
              (templateParticipants.length === 0 && !templateEventId) ||
              templateOwners.length === 0;

            const actionButton = (
                <Button
                    view="action"
                    size="m"
                    onClick={onCreateButtonClick}
                    disabled={isButtonDisabled}
                    className={cnTemplateFormScreen('ControlButton', { type: 'action' })}
                >
                    {i18nTemplateFormScreen('button-create')}
                </Button>
            );

            return (
                <TemplateFormScreen
                    {...props}
                    actionButton={actionButton}
                    title={i18nTemplateFormScreen('title-create')}
        />
            );
        }
    ),
);

export default withTypeCreate;
