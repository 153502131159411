import { cn } from '@bem-react/classname';
import { compose, composeU } from '@bem-react/core';
import { useComponentRegistry } from '@bem-react/di';
import i18n from '@yandex-int/i18n';

import { Link } from '@yandex-lego/components/Link/desktop/bundle';
import { Button as ButtonBase } from '@yandex-lego/components/Button/desktop/bundle';
import {
    Icon as IconBase,
    withTypeClose as withIconTypeClose,
} from '@yandex-lego/components/Icon/desktop';
import React, { FC, ReactElement, useCallback, useState } from 'react';
import { withResponsive as withButtonResponsive } from 'components/Button';
import {
    withTypeUpdate as withIconTypeUpdate,
    withTypeEndCall as withIconTypeEndCall,
    withToneWhite as withIconToneWhite,
} from 'components/Icon';

import { ConfirmationModal } from 'components/ConfirmationModal';
import {
    CopyText as CopyTextBase,
    withIconChain,
    withIconSquare,
    withReverse,
} from 'components/CopyText';

import { DisconnectedParticipants } from './DisconnectedParticipants';
import { Header } from './Header';

import {
    ActiveCallScreenProps,
} from './ActiveCallScreen.interface';
import * as keyset from './ActiveCallScreen.i18n';
import './ActiveCallScreen.css';

const i18nActiveCallScreen = i18n(keyset);

const CopyText = compose(
    composeU(
        withIconChain,
        withIconSquare,
    ),
    withReverse,
)(CopyTextBase);

const Icon = compose(
    withIconToneWhite,
    composeU(
        withIconTypeClose,
        withIconTypeUpdate,
        withIconTypeEndCall,
    ),
)(IconBase);

const Button = withButtonResponsive(ButtonBase);

export const cnActiveCallScreen = cn('ActiveCallScreen');

export const registryId = cnActiveCallScreen();

const iconClose = (iconClassName: string): ReactElement => (<Icon type="close" className={iconClassName} />);
const iconEndCallToneWhite = (iconClassName: string): ReactElement => (<Icon type="endCall" tone="white" className={iconClassName} />);
const iconEndCall = (iconClassName: string): ReactElement => (<Icon type="endCall" className={iconClassName} />);

export const ActiveCallScreen: FC<ActiveCallScreenProps> = (props: ActiveCallScreenProps): ReactElement => {
    const {
        replacer,
        className,
        call,
        activeParticipantsList,
        isActiveParticipantsListCollapsible,
        user,
        onDeleteActiveParticipant,
        addParticipantsList,
        addParticipantsCount,
        onClearAddParticipants,
        onDeleteAddParticipant,
        onChangeAddParticipantSelectedMethod,
        onSuggestSelect,
        disconnectedParticipantsList,
        disconnectedParticipantsCount,
        onChangeDisconnectedParticipantSelectedMethod,
        onRecallToAllDisconnectedParticipants,
        onRecallToDisconnectedParticipant,
        onRemoveDisconnectedParticipant,
        onAddAllParticipantToCall,
        onEndCall,
        currentUser,
        onDisconnectCurrentUser,
        onAddCurrentUser,
        onChangeCurrentUserSelectedMethod,
        isCallAffectRequesting,
        onToggleParticipantMicrophone,
        onToggleParticipantCamera,
        isDurationChanging,
        onDurationChange,
        onGoToCmsClick,
    } = props;

    const {
        event_id: eventId,
        template_id: templateId,
        author_login: authorLogin,
        start_time: startTime,
        invite_link: inviteLink,
    } = call;

    const {
        ParticipantsList,
        UserCallControl,
        Parameters,
        FormControlButton,
    } = useComponentRegistry(registryId);

    const [isEndCallConfirmationModalVisible, setIsEndCallConfirmationModalVisible] = useState(false);
    const onEndCallCancel = useCallback((): void => {
        setIsEndCallConfirmationModalVisible(false);
    }, []);
    const onEndCallClick = useCallback((): void => {
        setIsEndCallConfirmationModalVisible(true);
    }, []);

    const currentUserType = currentUser.state === 'active' ? 'disconnect' : 'join';

    const calendarEventUrl = new URL('https://calendar.yandex-team.ru/event/');
    calendarEventUrl.searchParams.set('event_id', `${eventId}`);

    return (
        <div className={cnActiveCallScreen(null, [className])}>
            <div className={cnActiveCallScreen('Content')}>
                { replacer || (
                    <>
                        <div className={cnActiveCallScreen('Main')}>
                            <Header
                                title={call.name || call.uri}
                                eventId={eventId}
                            />
                            <Parameters
                                id={call.id}
                                isAdmin={user.is_admin}
                                eventId={eventId}
                                templateId={templateId}
                                calendarEventUrl={calendarEventUrl.toString()}
                                startTime={startTime}
                                uri={call.uri}
                                authorLogin={authorLogin}
                                record={call.record}
                                stream={call.stream}
                                duration={call.duration}
                                isDurationChanging={isDurationChanging}
                                onDurationChange={onDurationChange}
                                onCopyURIText="URI звонка скопирован"
                                onCopyStreamLinkText="Ссылка на трансляцию скопирована"
                                titleCopyCallLink={i18nActiveCallScreen('active-calls-copy-call-link')}
                                titleCalendarAwesome={i18nActiveCallScreen('active-calls-show-redirect-to-calendar')}
                            />
                            <UserCallControl
                                type={currentUserType as undefined}
                                currentUser={currentUser}
                                onAddCurrentUser={onAddCurrentUser}
                                onChangeCurrentUserSelectedMethod={onChangeCurrentUserSelectedMethod}
                                isCallAffectRequesting={isCallAffectRequesting}
                                onDisconnectCurrentUser={onDisconnectCurrentUser}
                                className={cnActiveCallScreen('UserCallControl')}
                            />
                            <div className={cnActiveCallScreen('Participants')}>
                                <div className={cnActiveCallScreen('ParticipantsTitle')}>
                                    <div className={cnActiveCallScreen('ParticipantsTitleText')}>
                                        {i18nActiveCallScreen('participants-title')}
                                    </div>
                                    <Link
                                        view="default"
                                        onClick={onGoToCmsClick}
                                        className={cnActiveCallScreen('CopyInviteLinkButton')}
                                    >
                                        {i18nActiveCallScreen('browser-invite-link-title')}
                                    </Link>
                                    <CopyText
                                        textToCopy={`${inviteLink}`}
                                        title={i18nActiveCallScreen('browser-invite-link-copy-title')}
                                        onCopyText={i18nActiveCallScreen('browser-invite-link-copy-text')}
                                        icon="square"
                                        size="m"
                                        reverse
                                    />
                                </div>
                                <div className={cnActiveCallScreen('ParticipantsContent')}>
                                    <div className={cnActiveCallScreen('AddParticipants')}>
                                        <ParticipantsList
                                            title="Ожидают добавления"
                                            data={addParticipantsList}
                                            onSuggestSelect={onSuggestSelect}
                                            onChangeParticipantSelectedMethod={onChangeAddParticipantSelectedMethod}
                                            onDeleteParticipant={onDeleteAddParticipant}
                                            isListHidden={addParticipantsCount === 0}
                                            type="common"
                                            suggest
                                            placeholder={i18nActiveCallScreen('suggest-placeholder')}
                                        />
                                        {
                                          addParticipantsCount !== 0 && (
                                              <div className={cnActiveCallScreen('AddParticipantsButtons')}>
                                                  <FormControlButton
                                                      theme="action"
                                                      view="classic"
                                                      onClick={onAddAllParticipantToCall}
                                                  >
                                                      Добавить в звонок
                                                  </FormControlButton>
                                                  <FormControlButton
                                                      theme="normal"
                                                      view="classic"
                                                      icon={iconClose}
                                                      onClick={onClearAddParticipants}
                                                      responsive
                                                  >
                                                      Очистить
                                                  </FormControlButton>
                                              </div>
                                          )
                                        }
                                    </div>
                                    <DisconnectedParticipants
                                        disconnectedParticipantsList={disconnectedParticipantsList}
                                        disconnectedParticipantsCount={disconnectedParticipantsCount}
                                        onChangeDisconnectedParticipantSelectedMethod={
                                            onChangeDisconnectedParticipantSelectedMethod
                                        }
                                        onRecallToAllDisconnectedParticipants={onRecallToAllDisconnectedParticipants}
                                        onRecallToDisconnectedParticipant={onRecallToDisconnectedParticipant}
                                        onRemoveDisconnectedParticipant={onRemoveDisconnectedParticipant}
                                    />
                                    <div className={cnActiveCallScreen('ActiveParticipants')}>
                                        <ParticipantsList
                                            title="Активные"
                                            data={activeParticipantsList}
                                            onDeleteParticipant={onDeleteActiveParticipant}
                                            type="active"
                                            isChangeParticipantSelectedMethodDisabled
                                            collapse
                                            isCollapsible={isActiveParticipantsListCollapsible}
                                            onToggleParticipantMicrophone={onToggleParticipantMicrophone}
                                            onToggleParticipantCamera={onToggleParticipantCamera}
                                    />
                                    </div>
                                </div>
                            </div>
                            <div className={cnActiveCallScreen('Controls')}>
                                <FormControlButton
                                    view="pseudo"
                                    size="s"
                                    tone="redLight"
                                    icon={iconEndCallToneWhite}
                                    onClick={onEndCallClick}
                                    responsive
                                >
                                    {i18nActiveCallScreen('end-conference')}
                                </FormControlButton>
                            </div>
                        </div>
                    </>
                )}
            </div>
            <ConfirmationModal
                title={`${i18nActiveCallScreen('end-conference')}?`}
                onOutsideClick={onEndCallCancel}
                visible={isEndCallConfirmationModalVisible}
                className={cnActiveCallScreen('ConfirmationModal')}
                confirmButton={(
                    <Button
                        view="pseudo"
                        size="s"
                        icon={iconEndCall}
                        onClick={onEndCall}
                        responsive
                    >
                        {i18nActiveCallScreen('end')}
                    </Button>
                )}
                cancelButton={(
                    <Button
                        view="pseudo"
                        size="s"
                        onClick={onEndCallCancel}
                    >
                        {i18nActiveCallScreen('cancel')}
                    </Button>
                )}
            />
        </div>
    );
};

export default ActiveCallScreen;
