import React, { ReactElement } from 'react';

import cnHistoryScreen from 'components/HistoryScreen/HistoryScreen.interface';
import { CallsHistory } from 'components/CallsHistory';

import {
    useTheme,
    usePlatformContext,
} from 'hooks';

import './HistoryScreen.css';

export const HistoryScreen = (): ReactElement => {
    const platform = usePlatformContext();
    const theme = platform === 'mobile' ? 'normal' : 'grey';
    useTheme(theme);

    return (
        <div className={cnHistoryScreen()}>
            <div className={cnHistoryScreen('Main')}>
                <div className={cnHistoryScreen('Header')}>
                    <h1 className={cnHistoryScreen('Title')}>История</h1>
                </div>
                <div className={cnHistoryScreen('Content')}>
                    <CallsHistory />
                </div>
            </div>
        </div>
    );
};
