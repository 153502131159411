import { CustomError } from 'ts-custom-error';

export default class HttpError<T> extends CustomError {
    public constructor(
      public code: number,
      message?: string,
      public errorCode?: string,
      public errorDetails?: T,
    ) {
        super(message);
    }
}
