import React, { FC, ReactElement } from 'react';
import { Route, Switch } from 'react-router-dom';

import { Page } from 'components/Page';
import { HomeScreen } from 'components/HomeScreen';
import { ErrorScreen } from 'components/ErrorScreen';
import { CreateCallScreen } from 'components/CreateCallScreen';
import { TemplateFormScreen } from 'components/TemplateFormScreen';
import { HistoryScreen } from 'components/HistoryScreen';
import { StreamsScreen } from 'components/StreamsScreen';
import { ChatScreen } from 'components/ChatScreen';
import { StreamScreen } from 'components/StreamScreen';
import { ActiveCallScreen } from 'components/ActiveCallScreen';
import { CalendarEventRouterScreen } from 'components/CalendarEventRouterScreen';
import { NotFoundScreen } from 'components/NotFoundScreen';
import { InviteCallLinkScreen } from 'components/InviteCallLinkScreen';
import { PermanentLinkScreen } from 'components/PermanentLinkScreen';
import { Clicker } from 'components/Clicker';
import { ClickerZoom } from 'components/ClickerZoom';

export const appRoutes = {
    createCall: '/create-call',
    editTemplate: '/template/:id(\\d+)',
    error: '/error/:code',
    home: '/',
    stream: '/streams/:id',
    permanentLink: '/template/:id(\\d+)/:permanentLinkType(stream|chat)',
    template: '/template',
    callsHistory: '/calls-history',
    streams: '/streams',
    chat: '/streams/:id/chat',
    activeCall: '/active-call/:id',
    calendarEvent: '/events/:id',
    unAuthStub: '/unauth-stub',
    inviteCallLink: '/invite-link',
    clicker: '/clicker',
    clickerZoom: '/clicker-zoom',
};

export const Routes: FC = (): ReactElement => {
    return (
        <>
            <Switch>
                <Route path={appRoutes.home} exact>
                    <Page authRequired>
                        <HomeScreen />
                    </Page>
                </Route>
                <Route path={appRoutes.error} exact>
                    <Page authRequired>
                        <ErrorScreen />
                    </Page>
                </Route>
                <Route path={appRoutes.createCall} exact>
                    <Page authRequired>
                        <CreateCallScreen />
                    </Page>
                </Route>
                <Route path={appRoutes.template} exact>
                    <Page authRequired>
                        <TemplateFormScreen />
                    </Page>
                </Route>
                <Route path={appRoutes.editTemplate} exact>
                    <Page authRequired>
                        <TemplateFormScreen />
                    </Page>
                </Route>
                <Route path={appRoutes.callsHistory} exact>
                    <Page authRequired>
                        <HistoryScreen />
                    </Page>
                </Route>
                <Route path={appRoutes.streams} exact>
                    <Page authRequired>
                        <StreamsScreen />
                    </Page>
                </Route>
                <Route path={appRoutes.chat} exact>
                    <Page authRequired>
                        <ChatScreen />
                    </Page>
                </Route>
                <Route path={appRoutes.stream} exact>
                    <Page authRequired>
                        <StreamScreen />
                    </Page>
                </Route>
                <Route path={appRoutes.permanentLink} exact>
                    <Page authRequired>
                        <PermanentLinkScreen />
                    </Page>
                </Route>
                <Route path={appRoutes.activeCall} exact>
                    <Page authRequired>
                        <ActiveCallScreen />
                    </Page>
                </Route>
                <Route path={appRoutes.calendarEvent} exact>
                    <Page authRequired>
                        <CalendarEventRouterScreen />
                    </Page>
                </Route>
                <Route path={appRoutes.clicker} exact>
                    <Clicker authRequired />
                </Route>
                <Route path={appRoutes.clickerZoom} exact>
                    <ClickerZoom />
                </Route>
                <Route path={appRoutes.inviteCallLink} exact>
                    <Page>
                        <InviteCallLinkScreen />
                    </Page>
                </Route>
                <Route path="*" exact>
                    <Page authRequired>
                        <NotFoundScreen />
                    </Page>
                </Route>
            </Switch>
        </>
    );
};

export default Routes;
