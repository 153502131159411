import React, { ReactElement, FC } from 'react';
import { withBemMod } from '@bem-react/core';
import { Button } from '@yandex-lego/components/Button/desktop/bundle';

import { cnKhStreamScreen } from '../../KhStreamScreen.cn';
import { i18n } from '../../KhStreamScreen.translations';
import { KhStreamScreenRateInformerProps } from '../KhStreamScreen-RateInformer.interface';

import { WithActionsProps } from './KhStreamScreen-RateInformer_actions.interface';

export const withActions = withBemMod<WithActionsProps, KhStreamScreenRateInformerProps>(
    cnKhStreamScreen('RateInformer'),
    { actions: true },
    (KhStreamScreenRateInformer): FC<WithActionsProps & KhStreamScreenRateInformerProps> => (
        (props: WithActionsProps & KhStreamScreenRateInformerProps): ReactElement => {
            const {
                isActionButtonDisabled,
                isClearButtonDisabled,
                onClearButtonClick,
                onActionButtonClick,
            } = props;

            const actionButtons = (
                <>
                    <Button
                        onClick={onClearButtonClick}
                        view="clear"
                        size="m"
                        disabled={isClearButtonDisabled}
                    >
                        {i18n('rate-informer-action-clear')}
                    </Button>
                    <Button
                        onClick={onActionButtonClick}
                        view="action"
                        size="m"
                        disabled={isActionButtonDisabled}
                    >
                        {i18n('rate-informer-action-submit')}
                    </Button>
                </>
            );

            return (
                <KhStreamScreenRateInformer
                    {...props}
                    actionButtons={actionButtons}
                />
            );
        }
    ),
);
