import { IClassNameProps } from '@bem-react/core';
import { ReactElement } from 'react';
import { Stream, ErrorDetails as StreamErrorDetails } from 'ducks/stream';

export type ResponseError = Stream['responseError'];
export type EventDetails = StreamErrorDetails['event'];

export enum STATE {
    LOADING = 'loading',
    DISPLAY_NEXT_EVENT = 'display-next-event',
    ERROR = 'error',
    REDIRECT = 'redirect',
}

export enum PERMANENT_LINK_TYPE {
    STREAM = 'stream',
    CHAT = 'chat',
}

export type PermanentLinkScreenContainerProps = IClassNameProps;

export interface PermanentLinkScreenProps extends IClassNameProps {
    replacer?: ReactElement;
    event?: EventDetails;
    permanentLinkType: PERMANENT_LINK_TYPE;
}

export interface PermanentLinkScreenContainerMatchParams {
    id: string;
    permanentLinkType: PERMANENT_LINK_TYPE;
}
