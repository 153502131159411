import apiRxjs, { ApiErrorI } from 'util/api.rxjs';
import { Observable } from 'rxjs';
import { CalendarEventApi } from 'components/CalendarEvents/CalendarEvents.interface';

export const getCalendarEvents = (): Observable<CalendarEventApi[] | ApiErrorI> =>
    apiRxjs.get('/frontapi/events/');

export interface CalendarEventsApi {
  getCalendarEvents: () => Observable<CalendarEventApi[] | ApiErrorI>;
}

export const calendarEventsApi = {
    getCalendarEvents,
};
