import { compose, withBemMod } from '@bem-react/core';

import {
    Popup as PopupPresenter,
    withViewDefault,
    withTargetAnchor,
    Direction,
} from '@yandex-lego/components/Popup/desktop';

import {
    IButtonProps,
} from '@yandex-lego/components/Button/desktop';
import React, { FC, ReactElement, ReactNode, RefObject } from 'react';

const Popup = compose(
    withViewDefault,
    withTargetAnchor,
)(PopupPresenter);

export interface WithPopupProps extends IButtonProps {
  popup?: true
  popupContent?: ReactNode
}

export const withPopup = withBemMod<WithPopupProps>(
    'Button2',
    { popup: true },
    (Button): FC<WithPopupProps> => ((props: WithPopupProps): ReactElement => {
        const { popupContent, popup, ...rest } = props;

        const [isPopupVisible, setIsPopupVisible] = React.useState(false);
        const togglePopupVisibility = React.useCallback((): void => {
            setIsPopupVisible((prevState): boolean => !prevState);
        }, []);

        const shareLinkRef = React.useRef<HTMLDivElement>();

    interface AnchorProps {
      target: 'anchor',
      anchor: RefObject<HTMLDivElement>,
      direction: Direction[],
    }

    const anchorProps: AnchorProps = {
        target: 'anchor',
        anchor: shareLinkRef,
        direction: ['bottom-end', 'top-end', 'bottom-end', 'top-end'],
    };

    if (window.innerWidth < 768) {
        anchorProps.direction = ['bottom', 'top'];
    }

    return (
        <>
            <Button
                onClick={togglePopupVisibility}
                innerRef={shareLinkRef}
                {...rest}
        />
            <Popup
                visible={isPopupVisible}
                onClose={togglePopupVisibility}
                hasTail
                keepMounted={false}
                view="default"
                {...anchorProps}
            >
                { popupContent }
            </Popup>
        </>
    );
    }),
);

export default withPopup;
