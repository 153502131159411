import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import React, { FC, ReactElement, ReactText } from 'react';

import { BubbleTypePersonData } from './_type/Bubble_type_person';
import { BubbleTypeRoomData } from './_type/Bubble_type_room';
import { BubbleTypeCounterData } from './_type/Bubble_type_counter';

import './Bubble.css';

export const cnBubble = cn('Bubble');

export type BubbleData = BubbleTypePersonData | BubbleTypeRoomData | BubbleTypeCounterData

export type BubbleTypes = 'room' | 'person' | 'arbitrary' | 'counter'

export interface BubbleProps extends IClassNameProps {
  data?: BubbleData
  theme?: 'clear'
  children?: ReactText | ReactElement<HTMLImageElement>
}

export const Bubble: FC<BubbleProps> = (props: BubbleProps): ReactElement => {
    const {
        className,
        children,
    } = props;

    return (
        <div className={cnBubble({}, [className])}>
            <div className={cnBubble('Content')}>
                { children }
            </div>
        </div>
    );
};

export default Bubble;
