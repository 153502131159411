import { compose } from '@bem-react/core';
import i18n from '@yandex-int/i18n';
import { cn } from '@bem-react/classname';
import React, { FC, ReactElement } from 'react';

import {
    CopyText as CopyTextBase,
    withIconSquare,
    withReverse,
} from 'components/CopyText';

import { TemplateFormScreenCopyLinkProps } from './TemplateFormScreen.interface';
import * as keyset from './TemplateFormScreen.i18n';

const cnTemplateFormScreen = cn('TemplateFormScreen');

const i18nTemplateFormScreen = i18n(keyset);

const CopyText = compose(
    withIconSquare,
    withReverse,
)(CopyTextBase);

export const CopyLink: FC<TemplateFormScreenCopyLinkProps> = (props: TemplateFormScreenCopyLinkProps): ReactElement => {
    const { isStreamSwitcherOn, templateId, eventId, type } = props;

    if (isStreamSwitcherOn && eventId) {
        const permanentLink = new URL(`${window.location.origin}/template/${templateId}/${type}`);

        return (
            <CopyText
                textToCopy={`${permanentLink}`}
                textToDisplay={i18nTemplateFormScreen(`copy-permanent-${type}-link-text`)}
                onCopyText={i18nTemplateFormScreen('permanent-link-copied')}
                icon="square"
                className={`${cnTemplateFormScreen('CopyPermanentLink')}`}
                reverse
            />
        );
    }

    return null;
};

export default CopyLink;
