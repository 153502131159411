import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';
import './Icon_type_arrowLeft.css';

export interface WithTypeArrowLeftProps {
  type?: 'arrowLeft';
}

export const withTypeArrowLeft = withBemMod<WithTypeArrowLeftProps>(
    'Icon',
    { type: 'arrowLeft' },
    (Icon): FC => ((props): ReactElement => (<Icon {...props} />)),
);

export default withTypeArrowLeft;
