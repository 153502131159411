import React, { ReactElement, FC } from 'react';
import { withBemMod } from '@bem-react/core';

import { cnKhStreamScreen } from '../../KhStreamScreen.cn';
import { i18n } from '../../KhStreamScreen.translations';
import { KhStreamScreenRateInformerProps } from '../KhStreamScreen-RateInformer.interface';

import { WithSuccessProps } from './KhStreamScreen-RateInformer_success.interface';

export const withSuccess = withBemMod<WithSuccessProps, KhStreamScreenRateInformerProps>(
    cnKhStreamScreen('RateInformer'),
    { success: true },
    (KhStreamScreenRateInformer): FC<WithSuccessProps & KhStreamScreenRateInformerProps> => (
        (props: WithSuccessProps & KhStreamScreenRateInformerProps): ReactElement => {
            const replacer = (
                <div className={cnKhStreamScreen('RateInformerTitle', { state: 'success' })}>
                    {i18n('rate-informer-success-title')}
                </div>
            );

            return (
                <KhStreamScreenRateInformer
                    {...props}
                    replacer={replacer}
                />
            );
        }
    ),
);
