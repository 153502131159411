import { withBemMod } from '@bem-react/core';
import { Spin } from '@yandex-lego/components/Spin/desktop/bundle';
import React, { FC, ReactElement } from 'react';

import { InviteCallLinkScreenProps } from '../InviteCallLinkScreen.interface';
import { cnInviteCallLinkScreen } from '../InviteCallLinkScreen';
import './InviteCallLinkScreen_loading.css';

export interface WithLoading {
    loading?: boolean;
}

export const withLoading = withBemMod<WithLoading, InviteCallLinkScreenProps>(
    cnInviteCallLinkScreen(),
    { loading: true },
    (InviteCallLinkScreen): FC<WithLoading & InviteCallLinkScreenProps> => (
        (props: WithLoading & InviteCallLinkScreenProps): ReactElement => {
            const replacer = (
                <Spin
                    view="default"
                    size="l"
                    progress
                />
            );

            return (
                <InviteCallLinkScreen
                    {...props}
                    replacer={replacer}
                />
            );
        }
    ),
);
