import { FC, ReactElement, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import '@yandex-int/xiva-websocket';

import { selectStreamDetails } from 'ducks/stream';
import { selectCurrentUser } from 'ducks/user';
import { StoreI } from 'ducks/store';

import {
    ViewersSocketContainerProps,
    ViewersSocketMapStateToProps,
} from './ViewersSocket.interface';

const ViewersSocketContainer: FC<ViewersSocketContainerProps> = (props): ReactElement => {
    const {
        user,
        stream,
        onReceiveViewersNumber,
    } = props;

    const [socket, setSocket] = useState(null);

    const handleMessage = useCallback(event => {
        const {
            operation,
            message,
        } = event.detail;

        if (operation === 'viewers_count') {
            onReceiveViewersNumber(Number(message));
        }
    }, [onReceiveViewersNumber]);

    useEffect(() => {
        if (!socket && stream.etherBackId) {
            const topic = stream.etherBackId.replace(/_/g, '-');

            const socket = new window.Ya.XIVA({
                api: 'wss://push.yandex-team.ru/v2/subscribe/websocket',
                urlParams: {
                    service: 'vconf',
                    user: `${user.uid}`,
                    topic: topic,
                    client: 'client',
                    session: user.id,
                },
            });

            setSocket(socket);
        }

        return () => {
            if (socket) {
                socket.destruct();
            }
        };
    }, [socket, stream.etherBackId, stream.id, user.id, user.uid]);

    useEffect(() => {
        if (socket) {
            socket.addEventListener('message', handleMessage);
        }

        return () => {
            if (socket) {
                socket.removeEventListener('message', handleMessage);
            }
        };
    }, [handleMessage, socket]);

    return null;
};

export const ViewersSocket = connect<ViewersSocketMapStateToProps>((store: StoreI) => ({
    user: selectCurrentUser(store),
    stream: selectStreamDetails(store),
}), null)(ViewersSocketContainer);
