import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import { cnKhStreamScreen } from '../KhStreamScreen.cn';
import { i18n } from '../KhStreamScreen.translations';
import { KhStreamScreenProps } from '../KhStreamScreen.interface';
import { WithEmptyProps } from './KhStreamScreen_empty.interface';

import './KhStreamScreen_empty.css';

export const withEmpty = withBemMod<WithEmptyProps, KhStreamScreenProps>(
    cnKhStreamScreen(),
    { empty: true },
    (KhStreamScreen): FC<WithEmptyProps & KhStreamScreenProps> => (
        (props: WithEmptyProps & KhStreamScreenProps): ReactElement => {
            const replacer = (
                <div className={cnKhStreamScreen('NoData')}>
                    {i18n('no-data-text')}
                </div>
            );

            return (
                <KhStreamScreen
                    {...props}
                    replacer={replacer}
                />
            );
        }
    ),
);
