import dayjs from 'dayjs';
import { InformerState, KhStreamScreenRateInformerLS } from './KhStreamScreen-RateInformer.interface';

export const LSPrefix = 'KhStreamScreenRateInformer';

export const getInformerLSData = (): KhStreamScreenRateInformerLS => {
    const dataJSON = localStorage.getItem(LSPrefix);

    if (dataJSON) {
        return JSON.parse(dataJSON);
    }

    return {};
};

export const setInformerLSData = (data: KhStreamScreenRateInformerLS) => {
    localStorage.setItem(LSPrefix, JSON.stringify(data));
};

export const clearInformerState = () => {
    const items = getInformerLSData();
    const actualItems: KhStreamScreenRateInformerLS = {};

    for (const [id, item] of Object.entries(items)) {
        const daysAfterInformerClosed = dayjs().diff(dayjs(item.date), 'd');

        if (daysAfterInformerClosed < 1) {
            actualItems[id] = item;
        }
    }

    setInformerLSData(actualItems);
};

export const setInformerState = (id: string, state: InformerState) => {
    const items = getInformerLSData();

    items[id] = {
        state,
        date: dayjs().toISOString(),
    };

    setInformerLSData(items);
};

export const shouldHideInformer = (id: string | null) => {
    if (!id) {
        return true;
    }

    const items = getInformerLSData();

    if (!items[id]) {
        return false;
    }

    const informerState = items[id].state;

    return informerState === InformerState.VIEWED;
};
