import { withBemMod, compose, composeU } from '@bem-react/core';

import { Icon as IconBase } from '@yandex-lego/components/Icon';
import React, { FC, ReactElement } from 'react';
import {
    withSizeM as withIconSizeM,
    withSizeS as withIconSizeS,
    withTypeSquare as withIconTypeSquare,
} from 'components/Icon';

import { WithIconSquareProps } from './CopyText_icon_square.interface';
import { cnCopyText } from '../CopyText';
import { CopyTextProps } from '../CopyText.interface';

const Icon = compose(
    withIconTypeSquare,
    composeU(
        withIconSizeM,
        withIconSizeS,
    ),
)(IconBase);

export const withIconSquare = withBemMod<WithIconSquareProps, CopyTextProps>(
    cnCopyText(),
    { icon: 'square' },
    (CopyText): FC<WithIconSquareProps & CopyTextProps> =>
        (props: WithIconSquareProps & CopyTextProps): ReactElement => {
            const {
                size = 's',
            } = props;

            return (
                <CopyText
                    {...props}
                    addon={(
                        <Icon
                            size={size as undefined}
                            type="square"
                        />
                    )}
                />
            );
        },
);

export default withIconSquare;
