import React, { FC, ReactElement, useCallback, useState, useMemo } from 'react';
import { withBemMod, compose, composeU } from '@bem-react/core';
import { useComponentRegistry } from '@bem-react/di';
import i18n from '@yandex-int/i18n';

import { RadioButton } from '@yandex-lego/components/RadioButton/desktop/bundle';
import { Button } from '@yandex-lego/components/Button/desktop/bundle';
import { Icon as IconBase } from '@yandex-lego/components/Icon/desktop';

import { registryId } from 'components/GlobalContextRegistry/GlobalContextRegistry.constants';
import {
    withTypeFilter as withIconTypeFilter,
    withSizeM as withIconSizeM,
    withToneWhite as withIconToneWhite,
} from 'components/Icon';
import {
    TableCell as TableCellBase,
    withTypeCallName as withTableCellTypeCallName,
    withTypeHistoryActions as withTableCellTypeHistoryActions,
    withTypeCallTime as withTableCellTypeCallTime,
    withTypeCallParticipants as withTableCellTypeCallParticipants,
} from 'components/TableCell';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { ActiveCallI } from 'components/ActiveCalls/ActiveCalls.interface';

import { cnTable } from '../Table';
import { WithTypeHistoryProps, HistoryTableProps } from './Table_type_history.interface';
import * as keyset from '../Table.i18n';
import './Table_type_history.css';

const i18nTable = i18n(keyset);

const TableCell = composeU(
    withTableCellTypeCallName,
    withTableCellTypeHistoryActions,
    withTableCellTypeCallTime,
    withTableCellTypeCallParticipants,
)(TableCellBase);

const Icon = compose(
    withIconTypeFilter,
    withIconToneWhite,
    composeU(
        withIconSizeM,
    ),
)(IconBase);

const TableTypeHistoryContainer = (props: HistoryTableProps): ReactElement => {
    const {
        data,
        withRecordsFilter,
        getOnCollectCallClick,
        onChangeWithRecordFilter,
        SourceTable,
        user,
        isDeleteRecordModalVisible,
        onConfirmDeleteRecord,
        onCancelDeleteRecord,
        onDeleteRecordClick,
    } = props;

    const { StaffName } = useComponentRegistry(registryId);

    const [isFiltersMenuOpen, setIsFiltersMenuOpen] = useState<boolean>(false);

    const onFilterButtonClick = useCallback((): void => {
        setIsFiltersMenuOpen(!isFiltersMenuOpen);
    }, [isFiltersMenuOpen]);

    const actionsNumber = data.find((call: ActiveCallI) => call.event_id) ? 4 : 3;

    const iconFilter = useMemo(() => {
        let filtersIconTone: 'white';

        if (isFiltersMenuOpen) {
            filtersIconTone = 'white';
        }

        return (iconClassName: string): ReactElement => (
            <Icon
                type="filter"
                size="m"
                tone={filtersIconTone}
                className={iconClassName}
            />
        );
    }, [isFiltersMenuOpen]);

    const rows = data.map((call): ReactElement => {
        const urlParams = new URLSearchParams({ secret: call.secret });
        const downloadRecordLink = `/download_record/${call.id}/?${urlParams.toString()}`;
        const playRecordLink = `${downloadRecordLink}&inline=1`;

        return (
            <div
                className={cnTable('Row', { actionsNumber })}
                key={call.id}
            >
                <TableCell
                    type="callTime"
                    startTime={call.start_time}
                    endTime={call.stop_time}
                    withDate
                    withTime
                    className={cnTable('Cell')}
                />
                <TableCell
                    type="callName"
                    name={call.name}
                    uri={call.uri}
                    authorLogin={call.author_login}
                    className={cnTable('Cell')}
                />
                <TableCell className={cnTable('Cell', { type: 'authorLogin' })}>
                    <StaffName id={call.author_login} name={call.author_login} />
                </TableCell>
                <TableCell
                    type="callParticipants"
                    participants={call.participants}
                    className={cnTable('Cell')}
                />
                <TableCell
                    type="historyActions"
                    downloadRecordLink={downloadRecordLink}
                    downloadRecordText={i18nTable('history-button-download-record')}
                    record={call.record}
                    playRecordLink={playRecordLink}
                    playRecordText={i18nTable('history-button-play-record')}
                    onCollectCallClick={getOnCollectCallClick(call)}
                    collectCallText={i18nTable('history-button-recollect-call')}
                    className={cnTable('Cell')}
                    eventId={call.event_id}
                    calendarEventText={i18nTable('show-calendar-awesome')}
                    user={user}
                    isDeleteRecordModalVisible={isDeleteRecordModalVisible}
                    onDeleteRecordClick={onDeleteRecordClick(call.id)}
                />
            </div>
        );
    });

    const header = (
        <div className={cnTable('Header')}>
            <div className={cnTable('FiltersMenuButton')}>
                <Button
                    view="pseudo"
                    size="m"
                    checked={isFiltersMenuOpen}
                    icon={iconFilter}
                    onClick={onFilterButtonClick}
                />
            </div>
            <div className={cnTable('Filters', { filtersMenuOpen: isFiltersMenuOpen })}>
                <RadioButton
                    size="s"
                    view="default"
                    value={withRecordsFilter}
                    onChange={onChangeWithRecordFilter}
                    className={cnTable('FiltersRadioButton')}
                    options={[
                        { value: 'all', children: i18nTable('filters-all') },
                        { value: 'withRecords', children: i18nTable('filters-with-records') },
                    ]}
                />
            </div>
        </div>
    );

    const addonBefore =
        <>
            <div className={cnTable('ContentHeader')}>
                <div className={cnTable('RowTitle')}>{i18nTable('column-title-datetime')}</div>
                <div className={cnTable('RowTitle')}>{i18nTable('column-title-name')}</div>
                <div className={cnTable('RowTitle')}>{i18nTable('column-title-organizer')}</div>
                <div className={cnTable('RowTitle')}>{i18nTable('column-title-participants')}</div>
                <div className={cnTable('RowTitle')}>{i18nTable('column-title-record')}</div>
            </div>
            <div className={cnTable('Content')}>
                { rows }
            </div>
            <ConfirmationModal
                title={`${i18nTable('delete-record-title')}?`}
                confirmButton={(
                    <Button
                        size="m"
                        view="pseudo"
                        onClick={onConfirmDeleteRecord}
                    >
                        {i18nTable('delete-record-confirm-text')}
                    </Button>
                )}
                cancelButton={(
                    <Button
                        size="m"
                        view="pseudo"
                        onClick={onCancelDeleteRecord}
                    >
                        {i18nTable('delete-record-cancel-text')}
                    </Button>
                )}
                visible={isDeleteRecordModalVisible}
            />
        </>;

    return (
        <SourceTable
            {...props}
            header={header}
            addonBefore={addonBefore}
        />
    );
};

const withTypeHistory = withBemMod<WithTypeHistoryProps>(
    cnTable(),
    { type: 'history' },
    (Table): FC<WithTypeHistoryProps> => (outerProps: WithTypeHistoryProps): ReactElement => {
        return (
            <TableTypeHistoryContainer
                SourceTable={Table}
                {...outerProps}
            />
        );
    },
);

export default withTypeHistory;
