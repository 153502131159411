import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import './Icon_type_download.css';

export interface WithTypeDownloadProps {
  type?: 'download';
}

export const withTypeDownload = withBemMod<WithTypeDownloadProps>(
    'Icon',
    { type: 'download' },
    (Icon): FC => ((props): ReactElement => (<Icon {...props} />)),
);

export default withTypeDownload;
