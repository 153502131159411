import { Action, createAction, handleActions } from 'redux-actions';
import { combineEpics, ofType, StateObservable } from 'redux-observable';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, delay } from 'rxjs/operators';
import i18n from '@yandex-int/i18n';

import { INotificationMessageType } from 'components/Notifications/Notifications.interface';
import {
    ChangeSlideType,
    ClickerEvent,
    ClickerMessage,
    ClickerPage,
    SocketEventName,
    UserMessageCodes,
    WebSocketData,
    MessageCodes,
    MessageCode,
} from 'components/Clicker/Clicker.interface';
import { InjectedDependencies, StoreI } from '../store';
import * as keyset from '../../components/Clicker/Clicker.i18n';

const i18nClicker = i18n(keyset);
const MAX_RETRY = 5;

export interface ChangeSlidePayload {
    slide: ChangeSlideType;
    id: string;
    env: string;
}

export interface ChangePagePayload {
    page: ClickerPage;
    id: string;
    env: string;
}

export interface ConnectPayload {
    id: string;
    env: string;
}

export interface DisconnectPayload {
    id: string;
    env: string;
    retry: boolean;
}

export interface SendMassagePayload {
    id: string;
    env: string,
    event: ClickerEvent;
}

export type PayloadType = ChangeSlidePayload |
    ChangePagePayload |
    INotificationMessageType |
    ConnectPayload;

const CONNECT = 'vconf/Clicker/CONNECT';
const CONNECTING = 'vconf/Clicker/CONNECTING';
const CONNECTED = 'vconf/Clicker/CONNECTED';
const DISCONNECT = 'vconf/Clicker/DISCONNECT';
const RECEIVE_MESSAGE = 'vconf/Clicker/RECEIVE_MESSAGE';
const SEND_MESSAGE = 'vconf/Clicker/SEND_MESSAGE';
const SEND_MESSAGE_SUCCESS = 'vconf/Clicker/SEND_MESSAGE_SUCCESS';
const CHANGE_SLIDE = 'vconf/Clicker/CHANGE_SLIDE';
const CHANGE_PAGE = 'vconf/Clicker/CHANGE_PAGE';

export const connectAction = createAction<ConnectPayload>(CONNECT);
export const connectingAction = createAction<WebSocketData>(CONNECTING);
export const connectedAction = createAction<Event>(CONNECTED);
export const disconnectAction = createAction<DisconnectPayload>(DISCONNECT);
export const receiveMessageAction = createAction<ClickerEvent>(RECEIVE_MESSAGE);
export const sendMessageAction = createAction<SendMassagePayload>(SEND_MESSAGE);
export const sendMessageSuccessAction = createAction(SEND_MESSAGE_SUCCESS);
export const changeSlideAction = createAction<ChangeSlidePayload>(CHANGE_SLIDE);
export const changePageAction = createAction<ChangePagePayload>(CHANGE_PAGE);

export const idError = new Error(i18nClicker('idError'));
export const socketError = new Error(i18nClicker('socketError'));
export const connectionClosedError = new Error(i18nClicker('connectionClosedError'));
export const serverUnavailableError = new Error(i18nClicker('serverUnavailableError'));

const UNAUTHORISED_CODE = 1008;

export interface ClickerState {
    messages: Record<string, ClickerMessage>;
    userCodeMapping: Record<string, UserMessageCodes>;
    isLoading: boolean;
    retry: number;
    currentPage: ClickerPage;
}

export const initialState: ClickerState = {
    messages: {},
    userCodeMapping: {},
    isLoading: true,
    retry: 0,
    currentPage: ClickerPage.START,
};

const messageCodes: MessageCodes = {
    [SocketEventName.RIGHT]: ['nextSlide', 'click', 'change'],
    [SocketEventName.LEFT]: ['prevSlide', 'early', 'back'],
};

const getRandomElementFrom = <T>(array: T[]): T => {
    const randomIndex = Math.floor(Math.random() * array.length);

    return array[randomIndex];
};

export const reducer = handleActions<ClickerState, PayloadType>(
    {
        [CONNECTED]: state => ({
            ...state,
            isLoading: false,
            retry: 0,
        }),
        [DISCONNECT]: state => ({
            ...state,
            isLoading: true,
            retry: state.retry + 1,
        }),
        [CHANGE_PAGE]: (state, action: Action<ChangePagePayload>) => ({
            ...state,
            messages: {},
            currentPage: action.payload.page,
        }),
        [RECEIVE_MESSAGE]: (state, action: Action<ClickerEvent>): ClickerState => {
            const { id, event_name, time, login } = action.payload;

            if (id !== undefined && (event_name === SocketEventName.RIGHT || event_name === SocketEventName.LEFT)) {
                const messageCode = state.userCodeMapping[login] && state.userCodeMapping[login][event_name] ||
                    getRandomElementFrom<MessageCode>(messageCodes[event_name]);

                return {
                    ...state,
                    messages: {
                        ...state.messages,
                        [id]: {
                            id,
                            eventName: event_name,
                            isProcessed: false,
                            timestamp: Number(new Date(time)),
                            login,
                            messageCode,
                        },
                    },
                    userCodeMapping: {
                        ...state.userCodeMapping,
                        [login]: {
                            ...state.userCodeMapping[login],
                            [event_name]: messageCode,
                        },
                    },
                };
            }

            if (id !== undefined && event_name === SocketEventName.ECHO && state.messages[id]) {
                return {
                    ...state,
                    messages: {
                        ...state.messages,
                        [id]: {
                            ...state.messages[id],
                            isProcessed: true,
                        },
                    },
                };
            }

            return state;
        },
    },
    initialState,
);

const connectWsEpic = (
    action$: Observable<Action<ConnectPayload>>,
    _: StateObservable<StoreI>,
    { clickerApi: { connectSocket, logError } }: InjectedDependencies,
) =>
    action$.pipe(
        ofType(CONNECT),
        switchMap(action => {
            if (!action.payload.id) {
                return of(logError(idError, 'connectWsEpic'));
            }

            const wsData = connectSocket(action.payload.id, action.payload.env);

            return of(connectingAction(wsData));
        }),
    );

const messageSubscriptionEpic = (
    action$: Observable<Action<WebSocketData>>,
    _: StateObservable<StoreI>,
    { clickerApi: { logError, getAdditionalFromEvent } }: InjectedDependencies,
) =>
    action$.pipe(
        ofType(CONNECTING),
        switchMap(action => {
            return action.payload.webSocketSubject.pipe(
                map(receiveMessageAction),
                catchError((event: CloseEvent) => {
                    console.error(event);

                    const additional = getAdditionalFromEvent(event);
                    logError(socketError, 'messageSubscriptionEpic', additional);

                    return EMPTY;
                }),
            );
        }),
    );

const connectionCheckEpic = (action$: Observable<Action<WebSocketData>>) =>
    action$.pipe(
        ofType(CONNECTING),
        switchMap(action => {
            return action.payload.onOpenSubject.pipe(map(connectedAction));
        }),
    );

const connectionFailEpic = (
    action$: Observable<Action<WebSocketData>>,
    state$: StateObservable<StoreI>,
    { clickerApi: { logError, getAdditionalFromEvent } }: InjectedDependencies,
) =>
    action$.pipe(
        ofType(CONNECTING),
        switchMap(action => {
            return action.payload.onCloseSubject.pipe(map((event: CloseEvent) => {
                if (event.code === UNAUTHORISED_CODE) {
                    window.location.assign(`https://passport.yandex-team.ru/auth?retpath=${encodeURIComponent(window.location.href)}`);
                }

                const additional = getAdditionalFromEvent(event);
                logError(connectionClosedError, 'connectionFailEpic', additional);

                const retry = state$.value.clicker.retry < MAX_RETRY;
                const { id, env } = action.payload;

                return disconnectAction({ id, env, retry });
            }));
        }),
    );

const disconnectEpic = (
    action$: Observable<Action<DisconnectPayload>>,
    state$: StateObservable<StoreI>,
    { clickerApi: { logError } }: InjectedDependencies,
) =>
    action$.pipe(
        ofType(DISCONNECT),
        switchMap(action => {
            const { id, env, retry } = action.payload;
            const delaySeconds = state$.value.clicker.retry * 1000;

            if (retry) {
                return of(connectAction({ id, env })).pipe(delay(delaySeconds));
            }

            return of(logError(serverUnavailableError, 'disconnectEpic'));
        }),
    );

const sendWsMessageEpic = (
    action$: Observable<Action<SendMassagePayload>>,
    state$: StateObservable<StoreI>,
    { clickerApi: { getWsData, logError } }: InjectedDependencies,
) =>
    action$.pipe(
        ofType(SEND_MESSAGE),
        switchMap(action => {
            const { id, env, event } = action.payload;

            const wsData = getWsData(id);

            if (wsData) {
                wsData.webSocketSubject.next(event);
            } else {
                return of(
                    connectAction({ id, env }),
                    logError(socketError, 'sendWsMessageEpic'),
                );
            }

            return of(sendMessageSuccessAction());
        }),
    );

const changeSlideEpic = (
    action$: Observable<Action<ChangeSlidePayload>>,
    $state: StateObservable<StoreI>,
    { clickerApi: { getWsData, logError } }: InjectedDependencies,
): Observable<Action<PayloadType>> =>
    action$.pipe(
        ofType(CHANGE_SLIDE),
        mergeMap((action): Observable<Action<PayloadType>> => {
            const { slide, id, env } = action.payload;

            const wsData = getWsData(id);

            if (!wsData) {
                return of(
                    connectAction({ id, env }),
                    logError(socketError, 'changeSlideEpic'),
                );
            }

            const eventName = slide === ChangeSlideType.NEXT ? SocketEventName.RIGHT : SocketEventName.LEFT;
            const event = { event_name: eventName, time: new Date().toISOString() };

            return of(sendMessageAction({ id, env, event }));
        }),
    );

const changePageEpic = (
    action$: Observable<Action<ChangePagePayload>>,
    $state: StateObservable<StoreI>,
    { clickerApi: { getWsData, logError } }: InjectedDependencies,
): Observable<Action<PayloadType>> =>
    action$.pipe(
        ofType(CHANGE_PAGE),
        mergeMap((action): Observable<Action<PayloadType>> => {
            const { id, env, page } = action.payload;
            const { currentPage } = $state.value.clicker;

            const startingManagement = page === ClickerPage.MAIN && currentPage === ClickerPage.START;
            const endingManagement = page === ClickerPage.START && currentPage === ClickerPage.MAIN;

            if (startingManagement || endingManagement) {
                const wsData = getWsData(id);

                if (!wsData) {
                    return of(
                        connectAction({ id, env }),
                        logError(socketError, 'managementEpic'),
                    );
                }

                const eventName = page === ClickerPage.MAIN ?
                    SocketEventName.START_MANAGEMENT :
                    SocketEventName.END_MANAGEMENT;

                const event = { event_name: eventName, time: new Date().toISOString() };

                return of(sendMessageAction({ id, env, event }));
            }

            return EMPTY;
        }),
    );

export const epic = combineEpics(
    changeSlideEpic,
    changePageEpic,
    connectWsEpic,
    connectionCheckEpic,
    messageSubscriptionEpic,
    sendWsMessageEpic,
    connectionFailEpic,
    disconnectEpic,
);
