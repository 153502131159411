import api from 'util/api';
import { SuggestionInvite, SuggestionPeople } from 'components/Suggest/Suggest.interface';
import { ParticipantBaseI, ParticipantI } from 'components/Participant/Participant.interface';

export interface SuggestResponse {
  people: {
    result: SuggestionPeople[]
  }
  invite: {
    result: SuggestionInvite[]
  }
}

export const getSuggestions = (locale: string, layers: string[], text: string): Promise<SuggestResponse> => {
    const urlParams = new URLSearchParams({
        language: locale,
        version: '2',
        layers: layers.join(','),
        text,
    });

    return fetch(
        `/suggest?${urlParams}`,
        { credentials: 'include' },
    )
        .then((response): Promise<SuggestResponse> => {
            if (response.ok) {
                return response.json();
            }

            const message = response.statusText;
            throw new Error(message);
        })
        .then(data => {
            if (!data.invite) {
                data.invite = {
                    result: [],
                };
            }
            return data;
        }).catch(err => err);
};

export const getMeta = (participants: ParticipantBaseI[]): Promise<ParticipantI[]> =>
    api.post<ParticipantI[]>('/frontapi/calls/meta/', {
        body: JSON.stringify({ participants }),
    });

export default getSuggestions;
