import i18n from '@yandex-int/i18n';
import React, { FC, ReactElement } from 'react';

import { CreateCallForm } from 'components/CreateCallForm';
import { TemplateList } from 'components/TemplateList';
import { TextCollapse } from 'components/TextCollapse';

import { Header } from '../Header';
import { CreateCallScreenContentDesktopProps } from './CreateCallScreen-Content.interface';
import { cnCreateCallScreen } from '../CreateCallScreen';
import './CreateCallScreen-Content.css';
import * as keyset from '../CreateCallScreen.i18n';

const i18nCreateCallScreen = i18n(keyset);

export const CreateCallScreenContentDesktop: FC<CreateCallScreenContentDesktopProps> =
    (props: CreateCallScreenContentDesktopProps): ReactElement => {
        const {
            calendarEventMeta,
            onSetNameValue,
            data,
            onTemplateClick,
        } = props;

        return (
            <>
                <div className={cnCreateCallScreen('Content')}>
                    <Header
                        calendarEventMeta={calendarEventMeta}
                        onSetNameValue={onSetNameValue}
                        name={data.name}
                    />
                    <TextCollapse
                        html={calendarEventMeta?.event?.descriptionHtml}
                        className={cnCreateCallScreen('EventDescription')}
                    />
                    <CreateCallForm className={cnCreateCallScreen('CreateCallForm')} />
                </div>
                <div className={cnCreateCallScreen('Templates')}>
                    <div className={cnCreateCallScreen('TemplatesTitle')}>
                        {i18nCreateCallScreen('templates-title')}
                    </div>
                    <TemplateList
                        className={cnCreateCallScreen('TemplatesList')}
                        onTemplateClick={onTemplateClick}
                    />
                </div>
            </>
        );
    };
