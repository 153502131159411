import apiRxjs, { ApiErrorI } from 'util/api.rxjs';
import { Observable } from 'rxjs-compat';
import { CallTemplate } from 'components/CallTemplate';

export const deleteTemplateAllRequest =
   (id: number): Observable<CallTemplate | ApiErrorI> =>
       apiRxjs.post(`/frontapi/templates/${id}/delete/`);

export const deleteTemplateRequest =
   (id: number): Observable<CallTemplate | ApiErrorI> =>
       apiRxjs.post(`/frontapi/template/${id}/delete/`);
