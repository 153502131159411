export const ru = {
    'name-label': 'Название',
    'duration-label': 'Длительность',
    'participants-title': 'Участники',
    'participants-suggest-placeholder': 'Введите имя, переговорку или внешний номер',
    'stream-label-title': 'Онлайн-трансляция',
    'stream-label-subtitle': 'Возможность следить за звонком без участия',
    'record-label-title': 'Запись звонка',
    'record-label-subtitle': 'Возможность просмотра звонка в истории',
    'create-call-button': 'Позвонить',
    'cancel-button': 'Отменить',
    'create-template-button': 'Создать шаблон',
    'clear-button': 'Очистить',
    'stream-notification': 'Трансляция будет доступна всем сотрудникам',
    'is-private-stream-label-subtitle': 'Эта трансляция не будет выводиться в списке трансляций и будет доступна только по прямой ссылке',
    'is-private-stream-label-title': 'Приватная трансляция',
    'add-participants-from-event-button-text': 'Добавить участников в звонок',
};
