import { composeU, withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import {
    ErrorDetails as InviteCallLinkNotFoundErrorDetails,
} from 'ducks/inviteCallLink';

import {
    CallInfo as CallInfoBase,
    withTypeAuthorized as withCallInfoTypeAuthorized,
    withTypeUnauthorized as withCallInfoTypeUnauthorized,
} from '../CallInfo';
import { InviteCallLinkScreenProps } from '../InviteCallLinkScreen.interface';
import { cnInviteCallLinkScreen } from '../InviteCallLinkScreen';
import './InviteCallLinkScreen_error_notFound.css';

export interface WithErrorNotFound {
    error?: 'notFound';
    errorDetails?: InviteCallLinkNotFoundErrorDetails;
    onCreateCallButtonClick?: FunctionVoid;
}

const CallInfo = composeU(
    withCallInfoTypeAuthorized,
    withCallInfoTypeUnauthorized,
)(CallInfoBase);

export const withErrorNotFound = withBemMod<WithErrorNotFound, InviteCallLinkScreenProps>(
    cnInviteCallLinkScreen(),
    { error: 'notFound' },
    (InviteCallLinkScreen): FC<WithErrorNotFound & InviteCallLinkScreenProps> => (
        (props: WithErrorNotFound & InviteCallLinkScreenProps): ReactElement => {
            const {
                errorDetails,
                onCreateCallButtonClick,
            } = props;

            let callInfoType: 'unauthorized' | 'authorized' = 'unauthorized';

            if (errorDetails.is_authenticated) {
                callInfoType = 'authorized';
            }

            const replacer = (
                <CallInfo
                    type={callInfoType as undefined}
                    event={errorDetails.event}
                    onCreateCallButtonClick={onCreateCallButtonClick}
                />
            );

            return (
                <InviteCallLinkScreen
                    {...props}
                    replacer={replacer}
                />
            );
        }
    ),
);
