import { withBemMod } from '@bem-react/core';
import { useComponentRegistry } from '@bem-react/di';
import React, { FC, ReactElement } from 'react';

import { cnParticipantsList, registryId } from '../ParticipantsList';
import { ParticipantsListProps, ParticipantTypeComponentProps } from '../ParticipantsList.interface';
import { WithTypeRecall } from './ParticipantsList_type_recall.interface';

export const withTypeRecall = withBemMod<WithTypeRecall, ParticipantsListProps>(
    cnParticipantsList(),
    { type: 'recall' },
    (ParticipantsList): FC<WithTypeRecall & ParticipantsListProps> => (
        (props: WithTypeRecall & ParticipantsListProps): ReactElement => {
            const { Participant } = useComponentRegistry(registryId);
            const participantTypes = {
                room: (componentProps: ParticipantTypeComponentProps): ReactElement => {
                    const {
                        item,
                        onRecall,
                        onDelete,
                        onChangeSelectedMethod,
                        isDisabledChangeSelectedMethod,
                    } = componentProps;

                    return (
                        <Participant
                            methodSelect
                            type="room"
                            title={item.name}
                            value={item.method}
                            options={item.action}
                            recall
                            delete
                            onDelete={onDelete}
                            key={item.id}
                            onRecall={onRecall}
                            onChangeSelectedMethod={onChangeSelectedMethod}
                            changeSelectedDisabled={isDisabledChangeSelectedMethod}
                        />
                    );
                },
                person: (componentProps: ParticipantTypeComponentProps): ReactElement => {
                    const {
                        item,
                        onRecall,
                        onDelete,
                        onChangeSelectedMethod,
                        isDisabledChangeSelectedMethod,
                    } = componentProps;

                    return (
                        <Participant
                            methodSelect
                            type="person"
                            id={item.id}
                            title={item.name}
                            value={item.method}
                            options={item.action}
                            recall
                            delete
                            key={item.id}
                            onRecall={onRecall}
                            onDelete={onDelete}
                            onChangeSelectedMethod={onChangeSelectedMethod}
                            changeSelectedDisabled={isDisabledChangeSelectedMethod}
                        />
                    );
                },
                arbitrary: (componentProps: ParticipantTypeComponentProps): ReactElement => {
                    const {
                        item,
                        onDelete,
                        onRecall,
                        onChangeSelectedMethod,
                        isDisabledChangeSelectedMethod,
                    } = componentProps;

                    return (
                        <Participant
                            methodSelect
                            type="arbitrary"
                            title={item.id}
                            options={item.action}
                            value={item.method}
                            recall
                            onRecall={onRecall}
                            delete
                            key={item.id}
                            onDelete={onDelete}
                            onChangeSelectedMethod={onChangeSelectedMethod}
                            changeSelectedDisabled={isDisabledChangeSelectedMethod}
                        />
                    );
                },
                cms: (componentProps: ParticipantTypeComponentProps): ReactElement => {
                    const {
                        item,
                        onDelete,
                        onRecall,
                        onChangeSelectedMethod,
                        isDisabledChangeSelectedMethod,
                    } = componentProps;

                    return (
                        <Participant
                            methodSelect
                            type="cms"
                            name={item.name}
                            options={item.action}
                            value={item.method}
                            recall
                            onRecall={onRecall}
                            delete
                            key={item.name}
                            onDelete={onDelete}
                            onChangeSelectedMethod={onChangeSelectedMethod}
                            changeSelectedDisabled={isDisabledChangeSelectedMethod}
                        />
                    );
                },
            };

            return (
                <ParticipantsList {...props} participantTypeComponents={participantTypes} />
            );
        }
    ),
);

export default withTypeRecall;
