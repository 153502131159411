import React, { FC } from 'react';

import { ErrorState } from 'shared/ui/components/ErrorState';

import { cn } from './KhErrorState.cn';
import { IKhErrorStateProps } from './KhErrorState.interface';

import './KhErrorState.css';

export const KhErrorState: FC<IKhErrorStateProps> = props => {
    const { className } = props;
    return (
        <ErrorState
            {...props}
            className={cn(null, [className])}
        />
    );
};
