import { Observable, of } from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ErrorDetails as StreamErrorDetails } from 'ducks/stream';
import { ErrorDetails as InviteLinkErrorDetails } from 'ducks/inviteCallLink';
import HttpError from './httpError';

const DEFAULT_OPTIONS: RequestInit = {
    credentials: 'same-origin',
};
type IApiMethods = 'get' | 'post' | 'put' | 'delete';

function buildInit(method: string, init?: RequestInit): RequestInit {
    return { method, ...DEFAULT_OPTIONS, ...init };
}

type IApiRequest = <T>(url: string | URL, init?: RequestInit) => Observable<T | ApiErrorI>
type ErrorDetails = StreamErrorDetails | InviteLinkErrorDetails;

interface ApiResponseError {
    code: string
    message: string
    details?: ErrorDetails
}

export interface ApiResponseI<T> {
    response_code: number
    response_text: T | ApiResponseError
}

export interface ApiErrorI {
  error: boolean
  message: string
  response_code: number
  error_code?: string
  error_details?: ErrorDetails
}

const buildRequest = (method: IApiMethods): IApiRequest => <T>(
    url: string | URL,
    init?: RequestInit,
): Observable<T | ApiErrorI> => {
    return fromFetch(String(url), buildInit(method, init)).pipe(
        switchMap(async(response): Promise<ApiResponseI<T>> => {
            const json = await response.json();

            if (response.ok) {
                return json;
            }

            const code = response.status;
            const message = `Error ${response.statusText}`;
            const errorCode = json.response_text.code;
            const errorDetails = json.response_text.details;

            throw new HttpError(code, message, errorCode, errorDetails);
        }),
        map((data: ApiResponseI<T>): T => data.response_text as T),
        catchError((err: HttpError<ErrorDetails>): Observable<ApiErrorI> => of({
            error: true,
            message: err.message,
            response_code: err.code,
            error_code: err.errorCode,
            error_details: err.errorDetails,
        })),
    );
};

const buildJingRequest = (file: File, user: string): Observable<string | ApiErrorI> => {
    const timestamp = Date.now();
    const fileName = encodeURIComponent(`${timestamp}-${file.name}`);
    const jingFilePath = `${user}/${fileName}`;

    return fromFetch(
        `/jing/upload/${jingFilePath}`,
        buildInit('put', {
            body: file,
            headers: {
                'Content-Type': file.type,
                'Content-Length': `${file.size}`,
                Authorization: 'Basic ' + Buffer.from('jing:jing').toString('base64'),
            },
        }),
    ).pipe(
        switchMap(async(response): Promise<ApiResponseI<string>> => {
            if (!response.ok) {
                const code = response.status;
                const message = `Error ${response.statusText}`;
                const errorCode = `${response.status}`;
                const errorDetails = `Error ${response.statusText}`;

                throw new HttpError(code, message, errorCode, errorDetails);
            }

            return {
                response_code: response.status,
                response_text: `/jing/files/${jingFilePath}`,
            };
        }),
        map((data: ApiResponseI<string>): string => data.response_text as string),
        catchError((err: HttpError<ErrorDetails>): Observable<ApiErrorI> => of({
            error: true,
            message: err.message,
            response_code: err.code,
            error_code: err.errorCode,
            error_details: err.errorDetails,
        })),
    );
};

const api = {
    get: buildRequest('get'),
    post: buildRequest('post'),
    delete: buildRequest('delete'),
    jing: buildJingRequest,
};

export default api;
