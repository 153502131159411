import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import './Button_tone_green.css';

export interface WithToneGreenProps {
  tone?: 'green';
}

export const withToneGreen = withBemMod<WithToneGreenProps>('Button2', { tone: 'green' }, (Button): FC => ((props): ReactElement => (<Button {...props} />)));

export default withToneGreen;
