import React, { FC, ReactElement, useMemo } from 'react';
import { compose, composeU } from '@bem-react/core';

import { Dropdown } from '@yandex-lego/components/Dropdown/desktop';
import { RadioButton } from '@yandex-int/hr-components/RadioButton/desktop/bundle';
import { IRadioButtonOption } from '@yandex-lego/components/RadioButton/RadioButton';
import { Icon as IconBase } from '@yandex-lego/components/Icon/desktop';
import { withSettingsIcon } from '@yandex-int/hr-components/Icon/_glyph/Settings';
import { withGroupIcon } from '@yandex-int/hr-components/Icon/_glyph/Group';

import { Select } from 'shared/ui/components/Select';
import { Button } from 'shared/ui/components/Button';

import { KhStreamScreenControlsProps } from './KhStreamScreen-Controls.interface';
import { cnKhStreamScreen } from '../KhStreamScreen.cn';
import { i18n } from '../KhStreamScreen.translations';

import './KhStreamScreen-Controls.css';

const Icon = compose(
    composeU(
        withSettingsIcon,
        withGroupIcon,
    ),
)(IconBase);

const iconSettings = (iconClassName: string): ReactElement => (
    <Icon glyph="settings" size="l" className={iconClassName} />
);

export const Controls: FC<KhStreamScreenControlsProps> = (props: KhStreamScreenControlsProps): ReactElement => {
    const {
        playerType,
        viewersCount,
        translatedStreams,
        lang: streamLang,
        onStreamLanguageChanged,
        onPlayerTypeChange,
    } = props;

    const tranlationOptions = useMemo<IRadioButtonOption[]>(() => {
        if (!translatedStreams) {
            return [];
        }

        return Object.keys(translatedStreams).map(
            (lang): IRadioButtonOption => ({
                value: lang,
                children: i18n(`stream-language-${lang}`),
            }),
        );
    }, [translatedStreams]);

    const settingsContent = (
        <>
            {
                tranlationOptions.length > 1 && (
                    <RadioButton
                        className={cnKhStreamScreen('SettingControl', { type: 'streamLanguage' })}
                        value={streamLang}
                        onChange={onStreamLanguageChanged}
                        options={tranlationOptions}
                        size="m"
                        view="default"
                        fullWidth
                    />
                )
            }
            <Select
                className={cnKhStreamScreen('SettingControl', { type: 'playerType' })}
                value={playerType}
                onChange={onPlayerTypeChange}
                options={[
                    {
                        value: 'protected',
                        content: i18n('player_type_protected'),
                    },
                    {
                        value: 'audio',
                        content: i18n('player_type_audio'),
                    },
                ]}
                size="m"
                view="default"
                width="max"
            />
        </>
    );

    return (
        <div className={cnKhStreamScreen('Controls')}>
            <div
                title={i18n('alt-viewers')}
                className={cnKhStreamScreen('ViewersCounter')}
            >
                <Icon glyph="group" size="l" />
                <span className={cnKhStreamScreen('ViewersCounterValue')}>
                    {new Intl.NumberFormat().format(viewersCount)}
                </span>
            </div>
            <div className={cnKhStreamScreen('Control', { type: 'settings' })}>
                <Dropdown
                    content={settingsContent}
                    theme="normal"
                    direction={'top-end'}
                    trigger="click"
                    secondaryOffset={0}
                    className={cnKhStreamScreen('Settings')}
                >

                    <Button
                        size="m"
                        icon={iconSettings}
                        view="clear"
                        title={i18n('alt-settings')}
                />
                </Dropdown>
            </div>
        </div>
    );
};

export default Controls;
