import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

export interface WithToneGrayProps {
  tone?: 'gray';
}

export const withToneGray = withBemMod<WithToneGrayProps>(
    'Icon',
    { tone: 'gray' },
    (Icon): FC => ((props): ReactElement => (<Icon {...props} />)),
);
