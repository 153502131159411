import i18n from '@yandex-int/i18n';
import { push } from 'connected-react-router';
import React, { FC, ReactElement, useCallback } from 'react';
import { connect } from 'react-redux';

import { useTitle } from 'hooks/useTitle';

import { HomeScreenMapDispatchToProps } from './HomeScreen.interface';
import { HomeScreen as HomeScreenView } from './HomeScreen';
import * as keyset from './HomeScreen.i18n';

const i18nHomeScreen = i18n(keyset);

const HomeScreenContainer: FC<HomeScreenMapDispatchToProps> = (props: HomeScreenMapDispatchToProps): ReactElement => {
    const {
        push: pushAction,
    } = props;

    useTitle([i18nHomeScreen('home-screen-title')]);

    const handleCreateCallClick = useCallback((): void => {
        pushAction('/create-call');
    }, [pushAction]);

    return (
        <HomeScreenView
            onCreateCallClick={handleCreateCallClick}
        />
    );
};

export const HomeScreen = connect(
    null,
    {
        push,
    },
)(HomeScreenContainer);
