import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import './Icon_type_phoneAwesome.css';

export interface WithTypePhoneAwesomeProps {
    type?: 'phoneAwesome',
}

export const withTypePhoneAwesome = withBemMod<WithTypePhoneAwesomeProps>(
    'Icon',
    { type: 'phoneAwesome' },
    (Icon): FC => ((props): ReactElement => (<Icon {...props} />)),
);

export default withTypePhoneAwesome;
