import { withBemMod } from '@bem-react/core';
import { useComponentRegistry } from '@bem-react/di';
import React, { FC, ReactElement } from 'react';

import { registryId } from 'components/GlobalContextRegistry/GlobalContextRegistry.constants';
import { YabblePropsI } from '../Yabble.interface';
import { cnYabble } from '../Yabble';
import './Yabble_type_staffName.css';

export interface WithTypeStaffName {
  type?: 'staffName';
}

export const withTypeStaffName = withBemMod<WithTypeStaffName, YabblePropsI>(
    cnYabble(),
    { type: 'staffName' },
    (Yabble): FC<WithTypeStaffName & YabblePropsI> => (
        (props: WithTypeStaffName & YabblePropsI): ReactElement => {
            const {
                title,
                login,
            } = props;

            const { StaffName } = useComponentRegistry(registryId);

            const addon = (
                <StaffName id={login} name={title} />
            );

            return (
                <Yabble
                    {...props}
                    addon={addon}
        />
            );
        }
    ),
);

export default withTypeStaffName;
