import { Registry, withRegistry } from '@bem-react/di';
import { composeU, compose } from '@bem-react/core';

import { Button as ButtonBase } from '@yandex-lego/components/Button/desktop/bundle';
import React, { ReactElement } from 'react';

import {
    withResponsive as withButtonResponsive,
    withToneGreen as withButtonToneGreen,
    withToneRedLight as withButtonToneRedLight,
    withToneYellow as withButtonToneYellow,
} from 'components/Button';

import {
    Participant as ParticipantBase,
    withDelete as withParticipantDelete,
    withMethodSelect as withParticipantMethodSelect,
    withRecall as withParticipantRecall,
    withTypePerson as withParticipantTypePerson,
    withTypeRoom as withParticipantTypeRoom,
    withTypeArbitrary as withParticipantTypeArbitrary,
    withTypeCms as withParticipantTypeCms,
    withControls as withParticipantControls,
} from 'components/Participant/mobile';

import {
    ParticipantsList as ParticipantsListBase,
    withSuggest,
    withCollapse,
    withTypeRecall,
    withTypeCommon,
    withTypeActive,
    registryId as participantsListRegistryId,
} from 'components/ParticipantsList';

import {
    registryId as userCallControlRegistryId,
    UserCallControl as UserCallControlBase,
    withTypeDisconnect as withUserCallControlTypeDisconnect,
    withTypeJoin as withUserCallControlTypeJoin,
} from 'components/UserCallControl';

import { ActiveCallScreenParameters } from './Parameters/ActiveCallScreen-Parameters@mobile';

import {
    ActiveCallScreen as ActiveCallScreenBase,
    registryId as activeCallScreenRegistryId,
} from './ActiveCallScreen';

const Participant = compose(
    withParticipantMethodSelect,
    withParticipantDelete,
    withParticipantRecall,
    withParticipantControls,
    composeU(
        withParticipantTypeRoom,
        withParticipantTypePerson,
        withParticipantTypeArbitrary,
        withParticipantTypeCms,
    ),
)(ParticipantBase);

const Button = compose(
    withButtonResponsive,
    composeU(
        withButtonToneGreen,
        withButtonToneRedLight,
        withButtonToneYellow,
    ),
)(ButtonBase);

const participantsListRegistry = new Registry({ id: participantsListRegistryId });

participantsListRegistry.set('Participant', Participant);

const ParticipantsList = compose(
    withSuggest,
    withCollapse,
    composeU(
        withTypeRecall,
        withTypeCommon,
        withTypeActive,
    ),
)((ParticipantsListBase));

const userCallControlRegistry = new Registry({ id: userCallControlRegistryId });

userCallControlRegistry.set('Participant', Participant);

const UserCallControl = composeU(
    withUserCallControlTypeDisconnect,
    withUserCallControlTypeJoin,
)(UserCallControlBase);

export const registry = new Registry({ id: activeCallScreenRegistryId });

registry.set('ParticipantsList', withRegistry(participantsListRegistry)(ParticipantsList));
registry.set('Participant', Participant);
registry.set('UserCallControl', withRegistry(userCallControlRegistry)(UserCallControl));
registry.set('Parameters', ActiveCallScreenParameters);
registry.set('FormControlButton', (props): ReactElement => (<Button {...props} size="m" />));

export const ActiveCallScreenMobile = withRegistry(registry)(ActiveCallScreenBase);
