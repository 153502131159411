import {
    EventPayload,
    StreamEvent,
} from './stream';

export const convertEventPayload = (payload: EventPayload): StreamEvent => ({
    id: payload.id,
    startTime: payload.start_time,
    endTime: payload.end_time,
    description: payload.description,
});
