export const ru = {
    'title-create': 'Создание шаблона',
    'title-edit': 'Редактирование шаблона',
    'participants-title': 'Участники',
    'name-label': 'Название',
    'name-field-placeholder': 'Введите название шаблона',
    'owners-label': 'Владельцы',
    'record-label-title': 'Запись звонка',
    'record-label-subtitle': 'Возможность просмотра звонка в истории',
    'stream-label-title': 'Онлайн-трансляция',
    'stream-label-subtitle': 'Возможность следить за звонком без участия',
    'stream-description-label-title': 'Описание трансляции',
    'stream-description-label-subtitle': 'Описание трансляции отображается во всплывающей подсказке на обложке трансляции',
    'stream-description-placeholder': 'Введите описание трансляции',
    'stream-picture-label-title': 'Обложка трансляции',
    'stream-picture-label-subtitle': 'Рекомендуемый размер картинки - {posterSize}px',
    'stream-picture-mobile-alert': 'Для редактирования обложки перейдите на десктопную версию VConf',
    cancel: 'Отменить',
    'button-create': 'Создать',
    'button-update': 'Обновить',
    'stub-title': 'Шаблон недоступен',
    'stub-description': 'Шаблона с таким id не существует, или вы не являетесь владельцем этого шаблона',
    'back-link-title': 'На главную',
    'participants-suggest-placeholder': 'Введите имя, переговорку или внешний номер',
    'owners-suggest-placeholder': 'Введите имя',
    'eventId-label': 'Встреча',
    'eventId-field-placeholder': 'Введите id или ссылку на встречу в календаре',
    'copy-permanent-stream-link-text': 'Постоянная ссылка на трансляцию',
    'copy-permanent-chat-link-text': 'Постоянная ссылка на чат',
    'permanent-link-copied': 'Ссылка скопирована',
    'delete-template': 'Удалить шаблон',
    'template-deleting': 'Удаление шаблона',
    'delete-for-all': 'Удалить для всех владельцев',
    'select-file': 'Выберите файл',
};
