import { withBemMod } from '@bem-react/core';
import i18n from '@yandex-int/i18n';
import React, { FC, ReactElement, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import {
    setReportId as setPlayerReportId,
} from 'ducks/streamPlayer';

import { cnPlayer } from '../Player';
import { PlayerProps } from '../Player.interface';
import './Player_type_ether.css';
import * as keyset from '../Player.i18n';

export interface WithTypeEther {
  type?: 'ether';
  etherUrl?: string;
  audioOnly?: boolean;
}

const i18nPlayer = i18n(keyset);

export const withTypeEther = withBemMod<WithTypeEther, PlayerProps>(
    cnPlayer(),
    { type: 'ether' },
    (Player): FC<WithTypeEther & PlayerProps> => (
        (props: WithTypeEther & PlayerProps): ReactElement => {
            const {
                etherUrl,
                audioOnly = false,
            } = props;

            const dispatch = useDispatch();

            const playerIframeRef = useRef(null);

            const srcUrl = new URL(etherUrl); // TODO: Выпилить после https://st.yandex-team.ru/VCONF-602

            srcUrl.host = 'frontend.vh.yandex-team.ru';
            srcUrl.searchParams.append('recommendations', 'off');
            srcUrl.searchParams.append('report', 'false');
            srcUrl.searchParams.append('hidden', 'externalPlayback');
            srcUrl.searchParams.append('live_offset', '10');
            srcUrl.searchParams.append('allow_muted', '0'); // разрешаем автоплей только со звуком

            if (audioOnly) {
                srcUrl.searchParams.append('audio_only', '1');
            }

            useEffect((): () => void => {
                function listener(event: MessageEvent): void {
                    const { data } = event;

                    try {
                        const parsedData = JSON.parse(data);

                        if (parsedData) {
                            if (parsedData.event === 'sendReportLog:return') {
                                dispatch(setPlayerReportId(parsedData.result.reportId));
                            }
                        }
                    } catch (e) {}
                }

                const yandexBug = document.querySelector('.YandexBug');
                const yandexBugListener = () => {
                    playerIframeRef?.current.contentWindow.postMessage({ method: 'sendReportLog' }, '*');
                };

                window.addEventListener('message', listener);
                yandexBug?.addEventListener('click', yandexBugListener);

                return (): void => {
                    window.removeEventListener('message', listener);
                    yandexBug?.removeEventListener('click', yandexBugListener);
                };
            }, [dispatch]);

            return (
                <Player
                    {...props}
                >
                    <iframe
                        ref={playerIframeRef}
                        src={srcUrl.toString()}
                        title={i18nPlayer('iframe-title')}
                        className={cnPlayer('EtherIframe')}
                        allow="autoplay; encrypted-media"
                        allowFullScreen
                    />
                </Player>
            );
        }
    ),
);

export default withTypeEther;
