import React, { FC, ReactElement } from 'react';

import { GlobalContextRegistryDesktop } from './GlobalContextRegistry@desktop';
import { GlobalContextRegistryMobile } from './GlobalContextRegistry@mobile';
import { Platform } from './GlobalContextRegistry.interface';

export const PlatformContext = React.createContext<Platform>('desktop');

export const GlobalContextRegistry: FC = (): ReactElement => {
    const platform = window.innerWidth < 768 ? 'mobile' : 'desktop';

    const GlobalContextRegistryView = platform === 'mobile' ? GlobalContextRegistryMobile : GlobalContextRegistryDesktop;

    return (
        <PlatformContext.Provider value={platform}>
            <GlobalContextRegistryView />
        </PlatformContext.Provider>
    );
};

export default GlobalContextRegistry;
