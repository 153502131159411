import React, { FC, MouseEventHandler, ReactElement } from 'react';
import { composeU, withBemMod } from '@bem-react/core';
import i18n from '@yandex-int/i18n';

import { Tumbler } from '@yandex-lego/components/Tumbler/desktop/bundle';

import { ActiveCallI } from 'components/ActiveCalls/ActiveCalls.interface';
import {
    TableCell as TableCellBase,
    withTypeCallParticipants as withTableCellTypeCallParticipants,
    withTypeActiveCallParameters as withTableCellTypeActiveCallParameters,
    withTypeActiveCallActions as withTableCellTypeActiveCallActions,
} from 'components/TableCell';

import { cnTable } from '../Table';
import { TableProps } from '../Table.interface';
import * as keyset from '../Table.i18n';
import './Table_type_activeCalls.css';
import { INotificationMessageType } from '../../Notifications/Notifications.interface';

export interface WithTypeActiveCallsProps {
  type: 'activeCalls';
  calls: ActiveCallI[];
  checkedAdminCheckbox: boolean;
  onNavigateToActiveCallScreenClick: (id: string, secret: string) => FunctionVoid;
  isAdmin: boolean;
  onCopyLink: (payload: INotificationMessageType) => void;
  onRowClick: (id: string, secret: string) => FunctionVoid;
  onActiveCallParametersClick: MouseEventHandler;
  onBubbleParticipantsClick: MouseEventHandler;
  onTableCellActiveCallActionsClick: MouseEventHandler;
  onShowAllChanged: FunctionVoid;
}

const i18nTable = i18n(keyset);

const TableCell = composeU(
    withTableCellTypeCallParticipants,
    withTableCellTypeActiveCallParameters,
    withTableCellTypeActiveCallActions,
)(TableCellBase);

export const withTypeActiveCalls = withBemMod<WithTypeActiveCallsProps, TableProps>(
    cnTable(),
    { type: 'activeCalls' },
    (Table): FC<TableProps & WithTypeActiveCallsProps> => (
        (props: TableProps & WithTypeActiveCallsProps): ReactElement => {
            const {
                calls,
                onNavigateToActiveCallScreenClick,
                checkedAdminCheckbox,
                isAdmin,
                onCopyLink,
                onRowClick,
                onActiveCallParametersClick,
                onBubbleParticipantsClick,
                onTableCellActiveCallActionsClick,
                onShowAllChanged,
            } = props;

            const header = (
                <div className={cnTable('Header', { admin: isAdmin })}>
                    <h3 className={cnTable('Title')}>{i18nTable('table-active-calls-title')}</h3>
                    {
                      isAdmin && (
                      <div className={cnTable('SwitcherWrapper')}>
                          <div className={cnTable('SwitcherLabel')}>{i18nTable('show-all-active-calls-label')}</div>
                          <div className={cnTable('SwitcherControl')}>
                              <Tumbler
                                  view="default"
                                  size="m"
                                  checked={checkedAdminCheckbox}
                                  onChange={onShowAllChanged}
                              />
                          </div>
                      </div>
                      )
                    }
                </div>
            );

            const rows = calls.map((call): ReactElement => {
                const actionsNumber = call.event_id !== null ? 3 : 2;
                return (
                    <div
                        key={call.id}
                        className={cnTable('Row', { actionsNumber })}
                        onClick={onRowClick(call.id, call.secret)}
                    >

                        <TableCell
                            type="activeCallParameters"
                            id={call.id}
                            name={call.name}
                            templateId={call.template_id}
                            startTime={call.start_time}
                            stream={call.stream}
                            record={call.record}
                            authorLogin={call.author_login}
                            className={cnTable('Cell')}
                            onCopyLink={onCopyLink}
                            onActiveCallParametersClick={onActiveCallParametersClick}
                        />

                        <TableCell
                            type="callParticipants"
                            participants={call.participants}
                            className={cnTable('Cell')}
                            onBubbleParticipantsClick={onBubbleParticipantsClick}
                        />

                        <TableCell
                            type="activeCallActions"
                            id={call.id}
                            secret={call.secret}
                            event_id={call.event_id}
                            onNavigateToActiveCallScreenButtonClick={
                              onNavigateToActiveCallScreenClick(call.id, call.secret)
                            }
                            className={cnTable('Cell')}
                            onTableCellActiveCallActionsClick={onTableCellActiveCallActionsClick}
                        />
                    </div>
                );
            });

            const emptyText = (<div className={cnTable('EmptyText')}>{i18nTable('empty-active-calls-table-text')}</div>);

            const addonAfter = calls.length === 0 ? emptyText : rows;

            return (
                <Table
                    {...props}
                    header={header}
                    addonAfter={addonAfter}
                />
            );
        }
    ),
);

export default withTypeActiveCalls;
