export const en = {
    'collect-call': 'Collect call',
    'call-is-broadcast': 'Broadcast',
    'call-is-recorded': 'Recorded',
    'show-call-details': 'Show call details',
    'table-active-calls-copy': 'Copy link',
    'table-active-calls-copy-call-link': 'Copy call link',
    'table-active-calls-copy-stream-link': 'Streaming (click to copy stream link)',
    'table-active-calls-go-to-stream-page': 'Go to stream page',
    'table-active-calls-stream-copy-message': 'Stream link has been copied',
    'table-active-calls-share-link-copy-message': 'Call link has been copied',
    'show-calendar-awesome': 'Go to calendar event',
    'delete-record-title': 'Delete record',
    'zoom-conf': 'Go to the ZOOM conference',
    'telemost-conf': 'Go to the Telemost conference',
};
