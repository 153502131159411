import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import { cnBubble, BubbleProps } from '../Bubble';

export interface BubbleTypePersonData {
  id: string
}

export interface WithTypePerson {
  type?: 'person'
  data: BubbleTypePersonData
}

const getAvatarUrl = (id: string): string => `https://center.yandex-team.ru/api/v1/user/${id}/avatar/80.jpg`;

export const withTypePerson = withBemMod<WithTypePerson, BubbleProps>(
    cnBubble(),
    { type: 'person' },
    (Bubble): FC<WithTypePerson & BubbleProps> => (props: WithTypePerson & BubbleProps): ReactElement => {
        const { data: { id } } = props;

        const imageSrc = getAvatarUrl(id);

        return (
            <Bubble {...props}>
                <img
                    className={cnBubble('Avatar')}
                    src={imageSrc}
                    alt="avatar"
        />
            </Bubble>
        );
    },
);

export default withTypePerson;
