import { composeU } from '@bem-react/core';
import i18n from '@yandex-int/i18n';
import { Icon as IconBase } from '@yandex-lego/components/Icon';
import { Button as ButtonBase } from '@yandex-lego/components/Button/desktop/bundle';
import React, { FC, ReactElement } from 'react';

import {
    withTypeStreamEllipse as withIconTypeStreamEllipse,
    withTypeCalendarGray as withIconTypeCalendarGray,
    withTypeShareGray as withIconTypeShareGray,
    withTypeCommentGray as withIconTypeCommentGray,
} from 'components/Icon';
import { withPopup as withButtonPopup } from 'components/Button';
import { ShareLinkPopup } from 'components/ShareLinkPopup';

import { StreamPosterActionsProps } from './StreamPoster-Actions.interface';
import { cnStreamPoster } from '../StreamPoster';
import * as keyset from '../StreamPoster.i18n';
import './StreamPoster-Actions.css';

const i18nStreamPoster = i18n(keyset);

const Icon = composeU(
    withIconTypeStreamEllipse,
    withIconTypeCalendarGray,
    withIconTypeShareGray,
    withIconTypeCommentGray,
)(IconBase);

const Button = withButtonPopup(ButtonBase);

const iconCalendarGray = (iconClassName: string): ReactElement => (<Icon type="calendarGray" className={iconClassName} />);
const iconShareGray = (iconClassName: string): ReactElement => <Icon type="shareGray" className={iconClassName} />;
const iconComment = (iconClassName: string): ReactElement => <Icon type="commentGray" className={iconClassName} />;

export const Actions: FC<StreamPosterActionsProps> = (props: StreamPosterActionsProps): ReactElement => {
    const {
        id,
        eventId,
        templateId,
        isTvButtonVisible,
        onActionsStreamPosterClick,
        onChatClick,
        onTvChatClick,
    } = props;

    const calendarEventUrl = new URL('https://calendar.yandex-team.ru/event/');
    calendarEventUrl.searchParams.set('event_id', `${eventId}`);

    const shareLinkPopup = (
        <ShareLinkPopup
            templateId={templateId}
            id={id}
        />
    );

    return (
        <div
            className={cnStreamPoster('Actions')}
            onClick={onActionsStreamPosterClick}
        >
            {
                eventId &&
                    <Button
                        size="s"
                        view="clear"
                        type="link"
                        url={`${calendarEventUrl}`}
                        icon={iconCalendarGray}
                        title={i18nStreamPoster('title-calendar-awesome')}
                        className={cnStreamPoster('ButtonCalendar')}
                    />
            }
            <Button
                size="s"
                view="clear"
                icon={iconShareGray}
                title={i18nStreamPoster('show-share')}
                popup
                popupContent={shareLinkPopup}
                className={cnStreamPoster('ButtonShareLink')}
            />
            {
                id && (
                    <Button
                        size="s"
                        view="clear"
                        icon={iconComment}
                        title={i18nStreamPoster('show-go-to-chat')}
                        onClick={onChatClick}
                        className={cnStreamPoster('ButtonComment')}
                    />
                )
            }
            {
                isTvButtonVisible && id &&
                    <Button
                        size="s"
                        view="clear"
                        title={i18nStreamPoster('show-TV')}
                        onClick={onTvChatClick}
                        className={cnStreamPoster('ButtonTv')}
                    >
                        TV
                    </Button>
            }
        </div>
    );
};
