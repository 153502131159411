import React, { FC, ReactElement } from 'react';

import { CreateCallForm } from 'components/CreateCallForm';
import { TextCollapse } from 'components/TextCollapse';

import { Header } from '../Header';
import { MobileTemplates } from '../MobileTemplates';
import { CreateCallScreenContentMobileProps } from './CreateCallScreen-Content.interface';
import { cnCreateCallScreen } from '../CreateCallScreen';
import './CreateCallScreen-Content.css';

export const CreateCallScreenContentMobile: FC<CreateCallScreenContentMobileProps> =
  (props: CreateCallScreenContentMobileProps): ReactElement => {
      const {
          isTemplatesVisible,
          onTemplatesSwitcherClick,
          onTemplateClick,
          appliedTemplateId,
          calendarEventMeta,
          onSetNameValue,
          data,
      } = props;

      return (
          <div className={cnCreateCallScreen('Content')}>
              <Header
                  calendarEventMeta={calendarEventMeta}
                  onSetNameValue={onSetNameValue}
                  name={data.name}
              />
              <TextCollapse
                  html={calendarEventMeta?.event?.descriptionHtml}
                  className={cnCreateCallScreen('EventDescription')}
              />
              <MobileTemplates
                  visible={isTemplatesVisible}
                  templateApplied={Boolean(appliedTemplateId)}
                  onTemplateClick={onTemplateClick}
                  onTemplatesSwitcherClick={onTemplatesSwitcherClick}
              />
              <CreateCallForm />
          </div>
      );
  };
