import {
    ActiveCallApi,
    ActiveCall,
} from 'components/ActiveCalls/ActiveCalls.interface';

export const convertActiveCallApiResponse = (call: ActiveCallApi): ActiveCall => ({
    id: call.id,
    uri: call.uri,
    secret: call.secret,
    name: call.name,
    templateId: call.template_id,
    eventId: call.event_id,
    chat: call.chat,
    chatId: call.chat_id,
    stream: call.stream,
    streamId: call.stream_id,
    record: call.record,
    state: call.state,
    startTime: call.start_time,
    duration: call.duration,
    stopTime: call.stop_time,
    authorLogin: call.author_login,
    inviteLink: call.invite_link,
    participants: call.participants,
    info: call.info,
    streamPicture: call.stream_picture,
    streamDescription: call.stream_description,
    viewersCount: call.viewers_count,
});
