import { withBemMod } from '@bem-react/core';
import { Spin } from '@yandex-lego/components/Spin/bundle';
import React, { ReactElement, FC } from 'react';
import { WithLoadingProps } from './StreamScreen_loading.interface';

import { StreamScreenProps } from '../StreamScreen.interface';
import { cnStreamScreen } from '../StreamScreen.cn';

import './StreamScreen_loading.css';

export const withLoading = withBemMod<WithLoadingProps, StreamScreenProps>(
    cnStreamScreen(),
    { loading: true },
    (StreamScreen): FC<WithLoadingProps & StreamScreenProps> => (
        (props: WithLoadingProps & StreamScreenProps): ReactElement => {
            const replacer = (
                <Spin
                    className={cnStreamScreen('Spin')}
                    size="l"
                    view="default"
                    progress
                />
            );

            return (
                <StreamScreen
                    {...props}
                    replacer={replacer}
                />
            );
        }
    ),
);
