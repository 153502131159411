import { withBemMod } from '@bem-react/core';
import i18n from '@yandex-int/i18n';

import { Button } from '@yandex-lego/components/Button/desktop/bundle';
import React, { FC, ReactElement } from 'react';

import { WithPaginationProps } from './Table_pagination.interface';
import { TableProps } from '../Table.interface';
import { cnTable } from '../Table';
import * as keyset from '../Table.i18n';

const i18nTablePagination = i18n(keyset);

export const withPagination = withBemMod<WithPaginationProps, TableProps>(
    cnTable(),
    { pagination: true },
    (Table): FC<WithPaginationProps & TableProps> => (props: WithPaginationProps & TableProps): ReactElement => {
        const { onRequestMore, isTableEmpty } = props;

        const [page, setPage] = React.useState(1);
        const onLoadMore = React.useCallback((): void => {
            const newPage = page + 1;
            setPage(newPage);
            onRequestMore({ page: newPage });
        }, [page, onRequestMore]);

        if (isTableEmpty && page !== 1) {
            setPage(1);
        }

        let addonAfter;

        if (!isTableEmpty) {
            addonAfter = (
                <div className={cnTable('Pagination')}>
                    <Button
                        view="clear"
                        width="max"
                        size="s"
                        onClick={onLoadMore}
                    >
                        { i18nTablePagination('table-show-more') }
                    </Button>
                </div>
            );
        }

        return (
            <Table
                {...props}
                addonAfter={addonAfter}
            />
        );
    },
);

export default withPagination;
