import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import { BubbleParticipantsProps, cnBubbleParticipants } from '../BubbleParticipants';

import './BubbleParticipants_theme_clear.css';

export interface BubbleParticipantsThemeClear extends BubbleParticipantsProps {
  theme: 'clear'
}

const withThemeClear = withBemMod<BubbleParticipantsThemeClear>(
    cnBubbleParticipants(),
    { theme: 'clear' },
    (BubbleParticipants): FC<BubbleParticipantsThemeClear> => (props: BubbleParticipantsThemeClear): ReactElement => (
        <BubbleParticipants {...props} />
    ),
);

export default withThemeClear;
