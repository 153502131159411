import { IParticipantMethods, ParticipantI } from 'components/Participant/Participant.interface';
import { ActiveCallParticipantI } from 'components/ActiveCalls/ActiveCalls.interface';

/**
 * Определяет стандартный способ связи в порядке приоритета: установленный способ связи,
 * дефолтный способ связи из настроек, Яндекс Чаты, первый из возможных способов связи
 * @param {ParticipantI} participant
 * @returns {IParticipantMethods}
 */
export const getConnectionMethod = (participant: ParticipantI): IParticipantMethods => {
    let messenger = participant.action[0];

    if (participant.action.includes('messenger_q')) {
        messenger = 'messenger_q';
    }

    return participant.method || Object(participant.settings).method || messenger;
};

/**
 * Функция определяет, принадлежит ли входной объект типу ActiveCallParticipantI
 * @param {ParticipantI | ActiveCallParticipantI} participant
 * @returns {Boolean}
 */
export const isActiveCallParticipant =
    (participant: ParticipantI | ActiveCallParticipantI): participant is ActiveCallParticipantI => {
        return (participant as ActiveCallParticipantI).camera_active !== undefined;
    };

/**
 * Функция расширяет стандратный объект ParticipantI до ActiveCallParticipantI
 * @param {ParticipantI} participant
 * @returns {ActiveCallParticipantI}
 */
export const extendParticipant = (participant: ParticipantI): ActiveCallParticipantI => ({
    ...participant,
    microphone_active: false,
    camera_active: false,
    state: 'disconnected',
});
