import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import { YabblePropsI } from '../Yabble.interface';
import { cnYabble } from '../Yabble';

export interface WithTypeText {
  type?: 'text';
}

export const withTypeText = withBemMod<WithTypeText, YabblePropsI>(
    cnYabble(),
    { type: 'text' },
    (Yabble): FC<WithTypeText & YabblePropsI> => (
        (props: WithTypeText & YabblePropsI): ReactElement => {
            const {
                title,
            } = props;

            const addon = (
                <span className={cnYabble('Title')}>{title}</span>
            );

            return (
                <Yabble
                    {...props}
                    addon={addon}
        />
            );
        }
    ),
);

export default withTypeText;
