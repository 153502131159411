import {
    CalendarEvent,
    CalendarEventApi,
} from 'components/CalendarEvents/CalendarEvents.interface';

export const convertCalendarEventApiResponse = (event: CalendarEventApi): CalendarEvent => ({
    id: event.id,
    eventExternalId: event.externalId,
    name: event.name,
    startTs: event.startTs,
    endTs: event.endTs,
    rooms: event.rooms,
    call: event.call,
    organizer: event.organizer,
    attendees: event.attendees,
    conferenceUrl: event.conferenceUrl,
    descriptionHtml: event.descriptionHtml,
    description: event.description,
});
