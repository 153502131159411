import { cn } from '@bem-react/classname';
import React, { FC, ReactElement } from 'react';

import { PlayerProps } from './Player.interface';
import './Player.css';

export const cnPlayer = cn('Player');

export const Player: FC<PlayerProps> = (props: PlayerProps): ReactElement => {
    const {
        className,
        children,
    } = props;

    return (
        <div className={cnPlayer(null, [className])}>
            <div
                id="Player"
                className={cnPlayer('Inner')}
        >
                {children}
            </div>
        </div>
    );
};

export default Player;
