export const ru = {
    clicker: 'Кликер',
    subtitle: 'Удаленное переключение слайдов на компьютере ведущего',
    manage: 'Управлять',
    stopManagement: 'Завершить управление',
    next: 'Вперед',
    prev: 'Назад',
    instruction: 'Инструкции',
    nextInstruction: 'Далее',
    skip: 'Пропустить',

    inactivityWarning: 'Вы не переключаете слайды уже {idleMinutes} минут. Через {promptTimeout} секунд режим переключения слайдов будет отключен.',
    socketNotConnected: 'Сокет не подключен. Пожалуйста, попробуйте еще раз.',
    serverUnavailableError: 'Сервер недоступен. Пожалуйста, обновите страницу.',
    connectionClosedError: 'Соединение разорвано',
    socketError: 'Ошибка подключения сокета.',
    idError: 'Id не определен.',
    zoomDetailsError: 'Ошибка загрузки данных zoom',

    nextSlide: 'Следующий слайд',
    click: 'Клик',
    change: 'Переключай',

    prevSlide: 'Предыдущий слайд',
    early: 'Рано',
    back: 'Верни',

    howItWorks: 'Как это работает?',
    howItWorksEmoji1: '🙂',
    howItWorksInfo1: 'Нажмите «Управлять»',
    howItWorksEmoji2: '😮',
    howItWorksInfo2: 'Появятся кнопки «Вперёд» и «Назад», которыми и листаются слайды',
    howItWorksEmoji3: '🤗',
    howItWorksInfo3: 'Листайте слайды',
    howItWorksEmoji4: '😍',
    howItWorksInfo4: 'Если слайды листаются и над кнопками появляется зелёная галочка — всё хорошо',

    please: 'Пожалуйста',
    pleaseEmoji1: '😭',
    pleaseInfo1: 'Не листайте чужие слайды, ждите своего выступления',
    pleaseEmoji2: '😎',
    pleaseInfo2: 'Если слайды во время выступления удаленно не листаются или листаются слишком быстро, попросите ведущего, чтобы он их переключал по старинке',
    pleaseEmoji3: '🌐',
    pleaseInfo3: 'Не выключайте VPN во время своего выступления. Это не повлияет на зум, но повредит работе приложения кликера.',

    whatCouldGoWrong: 'Что может пойти не так?',
    whatCouldGoWrongEmoji1: '🔌',
    whatCouldGoWrongInfo1: 'В списке действий не появляются новые записи — возможно, у вас проблемы со связью',
    whatCouldGoWrongEmoji2: '🚨️',
    whatCouldGoWrongInfo2: 'Вместо зелёных галочек появляются красные восклицательные знаки — приложение у ведущего не обрабатывает команды',
    whatCouldGoWrongEmoji3: '😵‍💫',
    whatCouldGoWrongInfo3: 'Вы видите чужие действия в списке действий — кто-то одновременно с вами управляет переключением',

    initButton: 'Инициализировать',
    enterPin: 'Введите PIN',
};
