import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import { cnParticipantAvatar } from '../ParticipantAvatar';
import { ParticipantAvatarProps } from '../ParticipantAvatar.interface';
import { WithTypeZoomProps } from './ParticipantAvatar_type_zoom.interface';

import zoomAvatar from './assets/zoom.svg';

export const withTypeZoom = withBemMod<WithTypeZoomProps, ParticipantAvatarProps>(
    cnParticipantAvatar(),
    { type: 'zoom' },
    (ParticipantAvatar): FC<ParticipantAvatarProps & WithTypeZoomProps> => (
        (props: ParticipantAvatarProps & WithTypeZoomProps): ReactElement => {
            const image = (
                <img
                    className={cnParticipantAvatar('Image')}
                    src={zoomAvatar}
                    alt="avatar"
        />
            );

            return (
                <ParticipantAvatar
                    {...props}
                    replacer={image}
        />
            );
        }
    ),
);

export default withTypeZoom;
