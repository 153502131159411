import React, { FC, ReactElement } from 'react';

import { StreamPosterDescriptionProps } from './StreamPoster-Description.interface';
import { cnStreamPoster } from '../StreamPoster';
import './StreamPoster-Description.css';

export const Description: FC<StreamPosterDescriptionProps> = (props: StreamPosterDescriptionProps): ReactElement => {
    const {
        description,
        onStreamPosterDescriptionClick,
    } = props;

    return (
        <div
            className={cnStreamPoster('Description')}
            onClick={onStreamPosterDescriptionClick}
        >
            {description}
        </div>
    );
};
