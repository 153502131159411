export const en = {
    'copy-permanent-stream-link-text': 'Permanent link to the stream',
    'copy-permanent-chat-link-text': 'Permanent link to the chat',
    'permanent-link-copied': 'Link copied',
    select: 'Select',
    edit: 'Edit',
    cancel: 'Cancel',
    'delete-all': 'Delete for all owners',
    'template-deleting': 'Template deleting',
    'delete-template': 'Delete template',
    'to-calendar': 'Go to calendar',
    participants: 'Participants',
};
