import React, { FC, ReactElement } from 'react';
import { connect } from 'react-redux';

import { selectCurrentUser } from 'ducks/user';
import { selectFooter } from 'ducks/footer';
import { StoreI } from 'ducks/store';

import {
    FooterContainerProps,
    FooterContainerMapStateToProps,
} from './Footer.interface';
import { Footer as FooterView } from './Footer';

const FooterContainer: FC<FooterContainerProps> = (props: FooterContainerProps): ReactElement => {
    const {
        isFooterVisible,
        user,
    } = props;

    return (
        <FooterView
            isFooterVisible={isFooterVisible}
            user={user}
        />
    );
};

export const Footer = connect<FooterContainerMapStateToProps>(
    (state: StoreI): FooterContainerMapStateToProps => ({
        isFooterVisible: selectFooter(state).isVisible,
        user: selectCurrentUser(state),
    }),
)(FooterContainer);
