import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import './Button_tone_yellow.css';

export interface WithToneYellowProps {
  tone?: 'yellow';
}

export const withToneYellow = withBemMod<WithToneYellowProps>('Button2', { tone: 'yellow' }, (Button): FC => ((props): ReactElement => (<Button {...props} />)));

export default withToneYellow;
