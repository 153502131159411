import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import { ParticipantAvatar } from 'components/ParticipantAvatar';

import { cnBubble, BubbleProps } from '../Bubble';
import './Bubble_type_cms.css';

export interface WithTypeCms {
  type?: 'cms'
}

export const withTypeCms = withBemMod<WithTypeCms, BubbleProps>(
    cnBubble(),
    { type: 'cms' },
    (Bubble): FC => (props): ReactElement => {
        return (
            <Bubble {...props}>
                <ParticipantAvatar />
            </Bubble>
        );
    },
);
