import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';
import './Icon_type_play.css';

export interface WithTypePlayProps {
  type?: 'play';
}

export const withTypePlay = withBemMod<WithTypePlayProps>(
    'Icon',
    { type: 'play' },
    (Icon): FC => ((props): ReactElement => (<Icon {...props} />)),
);

export default withTypePlay;
