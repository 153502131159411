import apiRxjs, { ApiErrorI } from 'util/api.rxjs';
import { Observable } from 'rxjs';

import { CallTemplateAPI } from 'ducks/templateForm';

export const getCallTemplates = (): Observable<CallTemplateAPI[] | ApiErrorI> =>
    apiRxjs.get<CallTemplateAPI[]>('/frontapi/templates/');

export const getCallTemplate = (id: number): Observable<CallTemplateAPI | ApiErrorI> =>
    apiRxjs.get<CallTemplateAPI>(`/frontapi/templates/${id}/`);

export interface TemplatesApi {
  getCallTemplates: () => Observable<CallTemplateAPI[] | ApiErrorI>;
  getCallTemplate: (id: number) => Observable<CallTemplateAPI | ApiErrorI>;
}

export const templatesApi = {
    getCallTemplates,
    getCallTemplate,
};

export default templatesApi;
