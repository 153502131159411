import { cn } from '@bem-react/classname';
import { compose, composeU } from '@bem-react/core';

import { Button } from '@yandex-lego/components/Button/desktop/bundle';

import { Popup } from '@yandex-lego/components/Popup/desktop/bundle';
import { Icon as IconBase } from '@yandex-lego/components/Icon/desktop';
import React, { FC, ReactElement, useCallback, useRef, useState } from 'react';
import {
    withTypeHint as withIconTypeHint,
    withToneBlack as withIconToneBlack,
    withToneWhite as withIconToneWhite,
} from 'components/Icon';

import { HintProps } from './Hint.interface';
import './Hint.css';

const cnHint = cn('Hint');

const Icon = compose(
    withIconTypeHint,
    composeU(
        withIconToneWhite,
        withIconToneBlack,
    ),
)(IconBase);

/**
 * Компонент для отображения кнопки с иконкой подсказки
 * При нажатии открывает Popup с переданным текстом
 *
 * @param {HintProps} props
 * @returns {ReactElement}
 */
export const Hint: FC<HintProps> = (props: HintProps): ReactElement => {
    const {
        text,
        title,
        className,
        iconTone,
        iconActiveTone = 'white',
        onHintClick = () => {},
    } = props;

    const [isActive, setIsActive] = useState<boolean>(false);
    const buttonRef = useRef();

    const onClick = useCallback((e): void => {
        setIsActive(!isActive);
        onHintClick(e);
    }, [isActive, onHintClick]);

    const getButtonIcon = useCallback(
        (iconClassName: string): ReactElement =>
            <Icon type="hint" tone={isActive ? iconActiveTone : iconTone as undefined} className={iconClassName} />,
        [iconActiveTone, iconTone, isActive]);

    return (
        <div className={cnHint(null, [className])}>
            <Button
                view={isActive ? 'default' : 'clear'}
                size="s"
                icon={getButtonIcon}
                onClick={onClick}
                checked={isActive}
                innerRef={buttonRef}
                title={title}
                className={cnHint('Button')}
            />
            <Popup
                visible={isActive}
                view="default"
                anchor={buttonRef}
                target="anchor"
                direction={['right', 'bottom-end']}
                className={cnHint('Popup')}
                mainOffset={8}
                keepMounted={false}
            >
                {text}
            </Popup>
        </div>
    );
};
