import i18n from '@yandex-int/i18n';
import React, { FC, ReactElement } from 'react';

import { BackLink } from 'components/BackLink';

import { TemplateFormScreenHeaderProps } from './TemplateFormScreen-Header.interface';
import { cnTemplateFormScreen } from '../TemplateFormScreen';

import * as keyset from '../TemplateFormScreen.i18n';
import './TemplateFormScreen-Header.css';

const i18nTemplateFormScreen = i18n(keyset);

export const TemplateFormScreenHeaderMobile: FC<TemplateFormScreenHeaderProps> = (
    props: TemplateFormScreenHeaderProps): ReactElement => {
    const {
        title,
        className,
    } = props;

    return (
        <div className={cnTemplateFormScreen('Header', [className])}>
            <BackLink
                title={i18nTemplateFormScreen('back-link-title')}
                url="/"
                className={cnTemplateFormScreen('BackLink')}
      />
            <div className={cnTemplateFormScreen('Title')}>
                {title}
            </div>
        </div>
    );
};

export default TemplateFormScreenHeaderMobile;
