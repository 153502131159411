import apiRxjs, { ApiErrorI } from 'util/api.rxjs';
import { Observable } from 'rxjs';

import { ActiveCallApi } from 'components/ActiveCalls/ActiveCalls.interface';

import { UpcomingStreamApi } from './streams';

export interface RequestStreamsParams {
    limit?: number;
}

const getStreams = (): Observable<ActiveCallApi[] | ApiErrorI> => {
    const queryParams = new URLSearchParams({
        with_stream: 'true',
        show_all: 'true',
        state: 'active',
        sort: '-priority',
    });
    return apiRxjs.get<ActiveCallApi[]>(`/frontapi/calls/?${queryParams}`);
};

const getUpcomingStreams = (params?: RequestStreamsParams): Observable<UpcomingStreamApi[] | ApiErrorI> => {
    let limit: number;

    if (params) {
        limit = params.limit;
    }

    const searchParams = new URLSearchParams();

    if (limit) {
        searchParams.set('limit', String(limit));
    }

    return apiRxjs.get<UpcomingStreamApi[]>(`/frontapi/templates/streams?${searchParams}`);
};

export interface StreamsApi {
    getStreams: typeof getStreams;
    getUpcomingStreams: typeof getUpcomingStreams;
}

export const streamsApi: StreamsApi = {
    getStreams,
    getUpcomingStreams,
};

export default getStreams;
