import { replace } from 'connected-react-router';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { ANNOUNCEMENT_TYPE, STATE } from '../KhAnnouncementScreen.interface';

export const useRedirect = (state: STATE, id: string, announcementType: ANNOUNCEMENT_TYPE) => {
    const dispatch = useDispatch();

    const navigateToChat = useCallback((): void => {
        dispatch(replace(`/streams/${id}/chat`));
    }, [dispatch, id]);

    const navigateToStream = useCallback((): void => {
        dispatch(replace(`/streams/${id}`));
    }, [dispatch, id]);

    useEffect(() => {
        if (state === STATE.REDIRECT) {
            switch (announcementType) {
                case ANNOUNCEMENT_TYPE.STREAM:
                    navigateToStream();
                    break;
                case ANNOUNCEMENT_TYPE.CHAT:
                    navigateToChat();
            }
        }
    }, [announcementType, navigateToChat, navigateToStream, state]);
};
