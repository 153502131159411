import { withBemMod } from '@bem-react/core';
import React, { FC, ReactElement } from 'react';

import ErrorBox from 'components/ErrorBox/ErrorBox';
import { useUserError } from '../../../hooks/useUserError';

import { Spinner } from '../Spinner';
import { PageProps } from '../Page.interface';
import { cnPage } from '../Page';
import './Page_authRequired.css';

export interface WithAuthRequired {
    authRequired?: boolean;
}

export const withAuthRequired = withBemMod<WithAuthRequired, PageProps>(
    cnPage(),
    { authRequired: true },
    (AppLoader): FC<WithAuthRequired & PageProps> => (
        (props: WithAuthRequired & PageProps): ReactElement => {
            const {
                userError,
            } = props;

            let {
                replacer,
            } = props;

            const { isError, isUserUnauthorizedError } = useUserError(userError, true);

            if (isError) {
                const errorReplacer = <ErrorBox className={cnPage('ErrorBox')} code={userError.code} />;

                const spinnerReplacer = <Spinner />;

                replacer = (
                    <div className={cnPage('AuthReplacerWrapper')}>
                        { isUserUnauthorizedError ? spinnerReplacer : errorReplacer }
                    </div>
                );
            }

            return (
                <AppLoader
                    {...props}
                    replacer={replacer}
                />
            );
        }
    ),
);
